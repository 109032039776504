import React from "react";

function SlideItem({ images, slideKey }) {
    // Set the appropriate title based on slideKey
    let sectionTitle = "";
    switch(slideKey) {
        case "SMS":
            sectionTitle = "Slot Management Systems Compatible with ReelMetrics";
            break;
        case "EDW":
            sectionTitle = "Enterprise Data Warehouses Compatible with ReelMetrics";
            break;
        case "BI Suite":
            sectionTitle = "Business Intelligence Suites Compatible with ReelMetrics";
            break;
        default:
            sectionTitle = "Compatible Systems";
    }

    return (
        <div 
            className="compatibility_slide_item" 
            role="group" 
            aria-label={sectionTitle}
            itemScope 
            itemType="https://schema.org/ItemList"
        >
            <meta itemProp="name" content={sectionTitle} />
            <span className="sr-only">{sectionTitle}</span>
            
            {images.map((image, index) => {
                const { src, alt, height } = image;

                return (
                    <img
                        key={alt}
                        id={alt}
                        className={slideKey}
                        src={src}
                        alt={alt}
                        height={`${height}px`}
                        loading="eager"
                        itemProp="image"
                        itemScope
                        itemType="https://schema.org/ImageObject"
                        data-position={index + 1}
                    />
                );
            })}
        </div>
    );
}

export default SlideItem;