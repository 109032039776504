import React from "react";
import ReactGA from "react-ga";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S04E01 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname); 
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S04E01.defaultProps = {
        id: "16697249-s04e01-wind-creek-hospitality-ken-rohman",
        link: "s04e01-wind-creek-hospitality-with-ken-rohman",
        title: "Wind Creek Hospitality with Ken Rohman",
        subTitle: "Season 4, Episode 01",
        description: "In this episode, Nick and Don catch-up with Ken Rohman, Chief Marketing Officer for Wind Creek Hospitality. Learn about the Poarch Creek tribe's growth and expansion into commercial markets, its guest-driven service culture, the Wind Creek Rewards loyalty program, and the licensing, construction, positioning, opening, and early successes of the group's newest resort, Wind Creek Chicago Southland. Also in this episode, Don hangs-up his spurs.",
        episodeNumber: 1,
        seasonNumber: 4,
        datePublished: "2024-07-09",
        topics: [ 
            "Casino property grand opening strategies",
            "Innovative casino floor design concepts",
            "Casino loyalty program evolution",
            "Multi-property casino rewards integration",
            "Tribal and commercial casino market expansion",
            "Casino guest experience differentiation"
        ],    
        keywords: [
            "casino micro-environments, player loyalty programs, casino property design, cross-property rewards, destination gaming experiences, tribal gaming operations, commercial gaming expansion, Chicago casino market, sports partnerships for casinos, casino team culture, guest experience differentiation, community engagement for casinos, daily rewards programs, casino marketing innovations, property amenity integration, luxury casino hotel development, casino entertainment strategies, casino sports betting operations, high-value tournament promotions, casino floor optimization"
        ],    
        buzzSproutLink:
            "https://www.buzzsprout.com/2057836/16697249-s04e01-wind-creek-hospitality-with-ken-rohman",
        spotifyLink:
            "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
        applePodcastsLink:
            "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
        iHeartRadioLink:
            "https://iheart.com/podcast/104029260/",
        cdcLink:
            "https://cdcgaming.com/reelmetrics/s04e01-wind-creek-hospitality-with-ken-rohman",
    
    transcript: `Nick Hogan:
Morning, Don. How are things today in Southern Illinois?
Don Retzlaff:
Morning, Nick. It's a beautiful late winter day. About 65 degrees today, so can't 
beat that for February.
Nick Hogan:
Right on.
Don Retzlaff:
How's things in the Netherlands today?
Nick Hogan:
I don't know if you can see behind me, but it's a beautiful sunny day. I don't know 
what to do with myself. It's too weird for February.
Don Retzlaff:
Sunshine in the Netherlands? That never happens.
Nick Hogan:
Yeah, it doesn't happens. Don, great to be back on the mic with you here today. A 
quick apology to the listeners for our pause there over the Christmas holiday, we 
took a bit of a break. Then we had four, count them, four separate reschedules on 
our January show. As if that wasn't enough heartache, one of us had the nerve to 
actually retire during this whole scramble. To those who missed last month's press 
release, Don officially hung up his spurs on January 1st of this year. He'll be doing 
some projects with us moving forward, but other than that, he's a free man.
Don, we're going to need a download on what you've been up to. But I guess before
we go there, a little anecdote for the listeners. I guess it was last week, I sent Don 
a text about scheduling this show and just asking how everything was going 
generally. Two minutes later my phone dings, and what do I see? A photograph of 
a $500 win on Huff N' Puff Money Mansion. I looked at that and my first thought, 
Don, was right on time. Don, retired life, how's it been treating you so far?
Don Retzlaff:
It's been great so far. We decided to spend New Year's in Vegas, so that was fun. 
That's celebrating my birthday, my retirement with 400,000 of my closest friends 
on the strip. That was fun. Then went to Mexico for the first time, spent eight days 
on a Mexican cruise, and hit all the big ports of call and the Mexican Riviera and 
had a blast down there. The cities were wonderful, the people were great. Other 
than that, it's been a whirlwind for the last eight weeks so far, so having a blast.
Nick Hogan:
Okay. Cool. You have some more travel on the horizon here?
Don Retzlaff:
Do. We've got a couple more trips planned out. We're trying to do something every 
other month.
Nick Hogan:
Okay. Cool. Well, I'll have my phone in hand waiting for some more Huff N' Puff 
photos. I'm afraid I have a bit of some bummer news for our listeners in that this 
month's episode is going to be Don's final episode. I tried everything short of a 
shotgun to keep him on here, but he assured me he has no desire to be a podcaster
when he grows up. I'm lining up some guest hosts who will be filling in for a while 
while we get our new co-host up to speed, but that's where we are.
Don, just a brief pause to tell you your absence, it's really felt here. We all miss you
horribly, and you're an incredible mentor to so many people here. I certainly 
include myself in that. You had a huge impact on our offerings and our culture. 
Honestly, we're just so much better as a result. We're thankful we still get to talk 
to you and see you, but moving from the daily interactions to ad hoc interactions 
has certainly been an adjustment, and we really miss having you in the mix here.
Don Retzlaff:
Well, you're very kind, Nick. I appreciate it. I appreciate you and everybody from 
ReelMetrics reaching out and plus all our different clients and all the people from 
the industry over the last 30 years. Gotten some really neat notes, some fun notes, 
and brought back a lot of neat memories. The time was right and I get to enjoy 
retirement while I can still walk around and do things. It's pretty cool to be able to 
retire in your 60s.
Nick Hogan:
When you put your announcement up on LinkedIn, I don't recall who made the 
crack, but somebody was like, "Yeah, yeah. Well, it's just going to be a few months,
and then we're going to see you run around the floor like a kid in a candy store."
Don Retzlaff:
I know who that was. That was an old friend, Craig Robinson, who actually just 
retired as well.
Nick Hogan:
Okay. Okay. It was cool. Now Don, we did have a few listener questions but they've
grown a bit stale, so I think we'll skip that bit for this month. However, for next 
month, listeners, you know the drill. If you have questions, shoot them to us at 
reelcast@reelmetrics.com. Again, that's at reelcast@reelmetrics.com. Don, time 
for today's guest. This gentleman, a Chicago native originally, Don, is an alumnus 
of both Baylor and Tulane Universities and has had a long and distinguished 
marketing career having served in marketing leadership roles with Outboard 
Marine Corporation, Boats.com, Bass Pro Shops, the Avala Marketing Group, and 
Archer Malmo. These days he serves as a chief marketing officer for Wind Creek 
Hospitality, which in early November of last year opened its new $600 million 
Chicagoland resort, Wind Creek Chicago Southland. I'm speaking of course about 
Mr. Ken Rohman. Ken, how are things with you today?
Ken Rohman:
So far so good, but it's early. We'll see how it unfolds.
Nick Hogan:
All right. Very good. Ken, I thought we could just start off just have you tell us a bit
about yourself, your background and the path that led you to your present role 
there at Wind Creek?
Ken Rohman:
You hit a lot of the highlights in the introduction. I've worked in a lot of different 
industries doing a lot of different things, and I was very happy doing my own thing 
running an ad agency when I got a call from Jay Dorris, our CEO actually from a 
recruiting firm. Wind Creek was looking for a new marketing leader that really 
understood... At that time they were looking for somebody that understood Bass 
Pro's customer experience and how they engage with customers. I had done a 
several-year tour at Bass Pro in Springfield, spinning up their digital group and 
working with their customer experience team for quite a while.
We just hit it off. I really enjoyed hearing where Wind Creek and the tribe was 
headed. It was a really unique value proposition. It's the road less traveled and it 
was really, really inspiring. He's an inspiring leader, it's an inspiring organization, 
and despite not knowing anything about the casino industry, that being one of my 
bigger concerns, we took the gamble. My wife and I moved to South Alabama, and 
I joined the organization in June of 2019, about 30 seconds before the world closed
for COVID, which is a fascinating story all into itself. But it's been a great 
experience working with an organization that's really on a fairly steep trajectory of
growth, and taking its own brand of engaging with gaming customers to a lot of 
new places. It's been a lot of fun.
Nick Hogan:
Now, I am curious on there, so you really moved from more of a mainstream retail 
environment into a casino environment, and this is one thing we spend a lot of time
looking at data standards and analytical standards between those two sectors 
basically. I would assume that you're taking a lot of the learnings that you had out 
of mainstream retail and applying them into the casino business?
Ken Rohman:
I think it's a two-way street. Coming out of graduate school, I was early on in a dot-
com environment that was doing a lot of cutting edge stuff with personal 
experiences, one-to-one marketing. Bass Pro, we were doing very tailored offers, 
understanding customer behaviors and purchase patterns to make predictive 
suggestions to them about what their next purchases ought to be based on loyalty 
programs and stuff we've learned from the database. But the part that appealed to 
me at Wind Creek and what Jay had shared with me about his vision was how far 
down the path Wind Creek already was. What we're doing in rewards, what we're 
doing in loyalty, what they're doing from engaging with customers when they're 
not on property and making sure that you're keeping that top-of-mind experience 
with them wherever a customer is. They are, we are a very forward-thinking 
organization, and so it's merging the two, but it would be folly to think that they 
weren't already well down the directional path of a very similar engagement 
strategy.
Nick Hogan:
Sure. Gotcha. Gotcha.
Don Retzlaff:
Ken, can you give us a brief history of the Poarch Creek Tribe and how it came into
gaming and become such [inaudible 00:09:31]-
Ken Rohman:
Yeah. The Poarch Creek Tribe has been forever in Alabama. From the gaming side 
of it, they were the only federally recognized tribe in Alabama. The gaming side of 
the organization really began in the mid to late 80s. I think 1985, they opened the 
Poarch Creek Bingo Palace in Atmore, so that was the infancy of it. We opened our 
first casino in Alabama, that was Wind Creek Atmore, and then it's just grown like 
crazy. We opened the casinos in Montgomery and Wetumpka. We've grown all over
the place. We have a fairly significant online presence along with the nine full-
service casinos and the two pari-mutuel facilities, so it is really flourished into a 
widely regarded and widely recognized tribal gaming entity with both the tribal 
operations and the commercial operations. It's a nice blend.
Nick Hogan:
Yeah. Let's talk a bit more about that, the commercial markets that the tribe 
entered. We're talking about the Caribbean, Florida, Pennsylvania, and now 
Illinois. Can you give us a sense of the tribe's decision to parlay those successes in 
Alabama and move into that commercial market or into those commercial markets?
When did this occur? What was the order? What challenges did they bump up 
against as they were doing this?
Ken Rohman:
Wind Creek and the tribe itself are purpose-driven organizations. As you go 
through the purpose, which is prevalent, we really do like to provide from the 
beginnings and escape for guests. Escape from your everyday, mundane, ordinary 
and how do we provide you a chance to escape? Escape means different things for 
different peoples, but part of that is the opportunity to allow them to escape into 
one of our facilities and escape online. But it also took form in allowing them to 
escape to other destinations, and the Caribbean certainly was the first opportunity 
that presented itself to us that actually predates me. That deal had been executed 
before I joined.
The properties in Aruba and Curaçao, which they're flagged Renaissance hotels, 
but they're owned by Wind Creek and the casinos are Wind Creek and they're 
affiliated with us, fully integrated with us. That was the first extension of the ability
to allow our guests to escape to those Caribbean properties. As that grew there 
just continued to be opportunities for us to take that experience, which was really 
unique in casino gaming, and see where we could take our approach to customers 
and our approach with team members really being empowered to do the right 
thing for customers and provide a very unique experience on a gaming floor and in 
a hospitality environment.
We were able to expand that, and the opportunity came available for us in 
Pennsylvania. That actually happened right as I was joining. We closed in mid-2019
and re-branded from the Sands to Wind Creek in October right after I joined, so 
about four or five months after I joined. Simultaneously we were going down the 
path of getting the opportunity or [inaudible 00:13:07] for Chicago. It's convenient 
that several of our folks in leadership are Chicago natives and have deep ties to 
Chicago [inaudible 00:13:16] fun for a lot of us to be able to spend some time back 
in the old stomping grounds. That was a long process. The Chicago process was 
probably 10 years in the making when you go through all of it. There's a long 
timeline against that, but it was a nice opportunity for us and we're glad it's open.
Along that road... Actually, much earlier, you'd have to go back on the timeline, we 
did acquire the two pari-mutuel facilities in Mobile and Pensacola. As we were 
getting Chicago up and running, we then had the opportunity to purchase Magic 
City in Miami, which is where I'm at today. That was a nice opportunity for us to 
expand into South Florida a little bit and look at opportunities there. It's a great 
legacy facility that's been in South Florida for a very, very long time and allowed 
us to extend our brand. Again, for guests that want to escape and may not want to 
leave the country, South Florida's a pretty good place to escape to. It was a nice 
next extension.
Then I think we opened in Chicago on November 11th, and nothing like burying 
your own lead sometimes in the press, but I think two days later we announced the
intention to acquire Birmingham Raceway. There was a lot of ink about us over the
course of time. It's been a rocket ride I think is the best way to describe it, as we 
continue to see where there's opportunities to expand our brand of guest 
engagement and escape. We continue to be opportunistic about that, and it's been 
really, really a nice growth for the organization and for our guests.
Nick Hogan:
Great. Just out of curiosity, since you're at Magic City today, so now this was 
formerly... They had a big Highlight facility there, right?
Ken Rohman:
It was. It was a very... It's ebbed and flowed with Highlight, but when we acquired 
it they were operating Highlight again. It's still going on through the end of 2025 I 
believe. We have a Highlight being offered at the facility.
Nick Hogan:
Okay. Cool. Great.
Don Retzlaff:
Well, you mentioned Chicago and obviously Illinois went through the expansion. It 
was a slow moving train there for a while because I was at an Illinois property 
when all this was going on with the expansion between all the Chicago suburbs 
and downstate and all that. Obviously, like you said, it's a very long process. When 
did the tribe decide to move on it? It's very tough to get into this state and secure a
license for Wind Creek.
Ken Rohman:
It's about a 10-year process.
Nick Hogan:
Wow. I had no idea was that long. Wow.
Ken Rohman:
I was chatting with our head of development, our COO, last week when we were all
up in Chicago for some meetings and a little bit in preparation for this. Working 
with the local mayors up there and the local influencers as they went through that. 
We believe we had a really good proposal from the get-go with the location that 
East Hazel Crescent Homewood presented to us right there at 294 on Halstead 
was a phenomenal location, great traffic patterns, great opportunities for economic
development, great opportunities to help really empower economically that 
community. There's a lot of team members that we're going to have from that local 
market. There's a lot of customers in that local market and really bringing 
something very unique to that part of Chicago was really a great opportunity. I 
think part of it was the location and the partners we were able to work with. To 
your point, it was a long process.
You could go back probably 10 years to see the original thoughts on that deal 
where it was going to be an opportunity. 2019 we got our preliminary suitable 
recommendation from the state, and that gave us the opportunity to move forward.
We all know what was happening in 2020 and 2021. COVID really, it slowed down 
the process for a lot of us and a lot of opportunity. But we persevered, we kept 
moving through, and we were very grateful to get the doors open in November this
year of last year. It is been a long ride, but I think we're very, very pleased with the
initial results right now. It's been good for everybody involved I think.
Nick Hogan:
Okay. Cool. Can you give us some of the bona fides on the resort? We're talking 
cost open, number of slots, tables, employees? All that's going on there.
Ken Rohman:
Yeah. Again, as a tribal organization, we're a little conservative about some of that,
but it was north of a half a billion dollars. It was not a cheap project to get the 
front doors open. It was a little over half billion dollars to get it done. We opened it
up on November 11th. I think just over 1400 slot machines in that building right 
now, and 55 or 56 table games. Got that a little over a thousand full-time 
employees. The hotel is slated to open next month. Wait a minute. Slated... Yeah. 
We're February still. Yeah. Next month it's slated to open. 250 hotel rooms. A 

Page 7 of 21
rooftop steakhouse and full service spa. Yeah. It's been a phenomenal facility to 
uncover or to get up and running.
We're really, really happy about the partnership with Fabio Viviani's group. The 
food hall is up and running. All but one entity is currently open. The oyster bar 
should open also next month. We're really, really happy about that. That's been a 
great partnership. His group's also going to run the steakhouse for us on the roof. 
The sports bar is currently open. We're going through the process with IGB now 
about the sports wagering. We're hoping to have that sometime later this spring as
well. We'll be able to have a full service sportsbook again hopefully by this summer
that will be up and running. It's been a great operation, and we're excited about 
where we're at and we're excited about where we're going.
Don Retzlaff:
Awesome. Well, you've built in a very interesting area. Obviously Chicagoland's 
had casinos now for 30 years. Big population, solid economics, still a fairly 
constrained slot market, but you're also going against some really big established 
players. Penn has several casinos in the area, Caesars, Boyd, Rush Street, they 
have all had loyalty programs going on for 25 plus years in the Chicago market. 
How are you taking on these large corporations and how are you differentiating 
yourself within the local market?
Ken Rohman:
You forgot Hard Rock.
Don Retzlaff:
And Hard Rock [inaudible 00:20:41].
Nick Hogan:
They're there as well.
Ken Rohman:
But your point is well taken, it was a very unique challenge. We were a new 
entrant to a market as a brand, not just as a building, if you will. Again, there are 
some stalwarts with high brand recognition and deep loyalty roots in that market. 
Again, for any of us that are longtime Chicago residents or natives understand 
there's some deep loyalties there. I think we were aggressive and we were 
deliberate to start to get the brand name out. We almost immediately upon getting 
our initial primarily suitable opportunity, we started looking at opportunities to 
expand the name. Certainly in the markets nearby us, we started breaking ground 
in that market with out-of-home, some digital, some social media engagement. We 
also were fortunate the timing was just right, we were able to engage in a 
partnership with the Chicago Bulls, which certainly helped give us some I would 
say overnight credibility and legitimacy in that market.
The Bulls is a phenomenal organization. We're very fortunate to be almost halfway 
through our agreement with them and everybody really, really happy with that, so 
it gave us some opportunity to engage a really large fan base to start to introduce 
the Wind Creek name. We just continued to ramp that up from there. We're 
relatively happy with where our awareness is in the market, although you'd always 
like to be getting better, but I think we've done a really good job of getting 
awareness within the consumers and certainly trial has been really, really good for 
us. We've had a lot of folks come in, we've got a lot of folks that have already 
tested the waters, if you will, and joined the loyalty program. We do have a very, I 
think, compelling offering in our rewards program and [inaudible 00:22:55] over 
time get those folks back the second time, the third time, and in our vision make 
Wind Creek the [inaudible 00:23:03] for the guests that want to come out and have
a good night and work casino gambling as part of that. That's even better.
Nick Hogan:
Yeah. Let's talk a little bit more about Wind Creek rewards. I know that you're 
using that network wide, I believe in all of your venues at this point. You 
mentioned that getting these players, giving them the ability to escape down to the
Caribbean and stuff. Is cross-visitation a huge part of your platform there or your 
rewards program there?
Ken Rohman:
For a segment of the guests, it certainly is. I think the... There's two pieces of our 
rewards program, the card loyalty program for sure and then the daily rewards 
program that marries up with that. But certainly within the card at Wind Creek 
Rewards loyalty program, giving guests an opportunity to escape to other 
properties is a compelling part of that. More than that, we revamped our program 
for 2025. It was already a compelling offering, but again, all credit to our CEO, we 
really wanted to model the program more about some of the affinity programs that 
you'd see in airlines and hotel and other areas of hospitality [inaudible 00:24:30] 
just a fairly high degree of choice, certainly at the upper levels of the program, to 
let guests pick the sorts of rewards they want as opposed to just telling them, "This
is what you get when you get this many tier points." That was a nice part that 
we've added to it this year that allows guests to really select what they want, 
whether it's spa or food and beverage or free play, which everybody wants more 
free play.
Nick Hogan:
Of course.
Ken Rohman:
Hotel stays, tickets to... Whether it's the Bulls in Chicago or whether it's Alabama 
and Auburn games down by us or whether it's Talladega or NASCAR or whatever it
happens to be, really opening up that escape to what is your version of an escape. 
The ability to earn and redeem at any property in the system is... As a marketing, 
as a customer guy, to me I think that's paramount if you're trying to really engage 
with your customers.
Nick Hogan:
Absolutely.
Ken Rohman:
If our customers are... If they're going on a cruise, for example, which is great for 
us. If you've got a night in Miami before you head out on your cruise ship, we're 
happy to have you over to Magic City and you can redeem any rewards that are in 
your account. You can earn towards your annual state or you can earn while you're
there. Again, if you're on that cruise ship, we're very fortunate in Aruba and 
Curaçao that the cruise terminals are literally across the street from both of those 
properties. As you dock the Renaissance Wind Creek Aruba, the Renaissance Wind 
Creek Curaçao, your cruise ship is docking on our front door. The ability to use the
rewards you've earned at your home property or the ability to earn more for what 
you can [inaudible 00:26:14] is certainly great for guests. It is a facet of the 
program. I don't know the exact numbers on what the cross-property play is, but 
it's certainly something we promote as aspirational.
Nick Hogan:
Sure.
Ken Rohman:
It's a four level program, Play, Escape, Delight and Fantasy. The top three tiers of 
the program all get trips to the Caribbean or trips to a property of your choice, I 
shouldn't say the Caribbean, are part of that reward package. It varies by the 
number of nights and what's included and things of that nature, but we really do 
tout that as an opportunity. If you want to go to Miami for a couple nights or if you 
want to come to Alabama or if you want to go to Pennsylvania [inaudible 00:26:57] 
if you want to go to Chicago, once that hotel opens, really give you the opportunity 
to pick your escape and to explore other Wind Creek properties.
Don Retzlaff:
That's fantastic. I remember when Chicago Southland opened, I was watching the 
news and reading the reviews and the newspaper articles, and it was such an 
overwhelmingly positive reviews on the property. Everyone talked about how 
beautiful it was. Can you give us a sense of what you were hearing from the 
customers, what they liked, what the first impressions were of the property?
Ken Rohman:
The same. It's really been very, very positive. It's been a positive experience for 
everybody that's gone through the property. Whether it is consumers who are 
giving positive feedback or telling us about their experiences compared to whether
it was expectations or competitive sets or other markets, it's been very, very 
favorable from guests. It's also been favorable from call it vendors, partners, 
whomever that happens to be down there, whether it's media partners or whether 
it's the folks from the Bulls organization or folks from anywhere you can imagine, 
the aesthetic, the overall experience, the visuals, the gaming package, the food and
beverage offering. The only thing that I've heard any negatives on really is the 
lines, and that's probably my fault and the marketing team's fault. If we're doing 
our job, there should be lines.
It is great to see that many people want to come in and experience the property 
and signing up for the rewards program and really wanting to get the full 
experience. We've been very pleasantly surprised, or not surprised, we've just been
pleased with the results so far. I think it's also one of the things that's most 
gratifying for us is the experience that guests are having with our team members. 
Wind Creek really prides itself on having team members that go above and beyond,
empowering those team members to make sure our guests really have a 
phenomenal experience when they're at Wind Creek. It is fascinating. It's part of 
why I joined the organization, when I did my interview and Atmore and every 
single person from the front desk folks to the game attendants to the housekeeping
folks, they've all got a smile on their face, they're all willing to answer questions 
and engage with the guests.
They're looking you in the eye, they're having legitimate and real interactions with 
guests. Kind of go back to your point, that's not a culture that was native to 
Chicago. That's something that we brought to the Southland, and we had to make 
sure in our training process and our onboarding and orientation that we were able 
to communicate that. You've worked with some of our team members in other 
markets, Roger Kuhn, who's the general manager of that property, Amy Applegate,
who's the director of games in that property, both actually worked on the 
transition team. I mean they actually helped transition Wind Creek culture into 
other properties, whether it was Pennsylvania or whether it was Miami or whether 
it's now Chicago. That is a key element of having... You want to engage and employ
as many local team members as you can so that you bring that economic benefit to 
the local community.
But you also want to make sure that we're able to maintain a culture because that 
is something that very much differentiates us from the competitive set. We've all 
got a slot package, we've all got a table game programming, everybody's going to 
have a sportsbook, everybody's going to have a steak house, everybody's going to 
have... The amenities are the amenities. I think what sets you apart is what's that 
guest experience look like? The only way for that to really be special is for the 
team members to be living that and bringing that to our guests every day. I think 
that's probably the most... The part that makes us the most happy when we hear 
feedback from guests is that we've managed to execute that in Chicago, a pretty 
far piece from Atmore, Alabama.
Nick Hogan:
Yeah. I think it's interesting for... Because I think three Midwestern guys on the 
call here today that are on the show here today. Yeah. Chicago has always been 
one of those, to me, it's Midwestern but it isn't because of the urban nature of it. 
With that local community there, have there been wants and needs communicated 
by the players that surprise you, that are new for you?
Ken Rohman:
Not really. I think that we continue to react as we hear feedback. We really do 
listen to our guests, and so when they give us feedback about things they'd like to 
see us do, we do try to react to that as quickly as possible. I think you'll start to see
that reflected over the next six months or so. There's a combination of burning 
your way in and getting things running on all eight cylinders operationally and 
then starting to adapt to what the guests are saying. But generally speaking, we 
have not had a lot of... Again, other than the lines which that's a first world 
problem, and trying to get more efficient as we go through that process for sure. 
But right now I think we're continuing to take the feedback and seeing where we 
start to get consensus around things that we'll need to adapt, but generally right 
now we've been pretty fortunate.
Nick Hogan:
Okay. Maybe in those lines you need to start to go local. You need to start passing 
out some brats and beers.
Ken Rohman:
Brats. Yes. No beers in Illinois. That's no-no. No free liquor in Illinois.
Don Retzlaff:
Absolutely. Well, talking about the lines, I'm not surprised by looking at your 
attendance figures for your first full two months. It looks like you're still getting a 
lot of trial visits. But you're averaging over 5,000 customers a day, which is more 
than twice the state average and the second highest in the state by far. I'm sure 
your weekends are absolutely packed. Has that slowed much recently? How is the 
reward signups going? Is that where a lot of your lot lines are at?
Ken Rohman:
That's definitely where a lot of the lines are at. We've been very happy so 
[inaudible 00:33:42] the attendance numbers for sure. We had a really good night 
back-to-back Saturdays with the new car giveaways that we used to kick off some 
of the promotions that we're doing. We've been very happy with that. A lot of what 
happens in Illinois is... We'd like to think everybody's joining the rewards program 
because it's a great rewards program, and it is. I'm not... But it also is the 
touchstone for our daily rewards program, Express Offers, which is what gives you 
your offers daily.
Not just your card loyalty program, but what did I get for what I engaged in 
yesterday? That is a very unique program to Wind Creek. It also provides the SEP 
check if you want to participate in some of the other promotions. That's how... If 
you want to try and get into the car giveaway or if you want to try to win the Bulls 
tickets or if you want to get into the hot seats or anything else that's going on on 
the floor, you do have to be a member of the Wind Creek Rewards program. We 
just did a watch party last Thursday night with the Bulls where they brought their 
production team down and the mascot and the cheerleaders.
Don Retzlaff:
Oh, neat.
Ken Rohman:
If you wanted to win some of that stuff, you had to be a member of the rewards 
program because that's how we make sure that you're... You should be in a casino 
and be there. We're happy with the attendance. We're happy with the signups. We 
have not seen those numbers slow. Historically as you come into tax rebate season,
those numbers typically don't start to taper until April, May. We're hopeful that 
we'll continue to see those trends on the path they're on or even greater.
Don Retzlaff:
Excellent.
Nick Hogan:
Cool. I know, well, there's some unique architectural features. I think Don, maybe 
you can ask more. Our listeners always love discussions about layout. Don, why 
don't you jump in on that one?
Don Retzlaff:
Yeah. Wind Creek Southland has these micro environments. How have they been 
received to date? I guess we need to explain this is a really unique concept. When I
first saw the drawings of it, I guess about two years ago, they had to explain it to 
me because I saw they take up a lot of space, but they're very unique 
environments. I guess I'll let you explain it more, Ken, but very unique features 
that you usually don't see on the floor. I've seen it one other place outside of Wind 
Creek.
Ken Rohman:
I'd love to know what the other places because I've never seen them or heard of 
them. It is unique to Wind Creek as best I can tell. Again, we have a very visionary 
organization. Our CEO is an architect by training, and so the concept got batted 
around a few years back about how do we provide an escape, part of our mantra, 
within the casino itself? The Hidden Gems, which is our brand name for the micro 
environments, allow you to have your own casino experience in the middle of our 
casino floor. The original concepting around that were if you want to come in and 
have a bachelorette party or a guy's night or a watch party or a birthday party and 
you want to have your own casino experience, you pick the gaming package. 
Maybe it's not a gaming package, maybe it's couches and chairs and a sports bar 
setup or a e-sports adventure, or maybe you have these six slot machines or this 
particular table game you want in there, we could accommodate that.
Now, there's some operational and regulatory bits that cause us to have a little 
lead time to execute that. But that's the inspiration for it is allow each customer, 
again, back to that one-to-one experience, what do you want your experience and 
your escape at Wind Creek to look like tonight and how do we help you accomplish 
that? The four Gems are varying in sizes, as you probably witnessed. There's the 
Baby Gem as we called it, all the way up to the Big Gem, which I think is a fire 
code of 120 or 140 people that we can put in there. It really does allow you to have
a very cool experience that suits you and your group of friends or colleagues and 
have that right in the middle of the casino with the whole excitement and vibe of 
the casino going around you.
The first 90 days of opening, we had folks in there, so we've been doing magic acts 
and we've had some experiential things going on. We had entertainment in there, 
starting to show off what you could do. We also have an experiential marketing 
team that will work with anybody that wants to come in and do that to help plan 
your night out and allow you to do that. It's in its infancy. I think there's a lot of 
people that are like, "Wow. That is super cool. I can't wait to do that." The seeds 
are being sown, and I think generally speaking, the market is excited about it. I 
think, again, we're in the infancy of that, so we'll see how it plays out over the next
six months to a year. But it really is a very cool concept, and so far the reception to
the idea has been very, very positive.
Don Retzlaff:
For a visual for our listeners-
Ken Rohman:
Sure.
Don Retzlaff:
... think about a large carousel of slot machines that's hollowed out on the inside. 
You've got basically this circle with an entrance, of course. But you've got slot 
machines ringing on the outside, and you've got slot machines ringing on the 
inside as well. You basically can walk into this bubble and play slot machines. Now,
the place I was thinking about, but they don't offer the entertainment, but it's a 
very large circle of slot machines is at Resorts World in Las Vegas where they've 
got their Buffalo Links right there in the middle of the casino. It's this massive 
circle of slot machines. Just picture that in a locals' casino floor with some great 
games both on the inside and the outside. You're really tall lease games that can 
border the outside and make this circular microenvironment your Hidden Gems. 
It's a great name by the way.
Ken Rohman:
Thank you.
Don Retzlaff:
It's [inaudible 00:40:32] a really neat place to play, so I can-
Ken Rohman:
[inaudible 00:40:35] and it's very cool because each one of those four has its own 
AV system, so you can have whatever program you want on the audio and in the 
video. You can, again, with enough notice for IGB, we can put your own gaming 
package in there so it's got the games you want in there for that night. It's a velvet 
rope, as they like to call it, the fear of missing out, or you can have your own sort 
of experience going on in there. It's a really neat experience, and whether you're 
doing it with a group of friends or whether you're doing it as a corporate outing or 
a reunion or a whatever, it's a really, really unique experience, certainly within the 
gaming floor versus in a banquet room somewhere. Not disparaging banquet 
rooms, but it's just a neat experience. Again, the initial response has been very, 
very positive. We're excited to see how those play out over the next year or so.
Nick Hogan:
Then the other thing we know is... Well, Don, I think you had some questions 
because you had done some work with the team there, and I think you wanted to 
dive in on [inaudible 00:41:48]-
Don Retzlaff:
Yeah. Obviously I worked with the team there. You mentioned Amy and Kevin and 
Michelle on the slot side and obviously many of the people at corporate from Ron 
and Nader and Trevor and everybody else, it's a knowledgeable team, but 
generally very nice people to work with. I had so much fun working with them. 
They were committed, they were knowledgeable, but it seemed like we were 
laughing and joking the entire way through it and getting a lot of stuff done. How 
has the team come together since opening? I know pre-opening can be so super 
stressful, and you're putting seven days a week, 12 hour days plus in there. How 
are they doing since it's opened?
Ken Rohman:
I didn't know that that schedule had tapered since [inaudible 00:42:36] you go 
through combat and you have a lot of shared experiences.
Don Retzlaff:
Indeed.
Ken Rohman:
I think it's still very much a team effort and understanding, again, we all are 
working towards the same purpose in a purpose-driven organization, that's what 
you're working towards. That be the best, do it right, have fun, which drives all of 
us. When you keep that at the forefront of what you're doing, even when you're 
having tough days or things don't seem to be breaking your way, it still enables 
you to get up the next morning and go back and do battle again or get in the 
trenches with each other. I think that we've all got a really healthy understanding 
that nobody here is trying to do anything untoward or we're all trying to do the 
absolute best for the betterment of the tribe, for the betterment of the 
organization, the betterment of the team members and our guests.
Teams like this don't come together by accident or happenstance, it's a very 
deliberate way. Even when we did our job fair in Chicago Southland in October, 
and every team member goes through an audition. That's part of the process is you
audition for a lot of folks, and we make sure that you... Or we try to make sure that
everybody that joins the team has that same level of enthusiasm and that same 
level of accountability. I think that helps you build a team that perseveres through 
some, to your point, long days, not everything's breaking your way, delays, more 
snafus, more this, more that. But at the end of it, you get to the end of the day and 
pray that it's three steps forward and only one step back and come back tomorrow 
and do it all again.
Don Retzlaff:
Well, I'm not surprised you put together a good team. My first dealings with Wind 
Creek, I guess, it was with Trenton, and getting to meet and know him and getting 
to know his philosophy. Then Ron the same way, and everybody was always on the 
same page. I'm really not surprised at all that you put together this all-star team in
Chicago because they let you do your job. They knew what they knew, but they 
were also okay with you knowing your expertise and taking the ball and running 
with it. It's just a great way to do business, so I just... My hat's off to you and 
everybody at Wind Creek for a very successful opening, and I'm really not 
surprised. It's been great.
Nick Hogan:
Yeah. Speaking of that, so Don was telling me he went through the January 
numbers and saw that Southland is already the second-highest earning casino in 
the state of Illinois, which is very impressive versus a November opening. 
Generally speaking... Excuse me. Generally speaking revenues come in in line with 
your original projections or [inaudible 00:45:56] do you guys feel about that?
Ken Rohman:
I think, look, it's nice to be second, but nobody goes to bed at night and dreams of 
winning silver medals. I think it's a nice start. I think we... When I was at Bass Pro,
one of our corporate partners was Toyota. They embody very much that Kaizen 
mentality of there is no best, there's only better. I think that it may not be codified 
necessarily at Wind Creek, but it certainly still is the general guiding principle. 
Wherever we're at today, we want to make tomorrow better. Is it a nice start for a 
new entrant to the market? Sure. But I don't think anybody likes to settle on 
second place.
I think everybody likes to be nipping on the heels or getting to where you're at the 
front of the pack. I think we'd like to be number one. We'd like to be number one in
revenue. We'd like to be number one in visitation. We'd like to be number one in 
guest loyalty. We want to be number one in everything. I think if you're not striving
to be number one, there's a small challenge in that. I think that not discounting 
what the team was able to accomplish for a new property and a new brand in a 
new market, but I think all of us, the eye on the prize is how do we get to be better 
than that?
Nick Hogan:
Sure.
Ken Rohman:
How do we keep moving it forward? Not how do we rest on our laurels? For me to 
say we're happy with where we're at would be tough. We're not unhappy, but we 
would like to be better.
Don Retzlaff:
Well, the way you've started and your long-term goals with your customers and the
way you've set your hold percentages on the slot machines and things like that, I 
think you've got a great long-term strategy. I'll be shocked if you don't continue to 
grow. That being said, you talked a little bit about you got a hotel opening soon, 
250 rooms. You've got a sportsbook coming soon.
Ken Rohman:
We do.
Don Retzlaff:
Which will be nice, especially if you have it ready for the NFL season, which 
[inaudible 00:48:20] because everything moves slow in Illinois, and I get it. I 
opened a sportsbook in Illinois myself.
Ken Rohman:
I'm just glad you didn't say open for March Madness.
Don Retzlaff:
No, no, no.
Ken Rohman:
NFL season I can live with.
Don Retzlaff:
I'm having flashbacks [inaudible 00:48:34] I actually opened the sportsbook at my 
casino in Illinois the day that they started shutting down everything for COVID. 
Yeah. We were having a local celebrity come over and make the first bet, and we 
started getting the phone calls that the NCAAs were getting canceled and 
everything else. We know we won't go through that again, but it'll be very exciting.
I saw the [inaudible 00:48:56]-
Ken Rohman:
[inaudible 00:48:56]-
Don Retzlaff:
Exactly. I saw the design for the sportsbook, and the hotel looks fantastic. You're 
looking at, what, 250-ish rooms?
Ken Rohman:
Yeah, I think 252 hotel rooms and suites was the final number.
Nick Hogan:
Okay.
Don Retzlaff:
Nice. That's a great size. What else is on the horizon? Any big marketing or 
operation initiatives that you have for 2025? Or is it just getting all the restaurants 
and hotels and sportsbooks and all that open?
Ken Rohman:
I think that absolutely getting the sportsbook open, getting the restaurants open, 
getting the hotel open. The spa is absolutely amazing. I actually was able to tour 
everything Friday when I was up there. I toured a number of times, but it is getting
pretty close to completion now. The hotel rooms look really, really gorgeous. The 
spa is going to be world-class. The steakhouse has got amazing views of downtown 
and of the quarry. It is really going to be compelling, and if you've eaten at a 
Viviani restaurant, you know what the food quality's going to be like. That's going 
to be awesome. It will be nice to get the sportsbook open for sure. It's a beautiful 
facility as a sports bar right now. We had a really good time with the folks from the
Bulls last week.
We've got three more watch parties coming up with Chicago Sports Network 
between the Blackhawks and the White Sox. I know we've got at least three watch 
parties coming up. We're also going to be broadcasting their Chicago Daily Lead 
sports show from the sportsbook once a month. Those will likely coincide with big 
events. I believe our first live broadcast will be during March Madness, and then 
we'll figure out the rest of the schedule. They're also doing their Respect Point 
sports wagering podcast live from the casino twice a month. A lot of opportunities 
for fans of sports to be involved in live studio audiences. We're also going to be 
doing the BearDown Podcast for four of the Bears games next year live on 
property, so lots of opportunities there.
We are going to have in market, I believe we're submitting, but we'll have a large 
scale million dollar blackjack tournament and a large scale million dollar slot 
tournament both this spring. Those should both be going to submission for IGB this
week. The timing around those should be fantastic. Those are great for the folks 
that like those high value million dollar tournaments. We'll have a full slew of car 
giveaways. The two Mercedes were nice off the blocks. I believe the next, after the 
AMG G-Wagon goes away, I think we have a limited edition Corvette, it's the next 
lineup in the car giveaway. We're going to bring... We have a concept called Sing 
Your Way to $100K.
Nick Hogan:
Sing? Sing? Singing?
Ken Rohman:
Sing Your Way.
Nick Hogan:
Okay.
Ken Rohman:
It's on par with X City's Got Talent, and so we're executing in Miami right now. We 
just finished. We had the tryout rounds, and we've had the preliminary round. We'll
use local judges. Nobody affiliated with the casino gets to judge on you, but we're 
going to allow guests that have some talent to sing, to sing their way to a $100,000
payday, which is a different entertainment offering that you'll see. We've got some 
pretty spectacular, I would call spectacle events coming for sure in and around the
casino. I don't want to get too far over my skis, but you'll see a pretty big presence 
from us as a brand doing something that's compelling for casino guests and non 
casino guests alike to really take advantage of.
Also, we'll have a local artist program going on. I don't know, Don, when you walk 
the property, if you saw the banquettes as you walked from the parking connector 
into the main casino past Starbucks and through the main hotel lobby, there's 
three... They're called banquettes. They're cutouts, if you will, in that hallway 
where we're going to be featuring local artists from around the Southland where 
they'll be able to have their art there, and then they'll be able to... Guests will be 
able to bid on it and acquire art from local artists to help us support the local 
community.
Don Retzlaff:
That's great.
Ken Rohman:
A lot of local engagement. It seems like a cliche tagline, but we really do believe 
that we like to build communities, not just casinos. We are heavily involved with 
the local communities in outreach on a variety of ways, and I think you'll see that 
step up in the south. We were pretty good about that in the years leading up to, to 
help with some brand awareness, but also to just show to be a good citizen and be 
involved. I think you'll see more of that as well. Yeah. There's a lot going, but I 
think that hits the highlights of it for sure.
Nick Hogan:
Great.
Don Retzlaff:
It's a really busy calendar, and the quotes that I've seen from the local mayors and 
everybody else have been just glowing. You're already off to a great start with your
community involvement, and I know that'll just grow as your employees and your 
team members become more involved with the local charities and business 
organizations. You're off to a great start.
Ken Rohman:
Thank you.
Nick Hogan:
For sure. Ken, anything else that... Any upcoming events or any of the casinos in 
your network that you'd like to mention or promote a bit before we wrap today?
Ken Rohman:
They're all very unique. If you're ever in one of our markets, we would encourage 
you to visit, obviously. We're still in the middle of the Sing Your Way to $100K in 
Miami, so that's fun right now. We do everything we can to promote a consistent 
experience. When you're at Wind Creek property, you should know you're at Wind 
Creek property, but they're all very local also. Everything when you see that is 
based on engaged with the local community. The guests and the team members 
that live and work in those markets, we want to make sure that we're providing a 
compelling offering to everybody there. That's hopefully what you'll experience 
when you come into one of our properties.
Nick Hogan:
Okay.
Don Retzlaff:
[inaudible 00:55:09] Thomas Robert I'd come down and visit him at Magic City, so 
I'll be down in Miami sometime soon to come down and walk the [inaudible 
00:55:16]-
Ken Rohman:
Come on down. As I said, we're shooting a new TV commercial today, so you're 
going to see a whole new brand voice and a lot of volume from us probably over 
the next 90 days I would think.
Don Retzlaff:
I know from talking to the team down there, you spent a lot of money revamping 
and putting a lot of new slot machines in and expanding the product.
Ken Rohman:
We did. We've added... Not to go too far off-topic, but I think that it was about 800 
in the game count when we acquired it, we're up to a little over 1000 right now. 
There's probably another 20% of that floor that we have refreshed and renewed. 
We've also engaged that same philosophy of very, very low hold. We're arguably 
the loosest slots in Miami. I think the numbers would bear me out on that. We've 
introduced car giveaways down here, which they hadn't done in a very long time.
We're adding a smoking [inaudible 00:56:08] smoking gaming area for folks that 
are so inclined. We're [inaudible 00:56:13] that as an amenity. We've upgraded the
food and beverage offering. The entertainment is up there. We've got a million 
dollar giveaway going on right now in Miami. The first weekend for playing that 
was either last weekend or the weekend before, so we're having some really 
banner nights with visitation and revenue for that. I think you're going to continue 
to see that we have a full calendar over the next 12 months of really high-profile 
events that are going to be happening at Magic City as well, so [inaudible 
00:56:46] off the gas anywhere.
Don Retzlaff:
Yeah. That's a neat location. I really love that area.
Ken Rohman:
It is. It's 29 acres of real estate in Miami-Dade too.
Don Retzlaff:
Wow.
Ken Rohman:
It's a big, big footprint.
Don Retzlaff:
Yeah. It is.
Ken Rohman:
There's some big things on the horizon for that facility over the next few years, but
right now it's getting everybody that hasn't been in to come in and check us out. If 
you haven't been in a decade, you should check out what we're doing because it is 
not the Magic City you remember. Just opening 24 hours was a big step for us.
Nick Hogan:
Incredible. Just a heads-up, Ken. When Don does show up, you're going to want to 
make sure all those reels are really well-lubricated and ready to go [inaudible 
00:57:27]-
Ken Rohman:
We can take care of that for you.
Nick Hogan:
[inaudible 00:57:29] to play a little bit.
Don Retzlaff:
Got to make a donation.
Ken Rohman:
Absolutely. Seems like that's what I do when I'm somewhere.
Don Retzlaff:
Absolutely.
Nick Hogan:
Okay. Well, Ken, thank you so much for your time today. It was really lovely to 
speak with you and appreciate the conversation today. Don, did you have anything 
else for Ken though before I forgot to ask [inaudible 00:57:51]-
Don Retzlaff:
No. Thank you very much. It was a pleasure meeting you, and best of luck to you 
and the team. Like I said, I can't say enough good things about the Wind Creek 
organization. Everybody I've met has been so friendly and so welcoming, and it's 
just been a blast to work with over the last couple of years, and nothing but the 
best to you all.
Ken Rohman:
Thank you.
Nick Hogan:
I second that. I second that wholeheartedly.
Ken Rohman:
Thank you both.
Nick Hogan:
Okay. Thank you so much, guys.
Don Retzlaff:
[inaudible 00:58:19]-
Nick Hogan:
Okay. Bye-bye.
Ken Rohman:
Have a great day.
Nick Hogan:
You too
`,

};

export default S04E01;
