import React from "react";
import PropTypes from "prop-types";

function Index({ link, date }) {
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${month} ${year}`;

    return (
        <article 
            className="reelhot-index"
            itemScope 
            itemType="https://schema.org/DigitalDocument"
            itemProp="itemListElement"
        >
            <meta itemProp="name" content={`ReelHot Performance Index - ${formattedDate}`} />
            <meta itemProp="description" content={`Monthly performance analysis of top-performing slot machine games in the casino industry for ${formattedDate}. Includes detailed metrics, market trends, and industry insights.`} />
            <meta itemProp="datePublished" content={date.toISOString()} />
            
            <a 
                href={link} 
                target="_blank" 
                rel="noopener noreferrer"
                itemProp="contentUrl"
                aria-label={`View ReelHot performance index for ${formattedDate}`}
            >
                <img
                    src="assets/images/reelhot-index-thumbnail.png"
                    alt={`ReelHot index report for ${formattedDate}`}
                    loading="lazy"
                    width="200"
                    height="150"
                    itemProp="image"
                />
            </a>

            <time dateTime={date.toISOString()} itemProp="dateCreated">{formattedDate}</time>
        </article>
    );
}

Index.propTypes = {
    link: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired
};

export default Index;
