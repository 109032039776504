import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import butter from "../../../utils/butterClient";

import Description from "./Description";
import Carousel from "./Carousel";
import Select from "../../shared/Select/Select";

const sortItems = items =>
    items
        .map(item => ({ ...item, date: new Date(item.date) }))
        .sort((a, b) => b.date - a.date);

const filterItems = (items, supplier, costModel) => {
    let filteredItems = items;

    if (supplier && supplier.length > 0) {
        filteredItems = filteredItems.filter(
            item => item.supplier === supplier
        );
    }

    if (costModel && costModel.length > 0) {
        filteredItems = filteredItems.filter(
            item => item.cost_model === costModel
        );
    }

    return filteredItems;
};

const getSelectOptions = (items, type) => {
    if (!items || items.length === 0) {
        return [];
    }

    const uniqueOptions = [...new Set(items.map(item => item[type]))]
        .sort()
        .map(option => ({ value: option, label: option }));

    const formattedType = type
        .split("_")
        .map(string => string.charAt(0).toUpperCase() + string.slice(1))
        .join(" ");

    uniqueOptions.unshift({
        value: "",
        label: `All ${formattedType}s`
    });

    return uniqueOptions;
};
function SpotlightAndHiddenGems({
    contentField,
    cmsContent,
    carouselTitle,
    reverseDescription
}) {
    const [items, setItems] = useState();

    const [suppliers, setSuppliers] = useState();
    const [selectedSupplier, setSelectedSupplier] = useState();

    const [costModels, setCostModels] = useState();
    const [selectedCostModel, setSelectedCostModel] = useState();

    useEffect(() => {
        if (!items) {
            butter.content.retrieve([contentField]).then(response => {
                const reelHotItems = sortItems(
                    response.data.data[contentField]
                );

                const supplierOptions = getSelectOptions(
                    reelHotItems,
                    "supplier"
                );
                const costModelOptions = getSelectOptions(
                    reelHotItems,
                    "cost_model"
                );

                setItems(reelHotItems);
                setSuppliers(supplierOptions);
                setCostModels(costModelOptions);
            });
        }
    }, [items, contentField]);

    const handleSupplierChange = selected => {
        setSelectedSupplier(selected.value);
    };

    const handleCostModelChange = selected => {
        setSelectedCostModel(selected.value);
    };

    const latestReelHot = items && items[0];

    const carouselItems =
        items && filterItems(items, selectedSupplier, selectedCostModel);

    return (
        <section 
            className="spotlight-hidden-gems"
            itemScope
            itemType="https://schema.org/ItemList"
            aria-labelledby={`${contentField}-heading`}
        >
            <meta itemProp="name" content={carouselTitle} />
            <meta itemProp="description" content={`Collection of ${carouselTitle.toLowerCase()} showcasing slot machine performance data and analytics.`} />
            
            <Description
                cmsContent={cmsContent}
                contentKey={contentField}
                imageDetails={latestReelHot}
                reverseDescription={reverseDescription}
            />

            <div className="box box--light">
                <div className="outside-wrapper container-fluid flex-row reelhot-select-container">
                    <div className="col-xs-12 col-sm-6 flex-column">
                        <h4 id={`${contentField}-heading`} className="text--red">{carouselTitle}</h4>
                    </div>

                    <div className="col-xs-12 col-sm-5 flex-column reelhot-select-wrapper">
                        <Select
                            className="reelhot-suppliers-select"
                            options={suppliers}
                            defaultValue={suppliers && suppliers[0]}
                            onChange={handleSupplierChange}
                            aria-label="Filter by supplier"
                        />
                        <Select
                            className="reelhot-cost-model-select"
                            options={costModels}
                            defaultValue={costModels && costModels[0]}
                            onChange={handleCostModelChange}
                            aria-label="Filter by cost model"
                        />
                    </div>
                </div>

                <Carousel items={carouselItems} />
            </div>
        </section>
    );
}

SpotlightAndHiddenGems.propTypes = {
    cmsContent: PropTypes.object.isRequired,
    contentField: PropTypes.string.isRequired,
    carouselTitle: PropTypes.string.isRequired,
    reverseDescription: PropTypes.bool
};

SpotlightAndHiddenGems.defaultProps = {
    reverseDescription: false
};

export default SpotlightAndHiddenGems;
