import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import PageMetadata from "../components/PageMetadata";

import Hero from "../components/shared/Hero";
import ReelHotIndexes from "../components/ReelHot/Indexes/ReelHotIndxes";
import SpotlightAndHiddenGems from "../components/ReelHot/SpotlightAndHiddenGems/SpotlightAndHiddenGems";

function ReelHot({ cmsContent }) {
    ReactGA.pageview(window.location.pathname);

    // Structured data for ReelHot
    const reelHotSchema = {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "ReelHot by ReelMetrics",
        "applicationCategory": "BusinessApplication",
        "operatingSystem": "Web-based",
        "description": "ReelHot delivers enterprise-grade real-time slot machine performance insights with advanced analytics for top performers and hidden gems optimization.",
        "image": "https://cdn.buttercms.com/rZ3jrYsxQzirDHaf5nqs",
        "publisher": {
            "@type": "Organization",
            "name": "ReelMetrics",
            "url": "https://www.reelmetrics.com",
            "logo": {
                "@type": "ImageObject",
                "url": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
                "width": "350",
                "height": "100"
            }
        },
        "creator": {
            "@type": "Organization",
            "name": "ReelMetrics"
        },
        "provider": {
            "@type": "Organization",
            "name": "ReelMetrics"
        },
        "license": "https://www.reelmetrics.com/legal",
        "hasPart": {
            "@type": "Dataset",
            "name": "ReelHot Performance Indexes for 2025",
            "description": "Monthly performance indexes tracking the hottest performing slot machine games in the casino industry. Each index provides detailed analytics on game performance, market trends, and industry insights.",
            "variableMeasured": [
                "Game Performance",
                "Market Trends",
                "Industry Insights",
                "Slot Machine Analytics"
            ],
            "includedInDataCatalog": {
                "@type": "DataCatalog",
                "name": "ReelMetrics Casino Game Analytics"
            }
        }
    };
    
    // Breadcrumb structured data
    const breadcrumbSchema = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.reelmetrics.com"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "ReelHot",
                "item": "https://www.reelmetrics.com/reelhot"
            }
        ]
    };

    return (
        <main className="reelhot" itemScope itemType="https://schema.org/SoftwareApplication">
            <PageMetadata
                title="ReelHot | Real-Time Slot Performance Insights | ReelMetrics"
                description="ReelHot provides real-time slot machine performance analytics, helping casinos identify top performers and hidden gems to optimize floor performance and maximize revenue."
                keywords="slot machine analytics, slot performance index, casino floor optimization, gaming analytics, top performing slots, hidden gem slots"
                canonical="https://www.reelmetrics.com/reelhot"
                
                // OpenGraph tags
                ogUrl="https://www.reelmetrics.com/reelhot"
                ogType="website"
                ogTitle="ReelHot | Real-Time Slot Performance Insights | ReelMetrics"
                ogDescription="ReelHot provides real-time slot machine performance analytics, helping casinos identify top performers and hidden gems to optimize floor performance."
                ogImage="https://cdn.buttercms.com/rZ3jrYsxQzirDHaf5nqs"
                
                // Twitter card
                twitterCard="summary_large_image"
                twitterTitle="ReelHot | Real-Time Slot Performance Insights"
                twitterDescription="ReelHot provides real-time slot machine performance analytics, helping casinos identify top performers and hidden gems."
                twitterImage="https://cdn.buttercms.com/rZ3jrYsxQzirDHaf5nqs"
                
                // Performance optimization
                preconnect={["https://cdn.buttercms.com"]}
                preload={[
                    {href: "assets/images/reelhot-index-thumbnail.png", as: "image"},
                    {href: "assets/images/reelhot-logo.svg", as: "image"}
                ]}
                
                // Structured data
                structuredData={[
                    reelHotSchema,
                    breadcrumbSchema
                ]}
            />

            <h1 className="sr-only">ReelHot - Real-Time Slot Machine Performance Analytics</h1>

            <Hero
                cmsContent={cmsContent}
                contentKey="reelhot_hero"
                imageSrc="https://cdn.buttercms.com/rZ3jrYsxQzirDHaf5nqs"
                imageAlt="ReelHot Analytics Dashboard for Slot Machine Performance"
            />

            <ReelHotIndexes contentField="reelhot_indexes" />

            <SpotlightAndHiddenGems
                key="reelhot_spotlight"
                cmsContent={cmsContent}
                contentField="reelhot_spotlight"
                carouselTitle="Previous Spotlights"
            />

            <SpotlightAndHiddenGems
                key="reelhot_hidden_gems"
                cmsContent={cmsContent}
                contentField="reelhot_hidden_gems"
                carouselTitle="Previous Hidden Gems"
                reverseDescription
            />
        </main>
    );
}

ReelHot.propTypes = {
    cmsContent: PropTypes.object.isRequired
};

export default ReelHot;
