import React, { useState, Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Cms from "./cms";

const Vimeo = lazy(() => import("react-vimeo"));
function Hero({
    cmsContent,
    animationVideo,
    animationVideoId,
    animationThumbnail
}) {
    const [animationLoading, setAnimationLoading] = useState(true);

    return (
        <>
            <Helmet>
   
                <link rel="preload" href={animationVideo} as="document" />
                <link rel="preload" href={animationThumbnail} as="image" />
            </Helmet>

            <section className="outside-wrapper container-fluid">
                <div className="box box--end container flex-row homepage_hero">
                    <div className="col-xs-12 col-md-8 text-center">
                        <h2 className="title--banner text--red">
                            <Cms
                                content={cmsContent}
                                content_key="homepage_hero"
                                type="title"
                            />
                        </h2>
                        <p className="paragraph paragraph--small">
                            <Cms
                                content={cmsContent}
                                content_key="homepage_hero"
                                type="text"
                            />
                        </p>

                        <div className="buttons_wrapper">
                            <div
                                className="contact"
                                data-toggle="modal"
                                data-target="#contact"
                            >
                                <button
                                    type="button"
                                    className="btn-block-mobile btn btn-lg btn-primary"
                                >
                                    Try Reelmetrics for free
                                </button>
                            </div>
                            <a href="/slot-machine-data-analytics-platform" className="platform">
                                <button
                                    type="button"
                                    className="btn-block-mobile btn btn-lg btn-transparent"
                                >
                                    Explore Reelmetrics PLatform
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="homepage_animation">
                <img
                    src={animationThumbnail}
                    alt="animation_thumbnail"
                    className="animation_thumbnail"
                    style={{ opacity: animationLoading ? "0.75" : "0" }}
                />
                <Suspense
                    fallback={
                        <img
                            src={animationThumbnail}
                            alt="animation_thumbnail"
                            className="animation_thumbnail"
                            style={{ opacity: "0.75" }}
                        />
                    }
                >
                    <Vimeo
                        videoId={animationVideoId}
                        className="vimeo"
                        playerOptions={{
                            autoplay: 1,
                            background: 1,
                            loop: 1,
                            muted: 1,
                            controls: 0
                        }}
                        autoplay
                        onReady={() => {
                            setAnimationLoading(false);
                        }}
                    />
                </Suspense>
            </section>
        </>
    );
}

Hero.propTypes = {
    cmsContent: PropTypes.object.isRequired,
    animationThumbnail: PropTypes.string,
    animationVideo: PropTypes.string,
    animationVideoId: PropTypes.string
};

Hero.defaultProps = {
    animationVideo:
        "https://player.vimeo.com/video/521339921?Fautoplay=1&background=1&loop=1&muted=1&controls=0",
    animationVideoId: "521339921",
    animationThumbnail:
        "https://i.vimeocdn.com/video/1079777491-87b36a5e18696172f53a3ec822f502697d3d5f7f0ec1227c83f29a937a37fe64-d_640"
};

export default Hero;
