import React from "react";
import { Card, Button } from "react-bootstrap";
import PropTypes from "prop-types";

class ReelCastEpisodeDescription extends React.Component {  
    render() {
        return (
            <div className="podcast-episode-description" itemScope itemType="https://schema.org/PodcastEpisode">
                <Card style={{ borderRadius: "10px" }}>
                    <Card.Header as="h4" className="linkHeader" style={{ paddingBottom: "0px" }}>
                        <Card.Link
                            href={this.props.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ cursor: 'pointer' }}
                            itemProp="url"
                            aria-label={`Listen to ${this.props.heading}`}
                        >
                            <span itemProp="name">{this.props.heading}</span>
                        </Card.Link>
                    </Card.Header>
                    
                    <Card.Body>
                        <Card.Text as="h3" itemProp="alternativeHeadline">{this.props.subHeading}</Card.Text>
                        <Card.Text itemProp="description">{this.props.description}</Card.Text>
                        <meta itemProp="episodeNumber" content={this.props.episodeNumber} />
                        <meta itemProp="seasonNumber" content={this.props.seasonNumber} />
                        <meta itemProp="datePublished" content={this.props.datePublished} />
                        <Card.Link
                            href={this.props.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={`Listen to ${this.props.heading} podcast episode`}
                            itemProp="contentUrl"
                        >
                            <Button variant="default">
                                Listen{" "}
                                <img
                                    src="https://cdn.pixabay.com/photo/2012/04/24/16/24/microphone-40327_960_720.png"
                                    alt="Microphone icon"
                                    width="10"
                                    height="18"
                                    style={{ verticalAlign: "middle" }}
                                />
                            </Button>
                        </Card.Link>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

// Add PropTypes for better documentation and type checking
ReelCastEpisodeDescription.propTypes = {
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    episodeNumber: PropTypes.number,
    seasonNumber: PropTypes.number,
    datePublished: PropTypes.string
};

// Default props for optional values
ReelCastEpisodeDescription.defaultProps = {
    episodeNumber: null,
    seasonNumber: null,
    datePublished: null
};

export default ReelCastEpisodeDescription;
