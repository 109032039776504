import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S02E04 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }


    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E04.defaultProps = {
    id: "12741246-s02e04-jackpot-liabilities-and-high-denom-links",
    link: "s02e04-Jackpot-Liabilities-and-High-Denom-Links",
    title: "Jackpot Liabilities and High-Denom Links",
    subTitle: "Season 2, Episode 4",
    description:
        "Welcome to this episode of Reelcast where Nick and Don share insights on how to manage jackpot liabilities and maximize the performance of high-denomination links on your slot floor.",
    keywords: [
        "Jackpot iabilities",
        "High denomination links",
        "Slot floor",
        "Slot machine management",
        "Risk management",
        "Casino revenue",
        "Progressive jackpots",
        "Casino operations",
        "Casino management",
        "Slot machine manufacturers",
        "Casino finance",
        "Player behavior",
        "Data-driven insights",
        "Inventory management",
        "Player loyalty"
    ],
    episodeNumber: 4,
    seasonNumber: 2,
    datePublished: "2023-05-19",
    topics: [
        "Understanding the risks of jackpot liabilities for casinos",
        "The impact of high denomination links on slot floor performance",
        "Best practices for managing and mitigating jackpot liabilities",
        "Using player behavior data to improve jackpot management and player loyalty"
    ],
    buzzSproutLink:
        "https://reelcast.buzzsprout.com/2057836/12741246-s02e04-jackpot-liabilities-and-high-denom-links",
    spotifyLink: "https://open.spotify.com/episode/3A3m7b5RgwuS2J7608XhRB",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/s02e04-jackpot-liabilities-and-high-denom-links/id1652485625?i=1000611008908",
    iHeartRadioLink: "https://podcastindex.org/podcast/5784001",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e04-jackpot-liabilities-and-high-denom-links/",
    transcript: `Nick:
Good Morning, Don. How are things today in Rome of the West?
Don:
Good morning, Nick. I'm fine. And that's a new one.
Nick:
Oh, you haven't heard that one?
Don:
I have not.
Nick:
Okay, so it caught me a little off guard as well. So the story there is that when the St. Louis diocese was established. And this is all the way back in 1826, it included the state of Missouri, the western half of Illinois, and all American territory west of the Mississippi River and north of Louisiana. And it was the largest American diocese, equaling in extent all of the other nine American dioceses. And then in 1847, Pope Pius IX designated St. Louis an archdiocese, and due to its strong Catholic identity and its role as the mother diocese of so many other dioceses, it was nicknamed Rome of the West. Yeah. So also one of the things you mentioned is the archdiocese and cathedral there, so the Cathedral Basilica of St. Louis. Do you know that place?
Don:
Yes.
Nick:
Okay. So among other things, it contains the largest collection of mosaics on earth. So, I have to visit.
Don:
It does. It's absolutely gorgeous.
Nick:
Okay, well I'll tell you, I'm increasingly eager to spend some tourist time in St. Louis, I have to say after all of these episodes here. So anyway... Go ahead Don. Sorry.
Don:
I remember, this probably is now over 20 years ago when Pope John Paul II actually visited St. Louis. And the Popemobile driving down the streets of St. Louis, and he actually held a mass at the football stadium, and it was sold out, like 80,000 people attended the mass.
Nick:
Okay. Wow.
Don:
I want to say it was like 1999 or 2000, so it's probably 20 years ago, but it was a neat event.
Nick:
Okay, cool. Yeah, I think it's just a natural bias. I just always assume that the Protestant churches were much stronger in the Midwest, et cetera, but I was just absolutely astonished to read this stuff. Totally, totally interesting. Okay, so we're recording on a national holiday here in Holland, King's Day. It's King Willem-Alexander's birthday. So the whole country shuts down so that people can dress in orange and get absolutely hammered. So it's a big boozy and gross affair. So I'm happy to be stuffed in a sound booth today. And let's see, we're one month out since our last podcast, and there are a few things shaking. So we did the IGA show in San Diego, which was lovely. I did a little speaking gig there and spent several days catching up with industry folks and checking out some new product reviews, et cetera. Then did a bit of a whistle-stop big boy tour across America, checking in with a few of our enterprise subscribers.
Then, let's see, next week it's off to Mexico City to speak at AGS's GameON Mexico. Then I'll do AGS's GameON event in Reno in June, then Ravings casino and technology and marketing conference in Reno in July. Then a bit of a repeat, then it's back to the silver state for G2E. So lots of travel coming up and really looking forward to all these events. And incidentally, while I'm on it, if you're in casino ops and have the opportunity to attend one of these AGS GameON events, I honestly cannot recommend it strongly enough. I've been going to industry events for 30 years and I've yet to attend any customer event that comes even remotely close to it. So it's just incredibly well conceived and executed. The speakers are great, the events are genuinely fun, and the interaction with other attendees is just off the charts.
Uniquely, AGS forbids its staff from selling during those events. So all that pressure is just gone and you can stay focused on everything that's being presented. And discuss it often in incredible depth with various attendees. So again, I can't recommend those events strongly enough, and thanks to AGS for including us in that, we're really honored to support these events, and really looking forward to them. So, then Don here we are, we have our third episode of the second season, and today we'll be tackling the topic of jackpot liabilities, which was a topic that was recommended to us by a listener. And as a reminder to everyone, we'd love to tackle any questions that anybody can have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at ReelCast@ReelMetrics.com. Again, that's R-E-E-L-C-A-S-T at ReelMetrics.com.
Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. We don't do that. Now as a refresher to listeners, we received an email a couple of months back from a Biz Dev executive on the supply side. And he explained that increasingly operators are pushing back against high denom links due to the sizable jackpot liabilities. And as you know, Don, some of these links require a six digit jackpot seed, some even higher, and that combined with the progressive values is apparently giving some operators the jitters. So, excuse me, this gent asked us if we'd mind tackling this topic during a podcast. We said yes. And then incidentally, while we were at NIGA, Tim and I had two other vendors approach us on the same topic. So it appears that it that this is a bit of a thing.
So for our example, Don's going to discuss them generally, and then we're going to use a specific example of Dragon Cash from Aristocrat, which was nice enough to provide us with some of the mathematical collateral that Don used in preparing his remarks. So a big shout-out to the folks at Aristocrat, who helped us out there, you know precisely who you are. So Don, I think the best place to start on this, perhaps, is just upfront, is to address the accounting treatment a bit. So we wanted to see if there was much variability out there in terms of how operators are treating jackpot liabilities from an accounting perspective. We looked at the filings of a number of public operators, saw that they were all working similarly, then we went to look at the various US gap rules covering jackpot treatment. And on that front, likely the best thing I located was a 2011 accounting standards update that was issued by the SEC, together with the Financial Accounting Standards Board, FASB, and the Government Accounting Standards Board, GASB.
And the update states, "Most entities accrue liabilities for a base jackpot rateably over the period of play expected to proceed a payout. An entity should not accrue jackpot liabilities, or portions thereof, before a jackpot is won, if the entity can avoid paying that jackpot." It continues, "Jackpot should be accrued and charged to revenue when an entity has the obligation to pay the jackpot. And this guidance applies to both base and progressive jackpots." So in layman's terms, my interpretation of this is that we have something like, let's say a $30,000 seed, and an expected hit period of three months, you'd accrue that liability rateably at 10K per month plus the progressive increment, over three months, then book once paid out. So Don, does that gel with how you know this is treated in accounting?
Don:
I've seen it both ways. I've seen most of the casinos will also just track the increase in liability on a calendar month period. So where you're tracking the progressives on the first day of each month and then doing a hit against revenue for how much that progressive jackpot increased during the month. So if it started at 10 and ended at 15, you're booking a $5,000 liability to your revenue. And so when it does hit and it goes from 15 back to 10, you get that $5,000 back.
Nick:
Okay, cool. And then on that one condition that was in there, this "If the entity can avoid paying the jackpot," was a bit of a conversation internally. What are some examples of why you wouldn't pay? But we largely ended up snake eyes on that one. So malfunctions, game removals, underage gamblers, barred players, pretty much every scenario that we discuss is dealt with either via internal controls or jurisdictional regulations. And has you ultimately paying out that money in one form or another. So we concluded that we could largely skip that exception, and simply assume that everyone is accruing roughly the same way. And Don, with that, I'm going to shut my trap and let you address this now.
Don:
Thanks Nick. Well, you really have to know your liabilities and your top awards, and I highly suggest a review of every machine on your floor looking at maximum awards. They really need to be looked at. In some cases they need to be adjusted, especially the smaller the casino, the more impactful it's going to be. Your biggest troubles are always going to be in video poker, especially the games with the multiplier such as Ultimate X. But there are ways to mitigate your exposure. Look at how many hands are available at the higher denominations. For instance, don't have the $5 denomination at 10 play. Limit the dollar to triple play or five hands. Those are things that you can do to limit that top award. But it all really depends on your customers. Do you have a lot of demand for your High Limit poker? You really need to consult with your Action Gaming reps.
They are some of the best in the business. Reach out to them, and they'll take the time to answer your questions and help if you want to set up whole percentages and all that type of stuff. Off-topic for a second. If you have video poker on your floor, contact your Action Gaming rep. Talk about new cabinet performance, new games, optimal setups. It's often on the collected segment of your floor and it can really boost your profits, and your customer satisfaction, if done right. Yeah, I was lucky enough to work with Mike Drummond, and he's really sharp. And I've worked with Shannon Bloom this past year and I have friends that swear by her, use those resources. Now back to the topic. No, wait, one more thing. You can steal customer trips from your competition if you have the right floor mix, doubly so on video poker, it's what I play.
I have my favorite game, and if it's not your casino, I won't play. In Vegas, I'll walk across the street from where I stay to play my favorite game. And it's the same in the local markets here. I had Tim Wilmont once tell me in a budget review on lease costs, that having the best leased games on your floor can be considered a marketing effort, and an expense. Because if your customers are wanting to play, say, Split Card Poker, and you don't have it, and your competition does, they will visit your competition. Now we can segue back to the topic for today, and let's substitute Dragon Cash for Split Card Poker. Only about half of the casinos that I've visited have Dragon Cash on their floor, or have Dragon Link and High Limit. And in most cases it's what you said earlier, Nick, it's about a fear of having these large jackpots hit.
Nick:
Yeah, because the performance values are always extremely robust on these products.
Don:
The players have really transitioned to higher average bet games, and I am seeing it more and more in High Limit. The 25 cent video games, the 10 cent video games that are getting average bets of $8 to $10 a spin, have really taken off in the last couple of years. And I understand the fear, $50,000 hits, a hundred thousand dollars hits are rough for casinos, especially for the ones that do less than 10 million a month in revenue. If you do $200,000 a day in revenue, and there's a hundred thousand dollars hit, you're going to get some grief.
I've been there. Even the good-natured ribbing can kind of make you gun shy. So you need to be aware. So I guess tip number one to any of the GM's or AGM's or VP's, CFO's out there listening, don't sweat the volatility. The players want these large jackpots, they will chase these large jackpots. You will get deeper into their wallet, as these customers will chase. And when they hit, you will have a bad day, but the other a hundred days that they don't hit are going to be great. You really need to have that discussion with your slot team, about whole percentage volatility and come to an understanding about exposure. And make sure your executive team is on board.
Hold percentages shouldn't be looked at on a daily basis. They have to be looked at on a longer term basis. Sometimes not even a month. You have to look at these months after months. I used to do 180 day review, and you did a 30-day review, 180 day review, a 365-day review. And those are where you look for your trends. When you get down to single spins and 10,000 spins, the volatility is really extreme, but once you start pushing that a hundred thousand, million spins, things start to level out.
So you will have months that you under hold, and you will have a lot of months that you over hold. If you don't, you probably need to contact me or someone to look at your floor. I actually did that recently for one of our clients. They had a problem where they were under holding every month. So they asked me to review it, and I reviewed it, and I found what the problem was. And there was a problem on their floor. So every once in a while you'll see something, but most casinos you're going to have months where you overhold and under hold, but when you look at it on a annual basis, you should be fine. So if you make your slot team tentative, you will limit your profitability. Just think of what happens if you limit your maximum bet to say, $2, on every one of your machines?
You're going to cap your performance. So would you trade a $50,000 jackpot one day for $3,000 a day for a hundred days? If you would say, no, give me the space. I'll put two or four Dragon Cash units and High Limit, I'll guarantee you a hundred dollars a day for the space, and I'll take all the risk myself. So Nick, if anyone takes me up on that offer, it was nice working with you.
Nick:
Yeah, exactly. [inaudible 00:17:00]
I've made a few of the same types of challenges throughout my career.
Don:
Oh, absolutely. So you're really going to have those spikes, but the trip there is going to be more than worth it. So now Dragon Cash is a great example of how to manage your exposure. You have options to set your grand award at different levels, and your denominations at different levels. You can set it up at 5 cents as a base denomination, you can set up at 10 cents, which is the one I recommend. And you can have a grand award at 25 or $50,000, which takes months to hit at most casinos. And the bonus is the jackpot is already baked into the RTP and it's just under 1%. So it's basically going to over hold, every month, before it hits, and the month it hits, it's going to under hold. But when you look at that six month hold or 12 month hold, it's going to be very close to par.
Nick:
Yeah. And I remember, Don, you had said that you had looked at a lot of Dragon Cash terminals, just looking at it on a day by day basis. And I think you had mentioned something like in just a very rare case you were seeing those games underwater, or are you going to address that later? Am I jumping forward?
Don:
No, you're fine. I've looked at over 500 months worth of data on Dragon Cash, 544 months worth of data, to be exact. And Dragon Cash booked a whopping loss 11 times. So 2% of the time.
Nick:
When you're talking 544 months, you don't mean linearly?
Don:
No, it's a total number of months, for a unit, each month is one.
Nick:
Okay, gotcha.
Don:
So I looked at results over the last, say six, seven months, and I looked at 500 different units that we're tracking. And out a 544 calendar months of performance, the game lost 11 times.
Nick:
Okay, gotcha.
Don:
2% of the units lose on a monthly basis. And in each case, the casino that had the losing Dragon Cash had multiple Dragon Cash machines, and none of the properties booked a net loser for the game theme for the month. The other units on the floor were positive, and made up for the loss. So let's say, let me do a quick calculation. I'm looking at over a hundred million in coin in on Dragon Cash for March. Theoretical hold was 9.1%. Actual hold was 9.8. So that makes sense. So that equates to a normal Dragon Cash over hold then by say three or $4,000 each month, until the jackpot hits. So that's a heck of a trade-off for a game that does at least $2,000 a day on your floor. And a lot of cases is doing north of $3,000 a day. We've got properties that have been doing $7,000 a day on Dragon Cash.
Nick:
I've heard many over five. Yeah.
Don:
Our average is well over $3,000 a day.
Nick:
That's incredible.
Don:
And we know that there are some casinos that probably don't have the clientele that would bet $10 a spin or 8.50 a spin. But if you look at your high limit video, and you've got, say, Ainsworth Games at quarters or dollars, that have an average bet of north of $7 a spin. If you've got some of the classic Aristocrat games, that's in quarters or IGT's, there's a lot of IGT stuff that works in high limit. There's some of the stuff from Light & Wonders doing really well on high limit. If you've got that clientele that's willing to play it, and you're getting good win per units out of it, you probably need to try Dragon Cash as well. And there's a couple other things that I really, really recommend for high limit video.
Some of them play really well. It's amazing to go on YouTube and look at some of the slot influencers, and what they're playing in high limit video, what their average bets are. And I think it's really impacting how people play. I'm starting to see, especially for the younger players, higher average bets, shorter session times, but they are really pounding these machines, chasing these jackpots. And I have to say, some of it is probably influenced by these slot videos that I've been watching. I've watched a ton of them lately. I didn't know who Brian Christopher was eight months ago. I don't get much [inaudible 00:21:48].
Nick:
He's a very nice guy by the way, I have to say.
Don:
Oh my gosh, his videos are great. And I stumbled on one of his videos, I was looking for, I think I was trying to figure out what cabinet a game was on. So doing all the internet research, and I stumbled across the video of him playing one the games. So I looked at the cabinet, and I started watching him play and then it started leading me to these other videos that are out there. And there are so many people posting these videos. And their average bets are $50 a spin. $250 a spin. They're pooling money from their groups, or they're playing it all themselves.
And it's really starting to impact how people play these games. Traditionally, you go back before, say, Lightning Link, there wasn't that opportunity to really play more than $10 a spin. I had 25 cent video games, but those were mostly 45 coin. So that was probably as high as I went. You had the $9 games from Ainsworth, but there was no way you could bet $250 a spin. But now that's all out there. So to me, the moral of the story is be afraid, but only be afraid when it's warranted. I limit my chances of being struck by lightning by not swinging a nine iron in a thunderstorm. Right?
So I limit my exposure to a jackpot that I can't overcome for a year by limiting the potential on games that do say $200 a day to $500 a day. A $75,000 hit on a $25 slot machine that does 250 a day is more of a threat, than that $50,000 hit on a game that does $3,000 a day. It's just simple math. So I'm reminded of, I had a $25 machine at my casino that hit the top award, 75 grand, three times in three weeks to my best customer. So that makes you start questioning everything you know about math. But then the top award didn't hit again for two years.
Nick:
Yeah, exactly.
Don:
So slot math is fun. It'll drive you crazy sometimes, but I'm more worried about those types of hits than high limit, because I know they're not getting the coin in. I'm not worried about as much of the liability on the Dragon Cash's, the Dragon Links and High Limit, things like that. And you can really set it up to where there's max bets of 250 bucks, or you can set it up or there's max bets a lot lower. And what we're really starting to see, Nick, we did that study a while back on what the sports book players are playing. And it was a fascinating study, and one of the favorite ones that we've done in the last six months. And the number one game is Dragon Cash. And it really surprised me. I had no clue it that a high limit video slot was the number one game for sports book players.
Nick:
Yep. Yeah. That as we had discussed last time, I think basically all the insights that you generated out of that study, everything that I saw was counterintuitive. There was not one thing in there where I said, "Oh yeah, that makes sense." It was absolutely the opposite of what I had expected with those younger players, I have to say.
Don:
Yeah. I made some predictions going into that study about percentage of wallet that they would spend on tables versus slots. And I had it totally mixed. I thought for sure it'd be 80% tables and 20% slots. And it's not, and we had it confirmed by the chief marketing officer of one of our clients, and say "That's exactly what we're seeing. They're playing slot machines, both the men and the women." And it was really interesting to look at which themes that they're playing, and which themes that they're not. And these people are chasing jackpots. They're playing Dragon Cash, they're playing Lightning Link, they're playing Ultimate Fire Link. They're playing very high volatility slot machines.
And the weird thing was they were staying away from electronic table games. They were staying away from physical reels. And they were really staying away from video poker too, which kind of makes sense. Video poker's more of a game that you play after you've been going to the casino for years, than walking right in. And a lot of these customers are going to the casinos for the first time, the sports book players, the under 45's. But Dragon Cash is right at the top of the list. And the average bets, especially for the, there's a little bit of a difference between the 25 and 34 year olds and the 35 to 44 year olds. And it kind of makes sense, the people under the age of 35 just don't have the wallets that the people between 35 and 45 do. And their average bets, their session times, are three times the volume of the under 35's. But they like these volatile games. And now we're starting to see casinos add Dragon Cash with a million dollar grand jackpot.
Nick:
Yeah. Some of those seed values are nuts. We saw that high roller room, our high limit room at the Wynn Las Vegas, and those were some bonkers values on those machines. Do you know how they had that set up?
Don:
No, I remember walking into that beautiful room. Boy, that's a great room. Yeah, it is. And that's a wonderful property. Anybody who wants to study floor layout, and how to design a casino without walls, without the use of walls for slot machines. Walk Wynn Las Vegas. They've done an amazing job.
Nick:
That's true.
Don:
But when we walked into that high limit room, beyond the decor, which was just stunning, the machines that were getting played were the high limit Dragon Link and Dragon Cash games. But I know that, I think we were looking in the...
Nick:
It was like a $25,000 mini.
Don:
I want to say it was 125,000 for the one, 25 for the other. Those are the ones that I remember. And they were getting played, I mean they were getting played hard. And I can still remember who was playing it. It was men under the age of 45.
Nick:
Which I remember you mentioned that afterward. I hadn't really taken note of that, but I remember you said that to me. I said, "Yeah, you're right."
Don:
And the same thing was happening when I walked through a couple other casinos when we walked through resorts. Heck, even when I walked through the Flamingo, and they're got a high limit room that's probably got 30 machines in it. You couldn't get on the Dragon Cash because it was young men that were playing those games. So it's something that since I've done that study, I look at every time when we're traveling the states. Or if I'm in my local casinos, I'm just really curious as to who's playing these high limit games, and what their average bets are. And it has just been fascinating to watch. I'm not privy to those actual Wynn numbers for those casinos that have that Dragon Cash with a million dollar grand. But these casinos are some of the best run casinos in the world. And they've had these for years, and I don't think they'd still have them if they weren't performing at a high level.
And I just heard yesterday that another casino out west is adding that million dollar jackpot on the Dragon Cash. So you're starting to see these people chase these large jackpots, just like you chased a Powerball or the Mega Millions lotteries. And the win per unit per day is supporting it. It's going to be a hit when it hits. I know I read the story, it's probably over a year ago now, that the same lady hit two of them down in Florida. And a million dollar, two jackpots. But it's if they perform, why not?
Nick:
And When we look at the mathematical modeling of the game we saw, I mean it's absolutely entirely safe, as you would expect. So Don, can you really think, maybe just the way to turn this around is, what would be some reasons that you wouldn't put it out there? What are some thoughts that you have on that?
Don:
I think the smaller the casino, the more you have to be wary of it. And it's both on the Dragon Cash level and on the Ultimate X Bonus Streak. Some of your high limit games. If you just don't get the coin in, or if you just don't have the customers to support games that have an average bet, say north of $7 a spin. If you've got a casino that's small, doing 2 million a month or less in revenue, 3 million a month or less, you'd really have to know your customers. If you've got enough customers support it, great.
But if you've only got one or two, it's probably going to be tough to overcome it. But looking at the win per units, and we've got some casinos that are clients that have revenues of less than 5 million a month. I'm looking at what is the smallest one I see, theoretical, I'm still seeing, the lowest I see out there is $22, 23,000 a month on theoretical. And those are the outliers. Let me sort this a different way. Get rid of all the partial month stuff. Full month, last month, the smallest one we had was 42,000, I think, about. There's a 29,500. So you're looking at a thousand dollars a day for some of the smaller casinos that we have as clients. So that's not bad, even for a small casino to have a game that you're probably looking at a floor average of 150, 175, and these games are doing five times the house.
Nick:
Yeah, yeah.
Don:
And for those casinos, you really have to make sure, that I would really start those jackpots at 25 grand.
Nick:
Okay.
Don:
Because even at a thousand dollars a day, if it hits once a month, you're going to break even. And these things don't hit once a month, especially for a small coin in. The coin in, let me go back to that line. Your coin in for these casinos that are doing 29, you're looking at, well, coin in there must be a 10% hold because the coin in is 295,000. So you're looking at, so $300,000 coin in. That jackpot's not going to hit for 2, 3 years? So it's going to be a while. A lot of those jackpots now have a lot of coin in input before they hit. And you're looking at 15 million worth of coin in before, on the average, these things hit. So it takes a while, even for your best casinos that are doing a million and a half, 2 million in coin in, these things don't hit every month.
Nick:
Yeah. And then Don, once they're set up installed and whatnot, then you use your same guidance then on those, trying to maintain an occupancy around 65% and that kind of stuff. Is that the way you look at that?
Don:
For the Dragon Cash, I always thought it was a little bit lower. I was comfortable in that 30% range.
Nick:
Oh, okay.
Don:
Yeah. Just because you want to have these games available to your best customers. And how frustrating would that be to your best customers to come in, and they can't play a game where they want to stick two or $3,000 in the game before they even start spinning. And so I would say anything north of 30%, you really need to look at it and say, "You don't have enough of them." And I'm starting to see some densities out there. The densities are definitely growing. This is a growing game, and it's got momentum. There's a couple of games like that that I'm really keeping an eye on.
And most of these games are in that 20 to 35% range. So we're seeing handle pulls north of a hundred thousand to 150,000 on these games, which is really amazing. So that'd be something I'll have to look at Nick, is where do Dragon Cash, at a $10 average bet rank as far as handle pulls, versus the rest of your floor with an average bet of a dollar? And just looking at some of these handle pulls, we've got handle pulls of 200,000 in a month, so you're looking at 7,000 pulls per day. That's incredible.
Nick:
Without question, and I know, Don, on this, so I think just generally, I know I've heard you say a couple of times, that on that specific Link, the Dragon Cash Link, I know I've heard you say a couple of times that there just aren't enough of them out there, at this point. Is that, you still feel that way?
Don:
Absolutely.
Nick:
Okay.
Don:
We did some studies on a couple of properties recently where their Dragon Link densities on weekdays was pushing a hundred percent. There's no need for that. You are just costing yourself money because the people who are pounding these games are your best customers. And same thing with Buffalo Link. You're seeing average bets north of $3 a spin, and people can't get on them. People, your guests will expand their wallets if you give them the option to. And if they play their favorite games, they will bet more. Every study that we've done has supported this for the last two years, that you need to make sure that you have your customer's favorite games. Because when they play their favorite games, they bet more, and they play longer. The session times are just incredible.
Nick:
Yeah, I know. I've seen some of these that are well over an hour.
Don:
Yeah, it was. It's north of 20% of the sessions on Dragon Links are over an hour before they get off the seat.
Nick:
Good Lord.
Don:
Yeah, no, and you're talking about an average session time of eight or nine minutes for a normal slot machine. And one out of every five trips is an hour in length before they get up. And I haven't seen anything that points to that they're doing anything but playing and chasing the jackpots and chasing that major. Most of them don't even chase the grand. It's nice when that grand gets tapped out at the 18,888, but most of the time they are chasing that 800, $900,000 major. And they will sit there and play. It's a great game. The designers of Dragon Link and Buffalo Link and Lightning Link did a wonderful job. And they really do attract the host level players. So yeah, there are very few, if your casino has a density above 65%, and this is for a monthly average, you really need to look hard at expanding. Because I know that your weekends, those games are packed. And unless you have zero host level play on the weekends, they all come on the weekdays, you are costing yourself money.
Nick:
Yeah. And Don, one thing I wanted to ask is, you had mentioned earlier that your favorite, that the setting you always recommend on Dragon Cash is a 10 cent denom. Is that what you said earlier?
Don:
It is. It's the one that seems to do really well. There are more and more Dragon Cash settings at a higher limit. But they start off at 5 cents, but the 10 cents did really well. But I'm starting to see games out there with a dollar as your base denom, and $25 as your max, and that's those $250 max bets. But there's also a $2,500 max bet setting out there. And so you've really got to have some high rollers that'll do that. But it's happening. But I like the 10 cent one for most casinos. But if you've got a high volume, high net worth, high limit room, you can start looking at some of these Dragon Links for high limit or Dragon Cash's and stick them at a dollar based denom.
You're looking at some really nice average bets. And a lot of them, like I said, are $250 max bets now. But the math models are pretty much the same as I looked at them. Incrementation rates are about the same. You're looking at about a 1%. Hit frequencies and the bonus rounds are about the same. They're pretty much standard for all the video games now. You're looking at somewhere between 120 spins to get the bonus round to 140. And pretty much everything I'm seeing, obviously you've got some high level jackpots, when you have these games, when you're starting at a dollar for a base denom, but the math looks really good. You're seeing more and more.
Nick:
Yeah, there was certainly nothing that I saw in there that gave me any jitters whatsoever, so.
Don:
You can set those grands at 50,000 or a hundred thousand. And you're looking at decent hit frequencies, and the jackpots, when they hit, aren't that bad. You're looking at stuff over 10 million pulls and a hundred million pulls that look really reasonable. So that's the great things about these games. And I don't know how many par sheets that I've read in my life, but it's well over a thousand. And get the par sheets. Look at the math, talk to your reps about this type of stuff. And it's the same for the Light & Wonder games. There's some really neat Light & Wonder stuff out there in high limit. The Aristocrat stuff gets a lot of attention because of the great success that Dragon Cash and Dragon Link has, but I'm really seeing some really neat stuff. Heck, I'm even seeing some really good stuff out of Prosperity Link from IGT. So there's some good product out there that everyone needs to have on the floor.
We were looking at one of the properties the other day, they asked me to pick their next 60 games that they needed to buy. So I looked at everything from conversions to where they're going to spend their capital. And I looked at their leased games, and I realized they didn't have Buffalo Link on their floor. Why? So you really need to be spending your leased game budget wisely. And there's so many games out there that are doing three times house average, that you really shouldn't have any games that are doing less than one and a half or two times house average. You've really got to spend that money wisely and have the cream of the crop. And there's a lot of good choices out there right now, and you really need to expand, going back to your original. Plus, Nick, it's a long way to get there. But most casinos can afford to have three or more banks of Dragon Links.
It's what the players want, and that's what we've been talking about for the last year, is giving the players what they want. They will come back to your casino. When I started this, we were talking about Split Card Poker. It's my favorite game, but good luck finding those. Or Big Split Poker. So there's even in the local market, I know where those games are. And when I want to play, I know which casinos to go to. I'll walk the floors at the other ones, seeing what's up. I enjoy walking slot floors. But while I'm there, I also look to see if they have my favorite games. And it's the same thing in Vegas. I know exactly which ones, which casinos have my favorite games, and that's where I spend my money.
Nick:
Sure, yeah. Why go someplace where you just get confronted with a bunch of junk that you have no interest in? So I think we've been, now we're sounding like a broken record on that one though, so.
Don:
I know, but it's such an important point.
Nick:
It is. No question about it.
Don:
People spend the most money where they enjoy playing. And I enjoyed the Life of Luxury's and the old BB2's as much as anybody else, but I've kind of moved on from that. And I enjoy the volatility. I still play. I was one that always put a $20 bill into every slot machine before I bought it. I was lucky enough that I could do that, especially being in a jurisdiction that was always just a little bit slower on approvals. So then I'd go to Tunica or I'd go to Vegas frequently enough that I get to play everything before it came out, or play in the showrooms. So it does make a difference.
Nick:
Okay. So I think... Oh, go ahead Don. Sorry.
Don:
I was going to say, have I talked long enough?
Nick:
Oh, yeah. Well what I wanted to say is I think what it sounds like, generally, then, on these products with these jackpot liabilities, is I think just with a possible exception of real edge cases, where I think you said something like your casino's doing less than 2 million in slot revenue per month. Or if you know you just don't have the players who are going to play at those higher average bets. Short of that, you're golden, I think. We get jitters on things like this quite often, but it's very rare that we advise somebody against taking on something like this. And I think that's probably where you land on this as well, Don, is it not?
Don:
Absolutely. I'd be more afraid of putting in a hundred dollars slot machine with a high top award than I would be of putting Dragon Cash on.
Nick:
And with some of these products, and the performance we're seeing out of them, I'd say I'd be more afraid to not have them, than have them. Because you really do want that play. And as we've seen, players, big players, do come in looking for those products specifically. That's really what they're after. They want to get them, and hit them hard. And yeah, I'd be a little bit more concerned about not having them present, really.
Don:
Absolutely. And you don't have to limit it to the high limit room anymore either. You can put these on the floor, and they work really well out on the floor. There's more and more casinos that are denom-ing up their video. And making a section where the smallest denomination for a video is going to be 5 cents or 10 cents, including the new cabinets. So yeah, don't be afraid to put these out on the floor. There's a clientele that will not walk in the high limit room.
Nick:
Absolutely.
Don:
But if you put these games on the floor, you're going to open it up to more people that will trial, and take a shot. Heck, I remember back when I first started counting cards, when I was done playing for the day, I'd always walk by the roulette table on my way out the door and put a hundred dollars on my favorite number. And I knew it was a reverse savings account, probably once every 40 times it would hit. But once it did, I'd get my 3,500 back. And there are people who will do the same thing, with these games on your casino floor, they'll put a hundred or 200 bucks in there. And they'll take their chance at 7.50 a spin for their 30 or 40 spins, whatever it is, and take their shot of getting a bonus round and hitting something good. So don't just limit these to the high limit room.
Nick:
Yeah, absolutely. It's a excellent piece of advice. And like you, Don, I'm seeing them spread around the floors more and more and generally rammed. That's what I am finding. Okay, cool. Well, I think by and large, we've probably drained the topic. And I think the kind of bottom line on it is, short of those just very rare exceptions that we talked about, very small slot operations, or just not having the players to carry, short of that, we think you're just absolutely safe as can be to take these things on. Okay.
Don:
Absolutely.
Nick:
Yep.
Don:
Then I was going to say that, go ahead Nick.
Nick:
No, go ahead, Don, please.
Don:
I was going to say that, thanks to all the people who have reached out with all the kind words about the podcast, there's not a week that goes by where I don't get a note from somebody I've worked with in the past. I had one of my old slot performance managers say these podcasts remind him of me and him sitting at my desk just talking about slot theory and practice and how much fun that is. This is the fun part of the job, trying to figure all this stuff out. So if there's any topics that you want us to cover, please let us know. We can go in so many different directions and we're really enjoying this.
Nick:
Yeah, for sure. And I want to echo that as well. We've had a lot of people say a lot of nice things, and we really do appreciate it. As I had mentioned previously, we do this for no money, and generally on days off and whatever. So we really do love it to hear that constructive feedback, really makes it all worthwhile for us. So thanks for that.
Don:
Yeah. We need to put some advertising or something on this.
Nick:
Yeah. So this is...
Don:
Sponsored by Pepsi or something.
Nick:
Anybody who wants to send us money...
Don:
Monster, Gold Peak Tea, whatever it is.
Nick:
Yeah. Perfect. Okay. So before we go, I did want to make a couple of announcements. So I guess it wouldn't be ReelCast if I didn't plug something. So as mentioned, I'm going to be speaking at AGS's GameON events in Mexico City on May 4th, and in Reno from the 13th of June to the 15th of June. And after that I'll be speaking and we'll be doing a little booth at Raving Consulting's Casino Technology and Marketing conference at Grand Sierra in Reno from July 17th through the 19th. So if you have some travel budget that's burning a hole in your pocket, we'd love to see you at any or all of those events. And then lastly, I did want to mention that we're now making transcripts of these podcasts available. So if you'd like to grab them, head on over to ReelMetrics.com/ReelCast and get your transcripts on, I guess, is the thing to say. And I think with that, Don, I think we're at the point where we can wrap it.
Don:
Awesome. Always fun, Nick. Thank you.
Nick:
Yeah. Okay, you too, Don. Thanks. And you have a great rest of your day there.
Don:
You too.
`
};

export default S02E04;
