import React from "react";
import ReactGA from "react-ga";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E02 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S03E02.defaultProps = {
        id: "14577542-s03e02-delaware-north-with-michael-carruthers",
        link: "s03e02-delaware-north-with-michael-carruthers",
        title: "Delaware North with Michael Carruthers",
        subTitle: "Season 3, Episode 2",
        description: "In our second episode of Season  3, Nick and Don speak with Michael Carruthers, Vice President of Gaming Operations at Delaware North. Michael shares his insights on the company's gaming operations, the impact of the pandemic, and the future of the industry. Join us for a deep dive into the world of gaming operations.",
        episodeNumber: 2,
        seasonNumber: 3,
        datePublished: "2024-02-13",
        topics: [
            "What are the financial considerations for casinos when deciding between leasing and purchasing slot machines",
            "Business overview of Delaware North", 
            "Delaware North's gaming division - past, present, and future",
            "How has Delaware North adapted its gaming operations in the face of industry changes and challenges",
            "What role does data analysis play in modern casino management and decision-making",    
        ],
        keywords: [
            "Delaware North",
            "Michael Carruthers",
            "Gaming Operations",
            "Casino",
            "Gaming",
            "Slots",
            "ReelCast",
            "ReelMetrics",
            "Gaming industry insights",
            "Casino operations and management",
            "ICE show London to Barcelona",
            "Slot machine leasing vs. purchasing",
            "Cashless transactions in casinos",
            "Delaware North gaming operations",
            "Career in gaming industry"
        ],
        buzzSproutLink:
            "https://www.buzzsprout.com/2057836/14577542-s03e02-delaware-north-with-michael-carruthers",
        spotifyLink:
            "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
        applePodcastsLink:
            "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
        iHeartRadioLink:
            "https://iheart.com/podcast/104029260/",
        cdcLink:
            "https://cdcgaming.com/reelmetrics/s03e02-delaware-north-with-michael-carruthers",
    
    transcript: `Nick Hogan:
    How are things in Illinois today?
    Don Retzlaff:
    Good morning, Nick. Everything's just fine. We had our typical weather where we had five inches of snow one day, and it was 70 degrees two days later.
    Nick Hogan:
    Yeah, right on time.
    Don Retzlaff:
    Yeah, that's February and March in St. Louis.
    Nick Hogan:
    Well, so here we are in late February, and well, sale-wise, this has been one hell of a start to the year. Several new clients onboarded, more on the way, and the days are just cooking by. I'd be up for every year starting this way. So we also have another ICE show in the tank. So this one of course was the 34th and final ICE to be held in London, with the whole works moving down to Barcelona next January. So a bit of a bittersweet event this year, but in the final tally, I think pretty much everybody was ready to turn the page there. It was already an extremely expensive and logistically difficult show, and certainly post-Brexit, everything just got more complicated. So it's going to be hello paella and so long boiled beef and cabbage.
    Let's see. Also since our last episode, one of us had the unmitigated gall to take a vacation. So you did a cruise around the Caribbean with our friends at Carnival, and I'll preempt next month's listener questions and ask how the slots treated you.
    Don Retzlaff:
    First off, Carnival has two casinos on Magic. One's smoking, one's non-smoking. And they did a really nice job on their game selection and their layout. It's not easy on a cruise ship with all the poles and support beams and everything else, and the low ceilings. You're not seeing a whole lot of Neptune Doubles on there, but a lot of new 49 Seas and Neptune Singles and Kascada Dual Screens and stuff like that. Everything basically banks a six. So they did a really nice job of laying out the casino.
    Unfortunately, I know on a cruise ship, the whole percentages are a little bit higher than what I'm used to in my local casinos, and it showed, although some of the people I went with actually hit some really nice jackpots and came home winners, I was not as fortunate.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    Yeah.
    Nick Hogan:
    I was expecting to hear about it. I was watching the news for an unscheduled port of call to replenish the cash in their cage or something.
    Don Retzlaff:
    Yeah, no such luck. And then after the cruise was over, we hit Hard Rock Hollywood down in Fort Lauderdale, Hollywood, Florida, and that place is a license to print money. What a nice place they have down there. Oh my goodness.,
    Nick Hogan:
    Indeed.
    Don Retzlaff:
    2000 plus slot machines, 200 table games. We were there on a Sunday evening, and the place was packed.
    Nick Hogan:
    Yeah, yeah. They have some fantastic business down there. Oh, yeah. Almost forgot. Have to gloat on the sports side of things. My alma mater, university of Iowa had cause for celebration last week with Caitlin Clark breaking the NCAA women's basketball record for career points. I take it you saw that game?
    Don Retzlaff:
    Absolutely. She hit a shot that was just an unbelievable long three pointer to break the record.
    Nick Hogan:
    That was a 30 footer.
    Don Retzlaff:
    She's something else. She's fun to watch.
    Nick Hogan:
    That kid is absolutely incredible. It's just really pride of Iowa at this point. So-
    Don Retzlaff:
    Pretty much.
    Nick Hogan:
    Okay, so time for listener questions. Before I dive in, let me say that we love to tackle any questions that anybody listening may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T@reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly, and don't worry about us revealing your identity. That's not something we do.
    So here we go. This one comes from an operator in the American Midwest who writes, I head up slots at a small casino with a daily net win in the $80 range, and I'm considering leasing a much larger percentage of my floor. New games are costing us around $30,000 these days with conversions running around five KA pop. If I were to lease games at an 80/20 split, and they do 1.5x floor, the annual lease costs would be $8,760 or $43,800 over five years. If they were purchased at $30,000, converted four times over five years, that comes to $50,000 excluding parts and maintenance. Sure, I'd own a core game outright, but that's not worth much considering. I'll be lucky to offload it for a thousand bucks in the second hand market. Sure there are financial advantages to owning certain games, but I'd guess that this applies only to about 15% of themes released. So what are your thoughts on this? Many thanks to the listener for that question. It's a great one, for sure. And Don, this is about as a central to your wheelhouse as it gets. So you want to hit that one?
    Don Retzlaff:
    Sure. And it's something that we talk about a lot, is expanding your lease games, and the cost to own versus the cost to lease. And she's got all the math right. You're looking at about $30 a day over the cost of five years for buying a slot machine. And if you can lease one for $24, go for it. I think it's a great move. You know you're going to get better revenue out of them. There is probably a tipping point, but you probably won't get to that. I wouldn't worry about it until your past 25% of your floor being leased games. But I can imagine if you're only doing $80 a day, that you would likely see a revenue bump, just because of the quality of games on the floor. The higher average bet, the longer session times. So it's likely a win-win situation for you until you get to half of your floor being leased games, then you definitely have to increase revenue.
    There are some tax advantages to owning some of the games, with depreciation and stuff like that, but it's not going to make a whole lot of difference. So I think the short answer is do exactly what you're thinking about and lease more games and purchase less, apply some of that CapEx dollars towards your lease fees. It's probably a really good move.
    Nick Hogan:
    And I have to say I really like the perspective and the thinking on this one. So it's really looking at it in terms of productive capacity and income over time, as opposed to a way to juice and get free assets on the floor and whatnot. So I have to say it was refreshing to hear that kind of calculus being expressed. And yeah. Again, thanks.
    Don Retzlaff:
    We talked a lot about generalities. Once you get to a certain percentage, you should add more, but every casino is a little bit different. If your place is doing $700 a day win per unit, it's going to be really tough for other units that you have on your floor lease games to do three and four times house, because your games are getting played all the time. But if you've got a smaller place where your occupancies never get really, really high, your lease games could do five and six times before you really think about expanding them. So this would be a neat little experiment to try this. I bet you this works really well for them.
    Nick Hogan:
    Yeah. And to the listener, if you're willing to do so, please keep us posted on this. We love to hear the outcomes on these things, but thanks again for that question. Greatly appreciated.
    So let's. See time to introduce this month's guest, Don. So this gentleman began his gaming career in performance analysis in the California tribal market a couple decades back. And through grit determination and a keen analytical mind, send it to operational roles with Spa resort and Casino, Casino Palma, and Wheeling Island Casino. And these days he serves as vice president of gaming operations for one of the oldest and most storied operators in America, Delaware North. So I'm referring of course to Mr. Michael Carruthers. Michael, hello. How are things in the big easy today?
    Michael Carruthers:
    Hey, good morning. Thanks for having me, Nick and Don, and I appreciate the invite and the opportunity.
    Nick Hogan:
    Oh, you bet. By the way, I learned today that you're an MSU bulldog, so I hope my Hawkeyes reference wasn't distasteful to you.
    Michael Carruthers:
    It's a little painful from a couple years ago in the Bull game, I'll admit. No hard feeling.
    Nick Hogan:
    Okay, very good.
    Michael Carruthers:
    We're at great baseball season now, so it's okay.
    Nick Hogan:
    All right. Well Michael, as I mentioned here in the intro, my understanding, so your career in casino operations started around 20 years ago with Agua Caliente there in Palm Springs. So can you walk us through your journey there from the first operational role to heading the gaming operations at Delaware North?
    Michael Carruthers:
    Yeah, sure. 20 years, makes me feel really old all of a sudden, so thanks for that. But yeah, this is my 20th year in gaming. So I broke into the market 2004 with Agua Caliente Casino. I started out as the admin assistant to the marketing director and spent my first three years working in marketing in numerous roles: from advertising coordinator, special events coordinator, ran the call center for two properties. Then I made my segue into slots as the performance manager and spent some time there and worked my tail off and learned all I could from some great mentors and people above me, and got into the director role, which a couple years later became a dual property director role. We have this property downtown Palm Springs and one Rancho Mirage. So I got the opportunity to oversee both of those. And that, I think, set me up for what I'm doing today. A lot of the things that I learned then and we practiced, was able to bring to this current role today.
    Nick Hogan:
    Okay. And did you originally meet Brian Hansberry there? Was that your connection there? Okay.
    Michael Carruthers:
    I did. Yep. He was our CEO for a period of time. I had three or four other GMs before him, but we did meet at Agua Caliente.
    Nick Hogan:
    Okay. All right, very good. So now we have to talk a little bit, of course, about Delaware North, because relative to other gaming operators, is a truly unique organization. So in addition to being more than 100 years old, it remains a private family-owned and operated company that employs around 50,000 people and is involved in a very wide range of businesses. So could you walk us through the company a bit and give us a feel for just an overview of the various business lines?
    Michael Carruthers:
    Yeah, sure. The company is over 100 years old. It's been based in Buffalo the whole time. Buffalo, New York. I think it's-
    Nick Hogan:
    On Delaware and North, right? In the corner there. Is that right?
    Michael Carruthers:
    Used to be on the corner of Delaware and North Street. That's how they got the name. And people always think that we're a Delaware company, but no, it's Buffalo New York.
    Nick Hogan:
    That's a beautiful office there, by the way. God, I love that place.
    Michael Carruthers:
    Beautiful office. And the summers there, July and August are fantastic. Right now, maybe not. July and August are the months to go. They have beautiful summers there. Yeah, the company, like I said, 100 years old. Started out selling popcorn at stadiums at ballparks. Growing from that to being a global hospitality leader in entertainment and restaurants and destination properties, gaming. We operate concessions at ballparks, airports. We're in the UK, we're in Australia, New Zealand. It's really an impressive company.
    Post-COVID, we had 40,000 employees worldwide. We were close to 50,000 pre-COVID, so 40,000. And I think in 2022, we served over a half a billion guests worldwide. That's just amazing to think the volume and the number of touch points that we have out there.
    Nick Hogan:
    Oh, for sure. And I did catch a tidbit. I was reading a little bit about the history. And so I guess it started in 1915. And what it said was that they were doing concessions in theaters, so performance theaters and whatnot. And I just never thought about this, but it was apparently during the summer it would get too hot. And so that was the original idea for moving to ballparks, because people went outdoors, and you just think, 'Man, that goes back away.'
    Okay. On that I'd also read that their entry into gaming was all the way back in 1939 when the company bought its first racetrack. And just from what I could gather there, continued expanding over the decades, culminating the development of a lot of racinos in the 90s, then branching out into casinos routes and international projects. So can you give us a sense of what your gaming operations look like today, just in terms of the scope and scale and all that?
    Michael Carruthers:
    Yeah, so today, we have over 13 U.S. gaming facilities, and one in Australia, I think a total of nine different jurisdictions. We own or manage over 12,000 slot machines across that portfolio. So we're in HHR Market, Class III. We own a route business in Illinois, the VLT space in Ohio, the VLT space in New York, and a property in Darwin Australia, full-blown cluster facility with tables and slots. And yeah, it's a pretty big operation, and a little bit in every different segment of the market.
    Nick Hogan:
    Now, just out of curiosity, in that Darwin venue there, is that mainly more of a destination market or is it more of a locals market there?
    Michael Carruthers:
    It's a little bit of both. It's on the top, they call it the top end in Australia. It's the farthest, one of the most northern points out there, but it's close to Singapore and some of those other Asian countries. It's a five-hour flight from Sydney, just to give you the size-
    Nick Hogan:
    Australia is huge.
    Michael Carruthers:
    There's no interstate travel. You got to get there by plane. So it's a local or destination market, for sure.
    Nick Hogan:
    Okay, very good.
    Don Retzlaff:
    Excellent. And I know Michael, being from St. Louis, obviously I'm more familiar with Southland in West Memphis. And I guess I'd like to ask you about the expansion that you underwent the last couple of years. I guess it was about $300 million, and it totally transformed that property from the old west Memphis Southland to what I saw yesterday is now in the top 20 revenue producers in the United States for commercial gaming outside of Nevada. It was extremely impressive. I know you've put a lot of capital in it, and it looks like you're still picking up market share down there. So what's the product strategy been for Southland in terms of new cabinets and lease games?
    Michael Carruthers:
    Yeah, that's been a great story. The story of Southland over the last 15, 20 years, going back to the flood that took place in Tunica is really what ramped that property up. And it's been just on an uphill growth climb since then. It's a fantastic property. The process of expanding the building was started before COVID and had to sit idle for a year and a half. But we finally got that done in 2022, opened up a brand new facility and a brand new hotel with the goal of being a destination market. It was always known as the track to the locals. We wanted to break that stigma and be known as a gaming facility and a destination resort. And the product ended up, you've seen it, Don, the product ended up turning out beautiful. We went with a free span structure, so there's not a single column in the building. It's just wide open and bright.
    And our philosophy around game purchases and product really changed around that same time too. A lot of that was based off of the data we saw from what we did during COVID and a lot of your post-COVID analysis as well, where we turned off 40% of our floor and we kept the best of the best on. And our numbers stayed flat or were up year over year, which was baffling. So that really changed our philosophy on how we approach things. And instead of giving, here's our capital pie, we're going to give everybody a little slice. You might get four. We don't do that anymore. It's probably limited like six, maybe seven vendors deep a year now we're going for some of these purchases. It's really very, I guess, stingy. It's an all star floor, and our approach is the best of the best.
    Don Retzlaff:
    And that's a great strategy. And you guys have done a really nice job. You've got a really nice team down there, first off. From the GM down to the slot people. And great people, great people to work with, and one of my favorite management teams that we work with. And the lighting down there is fantastic. The colors are fantastic. You've done a great job of shaping your banks and keeping them small.
    And you can tell you put a lot of thought into your purchases, and it shows, and it shows in your results. And you spend that much money on a property. And I saw your year-over-year results from '23 to '22 were really nice, and nice increase. And I hope you're off to a good start in '23 and '24. Back at G to E when we were talking, you were headed to Australia down to your Dharma property. I'm sure that takes you a while to get down there, but I'm curious, we've always heard that things that happened in the Australian market eventually happened in the United States market. I remember talking to the Aristocrat folks back when Lightning Link was at the top of the charts and they were super excited about that. Are you seeing anything locally that you're waiting to get approved in the United States? Anything on your radar?
    Michael Carruthers:
    Today, there's not a lot today, but back to your point, we have seen that in the past. We've seen Grand Star wasn't Aristocrat product that came out of Australia. It was actually available on Aristocrats gaming app here in the U.S. before it became a U.S. product. Didn't quite have the success in the States as it did in Australia, but we also see the same thing, though I see products here in the U.S. that might start here and end up in Australia, that we'll get a Darwin at some point that are strong performers. So that has been a good tool for us to gauge and prepare and see.
    Today, I think another example would be IGT's Outgrow Link that's been in Australia for quite a while, more new here to the States, but there's a few. Yeah.
    Nick Hogan:
    Yeah, it's always been an interesting... I think it's been a huge advantage for Aristocrat in that New South Wales market. There's so many units out in that market. And of course, I think Australians, on a per capita basis, remain the strongest gamblers on earth, that really, really sophisticated gamblers. And I think it's been huge, of course, for the companies based down there, because they get so much of that developmental feedback from just such a fantastic client base in there.
    Michael Carruthers:
    I was thinking about this yesterday. A lot of your prior podcasts talked about demand-driven inventory, that kind stuff, and that was really a lot of byproduct of analysis that you did post-COVID.
    Nick Hogan:
    Absolutely.
    Michael Carruthers:
    Thinking back about it yesterday, we owned two pubs in Brisbane, pre-COVID, 40 machines in each location. They called them hotels, but it was really just a restaurant bar with gaming attached. And while we were over there in that market doing research and looking at some of the most successful pubs in that area, we would go into a place that had 40 machines, and it would be 36 Dragon Links, and four [inaudible 00:20:51] videos.
    So they really had that demand-driven inventory stuff down pre-COVID, and at the time, we were like, 'Who would do 36 Dragon Links in a pub?' And look at where our floors are today, right?
    Nick Hogan:
    Yeah. The answer is, those who like money.
    Michael Carruthers:
    Yeah.
    Nick Hogan:
    It's been incredible, for sure. Well, on this note too about... So as a multi-jurisdictional operator, one of the things that always fascinates us is the cultural model as it pertains to the operational decision making.
    So in essence, we basically see in the industry two models, there's the centralized model where the bulk of these decisions really come from corporate, and then the decentralized model where it's all, the decisions are really distributed and a lot of autonomy given to the property level. And I think what's always fascinating to us is that although we see the merits of each model, it's a little bit difficult to determine which one we prefer, because it's really a fascinating topic. So just assuming that there's a type of centralization spectrum, where would you place yourselves on this and what are some of the pros and cons that you see as you guys operate?
    Michael Carruthers:
    Yeah, I would say that our model today is probably a hybrid of that. So there's a lot of things, there's a lot of synergies that we can centralize our CMSs. When I came into this role, every property was going out, buying locks from different vendors, bases from different vendors. We were getting zero buying power out of that. So we've standardized all of our chairs and bases and products. And it's really just helped leverage discounting, better pricing across the board. If I have a property that's growing and maybe one that's shrinking, I can move chairs or bases from one to the other, and they match. I don't have to go out and buy a different color.
    So we've really put a lot of thought into that as we build and design properties, the color palettes, the carpet, the chairs, they all complement one another, so we can reuse that product across our brand if we need to. I think where we have some autonomy is the properties are regional and there's different specific things. Memphis is different than West Virginia. They might be Saints and Titans fans versus Steelers fans. So the promotions are different, the offerings, the gift giveaways, there's autonomy with that kind of stuff, I think, within the marketing teams. Centralized, we have naming convention standards, pricing standards, that kind of stuff that we control from a corporate level. But it's really a hybrid model, and it's been good for us. It's taken a long time to get where we're at. I've been in this role for seven years, and I think we still have things we can clean up, but keeps you busy, never ending.
    Nick Hogan:
    Well, and certainly post-COVID, so you mentioned this strategy where you're not just, let's say, sprinkling, spreading the risk, it was the typical strategy, historically, and looking at ship share data and then allocating floor share, using that, et cetera. So moving away from that, did you find that was difficult culturally, internally to get people into that mindset that we're just going to approach this differently now? Did you feel much pushback from that?
    Michael Carruthers:
    Absolutely. I mean, it was really hard to control at first. Everyone was going seven different directions, and they weren't running things through a central point. So I didn't know that they were out buying bases from this company and they were buying bases from another company. And we really just said, 'Hey, everything's funneled through us. Let's make a decision as a team which direction we want go.'.
    And I said, there's still some of that going on today, seven years in, we, 'Oh, well, why are you buying something from so-and-so when we have this deal over here?' At this point, they're small things. They're not big things anymore, but we're still finding those today.
    Nick Hogan:
    Yeah, there's just so many of these, let's say, counterintuitive insights and discoveries that we've gotten, just over the last decade as the data landscape in the industry has completely changed. And we see pretty much every multi-jurisdictional operator with whom we speak, they're struggling with it on some level. So trying to move maybe more standardization as opposed to centralization and this type of stuff. So it's always fascinating for us to hear from.
    Michael Carruthers:
    And with the company our size and with the number of touch points that we have, that's been a challenge in other divisions too. You think about the ballparks and airports that we have. I think at one point we had 17 to 20 different POS systems across our company.
    Nick Hogan:
    Oh yeah. Wow.
    Michael Carruthers:
    From all the different airports. So trying to create a data warehouse and bring all that stuff in from 17 different POS systems, all the hot dogs we sell at stadiums across the country. And I think we were buying hot dogs from 40 different places. It's taken a long time.
    Nick Hogan:
    It shocks me that actually 40 hot dog producers.
    Michael Carruthers:
    That might be a low number. There might be more than that, I don't know. But those are the challenges in gaming and out of gaming that we have with the company this size. And it's tough. It's you got to just herd everything back in.
    Nick Hogan:
    And really on that point, when we talk about the overall portfolio of businesses inside of Delaware North, so we're talking industries like, well just travel and leisure generally, aviation, F&B, sports, et cetera. I have no doubt that COVID was an extremely challenging time for that organization. So can you walk us through that period and give us a sense how the company adapted to and survived what was just pretty much a terminal shutdown of all revenue streams?
    Michael Carruthers:
    Yeah. Very different times. I think looking back on the 20 years of my career, there's been two events that have really shaped us, and for the most part, made us better operators. And that was the housing bubble in '08, '09, and then COVID. So you look at our portfolio of businesses that we run, concessions at airports, concessions at ballparks, casinos, those were probably the three top revenue generating pieces of business for Delaware North. They all went from a hundred percent to 0% like that.
    Nick Hogan:
    Right.
    Michael Carruthers:
    No one was traveling. I think the rest of the year, there was no fans at stadiums. Baseball, basketball, their arenas were empty. And it went from a revenue hose to shut it off just like that on a dime. So obviously, as most people did, there was a lot of furloughs for a period of time.
    Nick Hogan:
    Sure.
    Michael Carruthers:
    Had to bring things back online slowly and methodically. Gaming was one of the first to come back online about five weeks later. At the time, we were operating in Illinois, New York, Ohio, West Virginia, Arkansas. And those were very differing states in terms of what you could and couldn't do.
    Arkansas was one of the first to come back on, along with West Virginia, but Illinois, New York, those were the laggers, and they were behind. Everyone had different roles on how you could operate every other game. Plexiglass had to be four by six or six by eight. It was different everywhere. So my job from being a person that looks at performance across the board was plexiglass, how to figure out how to tape out the floor, how to mount these things to bases. And that was months and months of my job was tracking down plexiglass.
    Nick Hogan:
    So gaming was the first division to really come back online as a revenue stream. I'm sure that they were delighted that they had that as an asset internally.
    Michael Carruthers:
    Aside from that, we do have, Ruby Seven Studios is one of our companies that we own, and that's an online social casino. And that was actually still operating through COVID, and it actually did really well during that period of time. People were stuck at home, so that helped a little bit too. But yeah, gaming was one of the first to come back online. And like I said, travel and stadium stuff were months, months later.
    Nick Hogan:
    Yeah, okay. Right. Well now, so our companies have had a relationship for a number of years now, and when we first get involved with new clients and really begin digging through the inventory and looking at things, we can determine pretty quickly the extent to which data and data analysis play a prominent role in terms of operational decision making. I recall vividly that when we onboarded your first property, actually I think it was Don when we were talking, he said, I just remember the comment, 'Okay, these guys are paying attention.'
    So can you talk to us a bit about how data and data analysis are leveraged internally, what that organization looks like? And then also, do you see it playing an increasingly significant role now in moving forward and all of that?
    Michael Carruthers:
    Yeah, coming off of the AGS conference, I think it was in 2022 when we were down in Florida.
    Nick Hogan:
    In Florida?
    Michael Carruthers:
    Florida. You guys shared a lot of data that was kind of like a light bulb just went off and going, 'Hey, I never thought about it that way.' And that was really regarding lease product at the time, not having enough of that stuff. And your top players couldn't get out on the weekends, and they were bouncing around having horrible experiences on bad games. So that changed our philosophy and our outlook on the lease side. On the purchase side that we've always... It's my responsibility, I've always thought is to make the best business decision, and to make the best decision that's going to make my company the most amount of money.
    So asking Don for feedback on what to buy, and him coming back and telling us that we made it really difficult for him to make recommendations, made us feel good. We had been doing things correctly. But we do. We put a lot of analysis into that, and I think we're only getting better at that. Just in the last couple of months, we hired two new VP of analytics to join us at the corporate level. One oversees marketing and one can see oversee operations, which is gaming, hotel, food and beverage. And we're doing some great stuff already internally with just marrying up player and slot data, which has always been tough. I've always controlled the slot data and marketing controlled the player data, but there's always been a Chinese wall between those two.
    Nick Hogan:
    Oh, yeah. Yeah. It's one of those things we see. In some organizations, it's unbelievably unhealthy. It's way more than a Chinese wall. It is a full on brick. What's that green Monster at Fenway Park? Those. Yeah. And as you say, when you merge those two and really look at them, the insights that pop out of there are just absolutely incredible.
    Michael Carruthers:
    We've paid a lot more attention to that. After some of your analysis, like, hey, look, it's your top tier players that are driving lease product, that are driving new product. It's not your everyday core grind players that are playing these things. So we've paid a lot more attention to that and made our purchase decisions based around those outcomes or that analysis.
    Nick Hogan:
    And Don, what was the stat, you've said this a couple of times though, in recent weeks where you were looking at the ages of performance core product and lease product. What were those stats that you had in terms of the age, how long they've been on the market?
    Don Retzlaff:
    On the core stuff, the stuff that you can purchase of the top 25 games, it was either 23 or 24 of them have been released in the last 18 months.
    Nick Hogan:
    In the last 18 months.
    Don Retzlaff:
    Yeah. Only Buffalo Gold collection, I think, was on the list from over 18 months ago. But on the lease side, it's flipped. The highest performing stuff is still the stuff that's been out for over two or three years. And there's a couple examples of some new stuff on there, but it's still dominated by stuff that came out four or five years ago. So your core stuff that you can purchase is lasting one to two years before it gets replaced by something else, but your lease stuff is holding on.
    Nick Hogan:
    And it's really interesting. Of course, Michael, you've seen this as you've been looking at this intersection of player and product is, when you really look at where stuff is hitting inside of the overall player matrix, it then becomes very clear why it's doing well. You can really see when those host level players are attacking something, it's clear that that stuff is going to work. And that's one of the things we find so fascinating about that intersection. And I imagine it's the same for you all.
    Michael Carruthers:
    With that data and analysis, we've had a lot of success creating neighborhoods within the casino. I've got a Buffalo range, Driving Den, I've got a UFL Zone, retro classic stuff, the old Mr Cashman, those have really made people that play those things play similar stuff. So they have their little ecosystem close by. It's been a positive thing for us, as well as changing our banking strategy, going from the 10 packs with five different themes, two of each on there to six-pack of maybe one theme or two tops. And that's helped quite a bit as well.
    Nick Hogan:
    Yeah, that whole, and again, I think that really comes back to that whole strategy that was always in place for so long. Certainly that's the way I came up, is spread the risk. Because you don't know, and there's such a huge failure rate and all this stuff, and it's just really, it's been so nice to be able to really harness that and start realizing, especially as we interact with a lot of financial people, CFOs and whatnot, that don't like to put out money for lease product and whatnot, but when you can show them that, hey, you actually have some control levers on revenue here, not just cost. I mean, that's always just, you just see the light bulbs go off a big, bright and heavy when we show them this stuff.
    Michael Carruthers:
    Yeah. I mean, what we did during COVID, and then the analysis post-COVID proved the fact that we were grossly over diversified and we didn't need that variety to that level. And going back to the pubs in Brisbane, they had 40 games, two themes, and they were crushing it.
    Nick Hogan:
    And we have some new modeling that we haven't unveiled yet, but it's coming out here quickly, and we're really going way, way deeper into this whole thing, and it is getting really fascinating. When we have a model and we start seeing something, we spend a lot of hours on conference calls, a whole bunch of us, and we're pretty excited about some new stuff, but it's really all tapping into this exact same thing, just really guiding us in terms of what stuff works, how much of it you need, and very importantly, why it's working. That's the question that's just really always eluded us in slots for so long.
    Okay. So what can you share with us? What's next for Delaware North's Gaming Division? Do you have some new projects or initiatives in the pipeline that you like to discuss today?
    Michael Carruthers:
    Yeah, there's always stuff going on. I'm not privy to what the latest is today, but our most recent venture was HHR. We opened up a facility in Nashville, New Hampshire, late 2022, about 45 minutes north of Boston.
    Nick Hogan:
    What's that competitive landscape looking like these days? I haven't been following HHR too much.
    Michael Carruthers:
    They're capped on how many licenses they can have in the state in that market. So there's one or two places within 20, 30 minutes of us, but we have a very nice large facility. We're running about 540 games in there right now. Table game has expanded a little bit, so it's a nice offering. Same thing in that market. We're seeing the same strong performers in HHR as we are in Class three. UFL, those fantastic, Blueberry's been a hammer. A lot of crossover with those two. And we're being pretty close to Boston. A lot of those players are familiar with those games from the properties down in that area, and it's been a good story for us. Yeah.
    Nick Hogan:
    Now, has there been much migration of that HHR stuff into other markets? This is another thing I really haven't been following. So are we seeing it move into more traditional game markets, for example?
    Michael Carruthers:
    HHR product into Class III market?
    Nick Hogan:
    Correct. Yeah, yeah, yeah.
    Michael Carruthers:
    No, it's usually the other way. It's usually they take Class III games and import them over HHR stuff. But the biggest challenge or the HHR stuff has been persistent state gaming, trying to tie that to outcomes that have already happened from a [inaudible 00:38:47] some other times. So that's been a challenge, but I think they are getting close to having some of those titles and themes out there from some of the manufacturers. So that'll be a big thing to watch for. That's probably the biggest thing we have missing today in that market, is those type of products.
    Nick Hogan:
    Yeah. Gotcha. So Don, do you have some more questions for Michael today that you wanted to hit?
    Don Retzlaff:
    No, I was just thinking about the Boston area, and obviously the Jacobs family owns the Boston Bruins and-
    Nick Hogan:
    That's right. And the arena as well, right?
    Don Retzlaff:
    Exactly.
    Nick Hogan:
    Yeah. Okay.
    Don Retzlaff:
    So I'm sure there's a lot of Bruins fans in the organization, and obviously, in one of the premier sports organizations in the country. It's just iconic original six. I'm thankful that my Blues got our championship a couple of years ago against the Bruins, but when I was growing up, I was a Bruins fan and a Blues fan. And so, do you get to follow... Have you been to TD Gardens and seen the game during the last couple of years?
    Michael Carruthers:
    I have been there. I haven't been there in the last year. No. I tried to get there last year for another event, but had to cancel last minute. And I thought that would come up today, honestly, some of the crossovers, what we see in stadiums, airports with how they're using cashless versus cashless in the gaming industry. And in 2023 TD Garden in Boston, which we own, was one of the first in the world to employ Amazon's just walk out technology.
    Nick Hogan:
    Okay. I'm not familiar with this. So can you give a brief overview of that?
    Michael Carruthers:
    Yeah, so it's an Amazon store that retails Bruins apparel, snacks, chocolates, drinks, whatever you want. You enter through some gates. You have a credit card that you use to enter, or you have an Amazon One app, and you go in and you just put stuff in your cart. And when you walk out, it charges you.
    Nick Hogan:
    Okay, cool.
    Michael Carruthers:
    Pretty much cashless. It's an easy way to just... They call it just walk out technology, but it's really in and out and on your way.
    Nick Hogan:
    Nice. So yeah. Okay. Then in the gaming space, what are you guys looking at in terms of these more advanced transactional technologies there? I know it's been a lot of movement and payment over the last couple of years.
    Michael Carruthers:
    There has been. The types of technology are just different. You think of a Starbucks app where you tie your credit card to it, and you just scan it and go, that's a little bit different than what you have to do to enroll in a cashless environment in gaming with KYC and the processes there. I think at this point, some of the barriers have been capital outlay. The fact that going from game to game is still faster with a Tito ticket. Until we can figure out some of those things, I don't know that we're ready for that today. We're watching it closely, and I think it will happen. There's some things I think that need to happen before we get to that point.
    Nick Hogan:
    Sure, sure.
    Michael Carruthers:
    This [inaudible 00:41:57] process. You look at who's done cashless today, I won't name names, but they've all done one part of that process really well, whether it's enrolling people, educating them on how to use the thing, or maybe one property has a really great intuitive phone-to-game connectivity, but they're not doing all things well. It's still-
    Nick Hogan:
    Sure, and we see in certain markets internationally, of course, you do see it as a little bit different. I think in some of Latin America, I think Mexico, a lot of it is all strictly cashless at this point. So like 100% carded play at one point. I think Norway has been doing this for quite some time. And most of that just came through by regulatory mandate. So it's really nice when the regulators step in.
    Michael Carruthers:
    That would make it easier. Right?
    Nick Hogan:
    Yeah, for sure. But yeah, indeed, I think it's one of the more interesting areas of slot to be watching now, just to see how these different things are incorporated. I've seen some stuff that's come to market that looks really good and some stuff that look pretty hokey, and it'll be interesting to see who emerges in that.
    Michael Carruthers:
    I mean, we're bringing that stuff, that technology into the property, into food and beverage outlets with mobile kiosk ordering and some of that stuff. But on the gaming floor, it's just not ready yet at a price that makes sense with an ROI attached to it. So still clunky. It's still a lot of stuff, and it's confusing for a lot of the players. And a real challenge that we face is in some of our markets, there's a high rate of people that don't have bank accounts, they don't like them, they don't trust them. For whatever reason, they just don't have bank accounts. They deal in cash. And that's a challenge too.
    Nick Hogan:
    Yeah, so... Oh, sorry, Don, go ahead.
    Don Retzlaff:
    You've seen the migration over the last, I don't know, five years or so at the stadiums where it was mixed use. You could use cash or cashless. And now it's rare. I can walk into a sports stadium where you can use cash. It seems like they're all going to cashless. And we've seen some movement. And to your point, there's some neat stuff coming out. The technology is continuing to improve. And I'll bet you in the next three years, you'll start seeing some really neat solutions out there with all the computing power. There'll be obstacles, whether it's a lack of smartphones by the customers in certain age groups, or like you said, like bank accounts or things like that. But it's going to continue to progress, and you're going to see it continue to march across the floors, across the United States.
    Michael Carruthers:
    And that's always been our struggle, is when you look at how easy and practical it is at airports and stadiums for cashless, it doesn't translate to the gaming floor. I go from two POSs is at an outlet at an airport to 2000 POSs essentially on a gaming floor. So there's a significant difference in cost. And then just what we call a cashless at an airport might be a credit card. It's not enrolling in an app, going through a process, creating an account. So there are some challenges.
    Nick Hogan:
    And I'm curious then about, in terms of your analytics organization there in Delaware North, because when we look at really new ways to contemplate analysis in the gaming floor, et cetera, we get a lot of cues from mainstream retail. We really look at, because that stuff has been out there for so long and it's so sophisticated, and they've got these things, especially fast moving consumer goods space, and it is just so dialed in and so incredibly efficient. So are you seeing more of that inside of Delaware North, that you're merging more of these methods across the various divisions?
    Michael Carruthers:
    Yeah, I mean, the analytics that they do on the retail side was when we went cashless or when Mercedes-Benz Stadium opened up in Atlanta and went cashless, the average check orders were significantly up. The average spend per ticket were up. So they do a ton of analysis on that, and they've seen a lot of positive movement with going cashless in terms of that average retail check being significantly increased.
    Nick Hogan:
    Yeah, yeah. Okay. So let's see. Do you have any upcoming events or other undertakings that you'd like to mention today, Michael? Any new things you guys are rolling out or things you're doing?
    Michael Carruthers:
    Well, I don't know if you guys are going to be participating in the upcoming conferences with AGS this year again, but hopefully you are. We could see some more of your insights at that. I'll be there. Maybe just a shameless plug for Delaware North is, we do have a couple openings within our organization to join the slot team. I have a director of slots opening in Wheeling, West Virginia, as well as one at Southland in West Memphis Arkansas, which is our flagship property. So if you or anyone out there is looking for joining a fantastic company and a great opportunity, please check out our website. I think it's careers.delawarenorth.com, or find me on LinkedIn, or contact Don or Nick, and they'll put you in touch with me.
    Nick Hogan:
    No problem. We'd be shocked how many of these requests we get from operators saying, 'Hey, do you know some guys who are looking..." So we do a lot of matchmaking, for sure.
    Don Retzlaff:
    We do. And obviously, I really like the Southland property. I like the team there, and the openings from a retirement. Congratulations there. And one of these days, we'll all get there, we hope, and get to retire. But it's Southland Properties, a really neat property, really neat management team, so it-
    Michael Carruthers:
    They have a great leadership team. They've been there a long, long time, and a lot of history. So it's really a good, solid team.
    Don Retzlaff:
    Yep. A neat area too. That West Memphis area is a really neat area. That Arkansas area, that side of the border is really a growing area. And I bet you that Southland's probably number one employer in that part of the community [inaudible 00:48:07].
    Michael Carruthers:
    Oh, yeah, for sure. The nice thing about the property is it's located so close to Mississippi and Tennessee that our team members live in all three states, and it's really centrally located.
    Don Retzlaff:
    Yep.
    Nick Hogan:
    Okay. Well I think that about covers it for today, guys, unless anybody has any additional topics they want to check in. And Michael, thank you so much for joining us today. It's always a pleasure to speak with you, and love learning more about Delaware North and what you guys are up to. So appreciate your time, and yeah, I think that pretty much covers it today, guys.
    Michael Carruthers:
    Thank you. I appreciate the opportunity, guys.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    Thanks, Michael.
    Nick Hogan:
    Thank you, Michael. All right, see you guys.
    Michael Carruthers:
    Bye.
    Don Retzlaff:
    Take care, Nick.    
    `
};

export default S03E02;
