import React from "react";
import ReactGA from "react-ga";
import PageMetadata from "../components/PageMetadata";

import Cms from "../components/cms";
import { butterImageURL } from "../utils/butterHandler";

class ReelScan extends React.Component {
    constructor(props) {
        super(props);

        ReactGA.pageview(window.location.pathname);
    }

    render() {
        // Create structured data for the mobile app
        const appStructuredData = {
            "@context": "https://schema.org",
            "@type": "MobileApplication",
            "name": "ReelScan",
            "operatingSystem": "iOS, ANDROID",
            "applicationCategory": "BusinessApplication",
            "description": "ReelScan is an enterprise-grade mobile application that enables casino operators to scan slot machines and access real-time performance analytics for data-driven decision making.",
            "screenshot": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
            "downloadUrl": [
                "https://apps.apple.com/app/id1476685256",
                "https://play.google.com/store/apps/details?id=com.reelmetrics.reelscan"
            ],
            "author": {
                "@type": "Organization",
                "name": "ReelMetrics",
                "url": "https://www.reelmetrics.com"
            }
        };

        // Create structured data for the organization
        const organizationStructuredData = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "ReelMetrics",
            "url": "https://www.reelmetrics.com",
            "logo": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
            "sameAs": [
                "https://www.linkedin.com/company/reelmetrics/",
                "https://twitter.com/reelmetrics"
            ]
        };

        return (
            <>
                <PageMetadata
                    title="ReelScan | Slot Floor Analytics Tool | ReelMetrics"
                    description="ReelScan provides real-time slot floor analytics, helping casino operators optimize their machine placements and configurations to maximize revenue."
                    keywords="slot floor optimization, casino analytics, slot machine placement, revenue optimization, floor configuration"
                    canonical="https://www.reelmetrics.com/reelscan"
                    
                    // OpenGraph tags
                    ogUrl="https://www.reelmetrics.com/reelscan"
                    ogType="website"
                    ogTitle="ReelScan | Real-Time Slot Floor Analytics | ReelMetrics"
                    ogDescription="ReelScan provides comprehensive slot floor analytics to help casino operators optimize placements and configurations."
                    ogImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                    
                    // Twitter Card tags
                    twitterCard="summary_large_image"
                    twitterTitle="ReelScan | Slot Floor Analytics Tool | ReelMetrics"
                    twitterDescription="Optimize your casino floor with ReelScan's real-time slot analytics and visualization tools."
                    twitterImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                    
                    // Structured data
                    structuredData={[
                        appStructuredData,
                        organizationStructuredData
                    ]}
                />
                <main itemScope itemType="https://schema.org/WebPage">
                    <section className="box box--light" aria-labelledby="reelscan-intro">
                        <div className="container">
                            <div className="row flex-row">
                                <div className="col-sm-6">
                                    <h1 id="reelscan-intro" className="title title--primary title--md" itemProp="headline">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelscan_title"
                                            type="title"
                                        />
                                    </h1>
                                    <div className="paragraph--small paragraph--small--small-screen" itemProp="description">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelscan_paragraph"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 white-frame">
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_upper"
                                        )}
                                        alt="ReelScan mobile app interface showing slot machine performance analytics"
                                        className="img-responsive gray-border"
                                        loading="eager"
                                        width="100%"
                                        height="auto"
                                        itemProp="image"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="box" aria-labelledby="installation-options">
                        <div className="container">
                            <h2 id="installation-options" className="sr-only">ReelScan Installation Options</h2>
                            <div
                                className="row flex-row"
                                style={{ alignItems: "flex-start" }}
                            >
                                <div className="col-sm-6 center-text qr-codes-section install-section" itemScope itemType="https://schema.org/HowTo">
                                    <meta itemProp="name" content="How to install ReelScan by scanning QR code" />
                                    <div>
                                        <h3 className="title title--primary" itemProp="step" itemScope itemType="https://schema.org/HowToStep">
                                            <meta itemProp="position" content="1" />
                                            <span itemProp="name">
                                                <Cms
                                                    content={this.props.cms_content}
                                                    content_key="reelscan_scan_text"
                                                    type="title"
                                                />
                                            </span>
                                        </h3>
                                        <p className="paragraph" itemProp="description">
                                            <Cms
                                                content={this.props.cms_content}
                                                content_key="reelscan_click_paragraph"
                                                type="text"
                                            />
                                        </p>
                                    </div>
                                    <div className="flex-row">
                                        <div className="col-sm-4">
                                            <img
                                                src={butterImageURL(
                                                    this.props.cms_content,
                                                    "reelscan_qrcode_apple"
                                                )}
                                                className="img-responsive qr-code"
                                                alt="QR code for ReelScan on Apple App Store"
                                                loading="lazy"
                                                width="150"
                                                height="150"
                                            />
                                            <meta itemProp="url" content="https://apps.apple.com/app/id1476685256" />
                                        </div>
                                        <div className="col-sm-2">
                                            <img
                                                src={butterImageURL(
                                                    this.props.cms_content,
                                                    "reelscan_scan_image"
                                                )}
                                                className="img-responsive"
                                                alt="Smartphone scanning QR code to download ReelScan"
                                                loading="lazy"
                                                width="80"
                                                height="auto"
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <img
                                                src={butterImageURL(
                                                    this.props.cms_content,
                                                    "reelscan_qrcode_android"
                                                )}
                                                className="img-responsive qr-code"
                                                alt="QR code for ReelScan on Google Play Store"
                                                loading="lazy"
                                                width="150"
                                                height="150"
                                            />
                                            <meta itemProp="url" content="https://play.google.com/store/apps/details?id=com.reelmetrics.reelscan" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 center-text install-section" itemScope itemType="https://schema.org/HowTo">
                                    <meta itemProp="name" content="How to install ReelScan by clicking download buttons" />
                                    <div>
                                        <h3 className="title title--primary" itemProp="step" itemScope itemType="https://schema.org/HowToStep">
                                            <meta itemProp="position" content="1" />
                                            <span itemProp="name">
                                                <Cms
                                                    content={this.props.cms_content}
                                                    content_key="reelscan_click_text"
                                                    type="title"
                                                />
                                            </span>
                                        </h3>
                                        <p className="paragraph" itemProp="description">
                                            <Cms
                                                content={this.props.cms_content}
                                                content_key="reelscan_scan_paragraph"
                                                type="text"
                                            />
                                        </p>
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-center col-xs-4 col-sm-4">
                                            <a
                                                href="https://apps.apple.com/app/id1476685256"
                                                className="app-store-link"
                                                aria-label="Download ReelScan on the App Store"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                itemProp="url"
                                            >
                                                <img
                                                    src={butterImageURL(
                                                        this.props.cms_content,
                                                        "reelscan_button_apple"
                                                    )}
                                                    className="img-responsive"
                                                    alt="Download ReelScan on the App Store"
                                                    loading="lazy"
                                                    width="150"
                                                    height="50"
                                                />
                                            </a>
                                        </div>
                                        <div className="col-sm-2 hidden-xs">
                                            <img
                                                src={butterImageURL(
                                                    this.props.cms_content,
                                                    "reelscan_click_image"
                                                )}
                                                className="img-responsive"
                                                alt="Hand clicking to download ReelScan app"
                                                loading="lazy"
                                                width="80"
                                                height="auto"
                                            />
                                        </div>
                                        <div className="flex-center col-xs-4 col-sm-4">
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.reelmetrics.reelscan"
                                                className="play-store-link"
                                                aria-label="Get ReelScan on Google Play"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                itemProp="url"
                                            >
                                                <img
                                                    src={butterImageURL(
                                                        this.props.cms_content,
                                                        "reelscan_button_android"
                                                    )}
                                                    className="img-responsive"
                                                    alt="Get ReelScan on Google Play"
                                                    loading="lazy"
                                                    width="150"
                                                    height="50"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row flex-center row-separated">
                                <p className="paragraph paragraph--extra-smallest">
                                    Keeping it reel at
                                </p>
                            </div>
                            <div className="row flex-center-space-around" itemScope itemType="https://schema.org/ItemList">
                                <meta itemProp="name" content="Industry Events Where ReelScan Is Featured" />
                                <div className="col-xs-3 col-sm-2" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                                    <meta itemProp="position" content="1" />
                                    <meta itemProp="name" content="G2E Las Vegas" />
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_g2e_logo"
                                        )}
                                        className="img-responsive fade-image"
                                        alt="Global Gaming Expo (G2E) Las Vegas logo - ReelScan is featured at this event"
                                        loading="lazy"
                                        width="100"
                                        height="auto"
                                    />
                                </div>
                                <div className="col-xs-3 col-sm-2" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                                    <meta itemProp="position" content="2" />
                                    <meta itemProp="name" content="ICE London" />
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_ice_logo"
                                        )}
                                        className="img-responsive fade-image"
                                        alt="ICE London gaming exhibition logo - ReelScan is featured at this event"
                                        loading="lazy"
                                        width="100"
                                        height="auto"
                                    />
                                </div>
                                <div className="col-xs-3 col-sm-2" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                                    <meta itemProp="position" content="3" />
                                    <meta itemProp="name" content="NIGA" />
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_niga_logo"
                                        )}
                                        className="img-responsive fade-image"
                                        alt="National Indian Gaming Association (NIGA) logo - ReelScan is featured at this event"
                                        loading="lazy"
                                        width="100"
                                        height="auto"
                                    />
                                </div>
                                <div className="col-xs-3 col-sm-2" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                                    <meta itemProp="position" content="4" />
                                    <meta itemProp="name" content="G2E Asia" />
                                    <img
                                        src={butterImageURL(
                                            this.props.cms_content,
                                            "reelscan_g2easian_logo"
                                        )}
                                        className="img-responsive fade-image"
                                        alt="Global Gaming Expo (G2E) Asia logo - ReelScan is featured at this event"
                                        loading="lazy"
                                        width="100"
                                        height="auto"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default ReelScan;
