import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S02E09 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches script to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E09.defaultProps = {
    id: "13871060-s02e09-Budgetary-Guidance-for-2024",  
    link: "s02e09-Budgetary-Guidance-for-2024",
    title: "Budgetary Guidance for 2024",
    subTitle: "Season 2, Episode 9",
    description: "In this post-G2E episode, Nick & Don offer budgetary insights for 2024, focusing on seasonal trends like Halloween and critical analyses for optimizing operations. They also tackle the CapEx/OpEx balance and strategies for pacing yearly spend. The episode wraps up with a listener Q&A session.",
    keywords: ["Budgetary Guidance", "2024 Budget", "Post-G2E", "Halloween", "Fair Share Analysis", "Occupancy Analysis", "Layout Analysis", "Demand Analysis", "CapEx", "OpEx", "Pacing Spend", "Nick Hogan & Don Retzlaff", "Season 2", "Episode 9"],
    topics: [
        "Post-G2E Takeaways and 2024 Budgetary Guidance Introduction",
        "In-Depth on Fair Share Analysis: Metrics and Implications",
        "Occupancy and Layout: Key Metrics and Optimization Strategies",
        "Analyzing Demand Trends and CapEx/OpEx Spending",
        "Halloween & Divine Intervention",
        "Pacing Spend in 2024 and Listener Q&A",
    ],
    episodeNumber: 9,
    seasonNumber: 2,
    datePublished: "2023-10-30",
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/13871060-s02e09-budgetary-guidance-for-2024",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelcast/",
    transcript: `Nick Hogan:
    Good morning, Don. How are things in The Prairie State today?
    Don Retzlaff:
    Good morning, Nick. I think we finally turned the corner from summer to fall. It's chilly, rainy, the leaves are starting to fall. How about things in your home?
    Nick Hogan:
    Well, same weather-wise. We're recording on Sunday the 29th of October, and I have to say I'm a bit groggy from a Halloween party I attended last night. Are you a Halloween guy, Don?
    Don Retzlaff:
    I am not, but my daughter sure is. But no, it's hit-and-miss around here. There's lots of parades for Halloween in this area.
    Nick Hogan:
    Parades?
    Don Retzlaff:
    Parades, yes. They do parades on the weekend and parades on Halloween night where there's floats and everything else. Think your typical 4th of July or Rose Bowl type stuff just on a small town level.
    Nick Hogan:
    Okay. Well, it is been my favorite holiday bar none since I was a little boy. But living in Northwestern Europe, it's not the greatest place to live if you're into Halloween, so just really isn't a thing here. But we do know a Canadian family in town that lives in a neighborhood with a bunch of Anglo American expats and they throw a big hoe down every year. So it's been, let's see, it's an annual tradition for us for, I think, it's 13 years now. But we're now at the stage where the boys are way too cool to attend and then this gives my wife her excuse to skip, so I went by myself.
    I was the only guy with neither a date nor a family with me, and I can't tell you how many times I heard, "You're here alone? It's so cool that you would still show up." So it was one of those things where the more you hear it, the less I thought that any of my actions were, quote, unquote, "cool." So I'm schlepping home in the station wagon post-party. I stop at a stoplight, and I catch myself in the rearview mirror. So I was dressed up as Edward Scissorhands.
    Don Retzlaff:
    Nice.
    Nick Hogan:
    So I had the hair, the white face, the scars and stuff, and I asked myself, I was like, "Has the time finally come? Do I need to hang this up?" Then no sooner had I thought that then a car pulled up next to me with a 50-something Dutch dad in a Batman suit. He looks over at me, smiled, gives me a thumbs up and then he takes off. So I've elected to interpret that as divine intervention, and I'm happy to announce that next year I'll be redoubling my Halloween.
    Don Retzlaff:
    That's perfect.
    Nick Hogan:
    Also, I think we can dedicate this episode to Dutch Batman, so thank you, Dutch Batman, for keeping me true to my Halloween self. So alrighty.
    Don Retzlaff:
    There's always signs, you just have to look for them.
    Nick Hogan:
    Exactly. Exactly. Okay, so let's see. So we had a truly great listener question come in last week, but before I hit it, let me say that we'd love to tackle any questions that anybody listening may have. If you have a question about what we're presenting or something that you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T@reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly, and don't worry about us revealing your identity. That's not something we do. So this comes from a Canadian operator and reads, "I have a question regarding fair share in unit adjustments. Our fair share premiums on $1.00 denom always point to increasing supply, but average utilization/occupancy is around 10 to 15%. When I drill into individual $1.00 titles and don't see any with occupancy rates exceeding 25%, I am hesitant to increase supply. What would you guys do?" So thank you for that question. Don, I'll let you fire away at that.
    Don Retzlaff:
    You're right, it is a great question. The things that we've talked about in the past were more fair share 101 or fair share basics. You can get a lot deeper into the fair share analysis by using occupancy, handle pulls, things like that, and he's right. There are certain denominations, certain game types that need a premium before you increase. 100% is not enough just because of the way they play. If you've got a game that's at 10% occupancy but it's at 100% fair share, you do not need to expand. Most of the time, I actually used a model, and I want to say it was UNLV that created it and it incorporated handle pulls, occupancy levels and you could tweak it. It was a great model, and what ended up happening is you could eyeball it then for $1.00 reels. 115 to 120% is probably more like the sweet spot than 100%-
    Nick Hogan:
    Okay.
    Don Retzlaff:
    ... just because on a Saturday night you're not going to have more than 1/3 or 40% of your machines in play versus a penny video where you're going to have, on a really busy casino, you're going to have above 65% above 75% of your pennies in play. You've got rooms, so there's no need to expand when you're talking about density on the $1.00s. The fair share really works best... it works good from $1.00 below, but you do have to have those caveats. If you can incorporate your occupancy, now you're starting to get into fair share 201 or fair share advanced where you're really looking at this stuff on a month-to-month basis and including other variables besides how many units are on the floor and what percentage of revenue they generate.
    Nick Hogan:
    Okay, as I understand it, so anything that's, let's say, $1.00 denom or up, you would just naturally boost the threshold on the fair share premium to around, you said 110, 125, is that what you said?
    Don Retzlaff:
    Yeah, I'd say 115 to 120 on the $1.00s.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    You really can't use a fair share analysis on your $5, $10 and 25s. It should be more of a theoretical, and do you have enough high-limit players to support those titles? 'Cause if your high-limit games are still sitting 75% of the time dead but they're doing great, you probably still don't need to expand them unless you have a lot of high rollers coming at the same time. So anything above $1.00, I usually didn't do a whole lot of fair share analysis on. It was more occupancy and theoretical and actual win.
    Nick Hogan:
    Yeah. Okay. Gotcha. Gotcha. Okay, well again, thank you to the listener for that question. It was an excellent one, really astute and yeah, keep them coming guys. We love to address these. So let's see, we have another G2E in the tank, Don. It was a great, ridiculously busy show, and we had a really great week meeting with all kinds of industry full. Don and I talked about how we wanted to approach this episode, and the obvious angle is to do some best-in-show approach and talk about what looked interesting, what didn't, and take it from there. But then we thought, "You know what? Other than a bunch of speculative commentary about what would work and what wouldn't," we just asked ourselves, "What contribution would we really make with that?" Because we know that about three quarters of everything that we saw is going to bite the dust in the field, so what's the real value in guessing which ones won't?
    So rather than the best-in-show angle, we thought it would be more useful to put ourselves in the roles of slot management personnel and ask ourselves, "How should we deploy our 2024 budget?" Accordingly, that's what we're going to do today, and here's the spoiler. Spending that money on new games we saw at G2E is way down the priority list. But I don't want to steal your thunder here, Don, so perhaps we could get started just with some general points on the best practices that apply to this stage of the budgetary process and then also address a number of questions that are nagging many, so what percentage goes into CapEx, what percentage goes into OpEx and that kind of stuff. So I'll turn it over to you, Don.
    Don Retzlaff:
    Sure. It was really good G2E, there was a lot of really neat product out there, but like Nick said, only about 25% of it's going to produce more than 1.5 times house average. When you're buying new boxes, you really need to get above 1.5 'cause there's enough titles out there that you can do that on. We had some, excuse me, some manufacturers last year that had closer to a 0% success rate on their new titles they released, and you just can't afford to make those types of mistakes. So starting off and look at your casino floor and it is basically, what are your players' plan? What are they voting for with their wallets? That's where you need to start. That includes your planning for your OpEx and your CapEx spends, looking at your lease games, looking at your core games because especially with these economic conditions, you really need to take advantage of it.
    Nick Hogan:
    Yeah, and it's a question that we're just getting left, right and center people will approach and say, "What should we tuck away for OpEx versus CapEx and what is that split, et cetera?" I can't tell you how many times I hear that question.
    Don Retzlaff:
    Yeah, in our meetings and at that week at G2E, how many times did we have CEOs or GMs say, "What's the magic number?"
    Nick Hogan:
    I would say it was every single meeting.
    Don Retzlaff:
    It was. It was really interesting, and every casino is different. You've got some casinos that are in a monopoly. I was talking to one of our clients yesterday, matter of fact, on Saturday that their win per unit's over $500 a day with their core games have a 35, 40% occupancy. Well, their lease games can't do three times, four times house average 'cause their core games are doing so well. Then you've got other casinos that are weekend-driven business and their games look like they're doing six and seven times house average, but it's because their lease games get pounded on the weekdays 'cause there's nobody else there. So everyone's playing their lease games so that when the weekends come, that's when they get busy.
    Nick Hogan:
    Right.
    Don Retzlaff:
    So every place is different. So you just have to really look at it, and you really have to dive in deep into the numbers to find out what your players are demanding and then try to give it to them.
    Nick Hogan:
    Yeah. Yeah, as much as we all like rules of thumb, unfortunately, sometimes you just cannot avoid analysis.
    Don Retzlaff:
    Yeah, it's true. I remember in one of our meetings one of our clients told us, he said, "Don, everybody around us is down 4, 5, 6% year-over-year and we're flat up 1 or 2%. Is it because we've changed our floor mix to add more lease games? Is it our marketing programs? What's the difference? Why are we up or flat and everybody else around us is down?" Those are really complicated questions to answer, but giving customers what they want to play, stocking those shelves with the product they wish to buy is an important part of it. We always simplified it and said it was marketing's job to get them into the door. It was casino ops job to keep them there and keep them coming back. The way you keep them coming back is give them the games they want to play.
    Nick Hogan:
    Yep. Yep. Yep. Based upon what you're seeing, do you feel that by and large, I think when we look at when we're really plat rate percentages of floor share that are premium, we're always concerned where it's that 2, 3%. It seems like it is coming up a bit, but do you think it just generally most operators need to look at perhaps expanding a bit on their premium portfolio?
    Don Retzlaff:
    Yeah, most of them do.
    Nick Hogan:
    Yep.
    Don Retzlaff:
    I had a meeting on Thursday with one of our clients and their Dragon Links were doing five times house average. Their Lightning Links were doing four times house average, and they're fighting with their CFOs and their GMs to try to expand, and it's not an easy battle.
    Nick Hogan:
    Right.
    Don Retzlaff:
    Sometimes the people that have the answers, the casino ops people don't have the power to increase it by themselves, and they really need to increase. They didn't have Buffalo Link on the floor. They had a very small selection, but what they did have was perfect. It was performing great. I couldn't even say, "Change this for that," because everything they had on the floor was doing over 3 1/2 times us average, so there was nothing that was doing poorly. They needed to expand, but now they've got that fight on their hand with their CFO or their GM or both.
    Nick Hogan:
    Right.
    Don Retzlaff:
    So I volunteered my time and said, "Let's do a conference call, and I'll show them where they're missing out, why their host-level players aren't producing anymore, why their average bets this much lower than everybody else."
    Nick Hogan:
    Yeah, and I think we can expand that offer to the entire listener base that if you are having these types of arguments internally, you just can't move it, feel free to give us a shout because we have walked a lot of CFOs now through this. I have to say two things. So number one, again, what I always say is that it is not unreasonable the positions that the CFOs are taking when they're saying, "Hey, look, you can guarantee that you're going to increase my expenses here, but you cannot guarantee you're going to increase my revenue," that is not a ridiculous position at all. It's what these guys are paid to do. But secondly, these are typically people who like quantitative data. They like these types of studies, and when we can walk them through it and show them what we've unearthed empirically, they love it. Then the cause is advanced, typically, so just make that offer. If you're having these conversations and stuck on them, feel free to give us a buzz. We can give you a hand with them.
    Don Retzlaff:
    That's one of the neat things about G2E is we get the CEOs and the CFOs and the chief marketing officers in the room with the VPs of slots and the slot directors, and we can show them the evidence. We can talk to them and it's pretty neat. The CFOs are some of the brightest people you'll meet and there's a reason why they're-
    Nick Hogan:
    Yeah, when they're in these meetings, they end the meetings out of ink because they're scribbling the entire time, so it's-
    Don Retzlaff:
    Exactly. They ask great questions, and they're pretty much unofficial AGMs at a lot of properties that don't have them, and they do control the purse strings. They have the master or coin and they ask good questions. Obviously, they don't want to spend money that's going to be wasted, whether it's on a marketing program or a casino operations program or labor. But we've turned a lot of CFOs around by just showing them the evidence, "If you do it right and you expand with the right number of games and the right volume, set them up properly, put them in the right spot, it increases host-level play." That's the holy grail is to get your host-level players to come more, play longer and have a higher average bet.
    Nick Hogan:
    Yep.
    Don Retzlaff:
    That's what we've been able to do, which has been really neat.
    Nick Hogan:
    Yeah. So Don, we were talking about... So going back, looking at the host-level segment, and I want to hark back to a listener question we had a few episodes ago, and they had asked us this question, if 15% of the players are generating 85% of the revenue, should we be devoting 85% of the budget to that 15%? I love that idea, and do you feel that that's a good general principle for attack in this?
    Don Retzlaff:
    Yeah, I do. The more I study our player data and we've got over what, 2 billion player sessions now in our repository, the more I realize that your frequent low theoretical customer, customer say below $100.00 in theoretical, 150 in theoretical, they don't play the new games.
    Nick Hogan:
    Right.
    Don Retzlaff:
    So you might be able to target 100% of your CapEx towards the host-level players.
    Nick Hogan:
    Wow.
    Don Retzlaff:
    Yeah, it was really shocking, especially when you look at who plays the older titles, who plays the obsolete titles, who plays lease games, all of your new games have been tailored toward higher average bets. So if you don't have the bankroll and customers figure it out pretty quickly that they don't have the bankroll to play some of these games, they play their classics that they like that they've grown up with. Your host-level players are the ones that are pounding the Dragon Links, Dragon Caches, all the games on the Neptune Singles, all the Coin Trios, all the Huff N' More Puffs, all those types of games are being supported by your host-level players and your daily players are shying away from them. So 85% of your CapEx at least should be spent on your host-level players, and I could probably make the case at 100% of your wallet should be spent on your host-level players because your core players just don't play the new things that you're buying. They're just not designed for them anymore.
    Nick Hogan:
    Okay. All right, cool. Okay, cool. So I think what we talk about here is step one then is that demand map and trying to figure out what those folks are really hammering.
    Don Retzlaff:
    Yep, absolutely. Yeah, it starts with your fair share analysis, looking at your floor, trying to figure out what you need. There were some really good reels introduced this year, but if you don't need the reels, don't buy them.
    Nick Hogan:
    Yeah.
    Don Retzlaff:
    Most casinos are in the spot where you still have to take off reels. Post-COVID, there's been this big decline in reel play, and I would say 95% plus the casinos have too many reels on their floor. Especially, low-limit reels, your $1.00s and up might be fine, but your quarters and pennies, you probably have too many of them. Yeah, sometimes you have to spend some money to keep your host-level $1.00 reel players happy, but it should be a very small percentage of your wallet that you spend on these games. So figure out what your players are playing and what you need the most, and most people are going to need video penny.
    There's probably another need of video nickels or video dimes that is a very much a growing segment, and it's one that people still haven't capitalized on fully. Some casinos have very few or none of these machines, and it should be a much higher percentage of your floor, but start with those fair share analysis before you start buying stuff for 2024. Then obviously next up is looking at things like your occupancy and especially weekend occupancy. We do a lot on a monthly occupancy level, but if your business... I'll give a good example. I had a client I was talking to recently, their Huff N' More Puffs were 40% busy-
    Nick Hogan:
    Yep.
    Don Retzlaff:
    ... on a monthly basis. On the weekends, they were over 90% busy.
    Nick Hogan:
    Yeah. Yep.
    Don Retzlaff:
    So before they bought anything else, they needed to buy more additional Huff N' Puff, Huff N' Puff More, More Huff N' Puff, I can't say that right, it's too many words, but that was the first thing they should look at before they buy anything else. These games are doing $1,500 a day for them, so there's no place else they should look. These games are maxed out. People are waiting in line to play them. Start there.
    Nick Hogan:
    Yeah.
    Don Retzlaff:
    Yeah.
    Nick Hogan:
    That period or the, excuse me, separating that analysis, looking at low-occupancy periods, high-occupancy periods is absolutely critical, something that oftentimes gets left off. That's a critical point, no doubt.
    Don Retzlaff:
    Yeah, and it works both for your lease games and the games that you're going to buy.
    Nick Hogan:
    Yeah.
    Don Retzlaff:
    If your host-level players can't get on the games they wish to play on the weekends, a lot of times they stop coming on the weekends. We had a client that we talked to and did just that. They said their host-level players are more of the Sunday through Thursday crowd and they shy away from Fridays and Saturdays 'cause they can't get in the games they wish to play, so why would they come then?
    Nick Hogan:
    Right.
    Don Retzlaff:
    They got their games, they like to play, they want to play, so they come on Tuesday nights. They come on Sunday afternoons or Sunday evenings to play. So if you want to gain additional trips from your players, make sure you can have all those games available for them to play seven days a week.
    Nick Hogan:
    Don, do you feel this as a follow-up on occupancy? Are you still seeing that inventories generally are a little too large? Do you feel that that is holding?
    Don Retzlaff:
    Yeah, we're seeing a big chunk of the floor getting very little or no play, some places 20, 25% of the floor where they're getting played once every four hours, generally. Like you've said in a lot of your presentation, those are boat anchors. They're not good machines, there's nothing you can do about it. It's just because you can't replace the percentage of machines on your floor like you used to. If you had 1,000 machine floor and you're buying 100 machines a year, it's going to take you 10 years to flip through everything. Most of those 1,000 machine floors are not buying 100 machines a year. They're buying more like 50 or 30.
    Nick Hogan:
    Sure. Yeah.
    Don Retzlaff:
    So you're looking at a 20-year replacement cycle, so you've got a lot of old, obsolete video games on your floor. They're only going to do 40, 50, 60 bucks a day no matter what happens to them if you're lucky.
    Nick Hogan:
    All right. We did see post-COVID, a lot of inventory levels came down, and I have to say, we still say we haven't seen the bottom in terms of what is still left there to cut.
    Don Retzlaff:
    Yeah. Unfortunately, there's a lot of minimums that a lot of our clients and casinos have to meet.
    Nick Hogan:
    Right.
    Don Retzlaff:
    You got to stay at X number of machines are above, and you just have to do what you have to do with those regulations.
    Nick Hogan:
    Yeah.
    Don Retzlaff:
    But there's a lot of-
    Nick Hogan:
    First they have to apply for these changes and things-
    Don Retzlaff:
    Right.
    Nick Hogan:
    ... it's a more arduous process, for sure.
    Don Retzlaff:
    Right, then you stair step that. You don't say, "I want to remove 300 machines," you say, "I need to remove 50 machines." Then the next time you go back, I need to move another 50. So there's two solutions. One is get more CapEx, which is easier said than done and the other is increase your lease spend so you can get rid of some of these obsolete cabinets and put some of these games on the floor. But you got to make sure you do the right ones, 'cause you do not want to spend money where you're not getting a return.
    Nick Hogan:
    Right. Right. We know at this point too, we have a good view on a number of platforms that we feel are pretty safe bets moving forward. I think in that the big four suppliers I think all those platforms are getting stuff with some good game lineups and all this kind of stuff.
    Don Retzlaff:
    Right. Yeah. Until I think it was I was at, I'm trying to remember the number, I was at 100 or 130 machines on a new floor build before I had to dip below 2 1/2 times house average on a lease game.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    So unless you have more than 100 lease games on your floor, you should pretty much demand 2 1/2 times house average unless, of course, if I go back to one of the earlier comments, your floor average is over $500 win per unit per day. If you have that problem, you're not going to get that many machines to do 2 1/2 times house average on your lease games just because that base is so high. But for most casinos that are in that three to $400 range or lower as far as our average win per unit per day, you should be able to have at least 75 of your lease games do 2 1/2 times house average before you have to think about dipping below that number.
    Nick Hogan:
    Yeah. Don, with the lineups that we have now and all these... there just seems to be so much great premium product out there at this point. When you look at the various revenue models like let's say the WAPs generally, are those appealing to you now when you look at what's out there for a daily fee?
    Don Retzlaff:
    No, they can be quite expensive, especially for good games. I know that the people who have to use WAPs, they hate it. If you can get by with a daily fee, you're in a lot better shape. I would much rather play a flat fee of 55 or $65 a day than entertain an 80/20. If you can wait, if you have the option to do an 80/20 now or $60 a day in four months, I'd wait the four months.
    Nick Hogan:
    Okay. Okay.
    Don Retzlaff:
    Some of those fees, we've got a client that's paying north of $200 a day for some of their games just because of an 80/20 model. Obviously, there's going to be some caps on it. You can set a minimum, you can set a maximum and that helps defer some of the costs, but those WAP models are... they're tough. Yeah. Yeah. Yeah, for sure.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    What's next on our list? I guess the one thing we wanted to talk about was, like I said, making sure you take care of your host-level players. They're your bread and butter. They're the ones that are playing up. They're the ones that are increasing their wallet. They're the ones that you want to take care of. Look to see what they are playing. Talk to your casino host. Look in your player data. In some systems it's not super easy to get that player data out of them. Some it is a little bit easier, but work with your marketing departments. I ran a list of my 100 best players and then what asset numbers were they playing and then just basically did the good old Excel pivot tables to figure out what themes they were-
    Nick Hogan:
    Right.
    Don Retzlaff:
    ... because theme names weren't listed in the system, but the asset numbers were. But try to figure out what your host-level players are playing. Those are the ones that you have to take care of first, and whether it's lease games, whether it's core games, that's where you probably should start. Like you said to start, there was a lot of really neat product at G2E, but we don't know which of those products is going to work or not. You can buy cabinets, and if you're going to make your selections on cabinets versus themes, buy the latest cabinets first. You don't want to get stuck with buying something that's going to get obsoleted in a year or two.
    Buy the new stuff first and if you can wait, wait till the end of the first quarter. Wait till April or May. If your fiscal year runs out at the end of the first quarter and you've got to buy stuff now, well then you've got to take a gamble. But you still can just buy the stuff that's already out there and working and wait until your fiscal year restarts in second quarter to look at the stuff that's been released, but there's just-
    Nick Hogan:
    Yeah, that's such a good point, Don, because I have seen this too where you have departments that get funded and they just seem to blow the entire bank roll that they have for the year within the first quarter let's say, and they stack it up. Did you typically spread that out well over the course of the year generally when you were operating?
    Don Retzlaff:
    I did. You hit two different philosophies that you get this bucket of money, let's go ahead and use it so you get the value of it all year long. So instead of spending 25% each quarter, you spend 75% in the first quarter and you get to enjoy the value that that 75% created all year long versus if you wait till July to spend your 75%, you've had the first six months where that capital is not in use. But unless there's a lot of product out there that you know you want, wait. You're better off just picking off your wishlist. A lot of times you know in August, September what you're going to buy in January-
    Nick Hogan:
    Right.
    Don Retzlaff:
    ... based on what you need are. The client I just mentioned before, he knows he has to buy some Kascada dual screens because those host-levels players are just waiting for these games, so he has to buy those first. So you pick off that low-hanging fruit first and then you wait for performance data on the new themes, the new cabinets, until after you already started getting the stuff in from your wishlist. I would usually say that maybe 50 to 75% of my themes I had chosen by Labor Day and then the rest of it I supplemented after I got more data.
    Nick Hogan:
    Yeah. Okay. Okay. I do want to lock in on one thing that you had stated on the cabinet saying buy the latest first. Now I take it what you mean by that, though, is that before you jump into those cabinets, you want to make sure that you have an idea of what the game library looks like and that this thing's been beaten up in the field a bit. Yeah, and-
    Don Retzlaff:
    Absolutely.
    Nick Hogan:
    ... you just wouldn't want to take it right off the line.
    Don Retzlaff:
    Yah. You probably can have a little more confidence in the major manufacturers versus the others because you know that their title library is going to be really strong. You would definitely-
    Nick Hogan:
    So certainly I know that we had this question last year. When the Neptune Single came out, everybody knew it was at a premium price point, and there was a lot of anxiety about jumping into that platform.
    Don Retzlaff:
    Right.
    Nick Hogan:
    I recall a lot of people getting the shakes about it. Within short order we saw Aristocrat was just killing it and throwing all kinds of great titles in there. So then it just became, yeah, it's just a safe one.
    Don Retzlaff:
    But it's also a great example of the very first title they produced on that cabinet didn't work. Most of the ones that they have released on that cabinet had been fantastic.
    Nick Hogan:
    Yeah, for sure.
    Don Retzlaff:
    But the very first title they had high hopes for, and it barely does house average.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    You spend $36,000 on a title that's doing house average, that's not good enough. We've talked about in the past about some of those horror stories where you had these great sales on these cabinets and then the libraries were really limited. People would jump in with both feet and you'd buy 9, 18, 27 of these cabinets and next thing you know they only had two or three working titles, and everything else fell on its face. I remember going back to the old WMS days when they released their Bluebird Cabinet, their first 10 themes they released, two of them worked, their next 10, five of them worked, their next 10, seven of them worked. So if you bought early, you had to suffer through some pretty poor results until they had enough themes to support their cabinets. So the major studios, they've got a lot more R&D behind them. Heck, even some of the minor ones, I look at some of these places, but they're producing two or three really good titles. So how many machines is two or three good titles going to support? A couple of banks?
    Nick Hogan:
    Right.
    Don Retzlaff:
    We've found out a lot. You can put one theme on a bank and have some really great results. So if you wanted to have four of each of the best titles, six of each of the best titles is probably the biggest stretch you can probably go unless it's just a phenomenal title. There's one company I can think of right now that's doing that, but you're limited in the number of games you can have. But with the Aristocrats and Light & Wonders, IGTs of the world, I have a little more confidence in buying their new stuff 'cause I know they're going to produce. It's every once in a while they'll put out a cabinet that just doesn't work, but it's been quite some time where they've had a total flop.
    Nick Hogan:
    Right, right, right, right, right. Okay, gotcha. Okey-dokey. So I think we've covered fair share occupancy, floor size and host-level favorites. I guess we could also talk a little bit about floor layout and the concept and neighborhoods and structuring your floor.
    Don Retzlaff:
    Yeah. One of the neat things that we've been doing the last year or so is trying to figure out if your players like one game, what else do they like? What else will they play? What's their second choice in starting the neighborhood games? People who like Lightning Link also like Ultimate Fire Link. People who like Huff N' More Puff more puff also like Buffalo Ascension, things like that. So you're putting games of similar taste and similar interests close to each other so your players don't have to roam the floors looking for their next machine they want to play. So by clustering these games and neighborhooding these games together, it really works out well. If you don't have ETG play that crosses over to video poker play, don't put them next to each other.
    If your high-limit play crosses over with the HEDs, put them next to each other. They likely won't. Your high-limit players will also likely play your nickels and dime video. So surrounding that your high-limit play with nickel and dime video or quarter video was always a good idea, and that's what we mean by neighborhooding. You can get down to specific cabinets, but it's more likely specific themes that they like to play or families of themes they like to play. It really can liven up an area that can traditionally be a little bit of a problem for you. A C-level area where you put a Dragon Link bank and some other great core games around it will definitely pick up the pace for that area. It's amazing how it happens.
    Nick Hogan:
    Right.
    Don Retzlaff:
    So when you're looking at your floor, and one of the things that we're working on now, and hopefully we'll release here later on this year, it'll be the bank swap feature to where we're going to target which banks you should move on your floor with scientific data. These work better in these locations, these games, you can switch this one from an A to a C location and not hurt it. Those are the types of things you should be looking at is, "Where would these games perform at so I can free up space for the games that really need it?"
    Nick Hogan:
    Right. Right. So by my count, Don, so the things that we went through, so I'm on, I think that was five steps in total, so I saw step one was that fair share analysis, state of the floor; two is occupancy; third, floor size; four, we're diving more into that host level and player demand, et cetera. Then five was the neighborhoods. So we're talking five largely analytical tasks before you even begin thinking about anything from the show, before you would... Then, so I know we talk to people sometimes about when they say, "Well, there are brand new concepts, this, that and the other thing," and we're always telling them, "Yeah, you wanted to vote a given percentage of your floor to just totally raw experimentation and then that stuff you want to put in typically on some trial or test bank basis." You think that that's solid guidance, generally speaking, Don?
    Don Retzlaff:
    Absolutely.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    We've found that if you are going to trial, if you are going to test, especially if they are unique math models, something you've never seen before, make sure you put them in a good location. It's everything that we've seen as far as a weird math model, if you put it in an even a B or a C location, it doesn't do very well. But if you put it in an A location, it gets a lot more trial and that trial can lead to some loyalty. So put your test banks, put your test math models in a really good location [inaudible 00:37:24]
    Nick Hogan:
    Near the aisles, end caps, this kind of [inaudible 00:37:26]
    Don Retzlaff:
    Yeah, as much as you'd like to say that they need to hold their own in a C location, they can't. They really do struggle until they get that following. So you really need to consider putting them in a prime spot to test it. Yes, the thing is, everyone thinks that an A location, they're all going to succeed, they don't. We've got a lot of machines in A locations that shouldn't be there because they're going to fail anywhere, so you just need to figure that out quickly. If you're putting these games in a location, in a test bank and they're still just barely making the grade, what happens when you move them, you need to have to get that information quickly.
    Nick Hogan:
    Gotcha. Okay. Very good. Okay. Generally speaking, Don, that overall percentage of the floor that's for experimentation, what do you guide on that? Is it like a 5%, 10% or a unit count? What do you do, just raw experimentation?
    Don Retzlaff:
    I usually limit it to say, three to five banks-
    Nick Hogan:
    Okay.
    Don Retzlaff:
    ... depending on how big your floor is. I've seen a lot of casinos, some really well done casinos where they put all their test banks right inside the doors, and they're constantly flipping these things out and they probably had seven or eight different test banks in that area; either true test banks where you're getting the stuff from the manufacturers for free or stuff that they would experiment themselves, whether it was lease games or core games that they wanted to see how well they worked before they moved them.
    Nick Hogan:
    Oh, yeah. When I refer to test banks, I'm really referring to stuff that the suppliers are putting out on an entirely free basis. They put it on your floor just to test it. In some environments where I see those test banks are really a material percentage of the floor, I often wonder if that's such a great idea in some of these cases because they're putting so many of these new... typically these test banks, these are, first of all, their hardware is impressive, so they're typically brand new stuff, dressed up, really impressive, et cetera, put new things on it. But I am a bit worried that with that stuff they'll pull some of that host-level play that's really critical and they maybe put them on a new concept that's completely experimental and then just maybe blow out completely the ADT on that player for that day or moving forward, it'd be even more damage. Do you ever see this in empirical data sets?
    Don Retzlaff:
    It's a good point. I've never really researched as far as what happens to players who play test games as their first choice, and then do they quickly move on to their favorite games, or do they stay at these test banks and just try everything new? You don't rotate test banks out maybe every 90 days-ish or 120 days to get some good data. So they may have trial on these games and then after they quickly realize that 75% of them aren't for them, they move on to their other games. It's a good question. I don't have any data to back up the opinion one way or the other, Nick.
    Nick Hogan:
    Okay. Okay. Okay. What we do know is that oftentimes these test bank things are great, though, because it's a way to keep the floor super fresh looking-
    Don Retzlaff:
    Yes.
    Nick Hogan:
    ... and all this stuff, depending upon where you place them and how you promote it, et cetera. But yeah, just I don't know if that's a-
    Don Retzlaff:
    Yeah, they're a great idea and you devote a lot of space to them. Usually, it's prime space. You get a lot of good data and you get to try stuff before anybody else does it obviously and keep your floor fresh. Then the positive is you get to keep that software and put it someplace else after the test cycle runs, so it's a great deal. Just remember that on the people use these test banks know it is the majority of them fail.
    Nick Hogan:
    Yeah.
    Don Retzlaff:
    Even when they get to that point where the math metals are done, the graphics are done, and they've tested it internally, they put it out on the floor and still 2/3 of them plus fail.
    Nick Hogan:
    Yeah, yeah, yeah, yeah, yeah. Gotcha. Okay. Then the other thing I think we didn't really necessarily hit on so much, Don, but so we talked about these things, fair share, occupancy, floor size, household favorites, neighborhoods, but I would say the first place that you're typically going to spend your capital is expanding stuff that's already killing it, right? That's going to be your first move?
    Don Retzlaff:
    Exactly. Especially in the last year, there's been some really neat titles released and the host-level players have really responded to it, and these titles need to be expanded first. I'm seeing a lot of occupancy levels above 40% on core games that you can purchase and they just need be expanded on-
    Nick Hogan:
    They have quite a few of them, actually, on some of these [inaudible 00:42:28] new platforms it's just been crazy numbers.
    Don Retzlaff:
    Yeah, it's been fantastic. It was actually a really good year for the game developers. You saw some really nice hits from some of the minor manufacturers and some really nice hits from some of the major manufacturers. It was a nice mix, but most people just didn't buy enough of them and rightly so. We talked about this whole show was making sure you get the data before you jump in with both feet. So a lot of people put one bank or two banks of Neptune Singles or Kascada Duals or PeakSlant49s or the Bluberi Focus Cabinet. Most people put just a couple of banks out there, now it's time to expand. These are proven winners. You want as many Buffalo Ascensions and Huff N' More Puff and Coin Trios and Devil's Lock and stuff like that on your floor that you can get. So if you only have two or three of these titles on your floor, it's time to expand these first before you even think about buying anything else. So that's the classic example. Look at the stuff from G2E, keep it in mind, expand the stuff. This is stuff from last year's G2E-
    Nick Hogan:
    Right.
    Don Retzlaff:
    ... that is now out there working. The stuff that we know doesn't work you don't buy. The Mr. Cashman Bingo, the stuff didn't work. But we know that Thunder Drums and all these other titles that came out, they do work, and these are the ones that you need to be expanding. We talk about our hidden gems and stuff at times. One of the issues when you buy a limited number of cabinets is you miss really good themes because you can put out your banks and put All Stars on your banks, but there's another class right below it that's doing 1.7, 1.9 times house average that people are missing because the other titles are doing so good. So this is a perfect chance to expand and grab these titles that are doing... there'll be rock stars on your floor. Core games that are doing 1.7 to two times house average used to be very uncommon, but in the last year there's a lot of them.
    Nick Hogan:
    Okay. Yeah. Great. So it sounds just overall, if we could just guide it with a single phrase, it would be to exercise a great deal of discipline at this point in time, right?
    Don Retzlaff:
    Yeah. Yeah, buy the hits, buy the known hits first. Then after you buy your known hits, then look at buying the stuff that you think will work. But I don't like making mistakes on capital. It used to drive me crazy. Sometimes you want to be first in the market on this stuff, but most of the time, I'd rather expand my hits first, wait for the data to come in and then look at buying that second round in the second quarter with the stuff that's really starting to produce that was released between now and the end of March. So yeah, just hit those first. Be really cautious with your reels.
    Some of the video poker stuff is out there that works really well. There's some opportunities to maybe expand on conversions on some of your cabinets that may not be working at video before you buy new cabinets. So utilize your conversions, your IGT bucks and all your coupons like that. But yes, I would really encourage people to stick with the video, mostly pennies, but do consider nickels and dimes. There are some really good titles out there that are producing great numbers at nickels and dimes.
    Nick Hogan:
    Okay, great. So Don, anything else you want to add for budgetary recommendations for the coming year or we drained it?
    Don Retzlaff:
    Well, good luck. Yeah. It's an interesting discussion. There's no right or wrong way to do it, but there are definitely some guidelines that'll help you. Especially for newer directors and newer VPs, this is their first or second time buying slot machines, stick with the proven winners. Get those easy ones first before you worry about taking a shot at what you saw at G2E. The one tough part for me was always one of the vendors would come up with this great sale. They would really undercut the price, and it's almost too hard to ignore. Sometimes you still have to ignore that sale price-
    Nick Hogan:
    Sure.
    Don Retzlaff:
    ... because you still don't want that cabinet on your floor 'cause anything you buy now you're going to have on for 10 plus years. So do you really trust that cabinet to produce for the next 10 years with no conversions? So think of it three or four years from now, the titles that you have you're going to be stuck with forever, and do you trust those titles? If you don't, wait.
    Nick Hogan:
    Yeah, yeah. Precisely. So last time on our last podcast, Don, you mentioned that you were going to be in Las Vegas for 10 days surrounding the show.
    Don Retzlaff:
    Yep.
    Nick Hogan:
    So I was already thinking, "Okay, this guy, he's going to be going nuts." So let's hear the gambling adventure you had in Vegas. How'd you do overall?
    Don Retzlaff:
    It was a really good trip. It was amazingly good. The first weekend was not so good, but the second week I made a really nice comeback. I always tell my players when they're losing, sometimes all it takes is one good spin to get it back. The first weekend I couldn't have been more wrong on sports betting, and the second weekend, all the games were over by halftime in my favor. So that was good. Then it was after our cocktail reception that Tuesday night heading back to the hotel, stopped in the high-limit room and said, "I'm going to take my chances." Sure enough, in about 10 minutes, I won 600 bucks and I said, "Okay, that's a good start." Went over to a Buffalo Ascension machine and in five spins I made all my money back for the week plus more-
    Nick Hogan:
    All right.
    Don Retzlaff:
    ... just by getting a really good bonus round. So between that and some really good college and NFL bets and golf bets, it turned out to be a profitable trip, which you really don't count on when you go to Vegas.
    Nick Hogan:
    No, no, no. For sure.
    Don Retzlaff:
    Yeah, it's rare, but it was a really good trip. I had a lot of fun, got to see the football stadium and got to see a couple of really great concerts and it was a fun trip. I had a good time.
    Nick Hogan:
    What'd you think of the sphere?
    Don Retzlaff:
    I didn't get to go inside of it, but I had a really good view of it from my hotel, and I've seen some of the videos. It's just incredible. Everybody I talked to that went to the U2 concert or saw the video, the movie that they're playing were just super impressed.
    Nick Hogan:
    Yeah, and it's interesting because I did hear from some content people that it's very clear that they're still warming up with the images on what to show inside. Some people who went to the concert, they said they're not really there yet in terms of taking advantage of the whole thing. They're still learning from the inside anyway. But my God, that thing is impressive, I have to say. It's just you can't look away. It's a beautiful thing. The other thing is this sports stuff in Vegas, it's just incredible that the city, just how it keeps reinventing itself. But as I flew in from Holland, I got to Salt Lake and I jumped on that plane up, or down, and I think I was the only guy not dressed in a Packer's jersey on that flight, just amazing that it's sports.
    Don Retzlaff:
    That happens all year round with the hockey.
    Nick Hogan:
    Yep.
    Don Retzlaff:
    If the Penguins are in town, all you see is Pittsburgh jerseys, the Blues are in town, all you see is St. Louis jerseys. People spend their weekends or their vacations to go out there and see their teams playing in Vegas.
    Nick Hogan:
    They just love it.
    Don Retzlaff:
    Yeah, it's going to be the same thing for baseball. Once they build their baseball stadium, you're going to visit Vegas when your team is in town so you can go see the Cardinals play the A's or whatever it's going to be. So yeah, it's a jumping town. The F1 race is coming up here in a couple of weeks. You can see them building the stands in the Bellagio Fountains and in front of the Mirage, and so it's-
    Nick Hogan:
    Yeah. By the way, to any listeners out there, I haven't asked this question to anybody yet, though. I want to know what those Cosmopolitan Wraparound Suites are going to cost the day of the race.
    Don Retzlaff:
    Oh, my goodness.
    Nick Hogan:
    You stayed one in recently, you saw-
    Don Retzlaff:
    I did.
    Nick Hogan:
    They're beautiful rooms.
    Don Retzlaff:
    Oh, they're gorgeous rooms, and I can't imagine what they'd cost. They're basically 500 bucks just for any given day.
    Nick Hogan:
    Oh, I think they're much more than that, aren't they?
    Don Retzlaff:
    Yeah.
    Nick Hogan:
    Isn't it a 1,200 ADR or something like that?
    Don Retzlaff:
    They are, yeah, exactly.
    Nick Hogan:
    Yeah, yeah, yeah. Yeah, yeah, yeah. So I know the prices have come down some from the initial offerings, but it's going to be a fantastic week out there for them. It's going to be must-watch TV for any race car fans.
    Absolutely. Absolutely. Okay, cool. Well, I think that pretty much wraps us up for today, Don. So thank you very much, and yeah, let's see. We have two more days, so you can rethink your Halloween position here for a couple of days still, I expect to see you in the conference calls tomorrow in a vampire costume or something like that.
    Don Retzlaff:
    Oh, boy. All right.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    I think, you're going to wear your Edward Scissorhands to the executive committee meeting then?
    Nick Hogan:
    No, because that was almost all makeup and that was like, it took me an hour-and-a-half to get it off. So no, I won't be-
    Don Retzlaff:
    Oh, boy.
    Nick Hogan:
    ... doing that again.
    Don Retzlaff:
    Wow.
    Nick Hogan:
    Thank you very much.
    Don Retzlaff:
    Yeah, no, but-
    Nick Hogan:
    I think I'll do something simpler-
    Don Retzlaff:
    ... one other quick thing is thanks to everybody-
    Nick Hogan:
    Okay, very good.
    Don Retzlaff:
    ... who came up to us at G2E and talked about the ReelCast, it's always neat to hear the comments, how many people actually listen and enjoy it. I had someone come up to me and say it was like, "We're sitting at your desk just having a conversation about interesting topics in the casino." So people are really nice when they come up and talk to us about this.
    Nick Hogan:
    It's true, and it's always a surprise to me to learn who's listening as well. I wouldn't think in a million years this person would, but then they're like, "Oh, yeah, I catch every one." So it's always really nice.
    Don Retzlaff:
    I always joke that I said we should play a warning before this that says, "Warning may cause drowsiness. Do not operate heavy machinery while listening to this ReelCast.
    Nick Hogan:
    Oh, well, part of the deal. Part of the deal. Okay, very good, Don. Well, thank you so much.
    Don Retzlaff:
    Thanks, Nick.
    Nick Hogan:
    Yeah, enjoy the rest of your Sunday.
    Don Retzlaff:
    You too. Take care.
    Nick Hogan:
    All right. You too, Don.
    Don Retzlaff:
    Bye-bye.
    Nick Hogan:
    We'll see you. Bye.    
    `
};

export default S02E09;
