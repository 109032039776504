import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S01E03 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

// Add the default props (for each episode)
S01E03.defaultProps = {
    id: "11771755-s01e03-making-your-peace-with-premium",
    title: "Making Your Peace with Premium",
    buzzSproutLink: "https://reelcast.buzzsprout.com/2057836/11771755-s01e03-making-your-peace-with-premium",
    spotifyLink: "https://open.spotify.com/episode/6m23ScMcL3Ou0LrfOIHPkt?si=LiG26fW6Rw2rhgUNqC1ZxQ",
    applePodcastsLink: "https://podcasts.apple.com/us/podcast/s01e03-making-your-peace-with-premium/id1652485625?i=1000587706821",
    iHeartRadioLink: "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s01e03-making-your-peace-with-105186604/",
    cdcLink: "https://cdcgaming.com/reelmetrics/s01e03-making-your-peace-with-premium/",
    description: "Discover the importance of premium content and its impact on your slot floor. Our team shares data-driven insights and best practices for incorporating premium content into your inventory strategy.",
    link: "s01e03-making-your-peace-with-premium",
    keywords: ["premium leased games","slot machines","casino floor optimization","segment neighborhoods","player behavior","game performance"],
    topics: ["Why is premium content important for your slot floor?", "What is the impact of premium content on inventory strategy?", "How can data-driven insights help incorporate premium content into your inventory strategy?", "What are the best practices for managing premium content on your slot floor?"],
    transcript: `Nick Hogan:
    At ReelMetrics, we collect and analyze a ton of slot data and we're hit up frequently for insights, tips, and tricks. So, we decided to do a webcast in which we present, discuss, and otherwise nerd out on our work. Coming to you from our offices in lovely Leiden in the Netherlands, welcome to ReelCast.
    Hello, Don. How are things today in the Missouri-Mississippi confluence zone?
    Don Retzlaff:
    Everything's fine. Thanksgiving's passed us. All the Christmas buying season's going on and the Christmas lights are going up on the houses.
    Nick Hogan:
    Indeed. We also have another election in the tank, so no more attack ads, which I'm sure has you absolutely despondent.
    Don Retzlaff:
    You can watch the news again.
    Nick Hogan:
    Yeah, yeah. There we go with reruns. So, the big gaming news was obviously the sports betting initiatives in California. So, we had that Prop 26 from the online companies that was seeking to legalize online sports betting in the state. This triggered the competing initiative, Prop 27, which then sought to legalize in-person sports betting at the state's tribal casinos. So, this number still throws me, $500 million in campaign spending on these two initiatives alone.
    Don Retzlaff:
    That's incredible.
    Nick Hogan:
    In which universe is the same? But in the end, I think both were rejected with Prop 26 chalking up what I understand to be the worst performance of a popular referendum in the state's history. So, the no's carried at 82 to 18. As for the in-person initiative, the Prop 27, it also went down, but that was a 67/33 split. So, generally, it's being viewed as a win for the tribes. First, they obviously wanted that Prop 26 go down and it did hard. As for the balance, well, I think they've certainly demonstrated that they're a political force with which to be reckoned in California. So, there's little doubt that this'll enhance their leverage moving forward. Were there any such initiatives out there in the Midwest at all done that you're aware of?
    Don Retzlaff:
    No, most of the Midwest states now have legalized gambling and the ones that don't are still going through the legislative process like Missouri. They're going to bring that back up again here in another five weeks or so when they reconvene. It's widely expected to pass this year, but most of the states in the Midwest now have it.
    Nick Hogan:
    Is that mostly in person or online or just a combination of two? I haven't really been monitoring.
    Don Retzlaff:
    It's both.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    It's both. So, it started off more restrictive and over time the restrictions have gone away. For instance, in Illinois, you had to sign up in person for online gambling to start for online sportsbook, but those restrictions have gone away. So, yeah, it's a combination of both mobile, online, and in person. Numbers keep getting better and better.
    Nick Hogan:
    I think some good progress, at least this cycle. So, hopefully, we get a little bit more steam behind it on the next one. Okay, so here we are, episode three. So, a few little housekeeping points before we dig in. First per popular request, we've made this podcast available on essentially every podcast service out there. I don't believe that Amazon Music is working for some reason, but everything else, Spotify, Apple Podcast, Google Podcast, iHeartRadio, Stitch or Deezer, you name it, it's up and accessible. The other thing is after doing that, we decided to dump the Vimeo channel. So, if you go there and don't find any new episodes, that's why.
    Secondly, we'd love to tackle any questions that anyone listening may have. So, if you have a question about what we're discussing or something you'd like us to discuss, please drop us a line at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T @reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That's not something we do. On that note, Don, we actually have a question. So, this comes from a Canadian and they ask, "Hey, Nick and Don. First, who's Paul Lynde?"
    Don Retzlaff:
    Oh, man. I feel old.
    Nick Hogan:
    Second, when looking at occupancy on premium, Nick said that 70% is the trigger point to add more. Don later said he prefers 80 to 85%. Can you guys clear this up? Thanks. Really enjoying the podcast. So, I can take the first part of this. So, Paul Lynde, L-Y-N-D-E, was an American actor from... Let's see, when was Bewitched? That was more '60s and '70s, something like that.
    Don Retzlaff:
    Exactly.
    Nick Hogan:
    Yeah, a sitcom called Bewitched in the '60s and '70s, and he had his super nasally voice and a really campy demeanor. So, when someone tells you that you sound like Paul Lynde, it's akin to saying that you have the confidence and masculine energy of say, a young Richard Simmons, right? So it's not cool. It's not cool and true. I am recording this in leg warmers and sat in short shorts, but hey, Paul Lynde. So, that's Paul Lynde. As for actual versus target occupancy on premium, I'll do what you should do and defer to Don. So, Don, take it away on that one. Is it 70% or 80 to 85?
    Don Retzlaff:
    I guess I'm just a little bit more conservative. If you're above 70%, you definitely need to be looking at it. If you're at 85, you definitely need to act. That's probably the best way to say it. If you're at 85%, especially on a monthly basis for handle pulls, your games are packed on the weekends. Your games are packed during prime time and they're really busy still at 9:00 in the morning. If you're at 70%, they're still packed during prime time. They're packed all during the weekend. They're just not as busy on your weekdays.
    But if you're getting 10,000 handle pulls per day, it would probably be a good threshold to look at. If you're getting 10,000 handle pulls per day on a Thursday, Friday, Saturday or Sunday, you're at 100%. So, I like 85. If you're at 85, you definitely need to act. At 70, you probably need to start looking at it and trying to figure out, "Is it capacity constrained on the weekends? Do I have room on the weekdays? Where is my capacity at?"
    Nick Hogan:
    So you always break it out in that way. You really look at low occupancy periods versus high occupancy and then a mean.
    Don Retzlaff:
    Yeah, exactly. One of the greatest ways to do it is just by looking at it, walk the floor, and most of the good operators are out there on the weekend nights. They're looking at their floor on the weekends and see who's playing it. Check out those average bets. Are the average bets down on the weekends because your best players can't get to those games?
    Nick Hogan:
    Okay, perfect. So, hopefully, that provides a bit more clarity on that question and thanks so much for submitting that. Okay, so for this week's show, we're going to talk about premium slot product. Now, Don, you know that there is no topic that fires me up more than the premium core debate. In many ways, it drives me crazy. Why is that? Well, it's because a lot of the prevailing notions, I guess that's a weird way to phrase it, the prevailing notions about premium, most of them poisonously negative are in a lot of ways completely divorced from reality. I'm saying this based on a lot of research that we've done over the past couple of years. So, before we dive into our talk here, I'm going to throw out four perhaps slightly provocative headlines just to get people charged up here.
    So, number one, for the overwhelming majority of those listening to us today, those operating single digit premium floor share, you are creating inventory scarcity that are hurting you badly. Number two, that scarcity and premium is hitting you where it hurts most. Your host level players and what we call the movable middle, that segment just below your host level play. Third, and this is largely directed to the CFOs out there, to a very considerable extent, misconceptions regarding premium are heavily rooted in operational accounting treatment and operational incentive structures. Four, slot personnel really need to stop fretting about cannibalization and moving money around the floor.
    We'll cover this in detail, but by and large, this is not happening and we'll explain how we've proved this and more importantly how you can prove it. So, I have to say, Don, just saying those headlines there already has me a little fired up. So, I think with that, we can dive in here today. So, we're going to go through these points today. First, we're going to cover definitions and models. So, what do we mean by premium product and what does that look like? Number two, then we're going to go into this issue of accounting versus analysis and getting a really clear view on how core and premium product perform over time. We're going to discuss then a bit of supplemental modeling that should be done during budget season.
    Then we're going to move on to a discussion of cannibalization and moving money around the floor. Then we'll talk about how you can ride the ship and prove some of these things out in your own operation and then move on to monitoring and adaptation then over time. So, let's start with the definitions. So, the three models that are most common in the industry would be, first of all, the daily fee model. This is for premium, obviously, the daily fee model, which is a rental model where you basically pay something like $55 per terminal per day to have that unit on the floor. Then we have participation models where there is typically a percentage set against either coin in or win. So, these are very common for the wide area progressive models that operate in the industry.
    Then of course, there are financial leases, which is a lease to own arrangement where you pay off the price of a cabinet over time. So, today, what we're going to do is we're really going to focus on the daily fee model, which is most prolific throughout the industry and the basic proposition there on standard premium daily fee model, it's limited term in duration. So, typically, the commitments are rarely beyond three to six-month commitments. There's generally some promotional package that's included either for an additional fee or as part of the fee, which is let's say some bank level promotional media or signs, et cetera. Conversions are free during the period and you have penalty free swaps and removals.
    Now, core, by comparison, these are long-term commitments. So, you're purchasing them outright. They don't typically include promotional packages. Conversions are typically paid, although you will occasionally get allotment of free conversions. Because you own it, swaps and removals are at your own expense. So, those are really the two things that we're talking about today when we're addressing premium and core. Now, the cautionary note regarding the accounting treatment. So, this is we're looking at accounting versus analysis. So, one thing to call out here is that our industry is extremely EBITDA focused, and that's EBITDA is the acronym for Earnings Before Interest, Taxation, Depreciation, and Amortization.
    Now, the reason that we're so focused on this is for Wall Street and publicly listed operators, it is an absolutely awesome comparative metric. It is because it ignores non-cash expenditures and variable costs. So, examples are tax rates that are beyond the operator's control and enables them then to isolate and monitor the operating profitability of the core business. So, it's generally used as a proxy for cash flow analysis in these companies and it's a really important metric. So, within slot operations, using an EBITDA model, the daily fees, so those rental fees at $55 a day per box, those are treated as operating expenditures or OpEx. On core, the slot and conversion purchases are typically treated as capital expenditures or CapEx.
    Whereas the OpEx charges impact EBITDA, CapEx charges do not. So, there are some issues with this approach. Now, I'm not suggesting that in slot operations today, people are really analyzing their slot performance looking at EBITDA. That's not what I'm saying. What I'm saying is that most operational bonuses are tied to EBITDA. As a result, a lot of slot teams, when they're looking at their slot capital budgets and whatnot, they budget in a way that's informed by EBITDA. The issue with this is that because what we discussed earlier about OpEx and CapEx, EBITDA models fundamentally distort the underlying economics on the slot floor in that it treats these core assets as just materializing for free and then having no cost to operate over time.
    This dramatically inflates what's known as productive capacity. So, the productive capacity of those assets over time refers to its ability to yield output, which is slot win in this case as we go into a longer time horizon. So, where is the productive capacity of premium? Thanks to all the swaps and conversions as part of the model, it remains relatively constant over time. Whereas the productive capacity of core assets decays at a compounded rate of 10% per year. Now, Don, I think as you pointed out, that's not necessarily consistent from supplier to supplier. I think that was just the average overall. Is that how you got to that decay rate?
    Don Retzlaff:
    It is. Some of the better suppliers are really helping that average. There are some of the smaller manufacturers who are really struggling to stay above house average after two years, and their rate is a lot larger than 10% a year.
    Nick Hogan:
    Okay. All right. So, just on core product overall, you can assume I think that's a safe rate to plug into modeling is 10% across the board on core. Okay. So, what happens here is that the EBITDAs is exaggerating core yield over time and this then leads to misguided economic strategies and decisions. So, one of the places where you can see this is if we look at casino floors today, what we'll typically find is that around 25% of the floors are core capital assets with effectively zero productive capacity. So, they're generating something like two minutes of play per hour. Now these units not only fail to generate the expected output, which is the slot win, but as we discussed in previous episodes, they degrade host level spend and crowd out hyper-productive alternatives.
    So, this is what we're really talking about when we look at this product and we ask the question, "Are you truly depreciating an asset here or are you just compounding a liability?" So, these are, again, just cautionary notes regarding the accounting treatment, but then there are some additional issues with core that I think are really important to note. The first one is that the capital budgets are not keeping up with cost increases, so the replacement cycles and cabinet prices continue to increase.
    So, Don, we were just at G2E and we were down on the floor and we saw a couple new cabinets. One of which was Aristocrat's Neptune Single, and it's a beautiful piece of equipment. I think as you noted, those graphics just pop off the screen. I mean, it would surprise me if that doesn't become Aristocrat's new hyper-productive workhorse. I don't know. Would you feel that way as well, Don?
    Don Retzlaff:
    I agree. Absolutely.
    Nick Hogan:
    But as we noted that one of the issues with it is I think it's listing at around 36 grand and that is a big jump. As you've pointed out many times, the slot capital is just not keeping up with that. Didn't you say you'd roll around 20% of your floor maybe 20 years ago?
    Don Retzlaff:
    Exactly. The average price per cabinet was say around $10,000 20 years ago. So, you can get 200 cabinets for $2 million. Now the average price is north of $20,000 a cabinet and I'm being very nice. So, now you're down to 100 for that $2 million and it's likely closer to 80 for that $2 million. So, you're changing out 120 machines less per year. So, you can imagine where that is over a five-year period and that's just going to keep getting worse and worse unless capital goes up.
    Nick Hogan:
    As we're stating here and as we just mentioned as the mean asset age increases on your floor, your mean productive capacity decreases, especially on the core side of things.
    Don Retzlaff:
    Manufacturers are really obsoleting these cabinets after a short period of time now. So, anything that you buy now, you're not going to get conversions for four or five years from now. The asset time that these machines are going to be on your floor is probably 10+ years. So, you got to figure for half of its life, it's going to be sitting there. There's not a whole lot you're going to be able to do to improve its performance.
    Nick Hogan:
    Precisely. Then secondly, another thing to consider here is relatively speaking, core product has very limited appeal to your most valuable customer segments. So, host level players with very few exceptions are largely disinterested in a lot of core product. Then that upper end of the middle tier also shies away from core, generally speaking. Now, Don, you've done those analyses. You've gotten way more into the weeds with those analyses. So, do you have many examples where the host level players are really pounding given core titles?
    Don Retzlaff:
    The host level players will play up their average bet and time on device on core machines. Their average bet is the highest on lease games and they spend a high percentage of their wallets on lease games at every trip. Unfortunately, the people who play the core games and shy away from the lease games are your daily low theoretical customers. They just basically do not play lease games for several reasons. One, including the cost of cover is higher, but they are the ones playing your obsolete games. So, your best customers shy away from the games that you buy and your worst customers are the ones that are playing the games that you buy. It was amazing study that we did earlier this year.
    Nick Hogan:
    Yeah, I mean, I guess my question was more about within that the X cluster stuff as we call it within the Cupid segmentation framework, what percentage of that would you say is core, in that let's say X1 bucket where we're talking about the highest of the high demand?
    Don Retzlaff:
    Maybe 10 or 15%. It varies a little bit from each casino based upon how many games that they have, but it is only the very, very top core games from the large manufacturers that are showing up there. It is games like Buffalo Collection, Buffalo Revolution, some places, Dancing Drums. Then some of the newer titles are out there, but you're not seeing a whole lot of lease games or a whole lot of core games at that upper right corner of the chart. It's all Dragon Links, Ultimate Fire Link, Buffalo Link, Dragon Cash. There are just a lot of really good lease games. Those are the games that your host level players are pounding.
    Nick Hogan:
    Yeah. So, then the third point then here about additional issues with core is that success rates in core are dramatically lower than they are in premium. So, only around 22% of all core product release is ever going to achieve a pan-industrial score of 1.0 or higher. Within premium, that's a little closer to 50% of the products that hit the market are going to get up to let's say 1.3 pan-industrially. So, that's just to know that the success rates and premium coming out of the gate are significantly higher, a little more than twice the rate as in core. Then fourth, independent of pan-industrial data service such as ours, you should assume that 60 to 70% of your core picks are going to drop below floor average within the first year of install.
    It's just something that we see over and over when we go into environments and we start enabling them with pan-industrial data. We look back over the historical trends. Those are typically massive leaps in success rates and game selection. A lot of that is just simply numerators and denominators. You've got a lot of options out there. Then really finding that needle in the haystack is a little bit tough, but a company such as ours has already mapped those haystacks. So, you don't have that problem when you're subscribing to pan-industrial data services. If you are not, then you should load that assumption yet.
    So, coming back to this EBITDA projection thing, what I want to do here is lay out how you could make these calculations, how you could change this in your modeling during budget season. So, I'm not suggesting that you do away with EBITDA projections. What I'm saying is that in addition to the EBITDA projection, you run either an EBIT projection that is where you add back the depreciation into your projection or EBITDAR where you remove the rental fees from the premium product. So, either approach is going to get you to the same space, but here I'll just walk through the drivers and then explain how this math pencils out over time.
    So, if we take a casino that has an average daily win of 300 bucks and we make a comparison of premium and core, what we're going to do is we're going to assume that that premium product is going to perform at 2.5X floor over a five-year period. So, that's going to be doing $750 in unit win on a daily basis. The core, we're going to be generous and we're going to assume that it's going to be at 1.5X floor to start. So, that's 450 per unit average daily win. The daily fees on premium, we're putting in at 55 bucks per unit. The daily depreciation on core, what we're assuming is a five-year depreciation schedule on a $25,000 asset. So, that comes down to $14 in depreciation per unit per day.
    Then the last thing that we're assuming is that core performance is going to decay at 10% per annum compounded. So, when we run this out over five years, here's what we get. So, first of all, the five-year revenue on the premium terminal and don't forget it's that 2.5X floor and because we can keep converting it or swapping it, et cetera, we can keep it at that level. So, over that five-year period, the revenue on premium is going to be $1.4 million. Comparatively, the five-year revenue on the core terminal, which is again decaying at that compounded rated of 10% per annum is going to be just $605,000.
    So, the cost per unit per annum is going to be $20,000 for premium, which is at $55 a day multiplied out by 365 and 5,000 for core, which is your depreciation expenses. So, then five years of premium income is going to be $1.3 million versus $580,000 in core. So, your five-year premium income is 219% superior to your core revenue. So, the range then there is 178% superior in year one, ranging out to 276% superior in year five. So, now an EBITDA analysis is going to yield a directionally similar conclusion. However, when you look at this over the five-year horizon, it's also going to project something like 160,000 in incremental income, which in actuality is never going to materialize. So, it's inflated by around 27%.
    So, mind you, this is just one terminal. So, if you multiply this out times your core floor share, you'll start to see why these EBIT and EBITDAR and performance decay points are critically important in these analyses. Now, am I suggesting that we should limit EBITDA as the industry's most standardized performance metric? Absolutely not. Am I saying that we should eliminate it as the basis for operational bonuses? Again, I'm not. What I'm suggesting here is that when you make these EBITDA projections during budget season, you really need to A, complete your projections with either these EBIT or EBITDAR comparisons of core and premium, and B, you need to make sure that you have a wide open dialogue with your CFO on this topic.
    The first step is awareness. Your CFO will most certainly understand what we're saying here. Once you're on the same page, you can figure out collaboratively how you want to go about this. But the problem is that we frequently encounter slot directors who know that they're short supplying premium, but can't convince their CFOs to free up that incremental OpEx. Why is that? Well, it's largely driven out of fears that the resultant revenue will not be truly incremental, but largely derived at the expense of other machines. So, this is known, of course, as cannibalization or as it's more popularly known, moving money around the floor.
    So, Don, before I jump forward and I realize I'm just talking up a storm, is there anything you'd like to add here about how this plays out within operational environments that you've experienced?
    Don Retzlaff:
    The biggest thing, like you said, is just making sure that you have enough lease fees available for you to expand where you need to expand. I guess the first place to start is making sure that you don't have any poor performing lease games, that all your money is being spent wisely, but look to grow your lease games. You can have to do it an incremental basis, do it that way. If you're having a good year, add lease games in the summer. When you're beat in budget, it's a great way to do it so you can carry it through. But most floors, if you're less than 10%, you probably need to add lease games. We have clients that are north of 20% and they're doing very well with it. It's a great strategy.
    Nick Hogan:
    Don, did you ever have these situations where you found yourself arguing with the CFOs about bringing new premium product on? I'm pretty sure the answer is yes.
    Don Retzlaff:
    Yeah, you can always control cost, right? Whether it's labor, marketing spend, or lease games fees, it's a really large number. When you look at your financial reports, where are you spending money in casino operations? It's going to be labor and it's going to be lease fees. You just have to make sure that you're doing it, one, wisely. Two, you definitely have to work with your GMs and your CFOs to say, "Look, we are cutting our nose off the spider face because there is definitely room for us to grow revenue by having these better games on the floor, especially for the places who have not increased our CapEx over the last five years and are putting less and less new product on the floor." It's a great way to refresh your floor.
    Nick Hogan:
    Yeah. What's been interesting for me too is on these presentations that we've done, where we've been discussing this type of stuff and whatnot, where there's been a CFO in the room, what was always so startling to me is how many no's those guys took. I think we had a number of meetings at G2E where we had CFOs in the room and those guys were scribbling from front to back. I didn't see them stop writing. So, I think there's a bit more analytical receptivity on this than a lot of people may assume.
    Don Retzlaff:
    I can really see this increasing year after year going forward being a new strategy just because of the fact that you're not going to be able to replace your floor as well as you once did and you're going to have machines out there that are going to be obsolete and it's going to be a large percentage or your floor. Just think of it five years from now, what percentage your floor is going to be obsolete cabinets that you're not going to be able to improve upon and it's going to be a very large percentage of your floor.
    Nick Hogan:
    Yeah, this is another thing. I mean, we talk about it all the time, which is the best practices stack. This is just another new reality. I really look at it that way and it's important in operations that we adapt to these realities. So, indeed, that is an excellent point. Don, what happens five years from now? We already know these imbalances are out there. Okay, so now let's roll back. So, one of the things, what I just said in struggling with the CFO with this is the underlying question about, "Okay, is this truly incremental revenue or are we cannibalizing the other machines?"
    So from the work that we've done, what we know is that the fears about cannibalization, generally speaking, are quieter ways, they don't necessarily reflect reality. So, the anxieties are more considerable than they need to be. I'll explain what we've done and why it is that we look at the world this way. So, if we push the rewind button and go back to mid-2020, COVID had hit the US. Following a temporary closure, we reopened with most casinos reducing capacity down to 60 to 70% of standard. What did they cut? They cut the garbage, the stuff that doesn't work. So, suddenly, the quality of the profile of the floors went way up.
    Despite material drops in visitation and some very notable demographic shifts, slot floors began meeting or exceeding revenue performance on a year-over-year basis. So, the lazy analysis on this, it was all pent-up demand. That was an explanation on which we called BS pretty much the incident we first heard it, because what we saw was fundamentally different. So, suddenly, there was significantly less variety on the floor, so did somebody just say overdiversification? Far less junk and valuable players were finding what they wanted more quickly and they played it more intensely. So, the machine sampling or that ricochet effect that we discussed in previous episodes, that dropped right off the charts.
    The mean session duration skyrocketed by 20 to 30% and the average bets and trip wallets boomed. Now, was this due to pent-up demand? Pent-up demand most certainly played a part, but we had sufficient controls in the analysis to show us that it was due mostly to compositional changes in the mix. Now, this was a big sample. It covered two separate six-month periods, dozens of markets, and close to 50 sizable operations. So, we felt really good about it. But with the government subsidies and demographic shifts that typified the period, we felt there was sufficient contamination in there to warrant incremental proof.
    So, using our segmentation model, we whipped up a test and we chose multiple highly competitive locals markets with large numbers of discriminating players and relatively robust visitation frequencies. What we did was this. We altered the floor's composition, that's all. We boosted the supply of high demand premium product at the direct expense of the doggy core. We did all of this with zero marketing support. There were no ad buys, there was no direct mail, there was no support by the hosts. It was compositional changes only. What did we find? Well, in our first analysis, we had seen a disturbing trend that showed high value players engaging with low demand product at an increasing rate year over year.
    Why? Because as we've been saying, the supply of premium is over constrained, and each year, the quality profiles of the mixes are getting worse. So, each year, the share of core assets with little or no productive capacity is increasing. But when we created the incremental premium capacity, that trend absolutely flatlined and promptly. So, within a month or so of altering the mix, high value players lost effectively all interest in the dogs and their engagement with high demand premium product was a straight-up hockey stick. Okay, threefold increase that is yet to abate. Then what about the big question? What happened to the segment strip wallet? Well, we modeled and determined that it would be earth-shattering at a 10 to 15% lift.
    What did we see? We saw 35 to 75% lift in the trip spend. So, host level players who were coming in and dropping a little less than 500 per trip were suddenly throwing down closer to 800. The session duration shot up by a quarter or so, average bet lifted by a buck, and the net promoter scores started really busting the curve. Why is that? Well, the reason cited was I can get on the games I like. So, what does this tell us then about cannibalization? Well, I really look at it this way. If we see 35 to 75% headroom in host level wallet, pretty much the last thing we need to be worried about on this is cannibalization.
    As I see it doing nothing, they're a little more than just distracting you from your chief problem, which is really failing to offer your best customers what it is that they want to play. So, Don, again, I'm monopolizing. Please throw in any additional thoughts you have on this.
    Don Retzlaff:
    If you're at a property that has a single bank of your highest performing lease games on the floor, you'll be shocked to see what your host level players do if you add another bank or two, Like Nick said, we did multiple experiments, multiple different properties that had different sizes of lease games on their floor. Some of them had a single bank of the best, some of them had four banks of the best. Those properties that had just a bank of six or a bank of eight of the best lease games, when they doubled that or tripled that, the session times really shot up for your host level players. Over 20% of the session times were over an hour in length. The theoretical skyrocketed. The average bets went up by a dollar per spin.
    It was just incredible to see what happened when you add the capacity to those markets. For the properties that had a lot of lease games, they had 30, 40 of them already of the best titles. They still saw market improvement in their host level players, but they also saw massive increase in that $150 to $300 ADT player, because now they could get on the games, their average bets went up, their session times went up, and best of all, their theoretical per trips went up as well. The good thing is the number of sessions per month didn't go down at all. They actually inched up as well. That's pretty cool.
    Nick Hogan:
    Yeah, awesome stuff. So, Don, in terms of with people moving forward on this stuff and starting to look at it and analyze it, you want to walk them through how they can look at it in this way from more of a segment perspective?
    Don Retzlaff:
    Sure. The first thing you have to do is you have to figure out what games are your hostable players pounding, but they can't get on consistently. If you only have a bank of eight of your best lease games and they can't play them on the weekends, we've proven that when they play something else, they don't play as much as they would. So, are these players not getting on their best lease games? So you've got to figure that out to start. Work with marketing. Try to figure out what these players are playing. Use your slot system if you can to figure this out. If you've got games that are doing five, six times house average, you don't have enough of them. These games should be pushed down to three times house average, three and a half times house average.
    There's no reason to have a game doing six times house average. That just means you're supply constrained. If they can't get on their games, if your densities are above, we'll go back to that 80% range, you definitely need to increase. You need to push them down to that 65% range. If you really want to be aggressive, you can even push them lower. If you've got games that are doing three and a half, four times house average, why don't you want more those games on your floor?
    That's been the key. If you've got a game doing $2,000 a day, why wouldn't you want more of those if your house average is 350, and that's what it boils down to. Those are the games that people want to play. Those are the games that people are coming back to. You really got to balance inventory.
    Nick Hogan:
    Do you say that it's equally important that you do a one for one replacement? So that is if you're putting in, let's say this high end lease product, you should take a dog off the floor. How do you feel on that one?
    Don Retzlaff:
    Most floors are probably still a little bit too big post-COVID. I would definitely do a fair share analysis, just try to see where you're at on your floor as far as your balance. For instance, the real players, the physical real players have not come back post-COVID. A lot of that is because the physical reels are being carried by that 65+ year old players and they just haven't come back as strong as the younger players. Like you said earlier, there's a chunk of your floor that's obsolete that's getting played two minutes an hour. So, with an average session time of eight minutes, that means it's getting played once every four hours.
    You can definitely take those off your floor. You can decrease your repairs and maintenance cost by reducing your count. You can open up your floors. Post-COVID, you see a lot of banks of sixes or banks of four, even smaller. So, you get a chance there to reimagine your floor and open things up. A lot of casinos have done a magnificent job of that. Others have done it only on half of their floor and they're still working towards the full floors, but it's at least a one for one reduction if not more.
    Nick Hogan:
    What we've seen then too is with this approach is that with this problem that we've identified and this comes down to inventory of balance and supply side scarcity for your best players, what we've noticed is you can take a gigantic bite out of this apple just with premium product alone. Would you incur with that, Don?
    Don Retzlaff:
    Absolutely. It doesn't have to be a ton. We're not talking about adding 100 lease games to your floor. We're talking about adding 25, 35, 40 lease games to your floor. It is still a big number, especially if you don't have that many on your floor already. But like we mentioned earlier, we've got property that have gone above 20% of their floor count being lease games. It is the best games out there in a market now. It's the games that people demand to play, and people will wait behind these games to play on.
    You can picture your floor right now, and you've got people sitting at machines just staring at these seats waiting for people to get up or they're not playing and they're not productive. There's no coin in going in when they're sitting there waiting. Your best players love these games. You'll see average best on your lease games from your host level players jump to over $4 per spin.
    Nick Hogan:
    Yeah, it's incredible. I think importantly, just for anybody listening to is that in the places where we've done this, we've yet to hear any complaints. Quite to the contrary, you've got a lot of accolades for these movements.
    Don Retzlaff:
    We did this at probably the worst possible time. We did this when the gas prices started going up in North America and we started seeing unrated play fall 15 to 20% of many of these casinos, but the host level play ended up being up significantly. Like Nick said, it was 30 to 75%. These are hundreds and hundreds and hundreds of players at each of these properties. I think our smallest sample size was 1,400 host level players that we looked at. So, they're all significant samples.
    Nick Hogan:
    These results, they truly blew me away and I wasn't alone on that. So, that was good. Okay. So, Don, maybe then we can talk about well, then making these change and then how do we monitor and adapt moving forward? Those are the other points.
    Don Retzlaff:
    There's no reason to have lease games on your floor that are doing less than two and a half times house average. There's enough of these games out there that you should be able to keep your house average above two and a half. If they start falling below two and a half, it's time to switch. You've really got to stay on top of these games. I know there's been some supply chain issues in 2022 and getting some of these games out there. I think most of those should be taken care of here in the first quarter of '23, but you've got to keep tracking these games. That would be the first thing.
    Nick Hogan:
    Yup.
    Don Retzlaff:
    Go ahead, Nick.
    Nick Hogan:
    Well, I had a question then about the notion of segment neighborhoods and stuff like this and the layout on the floor. Can you speak to that at all, Don?
    Don Retzlaff:
    Yeah, we've done a lot of studies on what games they will play. If they like a particular game, what else will they like? We've had some success creating neighborhoods where you have game A next to game B, and it doesn't have to be another lease game. It can be a core game, but it's similar bet patterns, but it definitely plays to the same crowd. For instance, we know that these host level players, if they play this least game, they'll also play this core game. It goes for the non-host level players too. If they like this certain core game, they also like this one.
    So, it is tracking, trying to figure out what games go best next to each other. You can definitely make these neighborhoods on your floor. The great thing about that is your host level players don't have to walk. They don't have to worry about going past an entrance and making that left turn out the door. You keep them in that area and you end up getting longer trips.
    Nick Hogan:
    Just as we say, if we had a 10 commandments of demand driven mix management, probably the number one would be thou shall collaborate with marketing. But I would say the second one would be you really have to keep in touch, I think, with finance as you're moving forward too and sharing with them these same analyses that you're running and looking at these assets and just keeping them in this loop. Would you generally agree with that, Don?
    Don Retzlaff:
    Yeah, you really have to look at the player reports. It's not just the financial reports. You can look at that and you can say, "These games are doing $2,000 a day," but look at what it's doing to your customers. Take your host level players that are playing these games and see if they're increasing their spend per trip, their trip frequency, their average bet, and show that to your marketing people and your CFO and say, "This is what we've done." We've added $55 a day, but look what's happened to our top 2,000 customers. They're coming more often. They're spending more money. That's your driver to be able to increase even further.
    Nick Hogan:
    Yeah, totally agreed. Okay. The only other thing that I would maybe add to this is the notion of outside assistance on this. Again, sorry to do a plug for ReelMetrics, but this is a type of stuff where we have really massively simplified segment targeting and really then watching those performance levels and maintaining them. I know, Don, you work with customers on this stuff like crazy, so maybe you can talk a little bit about how difficult it is for some folks to get access to this information and make sense of it.
    Don Retzlaff:
    Yeah, it's always tough to look at in the slot systems what they're playing. If you're lucky, you've got a slot asset number that you have to map up to a theme. So, using a search like ReelMetrics is extremely helpful and it really cuts back on the time that you have to spend chasing this data down. But the other nice thing too is it gives you an idea of what games should be the next ones on your floor. We had properties that went from a 40% success rate on conversions to 100%. Like Nick said earlier, there's a very high percentage of new themes that come out that fail. It's the same thing on the lease games.
    The other nice thing too is we can also guide you as far as, "When are you going to reach capacity? How many games should I get? Should I have one bank of these? Should I have three banks? Should I have 50 of these games on your floor?" We can show what other casinos have done where they've expanded too fast, where they haven't expanded fast enough. There's all that data that's out there for you to look at.
    Nick Hogan:
    I'm sorry, Don, what was that?
    Don Retzlaff:
    It makes it a lot easier, and it keeps all those games above that two and a half times house threshold. There's enough choices out there to stay above that level to where you don't have to have that fight with the CFOs saying, "Well, these games are only doing one and a half times house average." Make sure you take care of your house first before you can expand.
    Nick Hogan:
    Yeah, totally agreed. Totally agreed. Okay, Don, well, I think that largely covers what we were planning to cut through today. Did you have anything else you wanted to add on premium? Any parting pieces of wisdom at all?
    Don Retzlaff:
    I think we pretty much covered it. That was a pretty long podcast.
    Nick Hogan:
    Okay, okay.
    Don Retzlaff:
    We can talk on this for days.
    Nick Hogan:
    Yeah, yeah, yeah. I mean the premium thing just goes on and on. Like I mentioned, I had a call with some folks last night and we were on it for about an hour. I mean, it just is an endlessly fascinating topic and one that really has people divided, I have to say. Let's try to get everybody on the same page.
    Don Retzlaff:
    People hate the fees, but love the games.
    Nick Hogan:
    Yeah, yeah. I think that's the point overall. So, we just have to be able to tie them back to what they're actually doing on the floor.
    Don Retzlaff:
    Exactly.
    Nick Hogan:
    Okay, cool. Well, I think that pretty much covers it for today, Don. Thank you so much for your time, and I guess we'll be back here in about a month's time.
    Don Retzlaff:
    Very good. Take care.
    Nick Hogan:
    Okay, thank you, Don. You, too.`,
    episodeNumber: 3,
    seasonNumber: 1,
    datePublished: "2022-12-15"
};

export default S01E03;