import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S03E01 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S03E01.defaultProps = {
    id: "14296731-s03e01-bc-slots-with-brian-christopher-josh-o-connell",
    link: "s03e01-bc-slots-with-brian-christopher-josh-o-connell",
    title: "BC Slots with Brian Christopher & Josh O'Connell",
    subTitle: "Season 3, Episode 1",
    description: "In this first episode of Season 3, Nick and Don discuss the slot influencer phenomenon with domain pioneers and undisputed leaders, Brian Christopher & Josh O'Connell of BC Slots. Learn about the logistics of BC Slots engagements, their benefits to operators and suppliers, and the company's newest products, FlipTheSwitch.com and Brian Christopher Slots 2. Also in this episode, Dragon Link expansion.",
    topics: [
        "The origin, meteoric rise, and preeminence of BC Slots",
        "The logistics of a BC Slot engagement",
        "The benefits of influencer engagements and defining / measuring success",
        "BC Slots' work with Gaming Arts, Carnival Cruise Lines, and more",
        "Anti-smoking stance re casino engagements",
        "FlipTheSwitch.com and Brian Christopher Slots 2",
        "Listener questions"
    ],
    keywords: [
        "BC Slots",  
        "Brian Christopher",
        "Josh O'Connell",  
        "slot influencer",  
        "casino gaming",  
        "Gaming Arts",  
        "Carnival Cruise Lines",  
        "casino marketing",  
        "anti-smoking casinos",  
        "online gambling trends 2024",  
        "FlipTheSwitch.com",  
        "Brian Christopher Slots 2"
    ],
    episodeNumber: 1,
    seasonNumber: 3,
    datePublished: "2024-01-19",
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/14296731-s03e01-bc-slots-with-brian-christopher-josh-o-connell",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e01-bc-slots-with-brian-christopher-josh-oconnell/",
    transcript: `Nick Hogan:
    Good morning, Don. Here we are. First episode of our third season. Can you believe that?
    Don Retzlaff:
    That's crazy. I can't believe it's 2024. Wasn't it just 1999 like three years ago?
    Nick Hogan:
    It was indeed. So are you buried in snow there today in Illinois?
    Don Retzlaff:
    No, actually all the snow we had was melted, but we're supposed to get a storm tomorrow. Since we got that negative five, negative six over the weekend, it never gets that cold in St. Louis.
    Nick Hogan:
    Okay. Whoa. Alrighty. So we have two guests today for whom we have a lot of questions, so we're going to keep the pre-interview banner to a minimum today. So if it's okay with you, Don, I'm going to bust straight into the listener questions.
    Don Retzlaff:
    Awesome.
    Nick Hogan:
    Before I do, let me say that we love to tackle any questions that anybody listening may have. If you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S T@reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That's not something we do. So Don, first question comes from an operator in the American Midwest who writes. "Good Morning, ReelCast, I have a Dragon Link four pack that we expanded by two units and they're still doing nearly five times four. Would you, A, add some of the higher liability Dragon Cash units? B, add more Dragon Link units at the same jackpot liability? Or C, keep everything as is? All input is appreciated, fellas, thanks in advance." So Don, smack dab in the middle of your wheelhouse. You want to take that one?
    Don Retzlaff:
    Yeah, five times house I would really consider expanding still. Depends on your density levels, but if your density levels, your occupancy levels are over 80, 85%, I would consider expanding before you get into Dragon Cash as your players can play up on the Dragon Link machines. Normally anything above four, four and a half times house average, you can probably still add more units, especially if you added units and the play didn't go down, that means you have access demand for it. So we found a lot of our host-level customers really like Dragon Link and they will play up. You'll probably generate $50 more per player per theoretical in each hour. So add more Dragon Link first.
    Nick Hogan:
    Okay, very good.
    Don Retzlaff:
    There's just been a couple of cases where I've seen really high multiples and it didn't warrant expansion and that's usually because the oxygen, oxygen levels are really low, like 40, 50%. But at five times house, you're probably pretty safe.
    Nick Hogan:
    Okay, fair enough. Okay, so we have a second question that actually came in a couple months ago, and this one comes from an operator in Australia who asked, "Hi Nick and Don, would you be willing to do an episode on slot influencers? I think many operators are interested in working with them, but we don't understand much about business models and the other details. Thanks. PS, we love the podcast."
    So why we haven't done this yet is a bit beyond me, but in short, yes, we'd happy to do this. And it's perfect segue to introduce today's guests. These gentlemen run what has become, without question, the biggest name in slot influencing and one of the most fascinating businesses to emerge in the gaming industry over the past decade. They have roughly 650,000 YouTube subscribers, 4,400 videos up, incremented daily, a branded gaming area at the Plaza in Las Vegas, a branded slot machine produced and marketed by Gaming Arts, and branded cruises with Carnival Cruise Lines. Basically when slot influencing is mentioned, it's the first name that comes to mind. I'm referring of course to BC Slots and we're delighted to have with us today the founder and CEO, Mr. Brian Christopher and VP of Product Development, Mr. Josh O'Connell. Howdy boys. How are things in Palm Springs today?
    Brian Christopher:
    Very good. Thank you so much for having us.
    Nick Hogan:
    You bet. Now, like many in the gaming industry, when I first heard of Slot influencing, and I think this goes back to let's say 2018, 2019, I, not unlike essentially every industry person I know, just dismissed it. I just couldn't imagine anything more inane than watching YouTube videos of some dude playing slots. I just felt it would be a flash in the pan, placed it in ignore mode, and went on with life. And then fast-forward to 2022, Brian and I met at an AGS conference in Florida, and on one of the nights I bumped into him on the slot floor streaming a session at Rakin' Bacon Deluxe. And when I asked him how many people were watching him, he delivered a digit. I simply could not believe it was 18,000 people. Now my memory is a little bit fuzzy, but upon hearing that metric, I seem to recall my head spinning all the way around on its axis, like a radar dish, just ignore mode officially deactivated at that point.
    Brian, let's start from the top here. What on earth compelled you initially to bust out your phone and start streaming your slot play?
    Brian Christopher:
    Yeah, actually it started in 2016. That's when we first started doing this. I had no plan to do this for a living, let's be frank. I had set my whole life as an actor in Canada and my lifetime goal was to move to LA to further my career. And so after 20 years of it in Canada, I packed up and moved with my husband down to LA. When we first got here, it was right before Christmas, and so we didn't think about traveling back home for Christmas already, we just got here. So we thought, "Let's go to Vegas." We've never been to Vegas before.
    So we went online to look up which casino to stay at, where to go, what kind of games they have in Vegas. I didn't know if there's a big difference or not. And I came across some videos of people that did this. They filmed their slot play. I thought it was the dumbest thing I've ever seen. Why would anyone sit and watch this? It doesn't make any sense. And then eight hours later, I'm still there watching. I was so sucked in. But the actor side of me and the filmmaker side of me is watching these videos and I'm like, "They're barely talking or they're not even showing their faces or I can't fully see how much they're betting, their camera angle's really bad." And I was like, "Man, maybe when I go there, I'll film somebody this for fun and I'll throw them up on my YouTube channel" because I had a YouTube channel as being an actor. I had a whopping, I don't know, 15 or 20 subscribers probably.
    And so I did just that. I went there, I filmed a few games and with zero experience of doing anything on YouTube, I literally posted five or eight videos on day one, another five on day two, and the rest of whatever I had left, three more on day three. They were all two to three minutes long. And then I was done. I walked away from it. I was never going to do another one again. But to my surprise, a week later I had over a thousand subscribers and hundreds of people asking, "When is the next video coming out?" It was a little shocking. Like I said, I have no plans to do it. I posted it on my actor channel. It's still the same channel today. So if you go to the very beginning of my channel, you're going to see all my acting videos at the very beginning.
    Nick Hogan:
    Okay. Then tell us a little bit about those early days, particularly with respect to the operators. How did they react to it? Because I'm sure you at one point had some people coming and talking to you.
    Brian Christopher:
    Yeah, I had my share of being escorted out the door a few times.
    Nick Hogan:
    No kidding.
    Brian Christopher:
    In those first days, I don't know, six months to a year, I didn't seek permission. I just would walk inside and sit down at a game and just start filming myself on my cell phone. Wasn't filming anybody else or anything else, but some properties were just not open to that idea and had asked me to leave. And as a side note, the funniest thing was whenever I would ask, "Why not?" Their answer was always, "It's against the law," which it's not. I was told, "Because of terrorism." I was told that once, and I'm like, "What?"
    Nick Hogan:
    Yeah.
    Brian Christopher:
    Yeah, very weird. But then came along San Manuel Casino, which from LA, they're a short drive away, learned that they had an open filming policy. So I decided to go there in the film. That was led by Thomas LaRocca, who was just... I don't know how he thought so far ahead of everyone else, but he was open to this idea, so big shout out to him. So I started filming there more often, they invited me back, we became good friends and they were also my very first sponsor. Also, seven years later, I'm actually sitting in their hotel room right now because I'm filming there today.
    Don Retzlaff:
    Oh wow. That's-
    Nick Hogan:
    You've done quite a bit Agua Caliente as well, right? Isn't that another big one for you?
    Brian Christopher:
    That's correct, yeah.
    Nick Hogan:
    Okay.
    Brian Christopher:
    Yeah, we've worked with tons of sponsors all over the country. Agua Caliente is a big one because they're local to us. We live in Palm Springs. Yaamava's very close. We go to Vegas every month [inaudible 00:09:40] Plaza, and then everywhere else around the country too.
    Nick Hogan:
    Okay, great. And so then really when is it that you first noticed that it was a material fan base arising? When did this become really clear to you?
    Brian Christopher:
    I'll never forget it. I was filming, I don't remember if I was live or just filming a video, but out of the corner of my eye, I captured this woman and she just starts screaming really loud, and she was looking at me and she was so excited because she saw me on the slot floor. And I was shocked. It was like she saw a big TV star, movie star or whoever, and she was so excited. She wanted a photo with me and I'm like, "What? What is this?" I just play slots. This is not a celebrity status kind of job or what I'm doing here. And that's when I realized, "Oh wow, people really treat me differently and they think I'm someone now."
    Nick Hogan:
    Yeah, yeah, yeah. Okay, so for Josh, now you're the guy who founded the website, KnowYourSlots, and so you've been in the scene for a long time, you understand slot players really well. How would you explain this phenomenon of the BC Slots? What is it that really is turning players on so much?
    Josh O'Connell:
    Yeah, I found Brian's video... The YouTube algorithm by the way is top-notch because it knew I was interested in slot videos and put Brian's videos in front of me. The first batch came in front of me very quickly, so I was probably one of those people that saw it at the very beginning. I think for me as a player, I think what draws me to these types of things has been the interest in learning about new games. One thing that's great about watching people like Brian is that you get a taste of a game before you sit down yourself and get a feel for it and understand if it's something you're going to want to play. But it's also when you see someone on your screen, whether it's your TV, a lot of people watch Brian on their TV or on their computer every single day, you forge a connection because it's someone that's coming into your house every single day.
    And so then when you take the next step and you go to an event. In my case, Brian just happened to be in New York for what was supposed to be a live stream that got canceled on him at the 11th hour. And he is like... He was live with the Rudies from a Dunkin Donuts, and it's like, "Hey, I'm still willing to meet up with someone for dinner." And so we ended up meeting up for dinner that night. That was the first time I met Brian. And then when you meet him in person and you realize he's as genuine and as friendly and as nice in person as he is on camera, you build that next level of connection.
    And I think that's part of it, it's a humanizing aspect of this. Whereas if you're a slot player, it's many times a solo endeavor. You sit down at a machine, you're many times... Or at least historically I should say, you're by yourself, you tune out the world, you're focused on the game. But I think what players like Brian have done has started to build a community around these things. You meet people, some of my best friends that I've made over the years have been as a result of being in Brian's community, and now part of the travel is to see them as well as Brian, sorry Brian. I have friendships that are now six or seven years deep that come out of our shared interest in casinos and slots and we might socialize and have dinner before we go hit the casino floor.
    Nick Hogan:
    Yeah, yeah. Okay. And then I'm really curious when... This is, I guess, for you Brian. When did you realize that you could really take what you were doing and turn this into a healthy, vibrant business? Just give us a bit of an impression of those early startup days when you really started forming the company.
    Brian Christopher:
    Yeah. It was a pretty good success story from the beginning. After a month of the channel, I was invited to the partner program to start monetizing my videos. So month one I made a couple hundred bucks maybe, a month two I made 500. And it just kept slowly increasing every month. And that's when I realized like, "Maybe I'm onto something here, I should keep doing this." I could see the trajectory of where it was heading. Obviously playing slots is a losing sport, and eventually I figured... I remember back then my budget was about a hundred dollars loss per video for the month, and my goal was always make $100 a day on YouTube. And so I was like, "Eventually I can see it keep growing and growing until I will make a hundred dollars a day and make a profit." And that hit around the one-year mark when I started consistently making a large enough profit to make this viable.
    Nick Hogan:
    Now, is it permitted for you to monetize your videos on YouTube? They have policies against that or anything? Or is that allowed? Can you serve ads and things of that nature?
    Brian Christopher:
    Oh, 100%. Yeah, you can definitely serve ads. It's in their policies you're allowed to do gambling. They have some restrictions around types of gambling you cannot do like cryptocurrency and stuff. But yeah, showcasing gambling is totally fine with them.
    Nick Hogan:
    Okay. All right. And I know Don has some questions about the engagement. So Don, I've been usurping the mic here, so I'll turn it over to you here.
    Don Retzlaff:
    Yeah, it's a pleasure to talk to you, Brian. I've been blown away by two different things on YouTube over the course of probably the last 10 years, and yours was one of them. And getting the algorithm to get Babymetal, watching a video of them once, those are the two that really stand out of all the things that I've watched. I remember watching your first video-
    Nick Hogan:
    What did you just say? Did you say Babymetal?
    Don Retzlaff:
    Oh yeah. You got to watch it. It's insane. You look at it... I had the same reaction when I watched a Brian Christopher video. I said, "What did I just watch?" Because I was doing some research on slot machines and YouTube's a great resource for operators. I used to use it for training videos and showing bonus rounds and stuff like that. And I'm watching one of your live casts and I'm seeing all these comments and the 18,000 people watching. "What did I just watch?" Same thing happened to me when I watched Babymetal. Just watch it, it's insane. So what is a typical Brian Christopher BC Slots engagement for the operator?
    Brian Christopher:
    Our goal is always twofold. We want to bring a really fun and exciting experience for the fans, but obviously we want to ensure that the casino is well taken care of and that we bring business to them. So we offer lots of different things that we can do for the casinos. First and foremost, obviously we make videos. We showcase myself playing the games, but it's more than just playing the games. I will walk through the property, I'll show off the hotel rooms, the restaurants, the bars, talk about their players club, any concerts they have coming up, things like that. So it's more exciting and it's not an Instagram post of a steakhouse showing a plate of food. It is me taking a bite. It is like, "Wow, this is the best steak I've ever had, you guys." It's real. I will never lie either. I'm never going to... Casinos, they can give me talking points to talk about, but it's at my discretion whether I say them because I need to make sure it's 100% authentic. That's the role of an influencer. Outside of-
    Don Retzlaff:
    And like Josh said, it comes across really genuine on the videos and I think that's one of the reasons why people keep coming back. I've watched many of the influencers, we use it as a research point too for games that we don't know about and things like that, but your videos come across extremely genuine. Do you do like meet and greets with the customers there as well?
    Brian Christopher:
    Of course, yeah. So that's another part of the things that we offer them is a meet and greet. They always sell out. Depending on how big they want it to be, we could do a hundred people, 200, we could do 300 people. It depends on what they offer there as well. We've also introduced meat and drinks as well, which is really fun. We do that in Vegas mostly, where we'll actually go into the casino and everyone has drinks and we gamble together and I just get photos and hang out because that's what people really want. How do I get to gamble with Brian Christopher? But the meet and greet itself is usually in a room, I'm on a microphone and they ask me every question that you can think of, not only about how slots work, but about my relationship, about everything possible.
    Don Retzlaff:
    That's awesome.
    Brian Christopher:
    Outside of those, there's a live streams of course, which brings tens of thousands of people when we're live and then tens of thousands more after we're live. We get a lot more views after it's finished. And then we host other events. So there is a thing called... You know about slot tournaments obviously. I can host one of those, very easy to do. There's also group slot pools, and those ones are really exciting and those sell out so fast, usually within a day or a few hours or something. And what those are is everybody that comes to those puts in the same amount of money. It's either 200, 500, $1000, we all put in the exact same amount of money into one slot machine and we do large bets as a group. Those are really exciting for people because a lot of my fans, they're used to doing a dollar a spin or 50 cents a spin, and now all of a sudden we're doing 25, 50, 100 dollars a spin for a group whole. So those are really popular in person.
    It brings so much excitement to the casino floor also. And so the casino loves what's going on on the floor. Everyone's like, "What is that? What is that?" But then the videos do really well also on YouTube, so also brings more excitement to that casino. And so these videos, they live on forever, and so people are always going to see it. We'll get millions of views, tens of millions of impressions making people want to come to their locations.
    Don Retzlaff:
    That's wonderful. Outside of the group pools and that, who usually furnishes the bankroll? If it's the casino, do they usually give you cash or is it free play?
    Brian Christopher:
    So I 100% use my own money for gambling. It's a legal thing, first of all. The law is you cannot gamble with someone else's money. So I have to use my own money. As I am a big gambler already, I'm used to... We already get free hotel rooms and food and free play. So aside from all that kind of stuff that we would normally get anyways. Also, I'll add, any free play that I might get is not used in my videos. I use that for personal play, only for fun, and then anything I bet is my own. And so our agreements, they don't say anything about Brian has to do so much coin-in, he has to play high-limit slots. Nothing. I play what I want to play. It has to be what excites me and will excite my audience. So some videos, I might do a dollar a spin, other ones we're doing $100 a spin. My coin-in is not reflected at all anywhere.
    I'll also add, gambling's our biggest expense for us also because the US government does not allow businesses to gamble. You can't just take your company's money and gamble with it because otherwise every company would do that. If they have a chance of winning more money, let's do it and then we can count it as a loss if we lose it. That's our biggest expense obviously. So we have to make sure that we are compensated enough that we can afford to fly all over the country and all over the world with our staff to [inaudible 00:21:08] these events for them.
    Don Retzlaff:
    That pretty much answers my question about what you do with the winnings and all that type of stuff, so.
    Brian Christopher:
    Look right back into that slot machine.
    Nick Hogan:
    So Brian, then when it comes to these engagements, how is it that the operators define success? How is it measured? And then what would you say are the typical ingredients of a successful arrangement? What are the conditions you require?
    Brian Christopher:
    Yeah, and again, that is twofold because there are scenarios online where you can type in what an Instagram post is worth and it'll spew at a number, what a video is worth, how many impressions it's worth. One video of mine is... I did it like years ago, it said one of my videos was worth like $25,000, something like that. Or maybe even higher, ridiculous number. I don't go by that fully, but obviously the videos have some value to them because they are seen by millions of people. And our fans tell us in the comments that they're flying to that casino or visiting that casino because of us. When we turn people around, they'll often say, "Oh, I haven't been to that casino in years. I see they put in new games. I'm going to go back there now." The video aspect of it, worth every penny. It really is.
    But then for the events that we host, we are bringing hundreds of people to their casino, and these are not just regular people, these are gamblers, they want to play. And so by bringing them to their casinos, we know they're going to play. We have hundreds of them in there, and we want the casinos to make sure that they're able to get their return on investment immediately. And so they will. And so by doing that, we'll have the casino offer them some free play, $10, $20, whatever it is. That way they're able to make sure they have their player's card numbers and they can attract how they're doing and how they're playing on the floor. And we have some really great stats that show how many people we bring back to the casinos, how many people are brand new to the casino. We often end up bringing a younger demographic than they're used to as well. So some really great intel that we're able to share with them and to ensure that as a success for both parties. Yeah, that's all I'll say for that.
    Nick Hogan:
    That brings it to your fans, the Rudies, these folks. And yeah, maybe you could give us... So you mentioned that oftentimes you attract a younger demographic, but what does that [inaudible 00:23:33] of players look like? I'm curious. What is the demographic makeup-
    Brian Christopher:
    Yeah, it's really random. So you had mentioned earlier we have around 600 and... I forget, we have 670,000 subscribers on YouTube. We also have over 700,000 on Facebook.
    Nick Hogan:
    Oh, wow.
    Brian Christopher:
    Yeah. So I think we're about 1.6 million between our channels. And the thing is, on YouTube we do skew a little bit older. We're about 50/50, men and women. But Facebook for us skews much younger and it's more of a male millennial side than anything else.
    Nick Hogan:
    That's really surprising. I didn't even think those people were on Facebook
    Brian Christopher:
    Legit. For us, it really started during the pandemic, that's when we really exploded a lot more on Facebook. And so I don't know if young people were just really bored at that point, but that's how they found us and they've stuck with us through it. So definitely a lot more younger male audience skews on Facebook compared to YouTube. But overall at our events, you'll see every age range and they come from all over, every walks of life.
    Nick Hogan:
    Okay. We're talking a bit about demographics and data here. So Josh, what types of data insights are you plowing into the BC products and how does this drive success there?
    Josh O'Connell:
    So we look at a lot of different things. And as we expand our ecosystem of things we're working on, so does the data that we look at. For instance, with the YouTube channel and Facebook, we're looking at all sorts of things in terms of engagement. We want to understand what resonates with people. Brian and I spend a lot of time looking at titles, for instance, of the videos. What gets people to click, what types of things. We look at what other people are doing to see if they have certain things they're leaning into and try to experiment as well.
    When it comes to the types of content that's generated, are certain games sort of popping constantly? Because that could be a sign that they want Brian to play those games more, they're going to watch that more. We have a whole social media team that monitors comments, and so we're looking at how people are reacting to the live streams, what they're telling us they're interested in, what they're loving, what they're not loving. Brian has this thing he does when he is betting really high where he is clearing his throat, and there are people who just absolutely hate that, for instance. It's interesting, you get this feedback all the time.
    And then when we look at other more sort of common marketing things like search engine results, what are bringing people to our website, to our channels, now to Flip The Switch, our new content platform, and we use that to understand what they might be interested in as well. We're constantly keeping an eye on what the players are telling us that they're interested in what the fans are looking for to make sure that what we're putting out there really resonates with them. And that's part of the key to the staying successful over time.
    Nick Hogan:
    Then do the operators typically share data with you following your engagements then about results, and is there a tail on that of some sort, or do you guys get anything like that from the operators?
    Josh O'Connell:
    There are definitely examples where we get information from the operators and we certainly love to have that information because it helps us understand how to continue making the events even more successful as well. Brian cited some of the examples of things we learned, like we learned what percentage of players are new to their ecosystem. So if 250 people show up for meet and greet, what percentage of them were new to the casino, that's really helpful. What the increase in coin-in from those players are, it's obviously going to be anonymized and aggregated data, so we're not learning player by player necessarily, but you're getting of enough information to be able to say, "All right, this is what the engagement drove and this helps us understand how we can continue to make them stronger.
    Don Retzlaff:
    Right, okay. Josh, one of the neat things I get to do at ReelMetrics is I get to dive into a lot of player data, and we have over two and a half billion player sessions I get to look at, and I've noticed a trend that I've dubbed the Brian Christopher effect internally, and it is we've seen an increase in average bets and an increase in people pooling their money like Brian mentioned earlier. And I'm seeing on the casino floor, I'm seeing groups of five or six people, and it used to skew under 45, now I'm seeing it more on the over 65 players doing it. And it's not just on video, it's on video poker, they're playing Ultimate X and they're all throwing in a couple hundred bucks and taking that shot at the jackpot. And I'm really seeing these increased average bets outside of things like sports books on Dragon Link, Dragon Cash, stuff like that. Are you seeing or hearing similar customer tales of people pooling their money and taking shots at jackpots?
    Josh O'Connell:
    Yes, and I can vouch personally that I've done this with friends that I've met through this community quite a bit over the years actually. Yeah, we jokingly call them mini group pools because they're not quite the size and scale of what Brian puts on, but it's our version of. It's like each person might throw in a hundred dollars and you might do $10 spins and either if you're successful you might say, "Okay, cash out or maybe we'll move to another machine," or whatever the case may be. But yeah, definitely something. And then taking turns on player's cards, because we all know that it's important to get that coin-in on the card for our comps.
    But yeah, that's definitely something that I've participated in quite a bit and I would never have thought of doing that before being in this community. But now that I know other people who enjoy these activities as well, it's almost table stakes stuff that you do as a group. And it's a great way to socialize the slots in a way that, as I was speaking earlier, wasn't necessarily the case in the past. And I think that's great. It makes it a bit more of a healthy relationship. You're balancing the gambling with the social and it evens out everything a little bit.
    Don Retzlaff:
    Yeah, I completely agree and I'm seeing it more and more, and Nick will tell you that I get aggravated when I can't get to my favorite slot machines because there's a group of six or eight people and-
    Nick Hogan:
    All young men, right?
    Don Retzlaff:
    Yeah, it seems like it's a lot of guys under the age of 45 and they're pooling their money taking their shot and it's a little bit different than it used to be. Used to have a... You'd walk through [inaudible 00:29:38] limit on your way out of Vegas and put 100 dollars bill on a $5 reel. Now it's more video related and they're taking that shot, they're playing that $10 average bet or $20 average bet. So it's really interesting. Brian, do you play off camera as well? Do you enjoy playing the slots when you're not on film?
    Brian Christopher:
    Yeah, unfortunately I do.
    Nick Hogan:
    Aren't you getting mobbed in on these floors though if you go in and play?
    Brian Christopher:
    It's a thing. For me, gambling is my relaxation. So after a long day of filming and gambling, to relax I gamble. That could mean many things. Oftentimes I'll go sit at the bar, I'll play video poker. I love playing video poker. I don't do video poker in my slot videos obviously because it's a slots channel. Or I'll just hang out in the high-limit room and play there. I'll hang out with my friends and do low bets with them. Or I'll go up to my hotel room and play on my phone because I got other options on there too.
    Nick Hogan:
    But you haven't started with the disguises or anything yet to go undetected?
    Brian Christopher:
    I've tried. It does not work. During the pandemic, I was so happy because now I have a mask, I can cover my face. So I would wear the mask, I would put my hat down really low. I didn't put the sunglasses too because that was just way too much. People still found me, they still asked me for a photo. I don't mind, I really don't. If I saw my favorite celebrity or someone that I love, you have to say hi or at least take a little snapshot when you think they're not looking.
    Josh O'Connell:
    I'll add in. By the way, Brian does do a little modicum of dressing down a bit when he's going to go play on his own. But every once in a while he's absentminded. I went with him to a casino once a few months back and he had put the hat on and things just to change it up a little bit. But what he forgot to do was to take off the giant Brian Christopher Slots logo hoodie that he was wearing. And so by the time he sat [inaudible 00:31:38], "I guess it's too late now." Fortunately people didn't really notice that much, but.
    Nick Hogan:
    I thought you were going to say it was a BC Slots logo on the hat.
    Josh O'Connell:
    I'm pretty sure that's happened too before.
    Brian Christopher:
    We do have those too.
    Don Retzlaff:
    That's awesome. Now Brian, you said you play your favorite machines. How do you pick the machines and do you have a favorite that you like to play, do you go back to?
    Brian Christopher:
    It's an easy answer. My favorite game is my own game. Brian Christopher Pop 'N Pays More obviously, it has to be. I tend to like the more volatile style of games. I love all the Link games, and that's why I'm really thankful to Plaza Casino because they have allowed me to store all my favorite games in their casino. I have my very own room there with my favorite games. Anything with a really fun and exciting bonus, that's really what leads the way for me. Most games these days are more volatile and are extremely hard to get those bonuses in. And I think our fans love those. And so I do listen to what they want to see as well and play what they want me to play. But those are the ones that keep me coming back.
    Don Retzlaff:
    Do you ever play the classic games? Do you mostly-
    Brian Christopher:
    Yeah, I do. I love the classic games still. I was playing Wolf Run and Cleopatra yesterday, if those are still considered... Those are classic to me. I think this was-
    Don Retzlaff:
    Oh, yeah, absolutely. 100%.
    Brian Christopher:
    So yeah, I do love to play those ones. But the thing is on camera, the newer games just pop. They look really great. When I play an older game, you get that little line going up the screen, it doesn't really look as great. And my audience will often ask me, "Please play some of the older games." And I will, but our views are never the same. They're just never quite as good.
    Josh O'Connell:
    And actually, just to add there too, Brian was responsible in part for Everi, who had a very popular three real stepper that was on the market for like 15 years, and they finally decided to update it. And one of the things they did was they updated the lighting package because they found out that there was a flicker when YouTubers would record on their iPhones that came through and they fixed it for the new machine to ensure that they could film it better. So as a result of the work that Brian and others are doing, they actually made a modification to their machine to make it more camera friendly.
    Nick Hogan:
    Do you do much work with the suppliers then? Are you doing engagements for suppliers at times?
    Brian Christopher:
    Yeah, we've been working with them for years actually. We do a lot of world premieres, for example, with them. If they have a brand new game coming out, then we will set up a time to go to a casino to premiere it when it first comes out, which is... I love to do, obviously. To be the very first person to do it is exciting. Dollar Storm was one of those games that I was the very first person to press spin on. And so we do those kinds of activations with them. And now that we've started fliptheswitch.com with Josh and our team, we're working with them on there as well because we were featuring a lot of games on there as well. I'm definitely open with working with them further on those to... We would love to be the first to know about everything. Or to showcase everything to the world. Who wouldn't want to? And as a gambler, I definitely want to because that's super exciting as well.
    Nick Hogan:
    Yeah, yeah. Now, you mentioned the Brian Christopher Pop 'N Pays More, which is a game that was put out by Gaming Arts. And we're just curious to what extent were you involved in the design of that and do you plan to do more branded slots moving forward?
    Brian Christopher:
    I was actually very involved in the process, which is interesting because.... So Pop 'N Pays was already a proven game. I loved the game and they knew that, that's why they reached out to me to see if I wanted to be part of the second iteration. But they had actually already designed the second game, and they asked me if I wanted to Brian [inaudible 00:35:31] basically and turn it into a Brian game. And I said, "Absolutely." So we made a ton of changes. You'll notice that they still have the original, or not the original, their Pop 'N Pays More series with a different theme. And that's the one that they showed me basically. And so you'll notice huge differences between the games, all the extra features, the shaky shaky as I call it when Brian just gives you a feature or gives you something really exciting.
    We went to a studio and I shot over 200 sound bites for the game as well that we could use at different parts of it. I thought like the nth hour I came up with what if that people could poke my face on the screen and I say rude? Because that's one of my catchphrases is I say rude a lot because machines are rude. And I was like, "If they could poke me in the face when something's bad and I say rude, I think that'll be huge." So we added in that, and also when you touch the screen and my lucky wristbands go all over the screen, that's like a really, really cool extra bit. They come up with that idea. I got to give them credit for that one because that was really hot too.
    Nick Hogan:
    Okay. So you have to do a lot of brainstorming on these catchphrases.
    Brian Christopher:
    Yeah, the game had a lot of success. And I'll say it was mutually beneficial, our relationship, because it helped us a lot. But we helped them. We helped get them into casinos and areas they have never been before. Because of our relationship with doing cruises with Carnival, we were able to get Gaming Arts games on their ships now, so now people can play our games there. And we also introduced them to our other partner, Chumba Casino. And we got Brian Christopher Pop 'N Pays More on Chumba, so people can now play us all across United States and Canada as well. So definitely it's been a great relationship for all of us involved and we're definitely eager to come out with another slot machine very soon, hopefully.
    Nick Hogan:
    Okay. And you mentioned your deal with Carnival. So this is kind of an interesting thing. It makes all this sense in the world, but first when I read that press release, I said, "Man, that makes sense." Okay, so tell us a little bit about that. How many of these are... First of all, do you like cruises?
    Brian Christopher:
    I love the cruise, yes.
    Nick Hogan:
    Okay, [inaudible 00:37:42]. I see Josh shaking his head no, I'm also a no. Don is a yes.
    Josh O'Connell:
    Oh no, I love cruises. I was just joking he hates cruises though he absolutely [inaudible 00:37:50]. We're going to put him on a boat for eight weeks a year.
    Nick Hogan:
    That's good. And so I know you guys just did Australia, right? So you were down there for... How long was that cruise?
    Brian Christopher:
    We did two back-to-back cruises. The first cruise was just for us to get used to it over there, get used to the timing and get a lot of filming out of the way without all the fans on board. So that was just like a five-day cruise. And then we did, I think it was 11 or 13 days, maybe it was a 11-day cruise right after that with fans on board as well. So our first cruise there, I think we had about a 430 Australian people on... They came from all over the world actually, on that cruise. We're going back there in November this year for two cruises for fans, and we already have over 900 people sign up for those cruises.
    Don Retzlaff:
    Man, oh man. Yeah, Nick calls me a degenerate gambler and I've actually been invited to your cruises on the Carnival Cruises, not because I've known you, this is the first time we've ever talked, but because of past play on Carnival. I get those emails and those direct mails all the time, and I've always been curious as to what that would be like. And obviously you've got a lot of different gamblers on there, and that's who they're obviously marketing to. People who are not willing or who are willing to drop a bit of money. It's very tempting when you get those offers. You get a free play offer, come on a free cruise and get to play with Brian Christopher. It's a very tempting offer.
    Brian Christopher:
    We make it very good for our fans too, so they get a good deal. Even if they've never been on a cruise before, we have a thing on our website where they can sign up for a thing called Fun Match. And what they can do is take their own casino offers from their land-based casino, upload it to the Fun Match system, and Carnival will look at that or look at their tier that they're at a casino and be like, "Oh, they must be a gambler. We'll give them this offer." It could be a discounted cruise or it could be a free cruise. And then they all get free drinks in the casino. We host events for them. It's a whole shebang. They get a lot of fun out of it. And even if they have... Sorry, if they have no history of offers or anything else, they're still going to get a discount to come on board with those too.
    Josh O'Connell:
    And the other thing too that I always like to say to Brian, because I've been on a few of these myself, is that when you think about a cruise ship in the capacity, like say 3,500, 4,000 people, just like as an example, and then there's 600 people on that cruise ship that's part of the crew, you're literally taking 15 to 20% of that ship over, basically. So it's like it becomes a very interesting experience because you're constantly running into other members of the group in the dining halls, you're running into them during excursions, you're running into them during the activities on the ship in the casino. So you make a lot of friends and you make a lot of connections. And that's again, going back to that wholes community that develops out of this.
    I'm in a private Facebook chat with like 30 of us that have been on a number of these cruises with Brian, and we're constantly like, "Which cruises are you taking? What are you booking? Where are you going to be next?" Because of these relationships that we developed. And so that's the other thing that I think that people don't necessarily realize when they do these things is... They think they're going to be alone or they're going to be by themselves. They're like, "No, I've never been on a cruise before." It's a big family and everybody welcomes everybody in. It's really such a wonderful experience as a result.
    Nick Hogan:
    When you mentioned 900, [inaudible 00:41:09] came to mind. I said that must be 20% of the capacity of the ship. It's a huge, huge number of people. I'm shocked by that number.
    Brian Christopher:
    Yeah. So the 900 is between the two cruises in November, but we do have... We're capping them now. We have to do that. So depending on the size of the ship, we cap it at 550, 650, or 750 people per cruise.
    Nick Hogan:
    Okay.
    Brian Christopher:
    [inaudible 00:41:30] casino sizes.
    Nick Hogan:
    Yeah, they're smaller.
    Brian Christopher:
    Some of the casinos only have 300 slot machines in them. I could oversell and sell 1500 people on board, but that's not going to make it for a very fun cruise for anybody.
    Nick Hogan:
    Yeah, yeah, yeah. So are they concentrating you any place geographically or is it just basically you go all over the world for these things?
    Brian Christopher:
    Because of my stance on smoke-free gambling, we're only looking at the cruise ships that have smoke-free casinos on board.
    Nick Hogan:
    Okay. What is the mix of that? What percentage of them is still permitting smoke? I didn't even realize that.
    Brian Christopher:
    They all permit smoking in the USA, but some of their ships, we have 1, 2, 3, 4, 5. 5 of the... 6, 7, 8. 8. I'm doing in my head. Eight of their ships have a smoke-free casino on board. Of that, five of them have a completely separate casino on a different area or different floor even than the other one. What they're doing is they're testing it out right now. I'm trying to convince them that smoke-free is the way to go. 88% of people don't smoke anymore, so let's get on with this. And so what they've started to do is they've converted their old conference room areas into smoke-free casinos. So that's step one for me. Eventually world domination, but one [inaudible 00:42:51] right now.
    Don Retzlaff:
    First it's Carnival and then it's the world.
    Josh O'Connell:
    But to be fair, some of their competitors are starting to respond with their own smoke-free casino [inaudible 00:43:07]. It does seem to be starting to catch on, which is a wonderful thing to see.
    Nick Hogan:
    Yeah, now tell me-
    Don Retzlaff:
    [inaudible 00:43:12].
    Nick Hogan:
    Oh, sorry, Don-
    Don Retzlaff:
    Go ahead, [inaudible 00:43:13].
    Nick Hogan:
    Oh, well-
    Don Retzlaff:
    I was just...
    Nick Hogan:
    You.
    Don Retzlaff:
    I was just looking at Carnival Magic's layout and they had two different casinos on their vessel and had different levels, and they had one that's totally non-smoking and the smoking. So it'll be interesting to see that and experience that and see what the play's like.
    Brian Christopher:
    Yeah, we're going to be on there next week actually.
    Don Retzlaff:
    You're going to be on Magic?
    Brian Christopher:
    Yeah, I'll be on Magic in about a week and a half. So yeah, the Smoke-free casinos on one floor with about 100 slot machines, and the smoking one has about 300 on a different floor.
    Don Retzlaff:
    Amazingly enough so, I'll be on Magic in about a week and a half.
    Nick Hogan:
    Hey, there we go.
    Don Retzlaff:
    At the end of January, I'm going to the Caribbean.
    Brian Christopher:
    We'll see you there.
    Nick Hogan:
    Oh, we got to [inaudible 00:43:58]. Okay, so we got to have a contest on this, number one, and then Josh and I are going to bet on the side. Who's going to win? Huh?
    Don Retzlaff:
    Oh my goodness.
    Brian Christopher:
    Well, listen, if you were upset about six people around one slot machine, get ready for 300.
    Don Retzlaff:
    Yeah, well, this will be interesting. That's great, that's wild.
    Nick Hogan:
    But yeah, guys, let's talk about that because it's always about smoking. Yeah, because this is always... It's such a hot button in the casino industry. I'm with you guys, I can't stand it. I think, Brian, you had mentioned me in the past that you guys had talked to or you'd pulled your Rudies, your fans and asked them, and a lot of the people, even those who smoke, they don't like smoking casinos. It seems like so many attitudes have changed on this, but it's just something that's dying so hard in our industry. So tell me a little bit about that. What's really fueling that passion and what's it all about?
    Brian Christopher:
    I grew up in Canada and we haven't had smoking in our casinos in Ontario for, I don't even know, 20 years maybe. So when I moved to the States and I found smoking here, I was in complete and utter shock. You always imagine the states as ahead of everyone else, but in this one for some reason very far behind. And so soon after launching my channel, I actually came across an organization, ANR, Americans for Non-Smokers Rights. I found in my G2E, actually. I was like, "How can we work together?" And it took us a few years to figure that out because we didn't really know what we were doing. Yeah, together we came up with a survey to ask my fans what they think about smoking casinos.
    What I've learned is the amount of gamblers who smoke is a little bit higher than the amount of Americans who smoke. So that might go hand in hand a little bit, but again, a little bit, it's not 100%. We're talking maybe like 18%. But the other part of that is majority of people, including smokers, prefer smoke-free casinos. And that's what casinos need to understand is smokers are not driving or flying to a casino to have a cigarette, they're going there to gamble. Gamblers go to casinos to gamble. And these smokers, who are also there to gamble, they don't smoke in their own houses. Why? They don't want to stink all the time. They don't want to smoke [inaudible 00:46:46] either. It might sound weird to you, but they don't, and that's what they say. They don't like going back to their rooms and having a shower before bed either, just like the rest of us.
    Even the executives who... Or people that work inside the casinos, they have to get their suits dry-cleaned every other day. It's really, really horrible. I don't need to tell you about how bad secondhand smoke is because everybody knows it. We've known it for decades. And it's time that they just wake up and actually pull their own audiences. And that's what I find is not happening enough when I do speak at the casinos is they will tell me, "Oh no, most of our people smoke." I actually went off last week, I had a little rant on LinkedIn because I was sickened by a post by a casino that put out, or someone took a photo rather of a sign at a casino saying, "Moving forward, we no longer will allow cigars smoking within 15 feet of our table games." And I was reading the comments, and these are from people who are anti-smoking. The comments were, "That's great, that's a baby step. We're moving in the right direction." And I'm like, "Smoke and mirrors, people. This is nothing."
    Josh O'Connell:
    No pun unintended.
    Brian Christopher:
    What 15 feet? That's like five steps away. It's not that far. And I'm sorry, if you are like eight rooms over, I'm smelling your cigar. Cigars travel. And if you're smelling it, you're breathing it in and you're getting the toxic chemicals. So do not even. But that LinkedIn post, it actually exploded. It was picked up by... What was that? An online newspaper in Italy even, they reposted parts of my messaging in there. I had a few casinos that were in the chat in there too that were trying to argue with me the points a little bit, but made themselves look pretty petty. Their arguments were just not sitting right, like, "Oh, what am I supposed to do? Tell my person who loses 100 grand a month that they can't come now because they can't have their cigars?" Are you building your business around that one person or do you actually care about people's lives? Let's figure it out.
    Nick Hogan:
    Sure. Yeah, yeah. And I think it's what's oftentimes misconstrued, I think in the industry is people worry about drops in visitation and this other effect that they call time to think, which is that if they go outside and have a cigarette, then they're a bit concerned that they all of a sudden take inventory of their actions and they say, "Oh man, I have to stop and take off." And so typically you see some drop in visitation and drop in overall performance. However, it's really only applicable if there's an adjacent casino that offers it. That's really, I think, the first place to start on this-
    Josh O'Connell:
    But even that myth it's... I would throw the word myth out there, and here's why. In October we did a presentation at G2E about non-smoking casinos, and someone did some data research. The casinos that report their data outside of Nevada, the top seven are all non-smoking casinos. Half of them are non-smoking by state law, which therefore removes the competition, but half were not. Those three were Parx Casino in Pennsylvania, which is surrounded by smoking casinos.
    Nick Hogan:
    Oh yes, it is.
    Josh O'Connell:
    [inaudible 00:50:04] Pennsylvania and Atlantic City. All within reach. They were number one before the pandemic when all these changes happened, they actually grew their number one position after the pandemic. So they acquired more customers than they lost sticking to non-smoking. And then both Mohegan Sun and Foxwoods are not required to be non-smoking in Connecticut. They have Rhode Island right next door that still allows smoking, but they decided to commit to staying non-smoking as part of their return to normalcy.
    And so there definitely is a bit of a change in the wind starting to finally happen. And a lot of the changes that are happening tend to be adjacent states. You're seeing in New Jersey, Pennsylvania, Rhode Island there's really big efforts. But also other states too, like New Mexico, all the tribes decided together they're going to all commit to the sticking to non-smoking for the most part. And that seems to be working out.
    So I think that the seeds of change have begun. I think the pandemic finally shook the tree, but people still need to get that reinforcement. These operators just need to hear from the players that they prefer this non-smoking experience because a lot of us do. I'm a former smoker and I definitely remember the times when I enjoyed smoking a cigarette. Now I can't go into a smoking casino and be as happy because it hurts the experience. I spend less time in those casinos. I have to leave sooner if I'm going to be in one.
    Nick Hogan:
    I understand.
    Josh O'Connell:
    Yeah.
    Nick Hogan:
    Now, do you get much pushback from operators on this topic, guys, or are they behind you generally?
    Brian Christopher:
    Yeah, they're more behind us. I get private messages from casinos saying, "I wish we would do that. I wish they would listen to us." And I get it from slot manufacturers saying, "Keep up the fight. Nobody wants this."
    Nick Hogan:
    Yeah, yeah. Gotcha. Gotcha. Okay. So a little bit earlier, you had mentioned fliptheswitch.com. Josh, can you give us a rundown of that product offering and what it's all about?
    Josh O'Connell:
    Yeah. Flip The Switch is a very different thing for us because we sort of were historically in video and in specifically slots content. But Flip The Switch broadens our worldview quite a bit to cover other aspects of the casino world. So sports betting is one example of that. iGaming is another. In fact, Brian, yesterday, as we're recording this, announced that we're going to be doing a real money gaming channel that's launching on Sunday where he's going to be doing real money gaming live streams and shorts on a regular basis going forward.
    So we're broadening our world view to cover other aspects of it. But Flip The Switch is also written content primarily as opposed to videos. It allows us to reach people in different ways. I got brought in in part because I've spent a lot of time in the gaming community and I see a lot of well-meaning but misguided information from a slot tech or this or that, where people said, "The slot tech told me that the slots by the aisle pay more hand pay, so you should only play slots by the aisle because they pay better." A doesn't necessarily connect with B. And you see a lot of this stuff passed around in the world.
    So one of the things that we want to do is help break myths, educate about what's really going on in the casino floor, aim for a healthier mindset. Don't blame the boogeyman that you had a losing session. You have to understand that if you go into a casino, the money you're bringing in, you should consider it spent money like any other entertainment activity. And if you come out with money, that's just a bonus. That's not something you should expect or plan for. And so some of it's answering player questions. We have something called You Ask which we answer one question per day. Other parts of it are taking some of the things we've learned from our partners or ourselves along the way and sharing that information.
    And then some of it's also just educating about some of these newer opportunities like with sports books or iGaming for those who may not understand how a sports bet works, which includes Brian and myself before we started this. What is a money line? What is an odds bet? Or what are the numbers, all those little plus and minuses on the screen mean? And helping to give people that foundation so they can feel a little more confident if they do want to, for instance, bet on the Super Bowl in a few weeks and have never done it before.
    Nick Hogan:
    Okay, and I'm sure... So you mentioned that you get a lot of questions and you're busting a lot of myths. So you guys must have a couple just dandies, like [inaudible 00:54:17]. You have to give me one or two.
    Josh O'Connell:
    One of my favorite recent ones was someone who told me that, "I read an article that casinos in certain states will purposely pay less than the minimum required by law and will simply pay fines to make that issue go away and they can keep doing less." And then we wrote a post saying like, "This is not true." They said, "You need to do your research." So we did. We actually reached out to the states in question and asked them how many fines they issued to casinos, and the answer was zero. We're willing to take the extra time to do the homework because we understand that people read things and they just assume it's the truth and why should we believe you over what we read? We'll take the extra time to do this because we want people to understand that casinos don't need to do this.
    A slot machine has one of the best house advantages on the casino floor, so why do they need to... Oh, because you put your player's card in, they're going to lower the payouts or something like that? They're not that granular and they don't need to do that. They're going to make plenty of money for the casino just sitting there and doing a million spins. That's a lot of what we get. Again, the goal is to help people think from a healthier perspective about this, not try to create boogiemen that they can blame for the reason why they're losing, "Oh, I lost because the Casino... Journey was the event to stay, so they turned the payouts down because there was a big concert." Fill in the blank. There's so many of these.
    Or like casinos pump oxygen into the room to keep people awake or they purposely make distracting carpet patterns so you won't leave. The question I always ask is, how many of us have ever gotten distracted by the carpets of the point where we can't find the exit? It's one of those things that always makes me laugh, but yet people really believe that this is part of the calculus. So it's fun. It's a lot of fun actually to write about this stuff because you get to play with the ideas a little bit and tease it out, but at the same time, you want to help people just better understand what's going on.
    Don Retzlaff:
    Whoever puts the website together and monitors it does a really nice job. It's one of the more easier navigatable websites. I've really enjoyed looking around at it. It's very well done. So congratulations there.
    Josh O'Connell:
    Thanks. We had a lot of planning sessions amongst us to get that right. Brian and Marco, obviously Brian's husband, who's also part of the team, really have a good idea of how they want to see things from a visual perspective. I think that's one thing that's made Brian's channel successful is the emphasis on a good visual style and quality. And so we definitely wanted to make sure that carried over to Flip The Switch as well.
    Nick Hogan:
    Oh yeah, no question about it. And the production value of those is great.
    Brian Christopher:
    Yeah, and also to Josh's credit, we have slot manufacturers and casinos emailing us and messaging us saying, "Thank you for doing this work. Not only am I learning a lot, but you are proving my point that I've been trying to tell people for years, this is the way it is." There was even a great article that he did on taxes recently. An accountant left a message in the comments below it saying, "Oh gosh, I was so worried when I saw this article coming out. I'm like, 'Here it goes again' and someone else saying bad things, but everything you said is true. Thank you so much for telling everybody how to do their taxes with gambling because nobody really understands it and there's so many mysteries behind it."
    Nick Hogan:
    Oh, so you're talking about from the player's perspective, how they do their W-2 G filing and then their income taxes at the end of the year for-
    Josh O'Connell:
    Players give each other really bad information online. And the first thing I always tell people is you should never be getting tax advice from Facebook, but they'll be like, "Oh, you can just download all your win-loss statements and do your taxes and you're fine." But the moment you get audited... And honestly, all you have to do is go to the IRS website and they state in black and white, "Win-loss records are not enough. You need to keep a gambling diary and keep receipts." And you have to be able to really prove your record and you have to report all winnings and all losses, not just the W-2 G. So I can guarantee almost no one is putting in like, "Oh, I won 50 bucks on Saturday and so let me add that to my income for the year." That's the thing.
    I think a lot of players, they read what other players are doing and they just follow the lead. But the moment you... It's just the risk of the audit. You can file your taxes whatever you want, but if you get audited and you don't have what the IRS is it's expecting you to have, that's a problem. I had a player actually write in and say, "Hey, thank you for this. I got audited and had to pay a fine and pay back taxes because they didn't accept what I had filed." And so it happens. And so you just want to make sure people are protecting themselves.
    Nick Hogan:
    Yeah. It seemed to me as... I haven't spent a ton of time on Flip The Switch, but what I saw I really liked and it seemed is the primary angle to this, guys, really about educating the gamblers. Is it more of an educational thing than anything else?
    Josh O'Connell:
    That's definitely the primary aspect. What we've discovered, and this is something my previous site also was working towards, was there's just a lot of curiosity in the community. And so once you get to know the games, there's such a diversity to the games, there's so many different things happening. Some people just don't understand how games work. So even just educating about how a particular game works can be very valuable. Some people want to understand, is that bonus truly, is that pick real, or is it already decided at the moment? It may not make a difference in the final outcome of how the game pays over time, but some players just like knowing that their pick matters in certain games, for instance. They like to know these pieces of information.
    I think it's just great that especially now more of the manufacturers are also willing to share some of these pieces of information. You learn things like bonus frequencies, you learn things like hit frequency and volatility, even if it's a range from one to six and it's just a range within their company, you still get some sort of a bit of an understanding of what to know. And I think that's interesting is now there's enough information out there that also makes such a project viable.
    Nick Hogan:
    Yeah, yeah, yeah. And I think it's been just... Part of what you guys have been doing is it has just, I feel, really increased transparency levels all around. We certainly see a lot more operations that are willing to be a bit more transparent with players and tell them more about stuff and whatnot. Kudos to you guys, that's great stuff.
    Josh O'Connell:
    The other thing is Brian will learn things in share them in videos, but how do you search that? That's the other thing that we're also targeting. We learned at one point that there was a game that a company put on the floor that they purposely set a higher minimum payback percentage because it was going to be a faster gameplay. So that's how they balanced it out. They expected the speed of it's going to make up for the higher payback. And they told Brian the statistic, and Brian said it in the video probably like five or six times. And then people were constantly like, "How do you know this? How do you know this?" So being able to put this in a place where we can link them to the information and share it also makes it more accessible to more people than trying to find a video from two and a half years ago where he said it 20 minutes into a live stream. There's another value to that as well, in terms of just accessibility.
    Don Retzlaff:
    Educating the customers is always a good thing. Obviously I was in the casino operator side for nearly 30 years and I couldn't tell you how many times I recommended things like Bob Dancer's video poker products and cheat sheets and stuff like that to video poker players to try to get them educated so they weren't making these costly mistakes, holding an ace when they've got a pair of kings and all that type of stuff. An educated customer is a better customer. So I applaud your efforts there.
    Nick Hogan:
    Yeah, for sure, for sure.
    Don, do you have any... I see we're up on the 40-minute mark. Did you have any more questions for Josh and Brian today?
    Don Retzlaff:
    No, I'm good, Nick. And it looks like Brian, we're going to be sailing on different ports, unfortunately. And the same cruise lines, but I think you're going out of Texas and your [inaudible 01:02:00] coming up.
    Nick Hogan:
    Oh, did you just change your cruise right now as we're talking? It's going to be too awkward now.
    Don Retzlaff:
    So yeah, I couldn't do it.
    Nick Hogan:
    Okay. I guess I was mistaken.
    Josh O'Connell:
    So check your port. You never know. You might both end up at the same port on the same day and you can meet off the ship.
    Don Retzlaff:
    Yeah, that would've been interesting. I will probably book a Brian Christopher Cruise in the future because I do enjoy my slot machines. I do enjoy gambling. I'm a big fan of Carnival, and so I will definitely look... I'm looking at the stuff now I'm seeing sold out, sold out, sold out. So they book.
    Brian Christopher:
    Yeah. I was mistaken. We're on Carnival Breeze the next few weeks, and then it's Magic right after that in May.
    Don Retzlaff:
    Yep.
    Nick Hogan:
    Okay, gotcha, gotcha.
    Don Retzlaff:
    So soon.
    Nick Hogan:
    Okay, guys, any upcoming engagements or any other things you have going on that you'd like to mention here today?
    Brian Christopher:
    Yeah, the biggest one is our launch of our new YouTube channel, Brian Christopher Slots 2. It starts this Sunday. I'm not sure when this is coming out, but it's out maybe.
    Nick Hogan:
    Okay.
    Don Retzlaff:
    What's the difference between the original one too?
    Brian Christopher:
    So the 2 is 100% RMG. We are just doing online real money gaming, so I'll be traveling to different states to be able to play online. We'll do a live stream or a live premiere every single Sunday at two o'clock Pacific time and putting out daily shorts, playing online slots as well. [inaudible 01:03:35] a huge push on this, so it'll be both on our YouTube and on kick.com as well.
    Nick Hogan:
    Okay, cool. Now that brings up... Now I of course have another question, which is if you split your slot play online and brick-and-mortar, what is the split for Brian Christopher? What do you typically... This is on your own off time play?
    Brian Christopher:
    Luckily, I live in California, so I can't play online that often, but I do play a lot of Chumba and Luckyland, which is legal in California. And it's also responsible for all of my biggest wins of all time. By far. My biggest win is still really small on a slot machine, it's just over 30, $31,000. Whereas on Chumba and Lucky Land, I've hit a 200,000, a 202,000 and a $250,000 jackpot so far.
    Nick Hogan:
    Wow. And that's just playing alone with no audience?
    Brian Christopher:
    That's just me playing on my... Yeah, farting around on my phone.
    Nick Hogan:
    Okay, very good.
    Don Retzlaff:
    If anybody's really interested in that stuff, I know on the Flip The Switch, you've got different offers for the different casinos, including Chumba and other ones that where you can get free coins and match play on the sports books and stuff like that. So it's very well done, it's easy to find.
    Nick Hogan:
    Yeah, we encourage everybody to check out flipswitch.com. It's very cool indeed.
    Brian Christopher:
    Thank you.
    Nick Hogan:
    Okay guys, thank you so much for spending time with us today. As always, lovely to talk to you guys. Always a lot of fun. Yeah, we wish you the best and continued success. And yeah, we're watching you like hawks, we really love what you guys are up to.
    Brian Christopher:
    Thank you. And it's completely mutual. Next time I want to interview you guys, okay?
    Nick Hogan:
    Okay, we're down. We're down.
    Brian Christopher:
    Perfect.
    Nick Hogan:
    Okay, gents.
    Don Retzlaff:
    Thank you very much for your time, gentlemen, and enjoy your cruises and good luck playing, and all the best.
    Brian Christopher:
    Thank you.
    Josh O'Connell:
    Thank you so much.
    Nick Hogan:
    Okay, thank you, gents.
    `
};

export default S03E01;
