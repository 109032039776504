import React from "react";
import PropTypes from "prop-types";

class Service extends React.Component {
    render() {
        let divider = false;
        if (!this.props.last) {
            divider = (
                <div className="box">
                    <div className="divider mw-970" />
                </div>
            );
        }
        
        const serviceId = `service-${this.props.index}`;
        
        if (this.props.index % 2 === 0) {
            return (
                <article 
                    className="service-item"
                    itemProp="itemListElement" 
                    itemScope 
                    itemType="https://schema.org/Service"
                >
                    <meta itemProp="position" content={this.props.position} />
                    <div className="flex-row">
                        <div className="col-sm-6 col-md-5">
                            <div className="cycle-item">
                                <h3 id={serviceId} className="title title--primary title--md" itemProp="name">
                                    {this.props.title}
                                </h3>
                                <div
                                    className="paragraph paragraph--small"
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.text
                                    }}
                                    itemProp="description"
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-7 col-media">
                            <img
                                src={this.props.image}
                                alt={`${this.props.title} - ReelMetrics Slot Analytics Service`}
                                style={{
                                    height: this.props.imageHeight || "auto"
                                }}
                                className="img-responsive"
                                loading="lazy"
                                width="600" 
                                height={this.props.imageHeight || "400"}
                                itemProp="image"
                                aria-labelledby={serviceId}
                            />
                        </div>
                    </div>
                    {divider}
                </article>
            );
        }
        return (
            <article 
                className="service-item"
                itemProp="itemListElement" 
                itemScope 
                itemType="https://schema.org/Service"
            >
                <meta itemProp="position" content={this.props.position} />
                <div className="flex-row row--flex-column-reverse">
                    <div className="col-sm-6 col-md-7 col-media col-media-left">
                        <img
                            src={this.props.image}
                            alt={`${this.props.title} - ReelMetrics Slot Analytics Service`}
                            style={{
                                height: this.props.imageHeight || "auto"
                            }}
                            className="img-responsive"
                            loading="lazy"
                            width="600" 
                            height={this.props.imageHeight || "400"}
                            itemProp="image"
                            aria-labelledby={serviceId}
                        />
                    </div>
                    <div className="col-sm-6 col-md-5">
                        <div className="cycle-item body-right">
                            <h3 id={serviceId} className="title title--primary title--md" itemProp="name">
                                {this.props.title}
                            </h3>
                            <div
                                className="paragraph paragraph--small"
                                dangerouslySetInnerHTML={{
                                    __html: this.props.text
                                }}
                                itemProp="description"
                            />
                        </div>
                    </div>
                </div>
                {divider}
            </article>
        );
    }
}

Service.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageHeight: PropTypes.string,
    index: PropTypes.number.isRequired,
    position: PropTypes.number,
    last: PropTypes.bool
};

Service.defaultProps = {
    imageHeight: "",
    position: 0,
    last: false
};

export default Service;
