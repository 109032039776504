import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ReactGA from "react-ga";
import PageMetadata from "../components/PageMetadata";
import butter from "../utils/butterClient";

import Services from "../components/Services/Services";
import PickPlan from "../components/pick-plan";
import PlanDescription from "../components/plan-description";
import ProductInfo from "../components/product-info";
import AnalyticsInfo from "../components/analytics-info";
import PlanDescriptionLegend from "../components/plan-description-legend";
import ProductCompatibility from "../components/ProductCompatibility/ProductCompatibility";

function Product({ cms_content }) {
    ReactGA.pageview(window.location.pathname);

    const [cmsData, setCmsData] = useState({
        services: []
    });

    useEffect(() => {
        butter.content
            .retrieve(["services", "platform_testimonials"])
            .then(response => {
                const { services } = response.data.data;
                setCmsData({
                    services
                });
            });
    }, []);

    // Product structured data
    const productSchema = {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "ReelMetrics Slot Analytics Platform",
        "applicationCategory": "BusinessApplication",
        "applicationSubCategory": "Casino Analytics Software",
        "operatingSystem": "Web-based",
        "description": "Enterprise-grade slot machine analytics platform providing real-time performance data, predictive insights, and ROI optimization for casino operators. Make data-driven decisions to maximize slot floor revenue.",
        "image": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
        "url": "https://www.reelmetrics.com/slot-machine-data-analytics-platform",
        "publisher": {
            "@type": "Organization",
            "name": "ReelMetrics",
            "url": "https://www.reelmetrics.com",
            "logo": {
                "@type": "ImageObject",
                "url": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
                "width": "350",
                "height": "100"
            }
        },
        "featureList": [
            "Real-time slot performance tracking",
            "Advanced analytics and insights",
            "Performance benchmarking and comparisons",
            "Predictive trend analysis",
            "Custom reporting and dashboards",
            "ROI optimization and recommendations",
            "Multi-property data aggregation",
            "Competitive analysis tools"
        ],
        "softwareVersion": "2024",
        "applicationSuite": "ReelMetrics Enterprise Analytics Suite",
        "requirements": "Enterprise web browser access",
        "softwareHelp": "https://www.reelmetrics.com/contact"
    };

    // Breadcrumb structured data
    const breadcrumbSchema = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.reelmetrics.com"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Slot Machine Data Analytics Platform",
                "item": "https://www.reelmetrics.com/slot-machine-data-analytics-platform"
            }
        ]
    };

    // FAQ structured data
    const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "What is the ReelMetrics Slot Analytics Platform?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "ReelMetrics is an enterprise-grade slot analytics platform that provides casino operators with comprehensive performance data, predictive insights, and ROI optimization tools. It helps operators make data-driven decisions to maximize slot floor revenue through real-time analytics and benchmarking."
                }
            },
            {
                "@type": "Question",
                "name": "What key features does the ReelMetrics platform offer?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The platform offers real-time slot performance tracking, advanced analytics, performance benchmarking, predictive trend analysis, custom reporting dashboards, ROI optimization tools, multi-property data aggregation, and competitive analysis capabilities."
                }
            },
            {
                "@type": "Question",
                "name": "How does ReelMetrics help optimize casino operations?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "ReelMetrics helps optimize casino operations by providing data-driven insights for slot floor management, identifying high-performing games, analyzing player behavior patterns, and offering predictive analytics for better decision-making in game selection and configuration."
                }
            }
        ]
    };

    return (
        <>
            <PageMetadata
                title="Slot Machine Data Analytics Platform | ReelMetrics Enterprise Analytics"
                description="Enterprise-grade slot machine analytics platform providing real-time performance data, predictive insights, and ROI optimization tools. Make data-driven decisions to maximize your slot floor revenue."
                keywords="slot machine analytics, casino data platform, slot floor optimization, gaming analytics software, casino performance metrics, slot machine data, enterprise analytics platform, predictive gaming insights"
                canonical="https://www.reelmetrics.com/slot-machine-data-analytics-platform"
                
                // OpenGraph tags
                ogUrl="https://www.reelmetrics.com/slot-machine-data-analytics-platform"
                ogType="website"
                ogTitle="ReelMetrics | Enterprise Slot Machine Analytics Platform"
                ogDescription="Transform your casino operations with enterprise-grade slot analytics. Get real-time performance data, predictive insights, and ROI optimization tools for data-driven decision making."
                ogImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                
                // Twitter card
                twitterCard="summary_large_image"
                twitterTitle="ReelMetrics | Enterprise Slot Analytics Platform"
                twitterDescription="Enterprise-grade slot analytics platform with real-time performance data, predictive insights, and ROI optimization tools for casino operators."
                twitterImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                
                // Performance optimization
                preconnect={[
                    "https://cdn.buttercms.com",
                    "https://www.google-analytics.com"
                ]}
                
                // Structured data
                structuredData={[
                    productSchema,
                    breadcrumbSchema,
                    faqSchema
                ]}
            />

            {/* SEO-friendly H1 tag - screen reader accessible but visually hidden */}
            <h1 className="sr-only">ReelMetrics Slot Machine Data Analytics Platform</h1>
            
            <PickPlan navTo="plans-description" cms_content={cms_content} />
            <ProductCompatibility />
            <AnalyticsInfo cms_content={cms_content} />
            <Services services={cmsData.services} />
            <div id="plans-description" className="box col-xs-12">
                <div
                    className="container container--970 flex-row"
                    style={{
                        justifyContent: "space-between"
                    }}
                >
                    <PlanDescription isBasic />
                    <PlanDescription />
                </div>
                <PlanDescriptionLegend />
            </div>
            <ProductInfo className="hidden-xs" cms_content={cms_content} />
        </>
    );
}

Product.propTypes = {
    cms_content: PropTypes.object.isRequired
};

export default Product;
