import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S01E04 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                    keywords={this.props.keywords}
                    topics={this.props.topics}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S01E04.defaultProps = {
    id: "11918058-s01e04-top-ten-insights-of-2022",
    title: "Top Ten Insights of 2022",
    subTitle: "S01E04",
    buzzSproutLink:
        "https://reelcast.buzzsprout.com/2057836/11918058-s01e04-top-ten-insights-of-2022",
    spotifyLink:
        "https://open.spotify.com/episode/3qgxzHFldXdnkiZKuGDmCs?si=XavpzedXQuezRVZWR1Pnlg",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/s01e04-top-ten-insights-of-2022/id1652485625?i=1000591007235",
   iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s01e04-top-ten-insights-of-106330365/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s01e04-top-ten-insights-of-2022/",
    description:
        "Join us for this special episode of ReelCast as we discuss the top ten insights of 2022 and their impact on your slot floor. Get some 'reel' practical advice on how to prepare for the year ahead.",
    link: "s01e04-top-ten-insights-of-2022",
    keywords: [
        "top ten insights",
        "slot floor",
        "practical advice",
        "year ahead"
    ],
    topics: [
        "What are the top ten insights of 2022 for managing your slot floor?",
        "How can these insights impact your inventory strategy?",
        "What practical advice can you apply to prepare for the year ahead?",
        "What are the key takeaways from these insights for slot machine managers?"
    ],
    episodeNumber: 4,
    seasonNumber: 1,
    datePublished: "2023-01-25",
    transcript: `
    Nick:
At ReelMetrics we collect and analyze a ton of slot data and we're hit up frequently for insights, tips and tricks. So we decided to do a webcast in which we present, discuss and otherwise nerd out on our work. Coming to you from our offices in lovely Leiden in the Netherlands, welcome to ReelCast.
Good morning, Don. How are things in Purina country?
Don:
Good morning, Nick. Things are going to get real cold here today. It's supposed to be minus five Fahrenheit.

Nick:
Yeah, I saw my dad is in northeastern Nebraska. Those guys were at minus, I think 12 last night.
Don:
That's silly.
Nick:
Not pleasant. Not pleasant. Not pleasant. How Midwestern are we that we begin every one of these things with a discussion of the weather?
Don:
Yeah. Forget world events, let's do weather events.
Nick:
Okay. It's late December here, 22nd of December we have a World Cup behind us. As an American living in Europe, each time the Cup rolls around, I get a lot of questions from Europeans about the sport's popularity in the United States.
So I've made a bit of a habit of monitoring American viewership figures for the finals. I pulled some Nielsen data yesterday. So, here's what we have.
Let's see. 2022, France versus Argentina, 26 million American viewers. 2018, France versus Croatia, 17.8 million. 2014, Germany v. Argentina, the highest ever recorded, 27.3 million. 2010, Spain versus the Netherlands, 24.7 million. And 2006, Italy versus France, 18.9 million. Famously the match in which Zinedine Zidane headbutted that Italian dude.
So going into this tournament, the average was 22.2 million. This year's viewership represents, it looks like an 18% increase relative to that mean, but also then a 5% decrease relative to that 2014 record.
Now, I haven't seen much in the way of aggregate American sports betting numbers, but we both looked at some geolocational data that showed American online World Cup betting was second only to the Super Bowl in terms of the number of those placing wagers.
So, it was 35% higher than the NBA final, 39% higher than the NCAA final and 76% higher than the Stanley Cup.
Now it was only a third of those who wagered on the Super Bowl, but I must say I was pretty shocked to see it in that second fiddle position.
Now mind you, I've not correlated these data with anything. And as legalized sports betting is pretty new to the US, there's not a great basis for trendlines here.
So, take these data with a grain of salt, but if they are to be believed, there does indeed appear to be a modest uptick in popularity.
I think everybody is going to be watching this very closely. As we all know, there is some very big money in football.
So, some interesting stats there. I'm always interested to see where Americans are going with football. Don, anecdotally, do you find a lot more people becoming interested in this?
Don:
Oh, absolutely. It grows year by year. Living in St. Louis, it's always been a soccer hotbed.
Nick:
Oh, is that right? I didn't know that.
Don:
Oh yeah, yeah. Soccer's really big in St. Louis. Matter of fact, we get our MLS team here this year. They just released our schedule this weekend.
They start playing here in late February, for the first time. We have an expansion team. Yeah. Soccer is really popular.
If you went to the different restaurants around town this weekend, they had signs posted on the doors saying that, "We will be open early so we can show the World Cup match," at a lot of the sports bars and stuff.
Nick:
Yeah, cool. How'd you do?
Don:
Especially since in US time, that was a morning match. So it wasn't exactly a prime time five o'clock in the afternoon Super Bowl. The match was done at noon here in St. Louis.
Nick:
Okay. Yeah. How'd you do on your wagers?
Don:
I did okay. I bet Argentina pre-World Cup. Then I bet them again after their odds went up, after they got beat by Saudi Arabia.
I almost had a really nice score because I parlayed them with Messi to win the Golden Boot.
With 10 minutes left I was winning that bet. And then in overtime I was winning the bet until [inaudible 00:05:00]
Nick:
A certain 23-year-old Frenchman just shot that down the toilet.
Don:
Oh my gosh. Yeah. He scores a hat trick, so I ended up losing by a goal. But an unbelievable match. It was so much fun to watch.
Nick:
Yeah. There were a number of great ones. Croatia versus Netherlands was... or excuse me, Croatia versus Brazil, that was a hell of a match. That Netherlands versus Argentina was a good one too. So yeah, indeed. Lots of fun.
Okay. So here we are, episode four. Before we get started, a few housekeeping items. First, we'd love to tackle any questions that anyone listening may have.
So, if you have a question about what we're presenting or something you'd like us to present, please drop us an email at ReelCast@ReelMetrics.com. Again, that's R-E-E-L-C-A-S-T@ReelMetrics.com.
Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. We do not do that.
Secondly, and on that note, we have another question or in this case a request. This comes from a slot VP in the Western US who asks, "Hey guys, any chance you'd be up for doing a retrospective on your most interesting discoveries of the year? Great show. We're actually using this stuff."
That's great to hear. Thank you for that. In answer to your question, sure, we'd be happy to do that.
So based on your request, Don and I have assembled a list here, the top 10 insights of 2022. Don, you ready to get at this?
Don:
Let's go.
Nick:
Okay. My wife keeps telling me that I need to move my jaw a lot less on these podcasts. So, we're going to divvy these out.
I'll take the first two. You grab the next three, and then we'll just toggle from there.
Okay. So, here we go. Top 10 insights of 2022. So number 10, selecting games without independent data remains a fool's errand.
This isn't necessarily a new insight, but it's something that we've tracked from the very beginning.
Basically, whenever we onboard a new subscriber, we get a historical dataset that enables us to establish performance and activity baselines before and after the introduction of an industrial dataset.
So we sit down with the slot teams, look at what they are, set goals as to where we want to go, then we get at it. When we do this, what do we see?
First of all, let's just go back to our baseline assumption about success rates and products. What we see is that 78% of slot product released is never going to reach a pan-industrial value of 1.0. So, that's a 22% success rate overall.
The way then this trickles down is what we see is that when games are selected and placed into environments without any independent data sources, what we see is that 60 to 70% of games so selected are going to drop below 1.0 internally, within six months of install.
The other thing that we see on average, is that 32% of all game conversions result in regressive revenue performance. So, the revenue goes down instead of up.
Then when we look at this, after pan-industrial datasets come in, we see 95 to 98% of new titles that come in remain above 1.0, 12 months post-install. And close to a hundred percent... it's really just kind of a rounding error, on conversions result in progressive revenue performance.
So the key takeaways on this, number one, I just say we're always concerned with how analytical capital is expended within slot organizations.
Our data support pretty much unimpeachably, that's spending hours and hours combing through par sheets, cut sheets, math models and game mechs, is really an objectively bad use of analytical capital.
Why is that? It's really because the design elements that drive successful games, they really transcend whatever you can extract from this type of collateral.
So, we just really need to stop assuming that everything boils down to math and mechs because as we view it, a hit game is really like a hit song, in that its success is really contingent on its ability to establish emotional connections with specific player segments.
So as with hit songs and the reasons that we love them, okay, it's not just the melody. It's not just the beats per measure, just the lyrical content, just the cover art.
It's more what we would describe at this point as more of a fortuitous combination of design attributes. It spans hardware, graphics, sound, math, mechs and more.
If it was as easy as math and mechs, believe me, failure rates in new product introduction would be nowhere near 78%. We also wouldn't see the degree of mimicry that we see in response to each major hit. Everybody knows that one.
We get a product that comes to market and just absolutely knocks the cover off the ball. Within a year we see 20 products that look a hell of a lot like it.
So really, we wouldn't see this stuff on the scale that we see it if it really boiled down to just a couple of design elements like that. So, that's number 10.
As for number nine, it's that core product performance decays faster and more dramatically than previously assumed.
This comes from a study that was really led by Don earlier this year, in which he was able to quantify that core revenue performance decays at a compounded rate of 10% per annum.
Now, this does indeed vary by supplier, but is an overall just a macro figure that you can plug in to any budgetary projections, et cetera.
The way that this works is just to think it through. If you have an average daily win of let's say 300 bucks, and you have that decay rate and you put a game on the floor that's performing at a floor average, by the end of year one it's going to be at 90% of floor. By the end of year two at 81% of floor. If you draw this out all the way to year five, it's at 59% of floor.
So, why do we care about this? What's its effect? Well, when we look at inventory these days, we go into any casinos and we really look at the inventory with a microscope, what we see is that roughly 25% of everything that's out there exhibits effectively zero productive capacity.
If you want to know that term, go back to the previous episode. We spent a lot of time on it. What that means, at effectively zero productive capacity is it's generating something like two minutes of play per hour.
Then we see that between 55 and 60% of inventory falls into that critically low productive capacity category.
That's where we see revenue fair share in the 30 to 60% range. So, the floors are extremely bottom heavy.
Now, this core rate of decay is problematic. It's an issue because as we discussed in the last episode, those EBITDA-based projections of core versus premium dramatically inflate core productive capacity. They negate, not merely deflate the costs of core assets.
As a result, and for a bunch of other reasons, core has really been dramatically over-selected over the years. And the quality profile of our inventory reflects this. The floors are just getting older and dog ear.
Another thing that comes out of this is all these misconceptions about the economics of core and premium and just the economics of the slot floor in general. It's really creating a lot of problems, including a lot of needless tension between operators and suppliers.
So that was our pick for number nine this year, core product performance decaying faster and more dramatically than previously assumed. So Don, you want to take number eight?
Don:
Number eight is play patterns have changed post-pandemic. We've seen a lot of play patterns shift in the last two years, post-Covid reopenings.
The players have generally become younger. The over-65 crowd has been slow to come back to the casinos. Those were the players who were carrying the physical reels.
The average age of the customers has dropped by about four and a half years post-openings. Most of the floors had too many reels before Covid hit.
There's been a general shift towards video for the last 20 years now, but it's really accelerated the last 10.
Most floors had far too many reels before Covid, and it's really worse now. Most casinos could get away with 15% of their floor being reels, at the most.
We see floors still above 20%, above 25%. Those machines are just not earning any money at all for them.
So, most casinos need to do a fair share analysis to determine their proper levels. You really need to aim for a higher than a hundred percent fair share on the reels, just because of their low occupancy and their high average bet, especially in the dollar.
If you can get away with 110 to 120% fair share in dollars, so everyone really needs to look at their physical reels on their floors.
Nick:
Now Don, I had a question, because I know that you've dipped into a lot of the demographic data and looked at a lot of stuff about younger segments of players, et cetera.
I know that there was always school of thought out there that physical reels were great introductory games. So, there was not a lot of intimidation to them. They're pretty straightforward propositions, et cetera. Do you see anything in data that either supports or refutes that?
Don:
Yeah, I just finished a study on what customers under 45 have been playing and it's video.
Nick:
Yeah.
Don:
They spend very little money on physical reels. There are a couple themes that do resonate with them, but generally it is video.
Their average bets are higher. They're more of gamblers. They will tend to gravitate towards lease games more than any other segment. They really like DragonLink. They will play a really high average bet. It seems like they're chasing jackpots.
So, if you can think of some of those reel games that you can chase jackpots where you have decisions, those are the ones they've been gravitating to.
Nick:
Okay. All right, cool. So, on we go.
Don:
Number seven is, locational sensitivity is more dramatic than previously assumed. I've looked at all the lease games, all the core games. Even your very best lease games show location differences in revenue.
Your core games will vary greatly. Some will work anywhere. Some will only work in A locations. But the big takeaway is, don't bury your lease games.
There's a lot of casinos that try to put their lease games in the far corners of the casino to try to increase traffic patterns over there. It really does degrade their performance.
Those games that you bury sometimes go all the way down to house average, when they can do three and a half, four times house average in a good location.
When you're spending 50, $70 a day for those lease games, I'll take the four times house.
Nick:
Of course.
Don:
The other big takeaway is, don't waste prime real estate on games that'll produce the same revenue in an average location.
You see a lot of really good games in their prime spots and they don't need to be. They're not making any more money for you in a prime spot. They do just as well in a C location.
Conversely, there's a lot of games that need to have a prime spot to succeed, anything with a unique math model. So if any of your vendors are pitching anything truly unique, like the Hexagrams that came out a couple of years back, anything that's really wild, you really need to put those in a prime location. They just don't succeed anyplace else.
So you really have to use that location grading system to try to figure out what the best location is for your machines. So it's not a slam dunk. You just can't put them anywhere.
Nick:
Yeah. Just as a self-promotional piece here, I will mention that in ReelMetrics, we guide you to precisely the sweet spot location on every single title. I just wanted to throw a little self-promotional point in there.
Don:
Yeah. It's been really wild. It's one of the neat things about the site, is trying to figure out what works where. Bank swaps was always just... it was more of an art form than anything else.
It was such a neat thing when you could swap banks and both of their revenue goes up. Now this tool helps you do that. You don't have to play that guessing game. You can actually put stuff in neighborhoods.
Nick:
Yeah. Perfect.
Don:
Onto number six, there's too little awareness as to who's playing what theme. The lease games are being carried by your Host-level players. Your very best lease games are getting pounded by your Host-level players.
The Host-level players have less interest in your core offerings, beyond the very best themes. Your top 20 themes, top 50 themes at your casino are the only ones that your Host-level players are playing.
Conversely, your low ADT players, your daily hundred dollars or less players, they stay away from lease games.
They gravitate towards your obsolete games, your older cabinets, your older themes. They've got themes that they like and they stick with them. They don't try anything new.
Nick:
You had some great metrics on these things too, Don, I think about wallets and whatnot.
Don:
Yeah. Your Host-level players will play about 30% of their wallet on lease games, which is a really high number considering that most floors are five, 7% lease games, but 30% of their wallet is spent on lease games. Their average bet really jumps on lease games.
On a core game, their average bet's $2.45 per spin. On a lease game, it jumps to $4.50 cents per spin.
Nick:
Man.
Don:
They'll sit there. The average trip length on a slot machine is eight minutes. On a lease game that they like, that they prefer, 20% of their session time is over an hour. So, they'll sit in that seat for over an hour and play that game.
So their theoretical is up, their average bet is up. It's the perfect storm. So, if you really want to raise your Host-level play, make sure you have the lease games that they want.
Too many casinos are capacity constrained for the best lease games. They may have one bank of them, or they may have one of them. They're running at 85% density or higher. They're just restricting their Host-level play.
Nick:
Yeah. Yeah. As we've discussed in previous episodes, when we're doing that, when we're constraining that play, what's happening is we're experiencing significantly reduced player spend and satisfaction and loyalty.
This is most dramatically seen within those high-end segments. So it's a very, very important point. Okay.
Don:
Their play gets halved, if they don't play their machines that they want to play.
Nick:
Yeah. I think we've seen 50 to 60% lower productivity when they're on stuff they have to play. Yeah. Okay, cool. I'll take number five.
Marketing and operations are not collaborating in mix management. So through informal polling, we've found that with very few exceptions, marketing has zero role in mix management.
I think it's important to point out here, critical to point out that in virtually no other retail industry on the planet, is this the case.
In many cases, what we found is that operations has no access to player data. So, there are actually structural impediments in place that block the understanding of demand.
Honestly, if you're operating this policy, our guidance at this point is categorical. Just kill it. It's a terrible policy and really can't be defended.
But what we've seen is, the wall between these departments is far more widespread and problematic than we'd ever imagined. We hadn't imagined it because really it's just such a misguided approach.
So, it really for us, epitomizes the command model approach where you're selecting and marketing inventory without demand indication. It deepens this problem of lack of understanding as to who's playing what.
We get to the point where we are now, where our shelves are dominated truly by low-demand offerings, a product in which simply no one is interested.
So, that's number five. Marketing and operations not collaborating in mix management. So Don, you want to take four?
Don:
Number four, core inventories are growing older, dodgier and more malignant. We're seeing the core game stay on the floor well beyond their expiration date. The average age of the floor just keeps getting older and older and over... older and older and older.
As we talked about earlier, the decay rate for core games drops 10% per year compounded. So, their revenue capabilities, especially after year five, they really start to fall off because you don't have the conversion options.
Slot capital's not keeping up with the price increases. We've got cabinets now that have gone past the $30,000 mark.
It wasn't that long ago that cabinets were in that eight to $12,000 range. Now they're 25 to $35,000.
The slot capital's not keeping up with that. So, the floors just keep getting older as people put less and less new machines on every year.
The older they are, those machines are becoming dogs and they are malignant. They do crowd out high-demand product. They do damage Host-level play and spend and loyalty. When players are forced to play those games, they played less and less and less.
There's a couple reasons for that. One, that accounting treatments, the depreciation depends on what your organization does, but if you've got a game that you've got to keep on the floor for seven years versus getting rid of a poor product, that's an issue.
Another issue too is with the declining slot CapEx, if you buy a machine now, you better assume that you're going to have that machine on your floor for 10 years, unless you are a very progressive organization and you are still flipping out 15, 20% of your cabinets per year. There are very few of those.
If you buy a machine today, you better assume you're going to have it for 10 years. There's a lot of long-term slot directors and slot VPs out there. That's kind of a scary thought.
Nick:
It is. This is a trend that really has me quite concerned, especially as I look at this new course I was looking at.
We promoted one of those aristocrat titles this week for that Neptune Single. My god, those numbers are incredible.
Don:
They are.
Nick:
There's a lot of appealing stuff coming out and it's not cheap.
Don:
Yeah. You can almost see what's going to happen. These floors get older and older, the only way to fix this is to increase your premium share on your floor because people just will not have the CapEx dollars available to flip out 15, 20% of your floor every year.
So, if you increase your floor share of premium product, you won't have to because you'll have a lesser number to work on every year. There's enough premium product out there that you can do that with.
Nick:
Yep. You kill multiple birds with one stone on that one, so absolutely. Okay, cool. Number three, industry inventories are badly imbalanced.
First, what do we mean by this? So in a nutshell, what we mean is that due to really decades of this command model management... and that's when you're installing and marketing products without detailed demand indication by segment, because of that slot inventories are truly upside down.
So on average, for every one high-demand, super-performant unit that high-value players just pound, we're offering seven units to which they're largely indifferent and 38 that they absolutely detest.
Again, for every one that they love, we're offering seven that they don't care about and 38 that they hate.
Why should you care? Because not surprisingly, the folks who generate the lion's share of your revenue spend significantly less when they're playing stuff they don't like. It's that simple. If you think about it, it's hardly shocking.
So when we go out and we look at casinos today, what we see is that 55 to 60% of the inventory out there is exhibiting critically low productive capacity.
In more than 100 casinos studied on this, we found that fewer than 50 titles generate half of all slot revenue, which is just an incredible stat.
Don, I don't know. What is the highest number you've seen? Is it actually over 50 or below? Anyways.
Don:
I had one at 61, but I had several in the thirties, where 30 machines had generated half of their revenue.
Nick:
Man. Okay. So again, a lot of points about that, including the overdiversification point, which we dedicated the first two episodes of ReelCast. So, you can go back and check those out if you're interested.
The other thing that we see is that the highest demand inventory is relegated typically to less than 10% floor share.
All in all, these imbalances are disproportionately hammering your Host-level players. So, when they're playing games that they have to play instead of what they want to play, spend typically drops by 50 to 60%.
As Don mentioned, the good news on this one is you can take a good healthy bite out of this simply by expanding intelligently your premium floor share. Okay?
The other point here is that you really must plot a course for transition. We're leading a number of folks through this, but what it amounts to is a multi-year transition where you just pick a higher level of strategy and really make sure everything is all demand driven, all decisions, et cetera.
And then you work within your budgetary framework, your standard budgetary framework to then make that happen over multiple years.
What we found is that, there is between 30 and 75% headroom in that Host-level play. We can assure you that you are not going to capture this until you adopt a demand-driven mix management framework and really get after what it is that these people need, in terms of capacity.
Because it's related to the imbalance point, I'll take the next one as well. Number two, cannibalization fears regarding premium are largely meritless.
That's a big statement here. To what are we referring? Well, it's this age-old belief that highly performant products steal their performance from other machines.
So, when we place these ultras sticky units on the floor, all we're doing is moving static spending levels from one set of assets to another.
So the question is, is this happening? No, it is not. In fact, I described these fears as being largely meritless, only because I'm assuming that somewhere out there it may have happened.
Based on our evidence, however, and folks, we have a ton of it, we've yet to see this. In fact, what we see is that this static spending assumption is almost entirely self-fulfilling.
So, if it appears to be static, the overwhelmingly high probability is that your mix is constraining it. That is, you're offering too few products in which high-value players gleefully empty their wallets.
So, until you really put this in place, all you're going to do is you're going to capture only a fractional percentage of those wallets.
We reach these conclusions really via two ReelMetric studies, which are the largest of their kind. One, we had discussed in earlier podcasts, about our Covid versus post-Covid analyses and then through controlled experiments.
Those controlled experiments that we put out into the field really opened our eyes on this. So, these were just compositional changes only. We didn't have any type of marketing support or anything that nature.
We targeted Host-level players exclusively. We increased premium floor share, removing one dog for each incremental unit that we added. What we saw was that Host-level engagement with dogs, which we had seen increasing year over year just because the floors were getting older and dog ear, that absolutely flatlined.
Simultaneously, what we saw is their engagement with the new inventory absolutely skyrocketed, straight-up hockey stick.
Then the really critical variable was, we wanted to see what this did to Host-level wallet on a multi-trip basis. What we saw is that, that increased by 35 to 75%, where the average bet went up, the session duration went up. Very interestingly, all the Net Promoter Scores went off the charts with the reason cited, suddenly I can play what I want.
The cannibalization fear is out there. What we are finding is they're just largely the result of, as Don had said it at the end of last month's podcast, people love their revenues and hate the fees. So, that's a big part of this, is just the pricing.
But I think we all need to just get accustomed to the idea in gaming that superior products are more expensive. It's that simple.
We've talked a little bit about how EBITDA-based projections can contribute to these fears on cannibalization, et cetera, but what it really boils down to is just insufficient awareness of demand tendencies on a segment-by-segment basis.
Once you start looking at it that way, you see that a lot of these fears are abated. So Don, the number one.
Don:
Number one, best practices models for inventory management are badly outdated and need to change.
We're seeing virtually no strategic management or target marketing of inventory occurring anywhere in industry.
There's insufficient grasp of demand tendencies. There's a lack of unified product segmentation model and a customer segmentation model.
There's a lot of misconceptions regarding segmentation and segment marketing. They often be revised.
We talked to a lot of operators who use ship share rates as their basis for buying slot machines. Please stop. There's no sense in going on past history to buy current machines.
There's no way, if you looked at their performance, that some of these manufacturers should be getting what they're getting. Some should be getting a lot more and some should be getting a whole lot less.
Account Executive recommendations are fine, but you have to tailor those to performance indicators.
Industry awards are pretty irrelevant. We know which of the best games are. As Nick said earlier, 70% plus of the games are going to fail. You've really got to have some information out there to go off of, on your suggestions.
There's too little attention paid to configuration options. The big thing I find is, there's an insufficient use of fair share analysis and pre-purchase planning on, what do your customers want and need? What do you need to expand? What don't you have on your floor that you need to have on your floor? That's probably the biggest takeaway.
You go back to some of the earlier comments on what players were playing post-Covid. There shouldn't be a whole lot of reels being bought right now unless you're one of those very lucky few casinos that dollar reels were a very large portion of your revenue.
There's just a lot of floor management that has to take place. You really got to figure out what your players are demanding that you just don't have. There's just a big inventory imbalance and it gets worse and worse every year.
The casino operators are really retailers. It's time you start acting like it. You've got to know what your customers want. You got to put it in the right spots for them.
It's still grocery store analysis that we use pretty much every time we have one of these ReelCast broadcasts. You've got to put the right product in the right locations on the floor.
We've shown some success building unified segmentation models and best practices, step-by-step stuff. What do you need to do before you buy slot machines? If you're not doing that, you're going to make a mistake that's going to be on your floor for 10-plus years.
Nick:
Yep. I was thinking about this today, Don. I have to say that, honestly in the entire span of my gaming industry career and depressingly it's getting close to 30 years now, honestly, I've yet to feel more strongly about anything than I do about this.
In the past decade, our industry's information landscape has changed completely. The issue is that the best practices models for inventory management out there today are loaded with all sorts of hacks and shims that were really designed for that 20th century, now obsolete information landscape.
We just didn't have the data sources that we have today. So, when we're talking about ship share data, AE recommendations, EBITDA-only budgetary projections, command model selection and marketing, these methods, they distort reality, result in negative outcomes and in the end, really eviscerate value for one reason beyond all others combined. Really, that's habit.
There's a way to test this. I mean, if you just ask yourself, "Why am I using ship share data? Why am I asking for AE recommendations? Why am I not basing everything on demand?," if you're honest with yourself, the answer is not because no superior approaches or data sources exist, but rather because that's just the way we've always done it.
Although tradition is great in terms of its ability to provide us a sense of security and whatnot, it's also antithetical to progress. So, if you're brutally honest with yourself, you really start to see how false that sense of security can be.
So a completely revised best practices model represents, in my view, a big, bright, shiny brass ring that's dangling right in front of you. We cannot emphasize strongly enough that all you need to do here is grab it.
I can assure you, without fear of contradiction, that if you do so, you will be very pleased with the results.
Don:
I had a GM that I really enjoyed working with. One of his favorite sayings is, you don't know what you don't know.
Nick:
Yes.
Don:
Right? There's been so much change in slot leadership post-Covid. There's a lot of people who have retired. There's a lot of people who are becoming a director or a VP for the first time.
Those first year or two of being a VP of slots or casino operations is hard. It's a lot of work. It's a lot of learning.
Nick:
No doubt.
Don:
There's a lot of things out there that can make your life easier. You can make better decisions, but it's a process. There's some setup work.
You just don't go out and pick a new car just because you like the way it looks. You do some research. You look at all the things that are important to you.
It's the same thing with slot machines. You look at all the things that are important to your customers.
There's some research you have to do before you start buying and spending your million, two million, $10 million a year.
Nick:
Without question. Okay, Don. Well, I think we've covered it for today, our top 10 list of the top 10 insights of 2022.
I don't know about you, but tomorrow I will be loading up the family truckster and dragging my brood across Europe.
Don:
Well, have fun.
Nick:
Yeah. I'm ready to pack up and get some sleep in preparation for that. Okay. Well, very good.
Don:
Well, happy holidays, Nick.
Nick:
Yeah. Same to you, Don. You take care. Thanks so much.
    `
};

export default S01E04;
