import React from "react";
import PropTypes from "prop-types";

function ServicesBlockItem({ image, title, text, position }) {
    const itemId = `service-block-item-${position}`;
    
    return (
        <article 
            className="col-xs-12 col-sm-6 col-md-4 text-center service-block-item"
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/Service"
        >
            <meta itemProp="position" content={position} />
            <div className="question-box">
                <figure className="question-box__media">
                    <img
                        src={image}
                        alt={`${title} - ReelMetrics Casino Analytics Service`}
                        className="img-responsive center-block"
                        loading="eager"
                        width="150"
                        height="150"
                        itemProp="image"
                        aria-labelledby={itemId}
                    />
                </figure>
                <h3 
                    id={itemId}
                    className="question-box__title"
                    itemProp="name"
                >
                    {title}
                </h3>
                <div
                    className="question-box__body"
                    dangerouslySetInnerHTML={{
                        __html: text
                    }}
                    itemProp="description"
                />
            </div>
        </article>
    );
}

ServicesBlockItem.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    position: PropTypes.number
};

ServicesBlockItem.defaultProps = {
    position: 0
};

export default ServicesBlockItem;
