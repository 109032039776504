/**
 * @module PageMetadata
 * @description A centralized component for managing all page metadata including SEO tags, Open Graph, 
 * Twitter Cards, and structured data. This component wraps react-helmet to provide a standardized 
 * interface for setting document head elements across the application.
 * 
 * This component helps maintain consistent SEO patterns throughout the site, improves search engine
 * understanding through structured data, enhances social media sharing capabilities, and optimizes 
 * performance through resource hints.
 * 
 * By centralizing all metadata management, this component ensures:
 * - Consistent SEO implementation across all pages
 * - Proper structured data formatting for rich search results
 * - Standardized social sharing previews
 * - Simplified metadata management without direct Helmet manipulation
 * 
 * @example
 * // Basic usage
 * <PageMetadata 
 *   title="Page Title | Site Name" 
 *   description="Descriptive text about this page for search engines."
 *   canonical="https://example.com/page"
 * />
 * 
 * @example
 * // With social media and structured data
 * <PageMetadata 
 *   title="Product Name | Shop"
 *   description="Product description"
 *   canonical="https://example.com/products/123"
 *   ogTitle="Product Name - Special Offer"
 *   ogImage="https://example.com/image.jpg"
 *   structuredData={[productSchema, breadcrumbSchema]}
 * />
 */
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

/**
 * PageMetadata component for managing document head elements
 * 
 * This component serves as a centralized solution for all metadata-related needs including:
 * - Basic SEO tags (title, description, keywords)
 * - Canonical URLs for preventing duplicate content issues
 * - Open Graph protocol tags for social media sharing
 * - Twitter Card metadata for Twitter sharing
 * - JSON-LD structured data for rich search results
 * - Resource hints for performance optimization
 * - Additional custom meta tags
 * 
 * @component
 * @param {Object} props - Component props
 * @param {string} [props.title] - Page title that appears in browser tabs and search results.
 *   Should be unique, descriptive, and ideally 50-60 characters. Will be used as fallback for
 *   ogTitle and twitterTitle if those aren't specified.
 * 
 * @param {string} [props.description] - Meta description for SEO purposes. Should be a
 *   compelling, unique summary of the page content between 120-160 characters. Google may
 *   display this in search results. Will be used as fallback for ogDescription and
 *   twitterDescription if those aren't specified.
 * 
 * @param {string} [props.canonical] - Canonical URL to prevent duplicate content issues.
 *   Should be the full, absolute URL to the preferred version of the page.
 *   Important for pages that can be accessed through multiple URLs.
 * 
 * @param {string} [props.ogTitle] - OpenGraph title for social media sharing.
 *   If not provided, falls back to the title prop. Ideally 60-90 characters.
 *   Used when content is shared on Facebook, LinkedIn, and other platforms.
 * 
 * @param {string} [props.ogDescription] - OpenGraph description for social media sharing.
 *   If not provided, falls back to the description prop. Can be slightly longer than
 *   the meta description, up to 200 characters.
 * 
 * @param {string} [props.ogImage] - OpenGraph image URL for social media sharing.
 *   Should be an absolute URL to an image (JPG, PNG) ideally 1200×630 pixels for best display
 *   across all platforms. This image appears when the page is shared on social media.
 * 
 * @param {string} [props.ogUrl] - OpenGraph URL, typically the canonical URL of the page.
 *   Should be a full, absolute URL. Used by social media platforms to identify the page.
 * 
 * @param {string} [props.ogType] - OpenGraph type, identifying the type of content.
 *   Common values include "website" (default), "article", "product", "profile", etc.
 *   Helps social platforms display content appropriately.
 * 
 * @param {string} [props.twitterCard] - Twitter card type determining the appearance of shared links.
 *   Options include "summary" (small square image), "summary_large_image" (large banner image),
 *   "app" (for app installs), or "player" (for video/audio content).
 * 
 * @param {string} [props.twitterTitle] - Title specific for Twitter sharing.
 *   If not provided, falls back to ogTitle or title. Should be concise, under 70 characters.
 * 
 * @param {string} [props.twitterDescription] - Description specific for Twitter sharing.
 *   If not provided, falls back to ogDescription or description. Keep under 200 characters.
 * 
 * @param {string} [props.twitterImage] - Image URL specific for Twitter sharing.
 *   If not provided, falls back to ogImage. For "summary_large_image" card,
 *   recommended size is 1200×628 pixels.
 * 
 * @param {Array<Object>} [props.structuredData] - Array of structured data objects for JSON-LD.
 *   Each object should be a complete schema following schema.org specifications.
 *   Examples include Product, Article, BreadcrumbList, Organization, FAQPage, etc.
 *   These improve search engine understanding and can enable rich results in search.
 *   @example
 *   [
 *     {
 *       "@context": "https://schema.org",
 *       "@type": "Product",
 *       "name": "Product Name",
 *       "description": "Product description",
 *       "offers": {
 *         "@type": "Offer",
 *         "price": "19.99",
 *         "priceCurrency": "USD"
 *       }
 *     },
 *     {
 *       "@context": "https://schema.org",
 *       "@type": "BreadcrumbList",
 *       "itemListElement": [...]
 *     }
 *   ]
 * 
 * @param {Array<Object>} [props.meta] - Additional meta tags with arbitrary attributes.
 *   Useful for custom meta tags or meta tags not covered by the specific props.
 *   Each object should contain key-value pairs of attribute names and values.
 *   @example
 *   [
 *     { name: "robots", content: "index, follow" },
 *     { property: "og:locale", content: "en_US" },
 *     { name: "author", content: "Author Name" }
 *   ]
 * 
 * @param {Array<string>} [props.preconnect] - URLs to preconnect to for performance optimization.
 *   Establishes early connections to important third-party origins to improve perceived page load speed.
 *   Useful for domains that will be used soon after page load (CDNs, API endpoints, etc.).
 *   @example ["https://cdn.example.com", "https://api.example.com"]
 * 
 * @param {Array<Object>} [props.preload] - Resources to preload for performance optimization.
 *   Tells the browser to download key resources sooner, improving page load performance.
 *   Each object should specify the resource URL and the resource type.
 *   @example
 *   [
 *     { href: "https://example.com/font.woff2", as: "font" },
 *     { href: "https://example.com/hero.jpg", as: "image" }
 *   ]
 * 
 * @param {string} [props.keywords] - Meta keywords for SEO. While less important for Google,
 *   some search engines still consider this. Comma-separated list of relevant terms.
 *   @example "react, metadata, SEO, component"
 * 
 * @param {React.ReactNode} [props.children] - Additional elements to include in the Helmet.
 *   Allows for custom tags that aren't covered by the props, such as custom link tags,
 *   additional script tags, or other head elements.
 * 
 * @returns {React.ReactElement} Helmet component with configured metadata
 */
function PageMetadata({
  title,
  description,
  canonical,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  ogType,
  twitterCard,
  twitterTitle,
  twitterDescription,
  twitterImage,
  structuredData,
  meta,
  preconnect,
  preload,
  keywords,
  children
}) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      
      {/* Basic Meta Tags */}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {canonical && <link rel="canonical" href={canonical} />}
      
      {/* OpenGraph Tags */}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {ogType && <meta property="og:type" content={ogType} />}
      
      {/* Twitter Card Tags */}
      {twitterCard && <meta name="twitter:card" content={twitterCard} />}
      {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
      {twitterDescription && <meta name="twitter:description" content={twitterDescription} />}
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      
      {/* Preconnect Links */}
      {preconnect && preconnect.map((url) => (
        <link key={`preconnect-${url}`} rel="preconnect" href={url} />
      ))}
      
      {/* Preload Links */}
      {preload && preload.map((item) => (
        <link key={`preload-${item.href}-${item.as}`} rel="preload" href={item.href} as={item.as} />
      ))}
      
      {/* Additional Meta Tags */}
      {meta && meta.map((item) => (
        <meta key={`meta-${item.name || item.property}-${item.content}`} {...item} />
      ))}
      
      {/* Structured Data */}
      {structuredData && structuredData.map((data) => (
        <script key={`structured-data-${data["@type"]}-${data.name || data["@context"]}`} type="application/ld+json">
          {JSON.stringify(data)}
        </script>
      ))}
      
      {/* Additional Elements (scripts, etc.) */}
      {children}
    </Helmet>
  );
}

PageMetadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  ogUrl: PropTypes.string,
  ogType: PropTypes.string,
  twitterCard: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.string,
  structuredData: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.arrayOf(PropTypes.object),
  preconnect: PropTypes.arrayOf(PropTypes.string),
  preload: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    as: PropTypes.string.isRequired
  })),
  keywords: PropTypes.string,
  children: PropTypes.node
};

PageMetadata.defaultProps = {
  meta: [],
  preconnect: [],
  preload: [],
  structuredData: [],
  title: "",
  description: "",
  canonical: "",
  ogTitle: "",
  ogDescription: "",
  ogImage: "",
  ogUrl: "",
  ogType: "website",
  twitterCard: "",
  twitterTitle: "",
  twitterDescription: "",
  twitterImage: "",
  keywords: "",
  children: null
};

export default PageMetadata; 