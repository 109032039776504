import React from "react";
import PropTypes from "prop-types";
import OwlCarousel from "react-owl-carousel2";

import useWindowSize from "../../../utils/hooks/windowSize";

import Index from "./Index";

const itemCount = width => {
    if (width < 768) {
        return 1;
    }

    if (width <= 992) {
        return 2;
    }

    if (width < 1200) {
        return 3;
    }

    return 4;
};

const carouselPadding = width => {
    if (width < 768) {
        return 0;
    }

    return 100;
};

function Carousel({ items }) {
    const { width } = useWindowSize();

    const carouselOptions = {
        dots: false,
        nav: true,
        items: itemCount(width),
        slideBy: itemCount(width),
        stagePadding: carouselPadding(width)
    };

    let carouselItems = Array(4)
        .fill()
        .map((_, index) => (
            <div
                key={index} // eslint-disable-line react/no-array-index-key
                className="reelhot-index"
                aria-label="ReelHot index placeholder"
            >
                <img
                    src="assets/images/reelhot-index-thumbnail.png"
                    alt="ReelHot index placeholder thumbnail"
                    loading="eager"
                    width="200"
                    height="150"
                />
            </div>
        ));

    if (items && items.length > 0) {
        carouselItems = items.map(({ date, link }) => (
            <Index key={date} link={link} date={date} />
        ));
    }

    return (
        <div 
            className="reelhot-carousel-wrapper"
            role="region" 
            aria-label="ReelHot indexes carousel"
            itemScope 
            itemType="https://schema.org/ItemList"
        >
            <meta itemProp="name" content="ReelHot Performance Indexes" />
            <meta itemProp="numberOfItems" content={items ? items.length : 0} />
            
            <OwlCarousel
                options={carouselOptions}
                className="list-unstyled js-slider-single testimonial owl-carousel owl-theme"
            >
                {carouselItems}
            </OwlCarousel>
        </div>
    );
}

Carousel.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.instanceOf(Date).isRequired,
            link: PropTypes.string.isRequired
        })
    )
};

Carousel.defaultProps = {
    items: []
};

export default Carousel;
