import React from "react";
import OwlCarousel from "react-owl-carousel2";
import Cms from "./cms";

const PickPlan = props => {
    const options = {
        autoplay: true,
        dots: false,
        autoplayTimeout: 3000,
        items: 1,
        loop: true
    };

    return (
        <section 
            className="pick-plan-hero"
            style={{
                paddingBottom: "50px",
                alignItems: "stretch"
            }}
        >
            <div className="outside-wrapper flex-switch-direction container-fluid flex-row">
                <div
                    className="col-xs-12 col-sm-6 flex-column"
                    style={{
                        justifyContent: "space-evenly",
                        marginLeft: "10px"
                    }}
                >
                    <h2
                        className="title--banner text--red services_hero_title"
                        style={{
                            alignSelf: "flex-start"
                        }}
                    >
                        <Cms
                            content={props.cms_content}
                            content_key="pick_plan_title"
                            type="text"
                        />
                    </h2>
                    <p
                        className="paragraph paragraph--small"
                        style={{ textAlign: "justify" }}
                    >
                        <Cms
                            content={props.cms_content}
                            content_key="pick_plan_paragraph"
                            type="text"
                        />
                    </p>
                    <a
                        href={`#${props.navTo}`}
                        className="btn-block-mobile btn btn-lg btn-primary"
                        style={{
                            width: "50%"
                        }}
                        aria-label="View and compare ReelMetrics plans"
                    >
                        COMPARE PLANS
                    </a>
                </div>

                <div className="col-xs-12 col-sm-5 flex-column">
                    <OwlCarousel
                        options={options}
                        className="logos owl-carousel owl-theme"
                        aria-label="ReelMetrics plan options"
                    >
                        <img
                            src="/assets/images/rm_logo_pro.svg"
                            alt="ReelMetrics Pro Plan Logo - Advanced Analytics Platform"
                            className="img-responsive"
                            loading="eager"
                            width="300"
                            height="150"
                        />
                        <img
                            className="img-responsive"
                            src="/assets/images/rm_logo_basic.svg"
                            alt="ReelMetrics Basic Plan Logo - Essential Analytics Platform"
                            loading="eager"
                            width="300"
                            height="150"
                        />
                    </OwlCarousel>
                </div>
            </div>
        </section>
    );
};

export default PickPlan;
