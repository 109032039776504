import React, { useState, useEffect, Suspense, lazy } from "react";
import PropTypes from "prop-types";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import PageMetadata from "../components/PageMetadata";
import butter from "../utils/butterClient";

import Services from "../components/Services/Services";
import PlanDescription from "../components/plan-description";
import ProductInfo from "../components/product-info";
import AnalyticsInfo from "../components/analytics-info";
import PlanDescriptionLegend from "../components/plan-description-legend";



const Vimeo = lazy(() => import("react-vimeo"));

function Cupid({ cms_content }) {
    // ReactGA.pageview(window.location.pathname);

    const [cmsData, setCmsData] = useState({
        services: []
    });

    useEffect(() => {
        butter.content
            .retrieve(["services", "platform_testimonials"])
            .then(response => {
                const { services } = response.data.data;
                setCmsData({
                    services
                });
            });
    }, []);

    // Structured data for Cupid product
    const cupidSchema = {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "Cupid by ReelMetrics",
        "applicationCategory": "BusinessApplication",
        "operatingSystem": "Web-based",
        "description": "Enterprise-grade player analytics platform leveraging the industry's largest behavioral database to optimize player experiences, enhance loyalty programs, and drive data-driven decisions for casino operations.",
        "image": "https://cdn.buttercms.com/TO2EKYIrSTqNiXO2vLGN",
        "publisher": {
            "@type": "Organization",
            "name": "ReelMetrics",
            "logo": {
                "@type": "ImageObject",
                "url": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
                "width": "350",
                "height": "100"
            }
        }
    };

    // Breadcrumb structured data
    const breadcrumbSchema = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.reelmetrics.com"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Cupid Player Data",
                "item": "https://www.reelmetrics.com/cupid"
            }
        ]
    };

    return (
        <>
            <PageMetadata
                title="Cupid - Player Data | ReelMetrics"
                description="Optimize player experiences and elevate loyalty with Reelmetrics and cupid player data. Harness the power of the industry's largest behavioral database to master player preferences and streamline slot machine demand management."
                canonical="https://www.reelmetrics.com/cupid"
                keywords="player data, slot machine analytics, slot player behavior, player preferences, casino loyalty, player matching"
                
                // OpenGraph tags
                ogUrl="https://www.reelmetrics.com/cupid"
                ogType="website"
                ogTitle="Cupid - Player Data Analytics | ReelMetrics"
                ogDescription="Optimize player experiences and elevate loyalty with Reelmetrics and cupid player data."
                ogImage="https://cdn.buttercms.com/TO2EKYIrSTqNiXO2vLGN"
                
                // Twitter Card
                twitterCard="summary_large_image"
                twitterTitle="Cupid - Player Data Analytics | ReelMetrics"
                twitterDescription="Optimize player experiences with data-driven insights on player preferences."
                twitterImage="https://cdn.buttercms.com/TO2EKYIrSTqNiXO2vLGN"
                
                // Structured data
                structuredData={[
                    cupidSchema,
                    breadcrumbSchema
                ]}
            />

                    <div className="box">
                        <div className="container">
                            <div className="row flex-row">
                                <div className="col-sm-4">
                                    <h1 className="title title--primary title--md">
                                        Start here.
                                    </h1>
                                    <p className="paragraph--small paragraph--small--small-screen">
                                        Your players know what they want. Do you?  
                                    </p>
                                    <p className="paragraph--small paragraph--small--small-screen">
                                        With ReelMetrics Cupid, you leverage the slot machine industry&apos;s largest behavioral database to map demand, micro-target preferences, boost productivity, and fortify loyalty.
                                    </p>
                                    <p className="paragraph--small paragraph--small--small-screen">
                                        It&apos;s player/product matchmaking made simple. It&apos;s Cupid.
                                    </p>
                                </div>
                                <div className="col-sm-8">
                                    <img
                                        src="https://cdn.buttercms.com/TO2EKYIrSTqNiXO2vLGN"     
                                        alt="CUPID logo"
                                        className="img-responsive"
                                        loading="eager"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
           
                    <div className="box--dark compatibility_container">
                        <Container fluid className="outside-wrapper">
                            <Row className="align-items-center">
                                <Col xs={12} className="text-center">
                                    <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Penn_Entertainment_logo.svg"
                                    alt="PENN logo"
                                    className="img-responsive logo-size mx-auto d-block logo-center"
                                    loading="eager"
                                    />
                                    <h4 className="mb-2 whiteText">
                                    &quot;ReelMetrics delivers insights that transform your operations
                                    and ROI that&apos;s off-the-charts.&quot;
                                    </h4>
                                    <p className="attribution">
                                    - Todd George, EVP Operations, Penn Entertainment
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="box box--end">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-md-8 col-md-offset-2">
                                    <div className="text-center">
                                        <h2 className="title title--primary">
                                        Make better slot decisions
                                        </h2>
                                        <p className="paragraph">
                                        As a ReelMetrics subscriber, you have direct access to the world&apos;s largest repository of slot design, performance, and behavioral data. We give you the statistical power to skip the trial and error and get it right the first time. Purchase the best products, implement optimum configs and market to the most receptive player segments.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            
                                <div className="col-xs-12 col-md-8 col-md-offset-2">
                                    <div className="text-center">
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <Vimeo
                                                videoId="293602484"
                                                className="vimeo video-background-transparent"
                                            />
                                        </Suspense>
                                    </div>
                    
                                </div>
                            </div>
                        </div>
                    </div>

            <AnalyticsInfo cms_content={cms_content} />
            <Services services={cmsData.services} />
            <div id="plans-description" className="box col-xs-12">
                <div
                    className="container container--970 flex-row"
                    style={{
                        justifyContent: "space-between"
                    }}
                >
                    <PlanDescription isBasic />
                    <PlanDescription />
                </div>
                <PlanDescriptionLegend />
            </div>
            <ProductInfo className="hidden-xs" cms_content={cms_content} />
        </>
    );
}

Cupid.propTypes = {
    cms_content: PropTypes.object.isRequired
};

export default Cupid;
