import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S02E10 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches script to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E10.defaultProps = {
    id: "13989798-s02e10-aruze-gaming-global-with-kelcey-allison",
    link: "s02e10-aruze-gaming-global-with-kelcey-allison",
    title: "Aruze Gaming Global with Kelcey Allison",
    subTitle: "Season 2, Episode 10",
    description: "In this episode, Nick & Don catch-up with Kelcey Allison, COO of the newly-created Aruze Gaming Global. Kelcey walks Nick & Don through the Okada garnishment, the Chapter 11 filing, the auction, and the creation and future of Aruze Gaming Global. Also in this episode, Vegas F1 and casino signage.",
    keywords: ["Aruze Gaming Global", "Kelcey Allison", "Nick Hogan", "Don Retzlaff", "Reelcast", "Season 2, Episode 10", "Formula One Las Vegas", "Casino Signage"],
    topics: [
        "Kelcey Allison of Aruze Gaming Global.",
        "The bankruptcy auction.",
        "The future of Aruze Gaming Global.",
        "Formula One Las Vegas.",
        "Casino signage.",
    ],
    episodeNumber: 10,
    seasonNumber: 2,
    datePublished: "2023-11-17",
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/13989798-s02e10-aruze-gaming-global-with-kelcey-allison",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelcast/",
    transcript: 
    `Nick Hogan:
    Good morning, Don. What's new in the Inland Empire?
    Don Retzlaff:
    Good morning, Nick. The leaves are falling. You got to cut the grass two or three times now to get them all picked up, but it's a nice time of year.
    Nick Hogan:
    Okay, well we are still gray and raining have been since I returned from G2E, so no changes there. Well, so Don, the time has finally arrived this coming Sunday, November 19th. We'll see Formula 1 cars burning up the Las Vegas strip. So analysts are projecting the event is going to inject around one and a half billion dollars into the local economy. And without question, the promotional value for the city is going to be absolutely massive. But as opposed to the other major sports initiatives the city has undertaken over the past several years, this one has been notably controversial. So first, there are all the construction headaches on and around the strip and Don both you and I had a little taste of that during G2E, and sure enough it was no fun. But I think nothing has really irked Las Vegans like some of the strong man commercial tactics of Liberty Media, the group that owns F1, and most notably over the summer, they had attempted to impose these just outrageously aggressive licensing fees on basically any business with views of the tracks.
    So my understanding is they would look at the capacity of the facility, assume a hundred percent occupancy on race day, then assess a fee of 1500 bucks a head. And in one case there was a proprietor that was facing a $3 million fee for the day. Understandably, these businesses went ballistic and instructed Liberty to go jump up its own keister. And in return, Liberty stated that it would then be powerless to prevent barricade signs and other obstructions from going up in front of any of the businesses which refuse to pay. So Shakedown Street is what we ended up with. So they ultimately settled for fixed fees as I understand on licensing front, I've heard 50 grand bandied about, but I don't really have any details on that. But hardly the end of the gripes, you still have major questions about how strip employees are going to get to work on race day.
    Local unions have leveraged all this into negotiations with the strip operators. Still big concerns about ground transport, so Uber and the taxi services, et cetera on Sunday. And then recently Liberty went out and lined the glass on the strips pedestrian bridges with an opaque foil that obstructs views little tone-deaf. So I can probably go on listing local grievances for the full hour here, but what's fascinating to me really is how this experience has revealed some boundaries on the part of Las Vegas. So in a town that's famous for excess and reinvention, we're finally hearing, "Yeah, this is a bit much." So now I have to say that it's hard to see any scenario within the realm of reason that sees this event being anything but a resounding success. And I guess to a certain extent you just have to chalk this up to growing pains for the first race. And it's pretty hard to imagine that they're not going to have these kinks ironed out in time for the second. So Donnie, have you had any additional anecdotes or input on Vegas F1?
    Don Retzlaff:
    I know some friends of mine that live in Vegas are vacating the town trying to get as far away from Vegas as they can for this coming weekend, because they didn't want to deal with the hassles, but I've heard of the giveaways that the casinos are doing to get their casino employees in the door, giving away cars, and cash, and all that type of good stuff. The things they should be doing because they're afraid that their employees will massively call off sick. So it should be interesting. I was surprised at first glance to see what times all the events are happening. Vegas at 10 o'clock at night, midnight in November is a little chilly. So you're going to have, on the racing side, you're going to have a very, very cold racetrack, very cold breaks after going 200 miles an hour down the strip to hit these turns. It should make for some very interesting racing and I'm guessing maybe a red flag or two.
    Nick Hogan:
    Yeah. Are there any other F1 tracks that have that straightaways of that distance, because-
    Don Retzlaff:
    Yes, there is one other comparable where I think Vegas are estimating they're going to get the 205 as far as miles per hour. There's other tracks that get the 220 because of the long straightaways, but there aren't many like this. And so it's just going to be neat to see going down the strip in front of all the casinos. But being at a midnight type race, you're not going to get a whole lot of viewership in the United States, especially on the East coast. Race is going to go off at one o'clock, two o'clock in the morning, so it'd be great for Europe in the morning and all that, which is, I know why they do it, but end of the season, the championship's already decided. So we'll see what happens. It should be interesting. It should be must watch viewing for race fans.
    Nick Hogan:
    Yeah, couldn't agree more for sure. Okay, so we had an interesting listener question come in earlier this month, but before we address it, let me say that we love to tackle any questions that anybody listening may have. If you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T@reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity, that's not something we do.
    So this one comes from an operator in Northwestern Europe. And let me say that this email had really one hell of a preamble attached to it. This gentleman explained that he's a big fan of the podcast. He's adopted the bulk of the analytical practices that we discuss here and that they've had an absolutely transformative impact upon his business, particularly within that host level segment, which was just awesome. So when we asked for examples, not only did he give us some, but he also sent us this incredibly elaborate workbook complete with pivots, visualizations, source system hooks. It was amazing. And man, did that put smiles on our faces, hey Don.
    Don Retzlaff:
    It did. It was wonderful. It was many of the things that we've done for some of our clients and he just took our podcast and just put it in application. It was really neat to see.
    Nick Hogan:
    Yeah, it was incredible. So the question that you sent in was, "I'm seeking your expertise today on a particular matter concerning slot machine signage. Its visual impact is undeniable signs invigorate the gaming floor and capture attention. However, when the cost is sometimes equivalent to purchasing a new machine, it prompts reflection on the investment and its potential return. In this vein, do you have any concrete data or studies indicating that machines equipped with prominent signage yield superior performance compared to those without. This information is critical for me in order to formulate an informed budget strategy for 2024?" Okay, so first, many thanks, the listener for that question and the lovely correspondence, it really truly means a ton to us and trust that we are over the moon to hear that we helped. So truly heartfelt, thanks for that. Now in response, so first we do not track this within the ReelMetrics repository, so I'm forced to lean on anecdote here, but as I've spent about two thirds of my career in the promotional peripheral space, I feel that my views are at least somewhat well qualified.
    So some things. So first I think it's important to break signage into three categories. So informational, atmospheric, and promotional. So first, as it applies to that informational signage, which calls out things like bathroom locations, ATMs, reception, et cetera, don't pinch pennies there. Generally speaking, people don't like being confused. So make certain that your facility is easily navigable and that these critical locations within it are well signed. And the same can be set of atmospheric signage. So if it augments the venue's aesthetics and helps to distinguish your brand, go for it. Interior design does matter, of course, and atmospheric signage can really compliment it. Now on the promotional signage front, so as you know Don, I spent the early '90s at Mikohn, which effectively led the industry in promotion and display. So at the time we conducted extensive studies on this, and I recall that the range where we landed was somewhere between 10 and 25%.
    So with a sign, a bank is likely to perform 10 to 25% better than let's say a bank that doesn't have one. Now, there are caveats here. So first, those studies were conducted in the '90s when machines were much skinnier and shorter than they are today. So to a certain extent, the cabinets have really become signs. So that's something to think about. And then secondly, the environment itself is a big factor. If you have a ton of visual competition on the floor already, the effect of a promotional sign is going to be somewhat muted. And secondly, where I do have some quantitative data, and these come from some work that I did in the early 2000s, is in the realm of jackpot promotion through physical signage, using digital signage, et cetera systems, et cetera. But it's basically making a bunch of audio and video racket whenever jackpot's here hit.
    So what we did was we'd accumulate all jackpot activity on the floor, so standalones links, controller based system-based jackpots, and then we'd apply filters that allowed us to create celebrations every 30 to 40 minutes. And what we found was that this consistently extended session duration, and we theorized it was because players really felt that a lucky spin was just around the corner. So needless to say, physical signage is critical to these types of programs in that you need the hardware through which to broadcast those celebrations. And then third, a very important point that I see messed up more often than not is that promotional signs are for stars, not dogs. So if your assumption is that a sign is going to create a durable lift on a crappy bank, the odds are you're going to be disappointed. If the underlying game sucks, the players are going to figure this out pretty quickly, signage be damned.
    So your objective really is not to get a short term, let's say 25% lift on a four pack doing 250 a day. It's really to get a sustained, let's say five to 10% lift on a bank doing 3,500 a day. So I guess in some, what I'd say is that, yes, signage is generally a sound investment, although perhaps at a lower magnitude than it's been historically. But like anything, and it's an investment that requires thought and planning. So break it into those categories, spread the love a bit in terms of the budget and just be very strategic and financially focused on anything that's promotional signage. So leveraged logically, it's almost certain to have a material performance impact. So Don, anything you'd like to add on that point?
    Don Retzlaff:
    I'll take a little bit of a different tact and it's going to be where a lot of people spend their signage dollars is going to be on top of slot banks. And I really transitioned the last 10 years or so to purchasing signs to letting my signage come from the manufacturers on my lease games. The manufacturers do such a really good job on their banks and whether now it's a part of a purchase. I remember buying an eight pack of machines and it came with a sign, or it's your lease games, the Dragon Link signs. There's some great signs from IGT from Light & Wonder. The sign packages are really good. And that's where I led my signage to go to, was towards my lease games and it just freed up my capital to buy more slot machines. I was spending a quarter million dollars a year on slot signage, and I think I'd rather have 10 slot machines now than a couple more slot signs on the floor.
    And that's where I transitioned to. The slot signage for the floor is really expensive. The machines are really tall now. I remember a few years back going to G2E and they asked me what I was looking for and I said, "Any machine under six foot tall," because everything now is bigger and it's all 49 inch screens or 65 inch screens and they've all got toppers. And so they're all massive machines and sight lines are just... It used to be you tried to put all these big machines along the walls. Well, you don't have enough walls anymore, some just no walls. And I look at some of the really nice casino floors like in Vegas, and I always point to somebody like Wynn where they have no walls and they do such a great job of keeping their sight lines. And a lot of that is making sure that your signage is placed properly and it's minimal. So if I had a choice, unless your slot CapEx budget is really, really good, I would pair back on the sign and buy more slot machines.
    Nick Hogan:
    Okay, well, we'll see, since you and I have split views on this, we'll see if we can muster up a third view here, which leads us to discussion of today's guest. So this gentleman has held a number of prominent executive roles throughout his career, including a regional sales VP at Mikohn/Progressive Gaming, CEO at Aruze Gaming America, SVP at Ainsworth Game Technology, CEO at GSL Gaming Group, SVP of Sales and Marketing at Play Synergy. And as of late September of this year, chief operating officer at the newly created Aruze Gaming Global. So I'm referring of course to Mr. Kelcey Allison, Kelcey, what's cracking ke-mo sah-bee?
    Kelcey Allison:
    It has been a wonderful, very quick year. I feel like I'm actually in the Formula 1 race as a driver going 300 miles an hour over the last 10 months. It's been absolutely crazy. But thank you for having me on this podcast, very honored and excited to be on. I would love to interject my opinion on signage. As you know Nick-
    Nick Hogan:
    Let's hear it.
    Kelcey Allison:
    Yeah, I was in the, I call it the merchandising, as you well said, for over a decade in the '90s. So I give it a completely different opinion in both. So if I-
    Nick Hogan:
    Let's have it. So just to orient people, so Kelcey and I share some common DNA in that we really broke into the industry through Mikohn. So that was your first industry gig, was it mot?
    Kelcey Allison:
    That was it, yes.
    Nick Hogan:
    Yeah, mine too.
    Kelcey Allison:
    In the '90s. Yeah, started in the '90s in Mississippi. So I was actually having a conversation the other day with a pretty prolific operator here on the strip, and I said, as you have as well, Nick and Don, have been in hundreds and hundreds of casinos, hundreds. And the only way I could tell a casino from a different casino is the carpet and the trash cans, truly. Because we've gotten away from actually doing what we intended to do is have that theme correspond with where I'm at when actually, as Don mentioned, did a very great job of actually theming the casino too. When you're in the Wynn, you know you're in the Wynn. You don't feel like you're in a different facility. And they've actually, I was actually there a couple of weeks ago and looking around, and they've actually adopted this new wave of creating this merchandising around the banks and eliminating a lot of the lease and participation signs because they all look the same.
    So I come from a different perspective to say the only difference between A, B, C and casino is really the merchandising and what is the immersive feel inside the casino. They all have the same slot machines, they now all have the same sign packages from Light & Wonder, or Lightning Link or whatever. So my opinion is that I still, because it's still in my DNA as yours, Nick, is that signage or merchandising is really, really important to distinguish and identify the different brands that you're trying to achieve.
    Nick Hogan:
    And that's really my feeling, especially on the... And I am still, maybe I'm a bit old-fashioned now, but on the informational signage and atmospheric signage, I am completely with you. I think it can really distinguish an interior, and there are certain sign packages. There was one in the '90s that was Monte Carlo down on the strip. I don't know if you remember that sign package. It was a beautiful sign package. It just really helped distinguish that interior and stuff. So indeed, there are these cases and I hear what you're saying and sometimes I wonder if it's just I'm too close to it emotionally or something.
    Kelcey Allison:
    Right, me too, me too. Yeah, I agree with that. I agree with that. That was our foundation of gaming, is nice and raise the win per days on these banks. And I totally agree with you, Nick, that you can't slap a merchandising package on a dog and expect it to-
    Nick Hogan:
    Yeah, that doesn't work.
    Kelcey Allison:
    ... turn out a better ROI. It's just not going to happen, right?
    Nick Hogan:
    Yeah, and it's crazy how often you run into that idea where somebody tries to pull that off and it's just, no, it doesn't fly. Okay, well, cool.
    Kelcey Allison:
    On the other hand, I would like to say this though, Nick, on the other hand, the manufacturers have done a prolific job of really merchandising the lease and participation.
    Nick Hogan:
    No doubt.
    Kelcey Allison:
    Without a doubt. Some of the packages that I've seen, I was just at a casino the other day in Southern California, and I don't know if I can mention the name, but it was a monster series, and I walked up to this package and I went, "Wow, they nailed it." Absolutely nailed it. Floor to ceiling, absolutely gorgeous. It was like a moth to a flame. I just have to say it. So yeah, the manufacturers are intensifying their game for sure.
    Nick Hogan:
    Yeah. Yeah. Okay, so very good. So let's see. So Kelcey, the last time that you and I spent some time together, I guess it was at Iga in San Diego back in March, we went and had a drink after one of the show days, and you brought me up to speed on Play Synergy and what the company is all about. So before we get into Aruze and everything, can you just walk us through Play Synergy, tell us a bit about the company, its origins, what you guys are up to, et cetera.
    Kelcey Allison:
    Well, originally the company is called Empire Technological Group. They were founded by a gentleman named Frank Feng, F-E-N-G, and around 2012, 2013, as really just a table games provider and a table games technology provider, and really more focused, not in the class three environment, but really in the card room environment in California and some other states. So they started off with really inventing technology to boost that wooden felt. So their first go was a Baccarat trend board, which was been massively successful for Empire. Then they moved into analytics, which was quite interesting doing analytics on paper cards and wooden felt. You and I actually dove into that a little bit back in our lives, a couple or decade, I'll say a decade ago, to make it sound-
    Nick Hogan:
    Plural.
    Kelcey Allison:
    A decade ago. But Frank invented and did a wonderful job of this analytic program that actually counted how much money was coming in on the poker tables and what we call California tables.
    And they were doing technology that was just absolutely incredible. So it was not a competitor, but complimentary to a VizExplorer, a quick custom intelligence, but specifically for [inaudible 00:22:00]. And it took off, it blew up. People wanted that data. They craved the data because everything else was done 48 hours after the fact. So I didn't know I had a player there or this table was hot. We had no idea. So Frank invented this, did very well. Around 2018, I actually had my own company, which was called GSL Gaming. We were doing consulting and other projects throughout North America, and Frank and I teamed up. He said, "Hey, we want to get into the slot machine business." And I said, "Whoa, okay, well, getting in the slot machine business is opening a winery. You have to spend 3 million to make a million."
    Nick Hogan:
    Exactly.
    Kelcey Allison:
    [inaudible 00:22:43] want to do this. And he was teamed up at the time with a company called LT Game out of Macau. And so they were in conjunction together for a couple of years and then they split apart and I continue to do consulting with Frank. And then basically around 2021, we really decided, "Hey, we want to get into the slot business. It's a unique business. We have some really good ideas that are differential from other competitors." And we started to team up late 2022 with a company called Aruze, which ironically I was the former CEO, so I had some really good relations with Aruze at the time.
    So we actually signed a couple of deals with Aruze back in 2022, late Q4 of 2022 with them doing class two games on our box that we had. We had a very low value box, and it just fit the market at the time, still does. And so we signed a deal that we would use their content on our box for class two. They were also having trouble getting licensed, obviously as the tabloids and all the media knows that with their current owner at the time was having a lot of trouble getting licensed. So we signed another deal at the end of December of last year to put their content on our boxes in jurisdictions they couldn't get approved in. Then bam, February comes around. We had no idea that they were going to file bankruptcy, and I'll let you spearhead that, but that's where Play Synergy started, where Empire started. That's our life. Very short in the industry so far, but a pretty meteoric rise, I would say over the last four or five years.
    Nick Hogan:
    Yeah. Okay. And then, as you mentioned, it started bubbling up in, I think it was late '22. There was a report out indeed that this law firm in Denver had secured a $27 million garnishment judgment against Kazuo Okada. I think it was based on legal fees related to his Steve Wynn battle as I recall.
    Kelcey Allison:
    That's how I understand it, yeah.
    Nick Hogan:
    And then next thing we knew, we heard about Chapter 11 filing, and then, yeah, take us from there.
    Kelcey Allison:
    Yeah, we were caught off guard for sure on that one. We got a notice as everybody else did, that this law firm, they were actually out of Chicago, the law firm out of Chicago, and the garnishment was for 64 million. Well, the lawsuit was for 64 million. The garnishment through the circuit of appeals court was for 27 million. And because there was so much baggage behind the whole ownership of Aruze and not paying and just a black cloud that the judge said, "Hey, you have three days to basically pay this garnishment," and that's why they filed bankruptcy.
    So I just want to note Nick and Don, that it's important to note that the company was actually a very viable, magnificent company. It had nothing to do with the performance of the company. It had only to do really with that garnishment. And so that was just, it literally, that started the avalanche. February 3rd, they filed, and like I said earlier, we were caught off guard like, "Whoa, okay, what do we do? We have all these resources invested in the class two product. We have resources invested in porting your class three into our box." So Frank and I sat down and said, "Hey, what's the strategy now we're at 180 degree turn here. What do we do?"
    So we started planning and he and I basically said, "Okay, let's give it a shot. Let's just see what happens at the auction." So the auction was in June, it was Frank and I trapped in a room for 18 hours, literally trapped in a room for 18 hours, had no idea what was going on. This and that, attorneys are walking in and we're just like, "Okay, yeah, we'll do this and do that." But at the end of the day, we convened around 9:45 at night, no, I'm sorry, 10:45 at night, exhausted, just mentally drained.
    We made our bid and it was actually in conjunction with the bank group, and that actually won the bid. And throughout the process, we really never wanted to go into the ETG market. That's not our wheelhouse. We didn't want to do it. So it just worked its way through the whole day. That Interblock was bidding on the ETG side, and it was almost like destiny. I know that's cliche to say, but it was just weird how it all worked out. The bank group came in and said, "Hey, we want a credit bid on our product," because they had financed a lot of the machines, so they had collateral on the route that was in North America. So when it was all said and done, Nick, it is actually a quick funny story.
    So we go into the room, they've got the court reporter in and they've got all the attorneys there. There's a million people in the Zoom and there's a million people in the room. Frank and I sit down with our attorney and the auctioneer basically says, "Hey, we're going to do this." And the bank group said, "Inferior." And I went, "Oh man, we lost the deal." So we were walking out of the room, so they concluded the meeting. We walked out of the room and everybody's coming up, hugging Frank and I saying, "Congratulations." And I turned to Frank and I said, "Did we really win?" He said, "I think so."
    That's actually a true story. So we go home and we're just wiped out, but dazed and confused that we just won this massive, literally this has never been done in the gaming industry, so it's such a great story. So I go home, I sit and I actually make me a cocktail, a whiskey, just straight whiskey. I drink the drink, I call Frank at midnight, and I said, "Frank, are you sure we won?" He's like, "I think so."
    Nick Hogan:
    It's still in the same place.
    Kelcey Allison:
    And then literally the next day, Don and Nick, it was just chaos, just absolute chaos. Okay, we did win. So now what do we do? So that's how it went. So from June to, so we closed August 15th through the US bankruptcy courts, the Department of Justice, had to get approved, a lot of work to be done. We had only been licensed in a few jurisdictions in the US so now it's came [inaudible 00:30:15], because there was a lot [inaudible 00:30:16]. But it's been fun, absolutely. I'm in it, so I'm biased, but I think it's one of the best stories of the last 20 years.
    Nick Hogan:
    Oh, for sure.
    Kelcey Allison:
    Of how this all came to fruition.
    Nick Hogan:
    And then, you guys, you must have a ton of regulatory work to do now. Are you, is it-
    Kelcey Allison:
    Oh, amazing-
    Nick Hogan:
    Living it every day.
    Kelcey Allison:
    Yeah, we hired some really good staff. We just recently was approved in Nevada, going through Pennsylvania as you guys well know. It's a privilege, not a right to get licensing.
    Nick Hogan:
    And it is not easy ever.
    Kelcey Allison:
    It's not easy, it's not easy. But we've-
    Nick Hogan:
    Or cheap.
    Kelcey Allison:
    As of today, I think we're at 180 licenses.
    Nick Hogan:
    Great.
    Kelcey Allison:
    Yeah, it's been a wonderful ride. And that's in 60 days, really.
    Nick Hogan:
    Yeah. Incredible, incredible. And I know you guys did a big, big splash at G2E, and so maybe can you walk us through what you guys have planned in... Well, actually before that, we do get a lot of questions about what each company grabbed actually between yourselves and Interblock. So can you walk us through that a bit?
    Kelcey Allison:
    That's a good question. So Interblock and Aruze Gaming Global, have a fantastic partnership, have worked very well together over the last 90 days after the close of the bankruptcy. Great, great bunch of people over there. They've helped us out, we've helped them out, it's just been really good. But basically, their wheelhouse, obviously is their predominant leader in the electronic table games market worldwide. It was only complimentary for them to buy the ETGs, so they purchased all of the assets for electronic table games, including the newest roll to win craps, shoot to win craps, some of the legacy product like the big wheel. And we specifically went after the electronic gaming machines, AKA slot machines. So we own all the slot machine assets, we own all the hardware, they own all the hardware for ETGs. We do some cross licensing back and forth. We own the trademark obviously of Aruze Gaming, and that's pretty much it.
    Nick Hogan:
    And does the operations route, is that with you as well then, on the machines that are-
    Kelcey Allison:
    Yeah. In the deal with the bank group, we purchased all of the route in North America. We also, just to note, we purchased the Australian entity, the South African entity, the European entity, which is based in the Netherlands, and we just opened a branch in Asia, in Philippines and Macau.
    Nick Hogan:
    Okay, cool. So global presence for sure.
    Kelcey Allison:
    Yeah, for sure.
    Nick Hogan:
    Right on. Okay, so yeah, tell us a little bit about what you have planned there then. What do you guys plan in terms of products and service rollouts and whatnot?
    Kelcey Allison:
    Well, I told our group, we have an executive meeting on Monday. So Monday was the 88th day into this whole venture, 88 days, and it feels like almost like 88 years, but 88 days, obviously we debuted pretty strong at G2E and that in itself was an undertaking, as you guys can imagine. We actually historically-
    Nick Hogan:
    Yeah, you had to pull that together quickly.
    Kelcey Allison:
    Very quickly. Historically, Empire has never done G2E. We've always done it at our own facility, saved costs, passed the savings back onto our partners. But we decided it was very important to really make that statement, and I think the state was well-made at G2E and we debuted some of the brand new content, actually overwhelmingly success. We were packed from the start to the last second of Thursday. Usually Thursday-
    Nick Hogan:
    Yeah, we had the same, it was an unbelievable show.
    Kelcey Allison:
    Unbelievable show. So thank goodness that we did it. We had the foresight to actually pony up and do it, but it was definitely... We underestimated the success that we were going to have at G2E, to be honest with you, Nick. We really underestimated. We sold out of our Muso 55 line of product. We literally cannot build them fast enough. Insanity at this point. But yeah, so to answer your question, we debuted a lot of new content in our new box called the Muso 55, which is a beautiful, original, unique looking black box with all this beautiful hex design on the sides. I don't know if you guys had a chance to see it, but-
    Nick Hogan:
    Yeah, we've seen.
    Kelcey Allison:
    We debuted the Muso triple 32 with new content. We also debuted our class two product, which looks very exciting for the tribal nation here in the United States. That was just an absolute smash success, putting the Aruze on class two, that's never been done. We're very excited about that vertical as well.
    Nick Hogan:
    And is that mainly you looking more like Oklahoma class two and these types of markets? Where are you looking really close to? Yeah, okay.
    Kelcey Allison:
    Interestingly, I didn't even know this, but class two is very popular in South Africa, so we're going into that market with class two, going into some of the Mexico market with class two, but predominantly, yes, North America would be our class two. Washington is a huge class two market. Oklahoma is a class two market, Alabama massive class two market with the Port Creek Tribe there.
    Nick Hogan:
    Oh, yes, that is one hell of a venue there, and more.
    Kelcey Allison:
    Huge. So they came by and we're very impressed. So yeah, class two is going to be definitely a game changer for us over the next two weeks.
    Nick Hogan:
    And so moving forward, then you really, but I think less so than Aruze had been with the ETG thing, you guys, you're going to focus more on just slots really strictly?
    Kelcey Allison:
    A hundred percent slots. That's our wheelhouse, we want to stay laser focused on what we do well. We don't do ETGs, we don't do ETGs well, don't really want to do ETGs. We want to stay laser focused on what we do well, so that can translate to better profits for our partners.
    Nick Hogan:
    And it's an interesting range that you'd have then from portfolio perspective too, because you do have... When we were in San Diego, you told me a little bit about, well, we were talking a bit about what we call the core crunch, which is just the prices of core machines going up, and up, and up, and up and up. And you had mentioned that you guys were really looking to get in with a very low cost cabinet. What is the name of that cabinet, Kelcey?
    Kelcey Allison:
    Speed, actually, Speed.
    Nick Hogan:
    Okay. So you're going to fold that [inaudible 00:37:41] then all that stuff. Okay.
    Kelcey Allison:
    Yeah, we're continuing with that. It's our value proposition to our partners. As you and I talked in San Diego that some slot machines are at $30,000 today, and is that really a true partnership? Is it really, is that a true partnership for Don? So we took a different approach and value engineered a box that aesthetically looks beautiful. It could compete with anybody on the floor, but it's literally half the price of a normal box. So we introduced the Speed last April at Iga. We introduced Hyperspeed, which is portrait version, and the retails are literally half of the current ASP in the market. So a lot of the operators are, we got a lot of hugs and we got a lot of death threats.
    Nick Hogan:
    It means you're onto something, man, you get both.
    Don Retzlaff:
    Well, that's a great strategy and it really works. I remember back, oh my gosh, 20 plus years ago, Panami basically the same thing. They came in with a low cost cabinet, which really good titles, and it was really hard to pass up.
    Kelcey Allison:
    Right. It almost takes out the fear of... There's always been a saying back in the day, you never can get fired for buying an IGT. Back when Nick and I were selling merchandising packages to casinos throughout the world, you never can get fired for buying IGT. We want that same feel. We take the risk out. We take the risk out. You could buy a value added machine and with great content and that content. And just to note, Nick and Don, our portfolio of titles for 2024 is at 41 titles that we're going to release, which is unprecedented. Aruze has never done that. Unprecedented on the release of titles too. So we're really-
    Nick Hogan:
    Is that just on the single platform, Kelcey, or are you talking cross-platform, all platforms?
    Kelcey Allison:
    That's just on our Speed Muso line, just on the EGM line.
    Nick Hogan:
    Oh, wow. Okay. Indeed.
    Kelcey Allison:
    Big numbers.
    Don Retzlaff:
    Where are your studios located at?
    Kelcey Allison:
    That's a good question. So the majority of our studio is in Makati, Philippines. We've got nearly 200 people that actually work in that facility. We have a studio in Reno. We have a studio in Tokyo, Japan, and we have a studio in Sydney and a studio here in Las Vegas.
    Nick Hogan:
    And did you pick up those studios as well, Kelcey, when you made the purchase? Okay, so those came along with it, so it's continuity of the teams and whatnot. Okay, great.
    Kelcey Allison:
    We were probably out of the engineering and R&D side, I'd say probably 70% of the personnel we retained.
    Nick Hogan:
    Okay. Wow. That's fantastic. And that's Vegas crew as well, largely retained?
    Kelcey Allison:
    Yeah.
    Nick Hogan:
    Okay. Excellent, excellent.
    Kelcey Allison:
    That's why we had to move into this beautiful facility here. We went from a 22,000 square foot facility to over 90,000 square foot facility. And growing fast.
    Nick Hogan:
    Okay. Lovely, lovely, lovely. And then in terms of development focus, I guess a couple. So what are you guys looking at in terms of the core premium split? Are you planning on building up a pretty decent premium portfolio in addition to the core, or what are you looking at?
    Kelcey Allison:
    So yeah, that's a good question. Thanks for asking. So our core is about 80% of our R&D effort, 20% would be premium. But we look at premium in a different aspect. We try to create our own premium brand. Traditionally, Empire and Aruze have never really done brand slapping by putting brands out, we try to create our own. Like Lightning Link is a very good example.
    Nick Hogan:
    Honestly, Kelcey, there are tons of examples.
    Kelcey Allison:
    Tons of examples, and so we want to create that. For example, our number one hit right now is Firestorm Bull, just absolutely crushing it in the market, right on our Muso 55. So we're going to expand on that line of the firestorm line and create a premium aspect of that.
    Nick Hogan:
    Okay. Yeah. Cool.
    Don Retzlaff:
    Now, Kelcey, we've never met before, but we do have a mutual friend who is a big fan of yours, and a big believer of yours is Tim Warner.
    Kelcey Allison:
    Oh, yes. I love him. Absolutely.
    Don Retzlaff:
    I spoke with Tim this morning and he sung you praises, and I him wanted to say hello. He joined us, but he's on another call at the moment. But I was looking at some of your results, especially I'll say in SEC country on the Muso Curve-55s, the Gong Gong series is doing really well for you very well. Yeah, it's a really good start. You don't need a whole lot of titles, and it looks like they're putting a lot of, especially in the Louisiana area, it's doing really well, doing very well north of six, seven, $800 a day wind per unit, so-
    Kelcey Allison:
    Its doing very well.
    Don Retzlaff:
    It's a great start.
    Kelcey Allison:
    Just happy to have it right. The team, the R&D that we have from what... So we call AGA, the Legacy AGA, we call our new self the acronym is AG2, which is kind of funny. But yeah, the AGA team, just like I said at the beginning of the interview here, that R&D team, because they're so isolated and laser focused, they do a really good job of trying to differentiate our product versus everybody else. You know an Aruze product versus anybody else's product. You know when you walk up, "Okay, that's Aruze." That is for sure.
    Don Retzlaff:
    Absolutely.
    Nick Hogan:
    It's always had a unique look to it, for sure. Remember since those reels with the burning LEDs in them.
    Don Retzlaff:
    [inaudible 00:43:48] on all those.
    Kelcey Allison:
    I was actually the CEO at the time of Aruze when we introduced us to the innovator line. And funny enough, our competitors would come to us at G2E and say, "That's never going to work. You're going to give people epileptic seizures. This is just crazy." But if you look at the trend of what has done over the last decade, everybody's doing it now. It's just like, "Bam," right in your face, right?
    Nick Hogan:
    Yeah, really you could see, I think there was some serious Pachinko DNA in that cabinet. It really felt that way. It really just had that cool Pachinko vibe to it. It really did, for sure.
    Don Retzlaff:
    The innovative series, my success outside of Win Crew Unit was to see how many different vendors copied the math on some of those themes, and you saw some of those bonus rounds two and three years later appear everywhere. I remember playing, I was on a cruise and I'm playing Howling Wolf and some of those innovative lines and had very unique bonus rounds to it, and sure enough, it was a Attack of the Clones two years after that, you started seeing those types of bonus rounds pop up in other people's machines.
    Kelcey Allison:
    Flattering for us. Yes, for sure. For sure.
    Nick Hogan:
    So the way it always goes. Okay, so, well, Kelcey, any events or shows or anything that you guys have here in the near term?
    Kelcey Allison:
    Yeah, we're going to be on your side of the pond here in February, Nick. So we decided to do a booth at ICE, so we're very excited about ICE.
    Nick Hogan:
    Yeah, it's going to be the last hoedown in London.
    Kelcey Allison:
    Yeah. Then we're moving to a whole different deal. Right?
    Don Retzlaff:
    Absolutely.
    Nick Hogan:
    Yeah, it's going to be really interesting to see that. So it's going to be cool.
    Kelcey Allison:
    Yeah, I might have to take a cruise after the show. That'll be fun. But we have ICE coming up. We're very excited about that, working with our South African partners down in Johannesburg and in the Netherlands on getting that all wrapped up. I'll be at ICE, so that'll be fun. Thereafter, we have Iga, which is in Anaheim, again, Disneyland world. So really excited about that. Started to work on some really cool concepts on what we want to do different than just having a booth. We want to do something creative and something unique and fun for our partners. So we're working on Iga, and then obviously we have G2E right around the corner. It's just feels like it's always so quick, right?
    Nick Hogan:
    Yeah, it's November. It's just around the corner.
    Kelcey Allison:
    Again [inaudible 00:46:26].
    Nick Hogan:
    And let's see, do you have tickets for Formula 1 then?
    Kelcey Allison:
    I do not. I actually do not. I have to tell you, just a quick story if you have just-
    Nick Hogan:
    Go ahead.
    Kelcey Allison:
    ... left. But obviously the news outlets have said the tickets have dropped dramatically, right?
    Nick Hogan:
    Yeah.
    Kelcey Allison:
    They started off at 10 grand or millions of dollars, and you get a suite and a view and this and that. So my life partner told me the other day, she said, "Hey, I have a friend of mine's mother that's a big player. I can't say the name of the casino, but big player. They offered her a suite, whatever she wanted for the weekend, for this weekend for Formula 1. And I said, "Will she take it?" She said, "No, she didn't take it," but they keep calling her back daily saying, "Okay, now we want to give you a spa treatment. Now we want to give you a steak dinner." And I'm like, "Wow. How the world has changed over the last 60 days for Formula 1."
    Don Retzlaff:
    Oh, yeah.
    Kelcey Allison:
    And I live in Las Vegas, so unfortunately it's raining today and they have their trials last night. It was raining last night, so we'll see how that turned out. And it's cold, as Don said, it is cold. It's in the 50s in the morning and 50s at night. So with the braking and the tires, it'd be interesting to see how it [inaudible 00:47:44], but excited that it is in Las Vegas, but anxious at the same time. I went to dinner last night, I'm sorry, last Friday on the Strip, and I live in Henderson. It's usually about a 20-minute drive. It took us an hour and 20 minutes to get to the vineyard. I actually-
    Nick Hogan:
    Yeah, I had the same thing. It was like coming in from the airport to Venetian. It took me an hour at G2E to get from the airport to Venetian, and I think probably 20 minutes of that was just sitting still on Koval, not moving it all.
    Kelcey Allison:
    I was going to that venue last Friday night. I was actually in an Uber, and I was in the backseat, and I turned to my girlfriend and I said, "Where are we?" She said, "You're on Koval." I said, "There's no bridges on Koval." So I don't know if you guys know, they built bridges, they built like four bridges, so it truly is unrecognizable.
    Nick Hogan:
    What the hell? They put bridges on.
    Don Retzlaff:
    Yeah, And I believe they're going to try to keep them.
    Kelcey Allison:
    Yeah, it's wild. I looked up, I said, it almost looked like I was in New York City, all the rugged iron and we're underneath the bridge. I said, there's not one bridge in Las Vegas other than on the interstate, right?
    Nick Hogan:
    Yeah, that's it. Flyovers. That's it.
    Kelcey Allison:
    Like a bridge.
    Don Retzlaff:
    I just read an article. I want to say it was yesterday, that they're talking with the transportation department to try to keep at least one or two of them because it's working so well.
    Kelcey Allison:
    It is, it is. Well, right now it is all jammed up, but yeah, but I agree with you, Don. I think they should keep some of the bridges, but unrecognizable. You come to Vegas so much and you're just driving and where you're at. But I literally looked up from my phone. I said, "Where are we? Are we in New York City? Are we in Chicago?" No, nope. We're in Las Vegas now. Formula 1 baby. Crazy, right?
    Nick Hogan:
    Yeah. I'm really curious, but as I said, it's just hard for me to imagine that this will be anything but a grand slam in the end. You work a bit with the folks down in Monaco, and we see what it does in that city to all the results there. It's just nuts. The money in Formula 1 is positively-
    Kelcey Allison:
    Insanity.
    Nick Hogan:
    Bonkers. Yeah, it's crazy.
    Don Retzlaff:
    Just wait for the Super Bowl in a couple of months to.
    Kelcey Allison:
    Yeah, we have the Super Bowl coming three months from now, so, or four months. Well, Vegas is crazy.
    Nick Hogan:
    Yeah, next step it'll be FIFA.
    Kelcey Allison:
    Right?
    Nick Hogan:
    F1 was just a warmup. Be careful what we wish for, right? Very good. Okay. Well, Don, any additional questions for Kelcey today?
    Don Retzlaff:
    No, Kelcey, good luck. Congratulations on winning the bid.
    Kelcey Allison:
    Thank you.
    Nick Hogan:
    Indeed. Yeah, congrats, Kelcey.
    Don Retzlaff:
    Much success. Growing up in the casino industry, you always rooted for the underdogs because you always won that competition. Whether it was a Aruze, or an IT, or an Ainsworth or Konami when they first started, or Blueberry or whoever it is. It's always nice to have that additional banks that you can put on the floor that you know are going to work and not just rely on the big three or the big four.
    Nick Hogan:
    And teams behind them that are just gushing with passion and fevered love of development. It's always great for sure.
    Don Retzlaff:
    And good people. It's always easy to root for good people too. Yeah.
    Kelcey Allison:
    Well, thank you. That means a lot. Truly does, truly does.
    Nick Hogan:
    Okay, well, very good. Well, so Kelcey, thank you so much for giving us time today. I know you're busy there. So how many days are you in now? What's the count? How many days into this? 90 something?
    Kelcey Allison:
    Well, Monday was 88, so today's Thursday. So what is that? We're 91. 91 days in. 91 days in.
    Nick Hogan:
    Man, oh man, oh a man. Okay. Crazy, right?
    Kelcey Allison:
    Crazy.
    Nick Hogan:
    Well, sleep's important. Kelcey get some sleep every once in a while too.
    Kelcey Allison:
    Yeah, it's been definitely a rat race over the last 90 days, but it's been fun. It has actually been an absolute blast.
    Nick Hogan:
    Well, terrific, terrific. Okay, well thank you so much again, Kelcey and Don, and I think that's about it for today. So thanks guys. Awesome.
    Kelcey Allison:
    Thanks. Thanks Nick [inaudible 00:52:11].
    Nick Hogan:
    Thanks Kelcey.
    Kelcey Allison:
    Cheers. Bye-bye guys.
    Nick Hogan:
    Bye.        
    `
};

export default S02E10;
