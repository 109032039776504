import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S02E11 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E11.defaultProps = {
    id: "14213343-s02e11-top-ten-insights-of-2023",
    title: "Top Ten Insights of 2023",
    subTitle: "Season 2, Episode 11",
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/14213343-s02e11-top-ten-insights-of-2023",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e11-top-ten-insights-of-2023/",
    description: "Dive into the latest episode of ReelCast where we explore the top ten insights of 2023, offering valuable perspectives on slot machine management, player preferences, and emerging trends in the gaming industry. Discover practical strategies and forward-thinking approaches to enhance your casino operations.",
    link: "s02e11-top-ten-insights-of-2023",
    keywords: ["casino industry trends", "player behavior insights", "slot machine management", "gaming industry analysis", "future casino strategies"],
    topics: [
        "Innovative strategies for effective slot machine management in 2023",
        "Analyzing player behavior and preferences in the current gaming landscape",
        "The rise and impact of leased games in casinos",
        "Adapting to changing demographics and preferences of casino patrons",
        "Forward-looking insights and practical advice for casino operators"
    ],
    episodeNumber: 11,
    seasonNumber: 2,
    datePublished: "2023-12-29",
    transcript: `
    Nicholas Hogan:
    Good morning, Don. So, we are post-Christmas officially now. Did you have a good one, there in Illinois?
    Don:
    I'm doing good. How about you?
    Nicholas Hogan:
    Well, regrettably, after having managed to evade Covid for the better part of four years, it finally caught me. Went down last Wednesday, and today is my first full afternoon out of bed. So, it was a very quiet Christmas, featuring a lot of sleep.
    Don:
    Oh, boy.
    Nicholas Hogan:
    As we discussed yesterday, I think I'll be taking a bit of a backseat on this episode, as my lungs and my pipes remain plenty torched.
    Did you guys get any snow there in the Midwest, this year?
    Don:
    No, not even close. Christmas Eve night, when I left my daughter's, it was 63 degrees at 10:00 at night.
    Nicholas Hogan:
    Oh, wow. Yeah. Those are not normal winter temperatures for your neck of the woods.
    Don:
    It was actually warmer on Christmas than it was on Halloween.
    Nicholas Hogan:
    Wow. That's an interesting stat. Okay, very good.
    All right. But hopefully you did get some downtime, managed to jam yourself full of all kinds of great food, and all that kind of stuff.
    Don:
    I did. It's always nice to eat around the holidays. The food's always good. And it's always good to see the family, and share some smiles and some love.
    Nicholas Hogan:
    Yeah, indeed.
    Don:
    Always a good time of year.
    Nicholas Hogan:
    Agreed, agreed.
    Don:
    Thanks for the Christmas card, by the way.
    Nicholas Hogan:
    Oh, you're welcome. You're welcome. I had some reports that those were coming in very late, so I'm glad to hear you got yours.
    Don:
    Yep, got ours. Thank you, man.
    Nicholas Hogan:
    Good, good, good.
    Okay, so listener questions. We have two of them this month. Before I dive in, let me say that we'd love to tackle any questions that anybody listening may have. If you have a question about what we're presenting, or something you'd like us to present, please drop us an email at reelcast@realmetrics.com. Again, that's REELcast@realmetrics.com.
    Our policy is to keep all questions anonymous, so please speak directly, and don't worry about us revealing your identity. That is not something we do.
    Okay. The first comes from a general manager in the Midwestern US, and it's a great question. Rather than reading it verbatim, though, I'll just sort of paraphrase it. He's a relatively new GM, and he has a major issue with low ceilings. And as the heights of cabinets have grown ever skyward, the problem has become so acute that he's actually been granted budget to raise the ceilings, venue wide. Talk about a construction project. Wow.
    Now, as one might imagine, this is pretty complicated. First, there are a ton of considerations regarding what's already in those ceilings. You have sprinklers and other life safety equipment, hundreds of surveillance cameras, networking equipment cabling, all your ventilation ducts, et cetera. In fact, there's so much going on in there that he's really struggling to get contractors on board with his vision. So, now he's wondering, what should he do? Should he dial the whole vision back? Or should he first focus on some preliminary mitigations, and see how those go? Just generally, he's looking for some ideas.
    So Don, as a guy who struggled with this very topic on a daily basis for many years on end, I think you're pretty much perfectly situated to tackle this one.
    Don:
    Yeah. This is a pain point. I'm having flashbacks, now.
    Yeah. I spent nearly 30 years on a multi-floor riverboat casino. It had very low ceilings. I could reach up and touch a couple of them. I'm not that super tall.
    Nicholas Hogan:
    Wow.
    Don:
    But I completely understand the problem. I only had a couple areas where I could place anything like a double arc, or a helix tower. You end up being very careful on how you select your games, sign packages, slot toppers, all that type of stuff.
    It boils down, you probably have three options that I can think of. I did all three of them to get the taller machines on the floor. The first was to take over any area that had taller ceilings that wasn't already part of your slot floor. We had a casino host area that had really tall ceilings, so I relocated the casino host area to another spot on the floor, and used that for slot machines.
    Nicholas Hogan:
    Sure, that makes sense.
    Don:
    Right. There was another place that was a deli, another guest services area. Take that walk on your floor, scout it, and see if there's some opportunities to relocate things. That's probably the first cheaper option.
    Nicholas Hogan:
    Mm-hmm.
    Don:
    The second one was, look for areas that had drop ceilings, or easily removed low-hanging decorations.
    Nicholas Hogan:
    Mm-hmm.
    Don:
    I was able to raise the ceiling by a couple feet by getting rid of some drop ceilings in the center section of one of my decks. And the only reason I didn't get the whole thing was because of the duct work.
    Nicholas Hogan:
    Yeah.
    Don:
    Once the duct work got in my way, I wasn't going to mess with that, but I was able to increase the height on about 50% of that floor, just by doing that.
    Nicholas Hogan:
    Yep.
    Don:
    And then, the third way is to do what you're doing now, is raise the space. It is a challenge. Like you said, Nick, there's a lot of electrical surveillance, lighting, duct work. There's all sorts of stuff, up there.
    Nicholas Hogan:
    Mm-hmm.
    Don:
    Surveillance probably shouldn't be an issue for you. Because the surveillance people are usually really good about finding areas where they can put their cameras, and all that type of stuff. But it is a lot of time, a lot of research, a lot of different vendors.
    Relocating the duct work was something I found quite challenging, so I didn't do that. There were areas where I gutted everything but the duct work, painted the duct black, and just installed lighting underneath it. And that gave us more ceiling height. Gives you a little bit of a warehouse look, but if you're not bothered by that, that's another way to go. That way, I was able to create some more space, also. Of course, the key is how much CapEx you have, and is it really worth the time and the effort and the floor disruption, to remodel. Low ceilings are not a problem that's easily overcome. I tended to flex my muscles a little bit, and take over those areas where I could do it. And that usually ended up being the best choice.
    Nicholas Hogan:
    Well, luckily, it seems to be an architectural trend that's really gone away. Because I noticed there was always that. And the other thing, as you saw with so many new openings is, they'd always do these really ornate ceilings around the table pits. And then, as that stuff started moving around, suddenly you have a slot floor standing underneath this ornate ceiling for a table pit. A lot of these things have really changed. But mercy sakes, some of these, indeed you have this where, a lot of the new stuff coming out, you simply cannot stick that stuff in there.
    Don:
    No, you can't.
    Over the last 15 years or so, I think I've said this on a previous podcast, we had a meeting once before a G2E, and they asked me what I was looking for. And I said, "Any machine under six foot tall." And sight lines on the casino floors, signage, and everything else, used to try to put all that big stuff against the walls, around pillars. Now everything's large.
    Nicholas Hogan:
    Yep.
    Don:
    And I feel bad for all the slot VPs and directors out there trying to keep their sight lines decent. Because it is quite the challenge, right now.
    Nicholas Hogan:
    Yeah. And we don't see any indication that that trend is going to be changing, anytime soon, in terms of the form factors and the sizes.
    Don:
    No. They get taller, they get wider, every year.
    Nicholas Hogan:
    Yep, yep. For sure.
    Okay, great. Well, thanks again to that listener for that question. Very interesting question. Very interesting challenge, there. So, thanks again.
    Okay. Our second question comes from an operator in California who asks, "Hey, guys. Love the podcast. Would you be up for doing another Top Insights of the Year episode, to wrap up 2023? The simple answer there is, "yes." We've compiled the list.
    And Don, I think as we discussed, perhaps I'll just introduce them one by one. And you can provide the commentary here today while I rest my scorched out lungs.
    Don:
    Sounds good.
    Nicholas Hogan:
    All right.
    Okay. We have 2023 Top 10 Insights.
    And we haven't sorted these for dramatic effect, or anything. We're just running through them, this year.
    Number One. Increasing player revenue is not always about increased reinvestment. It's about giving players the experiences they want.
    Don, you want to jump into that one?
    Don:
    We now have run experiments for 18 months on casino floors. And we've seen the best results by increasing their player experience by giving the customers what games they want to play in more volume. And it has really been eyeopening. The results that we've come up with and we've seen have just been, they've been remarkable. They've been above what we expected.
    And it's all about getting the customers to come back to your casino, not to share their wallet with somebody else. And it's not about larger offers in their mailbox or more giveaways. It was about giving them something that they enjoy playing, and they come back, and the numbers have just been astounding.
    Nicholas Hogan:
    Yeah. And there's been a lot of interesting, I think, thought that's been out there this year about some of the things that we're doing other than serving up product that people really want. What are we doing with some things like, we talk about real-time marketing promotions and free play programs, and all this kind of stuff. I think Noah Acres wrote a really interesting white paper this year about the amount of free play that's just squandered. And it's not really getting the type of impact that operators want.
    And I think what we really saw that's just been so exciting is how much behavior you can alter, and the magnitude at which you can alter that behavior, simply through inventory composition alone. That has just been such an incredible thing to see. I have to say it. Throughout my career, it's one of the most impressive effects I've seen in altering inventory.
    Don:
    Yeah. It's really been remarkable to see. And it is been with small changes, too. It wasn't massive where, they had to spend millions of dollars of CapEx, or really increase their lease budget. It was with small, a couple dozen machines, that we were able to change. And we targeted your high worth customers, the ones that weren't able to play to their worth. And it's really made a difference on these floors.
    Nicholas Hogan:
    Without question.
    Okay. Onto Number Two. The shift to lease games among host level players continues to accelerate. We've moved from penetration of 50% of the best players to over 75%, over the last couple of years. So, you want to take that one, Don?
    Don:
    Yeah. This is something we just came up with in the last quarter or so, just tracking the host level players, trying to see what has changed on their play patterns. And you see more and more high level players switching to high average bet lease games.
    Nicholas Hogan:
    Mm-hmm.
    Don:
    I remember, years back, when Lightning Link first came out, and we started seeing some of our physical reel post level players switch over to video.
    Nicholas Hogan:
    Yep.
    Don:
    And now, with some of the great lease games that are out there, you're seeing that trend more and more. They're playing up. And like the stats says, we've seen a penetration rate jump from 50% to 75% over the last two years. Now, 3/4 of your host-able players are pounding lease games.
    Nicholas Hogan:
    Okay.
    Don:
    And it's not the wheel of fortune dollars, and $5 anymore. They're pounding Dragon Link, they're pounding Prosperity Link, they're pounding Frankenstein, some of the other games that are out there. They are just hitting these with $4 and $5 average bets.
    Nicholas Hogan:
    Yeah.
    Don:
    And it's really moving the needle.
    Nicholas Hogan:
    Yeah. And that plays seamlessly into the third insight here, which that we see these host level players playing a lot more per trip, but not necessarily playing longer sessions, or more sessions. And really what's going on, those average bets are going way up.
    Don:
    Yeah, they are. We're seeing a lot of casinos where the average bet for your host level players have gone from $3, $3.5 dollars per spend, to $4, $4.5. some have even pushed $5 a spend. If you're seeing a dollar average bet increase, you're getting about $50 more than theoretical per hour, assume at about a 10% hold, and eight and a half spins per minute. These customers are now, on a three-hour trip, bringing another $150 to your bottom line, just because of the increased average bet. And you didn't do anything else except for give them something better to play.
    Nicholas Hogan:
    Right.
    Don:
    It really pays for a lease game pretty quick if you've got one lease player a day that drops another 150 bucks.
    Nicholas Hogan:
    Yeah.
    Don:
    It doesn't take very often. And that's just in three hours of play. And you know your high octane lease games should have a density over 50%. And a lot of them were over 75%. So, you can do the math. 12 hours of playtime at 50 bucks an hour, that really pays for your lease games.
    Nicholas Hogan:
    Oh, yes. Indeed, indeed.
    Okay. And again, connected to that, Number Four, the experiments that we've run with inventory composition, those all continue to turn in very stellar numbers, and incredible. I saw it not in your notes, we're showing an increase of spend between 250 and 400 per month per player in the revenue, for players who jump on the target games that we're recommending.
    Don:
    Yeah. It was really neat. I ran a study running players who played on the games that we recommended versus players who, that still wasn't their cup of tea. And the revenue per month that these two groups were generating was almost equal, within $2, back before we started the experiments. And then, after we put the games on, the line started to diverge.
    Nicholas Hogan:
    Oh, that was a hell of a graph. I remember this graph you showed us, how those two just started splitting apart.
    Don:
    They did. And they just kept splitting. And the gap kept getting wider and wider and wider every month. It went from $10 to $50 to $100 to $200 to $300 to $400. And it's making this big V, if you look at it.
    Nicholas Hogan:
    Yep.
    Don:
    And it's not stopping.
    Nicholas Hogan:
    Yeah.
    Don:
    It's continuing to grow. And we haven't touched these games now in 18 months.
    Nicholas Hogan:
    Yeah.
    Don:
    There's been no additional cost. It's just been organic. And it's happening with not only the host level players, it's also happening with that $150 to $300 ADT player, where we're starting to see a split there. It's not as dramatic, of course, because they don't have the wallets that the host level players do, but it is sizable. It's $100 a month more per player. And you know how many $150 to $300 ADT players are in the database. There's a lot of them.
    Nicholas Hogan:
    Yeah. And anybody who just feels that they're really tapped out with their top players, and all that, I always just point them to that type of graph, and say, "Look. You mix this up a little bit, and you really pay attention to what these folks like, and start catering to that, you're going to see the same thing."
    Don:
    Absolutely.
    Nicholas Hogan:
    Yeah. All right, great.
    Okay. Number Five. On floors where the mix between leased and owned product is static, we're seeing a 6% decline in play on the core games, and a 14% increase in lease games.
    Don:
    It's almost, at every casino we look at, there's some really good owned games out there, but the trend is towards lease game play.
    Nicholas Hogan:
    Definitely.
    Don:
    Even for companies and casinos that have static lease games, the customers are jumping over to lease games. It's probably for a couple reasons. One is the price of the cabinets is so much higher that people are buying less of them.
    Nicholas Hogan:
    Yep.
    Don:
    And you can rotate those lease games out. Is one of the Blocking and Tackling 101s that we always talk about, how to keep the floor fresh.
    Nicholas Hogan:
    Absolutely.
    Don:
    There was some really neat stuff released last year, but the overall trend is, at most casinos I saw, was between a 3% and 8% decline in owned game play when per unit. But the lease games are up 10%, 15%, 20%. The blended average is 14%. And it was every month last year.
    Nicholas Hogan:
    Yeah.
    Don:
    It was really interesting to see. It's something I really haven't looked at a whole lot, but when I was looking at some of the play patterns for the host level players, I was seeing the split.
    And they're playing more. Their average bets are insane on lease games versus core games. And it's really driving that revenue. But it was across the board. You don't see so much on that C-level player, that under $100 theoretical, especially on the new lease games. Their average bet for those games is just too tall for those players. For the B-level players and A-level players, you're definitely seeing a split, that they're playing lease games more and more.
    Nicholas Hogan:
    Yeah.
    Don, does it just feel to you that, in terms of, I know you've been working with a lot of customers on capital budgets, and whatnot, is that just becoming an increasingly complicated exercise as you go through them?
    Don:
    Yes and no.
    The easy part is, since the cost has gotten more per cabinet, they're buying less. So, it's a lot easier to say, "Here's the 30 machines you should buy."
    Nicholas Hogan:
    Sure. You're just buying fewer cabinets.
    Don:
    Exactly. It just made it easy. We'll get into some of it a little bit later in one of them, about some of what really worked in 2023. It's a mix. Some of it's really hard to come up with, but the very cream of the crop, if you're buying less than 50 machines, you should be in pretty good shape.
    Nicholas Hogan:
    I know for sure you said that it's getting a hell of a lot trickier to keep all the vendors happy, being able to handle it out to everybody on the list, and you feel kind of guilty for it, and all that.
    Don:
    I just had this very same conversation last night.
    I've got a really good friend who's a salesperson. And I'd love to give them more games. And in my heart, I can't, because I'm serving the client, and the client's best interest, and I just can't recommend their games at a great volume. Because they're doing well, but they just don't have a very deep library. You can't put a hundred of these games on the floor. You just can't do it.
    Nicholas Hogan:
    Yeah.
    Don:
    We have to do the best we can for our clients. And it makes it really tough. There's some smaller vendors out there that are doing really well, but you just can't buy them in quantity. It's banks of one and two and three, at the most. Yeah. [inaudible 00:20:54]
    Nicholas Hogan:
    What we've really been struggling with, conceptually, is that when we are really looking at how hyper concentrated all this productivity is, both in players and in products, there's a very finite set of preferences that you're going after, at the end of the day, and then, as you would expect, a finite group the product that's catering to those preferences. So, it is indeed difficult.
    Don:
    It is.
    Nicholas Hogan:
    Okay. Let's see.
    Number Six, then. Whoops. This is a bit of a longer one. The shift post-Covid toward high-end players generating the bulk of revenue continues to climb, as we were just discussing.
    Don:
    Right.
    Nicholas Hogan:
    So, 5% of players generating 50% of your revenue, and the bottom 80% generating around 15% of your revenue. You can basically promote to 17% of your player base as they generate 87% of your revenue. The core is really 10% if you leave off the 7% of the player base that are infrequent players. I get where you're going, here. Again, productivity concentration.
    Don:
    Yeah. The key numbers there are, basically 17% of your players are generating 87% of your revenue.
    Nicholas Hogan:
    Yeah.
    Don:
    And I think a lot of casino companies saw that post-Covid, to where they stopped marketing to those under $75 theoretical players.
    Nicholas Hogan:
    Yep.
    Don:
    Because they were still coming in.
    Nicholas Hogan:
    Yeah.
    Don:
    And the growth rate on those players, actually, it's still going up. I was surprised that a lot of those players are spending more money than they did a year ago.
    Nicholas Hogan:
    Mm-hmm.
    Don:
    It's not a ton. They're up maybe 15% or so, 10% in a lot of cases, but the bulk of your revenue is coming from that top 5%, 10% of your customers. And those are the ones that you really need to be thinking about when you're buying slot machines, for two reasons. One, they're the most valuable. And two, your low theoretical players won't play anything that you buy new, anyway.
    Nicholas Hogan:
    Yeah.
    Don:
    They play the classics. They have their favorite games. They will not try your new stuff. They may try it, but they don't go back to it. One is higher average bet, two, the volatility in some of these games is a lot higher than they wish. But they like their games that they've been playing for years.
    Nicholas Hogan:
    Yeah.
    Don:
    Your high-level customers, your host level customers, love the new games.
    Nicholas Hogan:
    Yep.
    Don:
    They love the Buffalo Ascension, they love the Huff N Even More Puff, they love Devil's Lock, all that type of stuff they are just can't get enough of. So, when you're picking those games, pick the games for your host level customers, look at those average bets, look at the cost to cover. All those types of things should be factors on your 2024 purchases.
    Nicholas Hogan:
    Yep.
    And as we go back to one of the listeners who wrote in, I guess we'd update our things there, and they'd say, "If 17% of the players are generating 87% of the revenue, you spend 87% of your budget on 17% of the players."
    Don:
    Yep, exactly. And you can make a case that you should spend all of it. You should spend all of your budget on your host level players, or your players that have an ADT above $300. Because the under 150, under 100, are going to play the stuff that you already have on the floor.
    Nicholas Hogan:
    Yeah.
    Don:
    It's just that simple.
    Nicholas Hogan:
    Okay.
    All right. Next one up. Pretty straightforward one. Smaller banks and single theme banks continue to outperform larger banks, six or more machines, three or more themes. You want to nail this one, Don?
    Don:
    Yeah. It's a continuation. We updated that study. And I don't even know why we did this study in the first place. It was just something that popped out. But we looked at all the single theme banks, trying to get the bank sizes smaller. And then looking at them. And we've had really good success, especially with the smaller vendors.
    I was talking to one last week, and that's what they're doing. Banks of four, triangles of three, single themes. And their win per unit and their average against house has just exploded. Just basically putting the cream of the crop on in quantity, which is something we've been preaching now for years, is making sure you have the best games, and have a lot of them out there. Because those the ones that people like.
    Nicholas Hogan:
    Yeah, actually Don, I think that study came from, I think it was from a smaller vendor. That's where that started, when they were asking us what they should be putting out. And we had said, "Yeah, okay. This idea of doing a six-pack with two themes, one is a really solid theme, it's doing well. The other one is just, we say, stop this.
    What you want to do is, you put it out there, single theme, really get that thing punched in and performing really well, and then expand basically from there. I know several of them who listened to us and did that, and they're liking it. They're seeing it's working out that way, just cutting the title count a bit, and just focusing on really delivering performance on a bank.
    Don:
    It's tough for a smaller vendor to sell machines when you have games that are doing below house average on those banks. You have to fix those first. Well, what happens when you don't have anything to fix?
    Nicholas Hogan:
    Yeah.
    Don:
    You've got the confidence in the casino. And the casino can say, "Okay. These games are still doing one and a half times house average. Let's get more."
    Nicholas Hogan:
    Yeah.
    Don:
    And it wasn't just the smaller ones. Because I remember doing this study, and I did him an alphabetical order, by vendor. And I got to the Aristocrat games, and the same thing was happening with Aristocrat. This was before the Neptune singles came out. And I was looking at some of the Mars X product. And I was looking at single-theme banks at certain casinos. And the win per unit on those single-theme banks really outperformed the ones with multiple themes. And those were really good. These were all doing over one and a half times house average. But there was still a difference in single-theme banks. And it remains to this day.
    Nicholas Hogan:
    Yeah. If it's hitting, there's a reason that it's hitting. And then your challenge becomes, provide sufficient capacity. And that seems to be a way more important focus than getting another title out there, and trying to build another title.
    Don:
    Absolutely.
    Nicholas Hogan:
    All right. Cool.
    Let's see. Number Eight. This is an interesting one. There is a release date split on performance for owned games and leased games. Most of the best leased games have been out for two plus years, and very few of the best owned games have been out for two or more years.
    Outside of some of the great Aristocrat titles, all the owned games that are over 1.5 x housed have a release date within the past two years, only a couple of themes released in 2023 crack the top 25 themes in leased game performance.
    Don:
    Yeah. I was a little surprised on that, because there's been so many good games released. But when I started looking at it by date range, everything in the top 25, outside of stuff like Buffalo Gold Collection, on the owned games was all brand new releases.
    Nicholas Hogan:
    Okay.
    Don:
    Which tells you something.
    Nicholas Hogan:
    Yep.
    Don:
    That they've got a year, two years, to stay in the top. And then, they get replaced by something else.
    On the other hand, the really good lease games have a really long life. I know the manufacturers love that. They can put it on their floor and forget about it, and take their 50, 60 bucks a day. But those lease games that have been out for years still have legs. And the line on them is just flat.
    If they were doing three and a half times house average three years ago, they're still doing three and a half times house average. And we've talked about it before, about the natural decay in owned game themes, where they fall off 10% per year. And we're continuing to see that. There's some really good games out there, but you really should expect a 10% decline in them year after year.
    Nicholas Hogan:
    Yep. Yeah. It does feel to me as though it's getting a little bit harder to justify those core purchases, but I dare not say that with any degree of definition. But it is indeed the case. We're just seeing that huge performance gap. And it just sits there, and stays put.
    Don:
    Mm-hmm.
    Nicholas Hogan:
    Okay.
    Number Nine. Let's see. Of the non big-three manufacturers, there are some solid themes released in 2023. Zitro, Bluberi, KONAMI, AGS, Aruze, all have games generating well over a 1.5 X house. Looking at these manufacturers for small bank purchases could produce some nice results. Single theme banks, multidenom, if you can, as well, impacts of four should produce and ease some of the long-term purchase anxiety.
    Don:
    Yeah. We still hear casinos say, "I really don't want to spend all my money on one manufacturer or two manufacturers." If you've got the CapEx, you can definitely buy from most, if not all of these, in small banks, and they'll produce for you.
    Nicholas Hogan:
    Yeah.
    Don:
    Go with that single theme, use their best product. And all of these companies have games that are doing really well. And almost all of them also have a decent library coming out in 2024.
    Nicholas Hogan:
    Mm-hmm.
    Don:
    They've got some momentum, they're selling some boxes. Thankfully, some of these companies that have been in some slumps for a while are starting to come out of it. Competition's really good for casinos, with bulk pricing. And it gives you some options that you don't have to worry about spending all your money on one vendor. Things actually started turning around in 2023 for some of these companies, where I was getting really worried that between consolidation and bankruptcies, and everything else, that we'd have less and less choices for the casinos. But I think it finally turned around in 2023.
    Nicholas Hogan:
    Yep.
    Okay, cool. This brings us to Number Ten, Don. There is a difference in play metrics between the 45 and under 45-year-old players.
    Let's see. Brian Christopher effect, or parlay effect, or just willing to take more risks, the under-45 players are chasing progressives bonus rounds with larger average bets and shorter session times. They'll pool their play and shoot for the W2Gs. They also play a lot more slots than people think, 80% of the theo from females under 45 is on slots, 60% of the theo of men under 45 is on slots.
    I know, Don, I was there when you came out with a lot of these metrics on the younger folks. And I think you were saying that, was it the under-30s or something that were spending most of their wallet just chasing those jackpots on high-end premium? Do I have the stat right, there?
    Don:
    Yeah, it was. A lot of-
    Nicholas Hogan:
    They're higher than any other age bracket, as I recall.
    Don:
    The younger the demographics, the more they spend on premium games, which was totally wild. I just didn't expect that. I actually ran it by every age group. And of course, the over-75 age group played the least on lease games. But that now [inaudible 00:33:13].
    Nicholas Hogan:
    Now, Don, are these in absolute terms? Are you saying that for their group they spend more?
    Don:
    As a percentage of their wallet.
    Nicholas Hogan:
    Of wallet? Okay.
    Don:
    They spend more money on lease games than any other age bracket.
    Nicholas Hogan:
    Mm-hmm.
    Don:
    The under-45 spend the most money on lease games than anybody else, as far as percentage of their wallet. And they're playing higher average bets. They have lower session times.
    It all started with, "What games do you put outside your sports book?"
    Nicholas Hogan:
    Mm-hmm.
    Don:
    With the sports books, drawing a little bit younger demographic, people were struggling, "What do I put outside the sports book?" So, we started looking at it, what are they playing? And it was surprising. We talked to a couple of different chief marketing officers out there, that verified our findings that under-45s are playing more slot machines than they expected. Because a lot of them put table games outside of the sports book.
    Nicholas Hogan:
    Yeah, ETGs, as well. That was a big trend.
    Don:
    And the percentage of play on ETGs and video poker by under-45 is extremely small. It's less than [inaudible 00:34:28].
    Nicholas Hogan:
    I seem to recall it was 7% of wallet, combined.
    Don:
    Combined. Exactly. Combined.
    Nicholas Hogan:
    Yeah. Okay.
    Don:
    Yeah. It was basically 4% and 3%. And then, the percentage of physical slots that they play is really small. There are a couple of exceptions. There's a couple of themes that they like. But it fits perfectly with these gambler style games. It's stuff like Top Dollar that they're playing, that's their favorite physical reel.
    But it was both owned and leased, games that had high volatility. They're shooting for those bonus rounds, with high average bets, trying to get that $1000 win, or $600 win, or that W2G win. A lot of people pooling. Seeing that a lot more and more. Matter of fact, I saw a group of men that were probably in their 60s and 70s that pooled their money to try to chase a jackpot.
    But like I said, I call that the Brian Christopher effect. You're seeing a lot of people pool money, chasing it. And you're really seeing it for the under-45. For those of you that are looking for ideas outside of your sports book, Dragon Link, Ultimate Fire Link, Lock It Link, the Lightning Link games, anything that has a really good progressive, if you can do it, swing a dragon cache on your floor, outside of sports book, those games work really, really well with the under-45 crowd.
    I've told you this before, Nick, that it's one of my favorite games to play. And it's always infuriating. Because I walk in and I know I've got no shot of getting on those games because there's a group of six guys-
    Nicholas Hogan:
    It's always young men blocking you.
    Don:
    ... driving me crazy. It's always a group of six young guys. And they've all got $100 bills in their hand, ready to feed the bill validator. But it's really something to look at. Just don't assume that they're under 45 they're going to play table games. It's just not true.
    Nicholas Hogan:
    Yeah. I have to say, there's just so much counterintuitive playing. The average bet thing was the really shocking one for me, to see that. And then, you look at this, just because that age demographic historically has just not really been much on a casino floor. It's just the lack of disposable time, lack of disposable income.
    Don:
    Right.
    Nicholas Hogan:
    And you see these changes. And yeah, I think you're right to really point to the influencers, and look at the information those guys are pumping out. Because it is really all about showing up with an adequate bankroll, betting aggressively, and making sure you're covering lines. And all these things that, well, certainly Brian and his team really encourage all that with their fans, and stuff. And I think it has to be having an effect.
    Don:
    Yeah. I think some of this is coming from, you see a lot of young men that play live poker. And some of those guys are really good at things like bankroll management.
    Nicholas Hogan:
    Yeah.
    Don:
    They know what type of bankroll they need to avoid the risk of ruin.
    Nicholas Hogan:
    Yep. They're disciplined and they're focused.
    Don:
    Exactly. And even though they know that they're playing a 10% hold game, and there's a lot of live poker players that won't go near a slot machine, I'm not assuming that, but there are people who play both. They'll play table games, they'll play live poker. But the sports book has really opened up the casinos to the under 45 crowd.
    I see it when I go. I enjoy the sports books, myself. And you see it. You see them walk from the sports, book either to the bar or to the slot machines. And they play the same way every time. It's really interesting to see it. I think a lot of it is that parlay effect. Instead of betting that one game straight and putting your money on it, they're betting a 16 parlay, and trying to get that $1000 hit or $2000 hit. And it's the same philosophy on the slot machines. They'd rather sit there for five minutes, and pound away, than sit there for an hour and use the drip method.
    Nicholas Hogan:
    Yep, yep, yep.
    Well, in any event, it's been really cool watching this next generation developing, and seeing this. It's the first time I'm watching this occur. And it is pretty cool. We'd see some interesting trends there. And yeah, those behaviors are fascinating, without a doubt.
    Don:
    Yeah, it is. And it looks like it continues to spread with the introduction of sports books across the states. And that's going to be something that we're watching.
    And the other point on that, too, is there's really a difference between the under-30 and the 30 to 45. The under-30 theoreticals are really tiny.
    Nicholas Hogan:
    Yeah.
    Don:
    But the 31 to 45 group is definitely worth looking at.
    Nicholas Hogan:
    Yeah.
    Don:
    Because they have some money. They don't have those starter jobs.
    Nicholas Hogan:
    And some time.
    Don:
    Yeah. They've got time. Right. And they're willing to go out on the weekends. And they're not afraid of the drink prices, and all that type of stuff.
    Nicholas Hogan:
    Yeah.
    Don:
    And they're willing to spend some money. So, that 31 to 45 group is definitely worth looking at.
    Nicholas Hogan:
    Yep. Yep. Great.
    Okie doke, Don. Well, I think we were a little shorter than our average episode length, at about 40 minutes today, but I think we got through some good stuff. And is there anything else you wanted to touch upon before we wrap it today, Don?
    Don:
    No, that's it. Hope everyone has a Happy New Year, a great 2024, and stays healthy and happy.
    Nicholas Hogan:
    Yes. And I'd like to echo the same sentiment.
    Yeah. Happy Holidays to everybody. Here's wishing everybody a happy, healthy, and prosperous '24. And I guess that's it for the two of us for 2023, Don. We'll be back all reloaded with energy and ready to roll in January.
    Don:
    Sounds good.
    Nicholas Hogan:
    We have a good episode to start the year. We won't give any hints yet, but it'll be a good one. Yeah. It'll be good.
    Don:
    Yeah. That'll be [inaudible 00:40:53].
    Nicholas Hogan:
    You just got the invite yesterday. Okay.
    Don:
    I did. Yeah. It's going to be an interesting conversation.
    Nicholas Hogan:
    Indeed, indeed. Okay, cool.
    Well, Don, you have yourself a lovely balance of your day, there, and we'll talk soon.
    Don:
    You take care, Nick.
    Nicholas Hogan:
    You do the same. Thanks, Don.
    Don:
    Bye.
    Nicholas Hogan:
    Bye.`
};

export default S02E11;
