import React from "react";
import ReactGA from "react-ga";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S04E02 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname); 
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S04E02.defaultProps = {
        id: "16874638-s04e02-peninsula-pacific-entertainment-with-aaron-gomes",
        link: "s04e02-peninsula-pacific-entertainment-with-aaron-gomes",
        title: "Peninsula Pacific Entertainment with Aaron Gomes",
        subTitle: "Season 4, Episode 02",
        description: "In this episode, Nick and guest co-host, Lucien Wijsman, speak with P2E President & COO, Aaron Gomes. Hear about growing-up in a prominent gaming family, Aaron's path to his current role, the ins and outs of HHR, and P2E's history, philosophies, current portfolio, and upcoming projects, including the new Cedar Crossing Casino in Cedar Rapids, Iowa. Also in this episode, listener questions and the new ReelHot Index.",
        episodeNumber: 2,
        seasonNumber: 4,
        datePublished: "2025-03-26",
        topics: [
            "Casino branding and market-specific strategies",
            "Player segmentation and floor optimization",
            "Cedar Rapids Casino development journey",
            "Casino property acquisition and development process",
            "Charitable gaming models in New Hampshire",
            "Casino property grand opening strategies",
        ],    
        keywords: [
            "Peninsula Pacific Entertainment, P2E, Aaron Gomes, casino development, historical horse racing, HHR machines, Cedar Crossing Casino Iowa, Revo Casino, New Hampshire gaming, charitable gaming, Dennis Gomes casino executive, casino branding strategy, player segmentation analytics, gaming floor optimization, Iowa casino license, casino acquisition strategy, gaming tax structure, Sioux City Hard Rock Casino, casino technology evolution, Cedar Rapids casino project, Manchester casino development, gaming revenue performance, casino operation management, multi-property casino brands, Iowa Racing and Gaming Commission, Reelhot index, ISR casino metrics, casino licensing process, HHR vs slots performance"
            ],    
        buzzSproutLink:
            "https://www.buzzsprout.com/2057836/16874638-s04e02-peninsula-pacific-entertainment-with-aaron-gomes",
        spotifyLink:
            "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
        applePodcastsLink:
            "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
        iHeartRadioLink:
            "https://iheart.com/podcast/104029260/",
        cdcLink:
            "https://cdcgaming.com/reelmetrics/s04e02-peninsula-pacific-entertainment-with-aaron-gomes",
    
    transcript: `Nick Hogan:
Howdy everyone. Welcome to our second episode of our fourth season. As 
mentioned in last month's episode, my trusty go host, Don Retzloff retired in 
January, and so I've lined up some guest hosts for the next few episodes. My co-
host today is an operational consultant to some of the most powerful groups in 
gaming, founder of the Electronic Gaming Academy, founder, organizer and host, 
the Casino Operations Summit, fellow Leiden resident, and as I realized yesterday, 
the first ever guest on this podcast. I'm speaking of course, about Mr. Lucien 
Wijsman. Hello there, Lucien, how are things with you today?
Lucien Wijsman:
Hi, Nick. Yeah, we're sharing beautiful weather today, aren't we?
Nick Hogan:
Indeed.
Lucien Wijsman:
Which is rare in the beautiful city of Leiden.
Nick Hogan:
Yes, indeed. So Lucien, the big news, at least in the Reelmetrics universe this 
month is that on the 14th of March, we launched a new Reelhot index, and here we
took a very different approach than what the industry is accustomed to seeing in 
performance ranking in that the industrial rankings are broken out by player 
segment with game speed performance indexing and average bets quantified on a 
segment by segment basis. So for anybody who wants to check that out, just head 
over to the Reelmetrics LinkedIn page where you'll find a story covering the new 
Reelhot index and within the first paragraph of that story, link to the index itself. 
So now we received a fair bit of listener email about this, and we'll hit that in a 
second, but as I'm always interested in what you have to say about industry 
developments Lucien, I'm curious to first collect some of your thoughts on this. 
Have you had a chance to look at it and just want to get your thoughts on it?
Lucien Wijsman:
Yeah, actually I did and it came to me through a somewhat unexpected source, but 
nevertheless, it is something that we've been talking about quite a bit. What I find 
when I go to casinos on a Saturday night, I see the biggest players on the floor 
standing behind small players on the most popular games on the floor, and we've 
been going back and forth on this. A player comes to a casino with a bundle of 
money in his wallet, you want to make sure that he has access to product. So the 
way that you are now looking at segments of players and basically making sure 
that you have the product that your top players want to play available for those 
players.
In my book, that's probably the lowest hanging fruit on casino floors. It doesn't go 
lower than that and I think the impact of making sure that you have the right 
amount of game titles for your top segment or top segments, there is a bit of an 
overlap between the two. Making sure that you have enough product available for 
these people in all the casinos that I know in Europe and Africa and Asia is going 
to significantly impact the bottom line of casinos.
Nick Hogan:
Yeah, great. Yeah, and we did get a lot of the same kind of feedback in what we've 
seen and then there was a few questions that were pretty interesting. I'll jump into
one of these. Before I address it, let me just first say that we'd love to tackle any 
questions that anybody listening may have. So if you have a question about what 
we're presenting or something you'd like us to present, please drop us an email at 
reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T at reelmetrics.com. Our 
policy is to keep all questions anonymous, so please speak directly and don't worry 
about us revealing your identity. That is not something we do. Okay, so this one 
comes from a rather big name in casino ops from one of the bigger American multi-
site casino groups and it reads, "Hi guys, first, absolutely love the new index, 
especially the breakouts covering player share versus revenue share.
Internally, this helps me to emphasize two things that most of our slot capital 
should be directed toward the top players groupings and also that we need to 
reduce theme counts on a lot of slot floors. My question is about the ISR values. I 
understand that this measures the themes appeal to your A and B segments and 
that themes with higher ISR values should be installed in greater numbers. In 
terms of getting to the right number of units, are you mainly suggesting occupancy
benchmarks? If so, what are those? Love what you've done here, keep up the great 
work. Okay, so this is a very insightful question and many thanks to the listener for
that. So for anybody listening, the question is about a column in the index called 
ISR, which stands for intersegment resonance, and it measures that titles appeal to
players within the A and B segments.
So the upper segments and the listener who submitted that question pretty much 
nailed it. So the titles with the high and very high ISR designations are titles that 
appeal very strongly to both A and B segment players, and as you're dealing with 
height and demand levels, you'll want to install these in larger numbers so that you
can maximize the capture there, and in answer to your question, occupancy is 
indeed very important in terms of working toward the sweet spots in the saturation
levels. Now, it tends to be pretty variable by property, but as a general guideline, 
you'll want to target mean occupancy levels of 35 to 40% on the core product side 
and in the high-60s to mid-70s for premium. You do need however, to also keep an 
eye on the peak occupancy rates, which can change that calculus a bit. Now, it was
a pretty specific question. I'm not sure I left a lot of room for follow up there, but 
anything you'd like to add in terms of using occupancy to inform unit counts? I 
know you do this plenty yourself.
Lucien Wijsman:
I'll dumb it down a bit because what I notice is sometimes when I listen to the 
podcast, I think, "Whoa, this is very academic."
Nick Hogan:
Yeah, we're too nerdy.
Lucien Wijsman:
And it's sometimes a bit over my head. So you have a very academic way of saying 
something that I do in most casinos, and that is ask the operators, "Can you tell me
which percentage of the total games played is played by the people who play your 
lowest stakes?" And then do the same for the people that I would call medium 
stakes, high stakes, and extreme VIP, high maximum, and in dollar terms, that 
would be anybody playing a bet less than a dollar, a group of people with a bet 
between $1 and $2.50, $2.55 and more than that, depending on where you are, 
these bandwidths could be bigger as well, but what I find typically is that 
somewhere between 70 and 85% of the games played is in the lowest stakes.
So some managers say, "Yeah, but you don't understand my players, I'm on the 
floor. I know my players, I see them." What they see is the low stake player, it's the
80%, 70% of the games played and then you don't see the medium, which is 15% of
your games played, the high, which is 10%, and the extreme, which is between two
and 5% of the games played, but that's all true until you put a column total bet 
next to it, handle coin in whatever you want to call it, and then what you find is 
that these 70% of the games played with the lowest bet produces 18% of your coin 
in or total bid, and therefore 18% of your theoretical result. The theoretical result 
being produced by the medium and high stakes is so much bigger, and most 
casinos have a room where everybody can play. What you see is what you get and 
they have a VIP room
And they don't really differentiate between small, medium, and high stakes. They 
have the room and the VIP room, and I think in most casinos, if they would look at 
what you are now saying, and that is make product available for your segments 
that are not necessarily inside the VIP room. Make product available for the 
segments where the money comes from, not the games played, where the money 
comes from. Then either you have to dramatically increase the number of your 
most popular product, or you have to say, "Well, for starters, until we have more of
it, maybe we should make some of it available only with higher credit values." So it
can't be blocked by people from the low stakes area.
Nick Hogan:
Yeah, excellent point. Backing them off, keeping the exclusivity there, just really by
kind of pricing them out a bit more.
Lucien Wijsman:
Correct.
Nick Hogan:
Yeah. Okay, gotcha. Okay, Lucien, the time has come to introduce today's guest 
and I know you're like me, and that you'll always enjoy the company of smart 
experience, creative, and high energy people and this gentleman most certainly 
ticks each of those boxes. He's a graduate of both the University of Pennsylvania 
and Drexel University and is a 25-year industry veteran who started in the industry
as a valet attendant at the Trop in Atlantic City. So I'm not sure that casino street 
cred gets any more legitimate than that. Since then, he's held managerial and 
executive roles with MGM Resorts, Indiana Live Resorts, Casino Hotel, Australia's 
Star Entertainment Group, and his own developmental and operational group, 
Gomes Gaming. These days he serves as President and Chief Operating Officer of  
Peninsula Pacific Entertainment or P2E. I'm referring of course to Mr. Aaron 
Gomes. So good morning, Aaron. How are things in your neck of the woods today?
Aaron Gomes:
Good morning, they're great. Thanks for having me. I think I'm a little earlier over 
here in Richmond, Virginia, but honored to be on and look forward to chatting with
you guys.
Nick Hogan:
All right, great man. It's really good to have you here. So just to kick off here, of 
course your surname Gomes is one that's I think familiar to many in the American 
gaming industry as your dad, the late Dennis Gomes was for many decades a very 
prominent and hugely respected gaming executive. So can you give us a sense of 
what it was like growing up around the gaming business and really when it was 
that you decided that this was a business for you?
Aaron Gomes:
Sure, absolutely. So I have to say I have what I would consider the greatest 
upbringing and great parents. It was definitely a very interesting, adventurous, 
and different for sure, but I wouldn't trade it for anything in the world. My father 
began his career as head of investigations for the Nevada Gaming Control Board, 
and it was his investigations that he headed that led to the downfall of the mob.
His work while in the commission was a basis for the movie Casino. I remember he
was working at the Taj Mahal at the time, they were working on the movie and 
they sent a couple of the producers out to talk to him and interview him, and he 
gave them a lot of stories and a lot of that got incorporated into the movie. He 
always said once he kicked the mob out of Vegas, he worked himself out of a job, 
but while fighting them, he became one of the top experts in the country on writing
internal controls for the industry. So when New Jersey legalized gaming, he was 
hired by the state there to help write their internal controls and had their licensing
process prior at any of the casinos opening there.
So once the industry was cleaned up, pretty much cleaned up, the larger 
corporations began to invest in industry and it was hard to find top level executives
who were experts and could also get licensed at the time weren't many of them. So
that's when demand for my dad services really started to bloom. So amongst some 
other roles throughout the '80s and '90s, he ran all of Baron Hilton's casinos 
directly for him. Then Steve Wynn hired him to be president of the Golden Nugget 
when that was his only casino in town prior to the Mirage being opened. I 
remember going and meeting the Dolphins there months before the casino opened,
which was a pretty cool experience. From there, Donald Trump lured him away to 
run the Trump Taj Mahal in Atlantic City. What followed was a pretty high profile 
and at times ugly public lawsuit with Wynn suing Donald Trump over my dad's 
services.
So after that last job in the corporate world, he spent a decade plus running the 
Tropicanas between Atlantic City and Las Vegas. So the stories and characters are 
kind of endless from those years. I got to spend times with legends like Baron 
Hilton, Steve Wynn, Jack Binion, Donald Trump, Bobby Baldwin, the Fertittas who 
started station casinos were neighbors of us in Vegas, and if you know the name, 
The Housels, they started Showboat Casinos and we're good family friends, and 
have many fun stories with all of them. We actually lived in the Trump Taj Mahal 
for about a year when we moved to New Jersey.
Nick Hogan:
Oh, wow.
Aaron Gomes:
It was a fun experience. I remember we'd have my lunch brought up to me because
we didn't have a kitchen. So my school lunch got brought up in a brown paper bag 
every day, and I think I was in third grade at the time, and then also I got to go on 
some fun marketing trips with my dad to collect markers from customers in Brazil, 
Venezuela, Portugal, Italy, and in fact have pretty crazy stories from each of them, 
but one of the best was story of a kidnapping of a daughter of one of the customers
that happened while we were in Brazil. It was a good result in the end, but that 
was a story my dad always loved to tell, and stories and experiences are really 
endless, and no matter what I ever achieve, I'll never be able to fill his shoes and 
I'm 100% good with that, and that being said, in my opinion, I don't think anybody 
could fill his shoes.
Nick Hogan:
And so when did you realize you'd been fully bitten by the gaming bug and you 
wanted to pursue the industry would you say?
Aaron Gomes:
Yeah, well as you've heard, my life in casinos really began day one, whether good, 
bad or indifferent. I don't know life outside of the casino business, right. I didn't 
mention my mother who also has ties to the industry as well. She was a trained 
ballet dancer and was an adagio performer and shows all over Las Vegas, including
the Liberace Show on the Strip, and then was the first prima ballerina for the 
Nevada Ballet Theater.
Nick Hogan:
Oh, wow.
Aaron Gomes:
So my friends always joke and say not only were you born into the casinos, but 
likely conceived in them as well. So I tend to not want to think about that one, but 
having only lived between Vegas and Atlantic City for the first 30 years of my life, I
was destined to be in the industry and certainly was not going to be a priest. So I 
always found myself fascinated with it. During summers in high school, I 
remember ordering some used UNLV casino management textbooks from eBay and
read them on vacations, and before our high schools near trip to Atlantis in The 
Bahamas, I read several card counting books and just practiced counting down 
decks nonstop thinking I was going to own the Atlantis before I left, which clearly 
did not happen.
But as you mentioned, first casino job was valet parker at the Tropicana Atlantic 
City. Best summer job in the world. I was just getting ready to go play soccer in the
fall in college, so I used the garage stairs and would sprint up them to get every 
car. I think I had probably double the car returns of anyone else. So made some 
good money as an 18-year-old kid, and then when the preseason in pretty good 
shape,
Nick Hogan:
I did a valet gig myself when I was at University of Iowa. I was a ballet at the 
hospital clinics there, and it was the same for me, absolutely the same. One of the 
big benefits of it is just you're always hustling, so you wanted to make money. So I 
just stayed so fit.
Aaron Gomes:
Exactly, and there's no better train than running up and down garage stairs, five 
floors.
Nick Hogan:
Yeah, exactly and you could drive some cool cars too, right? You get to sample 
everything.
Aaron Gomes:
Absolutely, but as enamored as I was with the industry, I never to really go into it. 
While I was in college, my degree ended up being in history and economics and 
thought I'd end up going into investment banking like a lot of my buddies, but 
when it comes to applying for jobs, I think as most people do, you end up getting 
the most offers based on your personal network and mine seemed to be in the 
casino industry. So I had a couple opportunities in insurance underwriting and a 
good management consulting opportunity, but also a handful of management 
training opportunities in the gaming business. So granted, I think each of the 
casino opportunities paid about 60% less than the other jobs, but in the end, just 
found myself fascinated by the industry, loved the thought of living back in Vegas 
around my family and friends. So I accepted an offer to be part of what was called 
the MAP program. It was a management associate program at MGM Mirage.
Essentially it was a corporate management training program, and it was such a 
cool experience. Some of my best friends from these days were part of that 
program and other executives at the Mirage, and as I mentioned, I was assigned to
the Mirage as part of the program, rest in peace, but after well over a year 
spending time in just about every department across the casino from picking up 
cigarettes for a week or outside the volcano in July, which was not fun to dealing to
weeks on graveyard working the slot floor and everything in between. It really 
gave me a good perspective of the overall operation.
And eventually settled into marketing there. Started off as an analyst, stayed there 
for another several years, and then my father had partnered with the Cordish 
companies and asked me to join his new management company. At the time, 
Cordish had partnered with the owners of a horse track outside of Indianapolis 
called Indiana Downs, and Cordish was the developers. They didn't have any 
experience in gaming operations, so we had the management contract for all their 
current and future projects and then through that partnership, we were also able 
to win the RFPs for casinos in Kansas, which Pin now owns right outside Kansas 
City, and then a Rundle Maryland, which is the live property outside of Baltimore.
So then after we parted ways on that partnership with Cordish, we looked at 
several distressed properties around the country and found one right in our 
backyard. At the time we were living in Atlantic City, so it was a property that had 
been pretty neglected from capital by Colonial Capital. It was Resorts Casino or 
some may know as Resorts, the Merv Griffin property. I think it was the first casino
actually to open in Atlantic City.
Nick Hogan:
I believe so, yeah.
Aaron Gomes:
So we took over that property with a real estate developer from New York City 
named Morris Bailey, and went through a period of renovations and rebranding the
casino into what is today, including partnering with Margaritaville to bring their 
first restaurant to New Jersey, and then in 2012, fortunately had to deal with one 
of the toughest things I've ever faced. That was when my father passed away 
pretty suddenly. He was such a loved figure in Lanark City that the city renamed a 
street after him, a competitor actually put up a bronze memorial to him in their 
property, which is still there to this day, and many of the other casinos took down 
their billboards on the Atlantic City Expressway and put up a memorial to him for 
several days and left that up around the time of his memorial ceremony. So at that 
point, a writer for a newspaper in an interview with me had asked me the question.
He said, "What are the plans for you now that you're thrust in being the youngest 
CEO of Atlantic City Casino ever? I think I was just 29 at the time.
Nick Hogan:
Oh, wow.
Aaron Gomes:
Yeah, my reply was, "Look, I'll never fill his shoes and not sure I can drive down 
the street with his name on it every day for work. It was really fresh and so I said, 
"I'd like to get resorts to a good place, then go work far away for a while." And 
within a week of that article, I got a call from an Australian accent as I answered 
the phone. First thing out of the guy's mouth before he even introduced himself 
was, "I heard you want to move away. Is Australia far enough?" So I obviously 
looked at a map and you really could not get further opposite corners of the world 
and so Larry Mullen, who is previously CEO of Borgata Atlantic City was the CEO 
of a publicly traded company in Australia called Star Entertainment. I had known 
Larry for a long time and that was his recruiter.
He had reached out to me. So I went over there to be president of their Gold Coast 
Casino. At the time it was called Jupiter's and now it's Star Gold Coast. Larry had 
been CEO of the parent company for a couple years, and my job was to plan and 
execute on a 500 million redevelopment of that property, and it was just such a 
great time. My wife and I were just married. We got to travel all over before kids. 
Australian culture there was amazing and to this day, some of our best friends are 
those that we met over there.
But then when we wanted to have kids, we ended up coming home and then within
a few months of giving back, I ended up being asked to sit on a board of another 
publicly traded company over there controlled by a Hong Kong billionaire named 
Tony Fung. So within probably a few weeks of talking to them, they had to let their
CEO go. So they asked me to step in for a year as CEO of the company. So I was 
traveling back and forth from the United States over to Australia constantly for 
that year until we found a new CEO, and then for the last decade now, I joined my 
current company, Peninsula Pacific Entertainment as COO and now president and 
as you mentioned, a lot has happened since then.
Nick Hogan:
Oh, for sure and as a company now, just shifting more to P2E, you guys of course 
have a hugely impressive track record and development having built, operated, 
and in several cases sold several very successful properties. So the Diamond Joe 
Venues in Iowa, del Lago in New York, and Hard Rock, Sioux City to name just four
of those. So for those who are unfamiliar with P2E, can you kind of give us a bit of 
a company history there?
Aaron Gomes:
So Peninsula Pacific Entertainment or P2E as we call it, is and always has been 
privately held company a long history in gaming, but most that are not intimately 
familiar with the casino industry may not know much about us. I like to say that 
we're the chameleons of gaming. P2E isn't a customer-facing brand, and we don't 
like to force a corporate brand into any one of our developments. We go into a 
market and create brands that are market-specific. We listen to the community and
stakeholders and build something that's really best for the community.
Everything from creating our own unique restaurant concepts to the master brand,
we really customize for each of our developments, but back to the company 
history. The company started from one small riverboat acquisition in, I believe it's 
1999 in Dubuque, Iowa. That boat eventually went land-based starting the 
revitalization of the city of Dubuque. Then there was a second Diamond Joe, which 
opened in Worth county Iowa in 2006. Then came Evangeline Downs Racetrack, 
which we built and built a casino associated with that track in Louisiana. Then the 
Amelia Bell also in Louisiana was a casino that we had purchased, and the final 
property for that first version of the company was the Kansas Star in Kansas. It 
was a third commercial casino license there. So that iteration of the company was 
purchased by Boyd Gaming in 2012 for just under one and a half billion dollars, 
and it's at that point that I actually joined the company as COO, and we went on a 
major rebuilding of the company at that point.
We jointly developed a Hard Rock Casino in Sioux City, Iowa, which we ended up 
wholly owning. We won a competitive RFP process for an upstate New York 
Casino, which we branded del Lago Casino and Resort, and almost simultaneously 
at the same time we're working on the del Lago process. We won the RFP for the 
fourth and final commercial license in Kansas, which we named Kansas Crossing. 
It's in a town called Pittsburgh and southeast Kansas. Our most ambitious 
undertaking up to that point was when we purchased the rights to a thoroughbred 
horse track in Central Virginia called Colonial Downs. So it had been shuttered 
since I believe 2014 and was the only horse track ever built in the state. So we 
partnered with the horsemen and were able to successfully lobby for HHR 
machines at the track, and we could also put them at other towns across Virginia 
that had passed peer mutual waging referendums.
Well, really within a year I believe, we opened six locations across Virginia with 
approximately 2,700 games and had several more in the works. We branded those 
HHR facilities as Rosies, and we were the only gaming operator for several years 
until casino legislation, which we were involved with past. So I think we had about 
a three or four year runway as the only games in the state of Virginia. Then in 
November 2022, we sold a majority of the gaming assets to Churchill Downs. That 
was a $2.75 billion transaction. We kept our Kansas Crossing Casino and our rights
to the future casinos that we were developing in New Hampshire. So right now our
company has the Kansas Crossing, five casinos in New Hampshire with six license 
we own, but have not opened yet, and our license in Iowa for Cedar Rapids that we
were just granted or a few weeks ago and many more hopefully to come.
Nick Hogan:
Okay, all right.
Lucien Wijsman:
I was visiting Nick this morning and over coffee, I think he explained me that this 
Cedar Rapids license came with a story. I think a lot of people know parts of it, so 
can you talk me through that a little bit?
Aaron Gomes:
Sure. This goes way back, but It was a pretty unique market as Nick knows well. 
Technically it's a free market, meaning there's no limit on number of casinos with 
19 casinos currently. However, in order to get a license, a group must petition the 
Iowa Racing and Gaming Commission or IRGC. If they accept it and open up the 
process for a license in a specified county or city, then they put out an RFP at that 
point from any casino company can apply, and then they see which project meets 
the criteria that the legislation established really for the issuance of a new license. 
So there's a whole list of criteria from the economic development. It would bring 
the community support that a specific project has, tourism benefits, employment 
opportunities, etc. But the most important one is the net benefit to the state of 
Iowa. So obviously all those criteria have some level of judgment associated and 
subjectivity, but the big one being how much cannibalization of existing casinos is 
acceptable.
For example, if a proposed casino is expected to generate, call it 100 million of 
gaming revenue, but 10 million will come at the cost of the closest competitor. Is 
that too much is a question, then how about 20 million, right? Those type of 
questions are what the IRGC is really tasked on deciding. Commission, there's 
made up of five commissioners appointed by the governor, but it's always changing
based on when and whose term is up on a commission. So we first approached, or 
we were approached by a group of local investors about partnering on a casino 
there over a decade ago.
We had formed a partnership with them and first applied in 2014 with the 
commission not granting the license. Then again in 2017, we applied at a different 
site and lost in a vote of two to three against it, and then a couple of years ago, the 
closest competitor casino called Riverside Casino lobbied the legislation to pass a 
moratorium on issuing any new licenses in the state. So then we had to wait 
patiently with our local partners in the city of Cedar Rapids, having to fight hard in
the legislature to thwart another attempt by the competitor to extend the 
moratorium, and then this year we applied and were granted a license by the 
commission in a four one yes vote.
We had changed the location, really listened to the community on what would 
benefit them the most. Took out the hotel. We put on a large entertainment center 
and a cultural arts center as well, and the result was a greater net new revenue to 
the state than the previous applications, which is why they grant us the license, 
but we've been there for over a decade and I cannot thank enough the city and our 
local partners aren't sticking out with us. We would've not gotten it without their 
unwavering support, and really a day after we were grant the license, we had all 
the plans and the works after a decade of planning. So we broke ground and plan 
opening New Year's Eve 2026 in Cedar Rapids.
Nick Hogan:
Okay, great.
Lucien Wijsman:
Congratulations.
Nick Hogan:
Now, I think this is correct. This is your fourth in P2E, right? Fourth in Iowa?
Aaron Gomes:
Correct, this will be the fourth.
Nick Hogan:
Okay, and as an Iowa native and as you know, I'm originally from Sioux City and I 
love that casino there by the way. I think you guys just absolutely knocked the 
cover off the ball on that place, but obviously you guys have some serious affinity 
for projects in Iowa. So really, what is it about Iowa that keeps you guys coming 
back over and over?
Aaron Gomes:
Well, I think part of it is that we started there in as I mentioned, 1999, so we know 
the state well. We know how things operate, we understand the market. We feel 
like better than anyone else. We understand it and I think we can see opportunities
there before anyone else can. We've always had our offices in Iowa and still do to 
this day. So we've never left since 1999, and the casino industry is so important to 
the Iowa local communities and the not-for-profits that each casino supports. So 
we've never burned a bridge always done right by our stakeholders there, and so it
often starts with those in the local communities reaching out to us.
Saying we love what you've done for Worth county Dubuque or Sioux City, and 
they ask, "How can you help us bring one to our community?" And our reputations 
always open those doors for us, and we've always under-promised and over-
delivered on each and every development we've done in the state. So then Iowa 
has also just been growing at a pretty fast rate. So as it grows, new opportunities 
emerge that maybe weren't opportunities even a few years ago, so I'm sure even 
more will emerge down the road and hopefully we are there to help bring some of 
our developments to future casinos in Iowa.
Nick Hogan:
It always seemed to me like the state, it always seemed to me like it was a good 
kind of public-private partnership there. Even the way they legalized where they 
scheduled in the three referenda to command to vote on it, and then I remember 
they put some kind of clause in there. I don't know if it's still there, but it was a 
mandate on the percentage of goods and services you have to buy from state-based
businesses, right? From local businesses and things.
Aaron Gomes:
Correct. Yeah, they actually have to approve every contract, and you have to prove 
you tried to at least bid out total companies, and obviously there's items that aren't
made in Iowa like the gaming devices, but I think over our tenure running the 
three properties that we no longer own, we were able to source 97% approximately
of our goods and services from Iowa. So yeah, it's great for the local communities 
and really, like you said, a great partnership with the state.
Lucien Wijsman:
Great. Yeah, so that's Iowa and I understand why Nick wanted to ask some 
questions there, being from that state. So when I was there this morning, you also 
rolling out these REVO concepts, historical horse racing, which when Nick 
explained me what that was, we have quite a few people that listen to this podcast,
which are non-American like myself, and then the whole concept behind HHR is I 
don't think you'll find it anywhere else in the world, but I'm somewhat fascinated 
by it. Can you explain me what HHR is? Historical horse racing?
Aaron Gomes:
Yep. Well, I'll start off by just saying that from a player experience perspective 
today, playing an HHR game versus playing a traditional class three slot machine 
is pretty identical from the look of the game, the time on device that players spend,
the volatility, average wager, spends per minute, etc. Really the main differences 
in the non-player facing back end as I think most people listen to the podcast are 
aware with class three game, you're playing against the house where a random 
number generator determines a result after each spin. HHR is similar to class two 
bingo games that are still very present and popular across the country, mostly 
Native American properties, but in a central server determines a result of each 
spin, but unlike bingo class two games, the results of each spin are determined by 
Parimutuel math, which is based on random historical horse races. The origins of 
HHR came from rather conservative states where the track owners knew it would 
be very hard to get slot machines or table game legislation passed.
So they were pretty clever and were able to pass legislation that sounded much 
more palatable. That was live horse racing was already legal, so they just tweaked 
the legislation to not only allow individuals to bet on live horse races, but also 
random pass horse races. The games actually evolved similar to class two bingo as 
well. The original class two games were exactly what they were called electronic 
bingo machines where you had to pick your numbers manually, right? Think of a 
bingo card, a digital bingo card. Then the Bingo games, they sped up with autopick
functions, but you still saw the bingo card and there was little to no other graphics 
on the screen. Then the next versions had a theme with a basic graphics and a 
small bingo card on a part of the screen. Well, eventually the bingo card 
disappeared and was only in the background, and you were playing a machine that 
you would think was just a slot machine, right?
That's where it's evolved to today. In a lot of the Native American properties, it 
was really the same with HHR. The first versions, you actually had to watch the 
entire race of a pass horse race, and it was real grainy video of the real race that 
would pop up on the screen after you placed your bet. Then similar to the bingo 
games, they sped it up, so you only had to watch the last 10 seconds or so, and now
it's an animated result that flashes across the top corner of the top box for less 
than a couple seconds, and nobody really even pays attention to the horse race 
across the top.
But going back only five or six years ago, there were really two manufacturers 
making these games, a company called Xacta, and then another one called 
Paramax. Then with the massive expansion of HHR across Kentucky over the last 
decade, just about every manufacturer is now making games that can plug into the
HHR systems, and these are the same boxes, same things you see in class three 
casinos. We have every large manufacturer represented on our floors, and the 
games play almost identical to the class three versions, and we have the Wheel of 
Fortunes, Monopolys as you name it. We have them all now.
Lucien Wijsman:
And if you can add on, if you go to G2E and you see game titles of which you think, 
"I want those on the floor." What's the delay for the suppliers in making these 
games available for your market?
Aaron Gomes:
That is I would say the only downside with HHR right now, right? It's still not a big 
enough market that they're designing games necessarily, or themes specifically for
HHR. What generally happens is they put out the class three games and they see 
which ones are performing well, and then either on their own or the urging of the 
HHR operators, they then poured over the most successful games into a HHR 
version. So I'd say that process takes a couple months for them to redo the math. 
So we do unfortunately have a delay if a game's popular across the border at a 
state with class three games. We unfortunately can't get it for a few months, but 
once we do get it, it's exactly the same games and plays the same as the class 
three versions, but a lot of times, as Nick knows with his Reelmetrics, the lifespan 
of a successful theme, often I mean sometimes it's not even a few months, right?
So a lot of times, unfortunately, by the time they port over the games, they're not 
as popular as they were prior to them going into development, but I think just to 
give you some more data here on HHR, the only jurisdiction in the country that has
large amounts of both traditional slots and HHR is Virginia. I believe there's 
roughly 3,000 give or take of each type of machine. So despite the slots being 
located only in large-scale casinos with huge capital investments, the average win 
per unit for the slots is only 4% higher than the HHR win per unit. I think it's 
around 451 per unit for slots versus about 430 ish for HHR.
So I think that's proof that there's really not differentiated products anymore, and 
HHR as I walked you through, really started with antiquated machines at Oaklawn 
in Arkansas in early 2000s, but they really didn't take off until, call it 10 years ago 
when they spread through Kentucky, and now you see HHR in Wyoming, Virginia, 
New Hampshire, and I believe the only other state is Kansas just recently last 
government session past HHR in Kansas at one track or one site. I'm not even sure 
if they have a track there. Sorry, go ahead.
Lucien Wijsman:
So these HHR machines that you put on the floor, do they have to be connected to 
a central server where they keep all files from these historical horse races?
Aaron Gomes:
They do, correct. They plug in and it goes back to the central server and literally 
no time at all, and then spits back out the result, which shows up.
Lucien Wijsman:
This central sever, is that from the supplier from whom you buy the machines, or is
there a third party that provides the files where the historical races are?
Aaron Gomes:
Yep, it's a third party. So right now, I mean there's five or six companies that 
create the backend and software, but the two prominent ones, which probably 
control, I'd call it 98 plus percent of the market is Xacta, which was one of the 
original ones. They're now owned by Churchill Downs, and then the other is 
Ainsworth, which is owned by the Ainsworth slot machine manufacturer. So you 
could plug an IGT game, etc., into either system.
Lucien Wijsman:
Cool, so that's a big part of what you do in these Revo casinos, and then I saw the 
website, and you also have table games there, right?
Aaron Gomes:
We do, correct.
Lucien Wijsman:
Are there restrictions on that or is that just what you would see in any other free 
market?
Aaron Gomes:
So the table games, well they had a restriction of minimum bet of $50, or sorry, not
a minimum bet, I wish. They had a maximum bet of $50. However, what the 
regulators allow is for on the same hand of, we'll say blackjack, you can place up to
six bets of $50. So really, it's a minimum bet of call it $300, and on a roulette you 
could place a $5 wager on each number, right? Or sorry, a $10 bet on each 
number. I wouldn't really call it restricted market. It allows us to get a lot higher 
than the 50 minimum bet.
Lucien Wijsman:
And on roulette for sure, it takes away the volatility.
Aaron Gomes:
Exactly.
Lucien Wijsman:
It's actually not so bad as an operator, right?
Aaron Gomes:
Absolutely.
Lucien Wijsman:
So it feels like any casino out there. On the machines, you wouldn't see the 
difference and on the tables.
Aaron Gomes:
And we even have sports books there. It's on our property.
Nick Hogan:
Nathan, can you walk us through, so with the Revo venues, can you walk us 
through just some facts and figures on those? How many are running now, what 
they look like in terms of the operational footprint, that kind of thing?
Aaron Gomes:
Sure, so New Hampshire's an interesting market. I won't get into the details of the 
legislation. However, they're around 15 casinos there, and by legislation, the 
casinos can't move out of the city, which they were located when the legislation for
HHR passed. So we have five properties opened and one additional license still to 
build out. The model there is called, it's technically Charitable Gaming. So kind of 
similar but different to Iowa, but a large percentage of tables and HHR taxes go 
towards not-for-profit organizations that partner with the casinos, but other than 
that as you mentioned, full assortment of table games, machines, but we picked the
name Revo as a nod to New Hampshire's crucial role in the American Revolution, 
but more importantly, Revo, which is short for Revolution is a place to go to 
celebrate that rebel or rebellious attitude that each of us has or wants to think that
we have.
But we love the name and how it really fits the attitude of those that live up there. 
If you've been up there, they really embrace the state motto of, "Live Free or Die." 
Each of the locations has live entertainment on most nights. We have a lively bar 
scene assortment of fun vintage arcade games, and our signature rebels restaurant
concept at each location, which serves food at all hours. Our full branding is Revo 
Casino and Social House because we want to create a space to be a spot where 
people want to go and just hang out, and if you want to gamble while you're there, 
well, we have a casino too.
We're at various stages of development for each of the six locations. Our licenses 
are in Manchester, Keene, Lebanon, Dover, Conway, which is up in the White 
Mountains, and a town called Berlin. Manchester is the state's largest city by far 
and we're the only license in the city, and that's where we plan on building our 
largest facility, which would more than double the amount of games we have 
installed in New Hampshire to date. We're operating out of an existing building in 
Manchester that we purchased while we finalized those plans, but as of today, we 
have, call it 750 machines and around 85 tables across the five opened Revo 
casinos.
Nick Hogan:
Okay, sorry.
Lucien Wijsman:
I'm always very curious to hear if such a big part of your revenues, the gaming 
revenues go to good causes and everything if you want to share this. So what's the 
breakdown between gaming and non-gaming in these locations?
Aaron Gomes:
Well, I would say a majority of it because we're not built up with the large 
amenities yet that you'd see at a Vegas style resort, right? Eventually the industry 
there may grow to that. A couple recently have opened near the Massachusetts 
border that we don't own that are a thousand plus games, multiple food and 
beverage outlets, and that is our plan for Manchester. So when we get to that 
point, I think it'll shift a lot more to non-gaming revenue, but right now it's a vast 
majority across the state of gaming revenue that each of these licenses are getting,
but the taxes on table games are 45%, so it's one of the highest in the country, and 
of that, charities get 35%, or sorry, they take 35% of the total and the state gets 
10%, and then on HHR machines, it's a 25% tax rate with each charity getting 25%
of that 25% tax rate.
Nick Hogan:
Okay, so pretty significant there. Now, these venues, I guess obviously it sounds 
like they're quite a bit different than what you guys had really done. More on the 
casinos, I have a full service casinos and some cases hotels and things of this 
nature. Obviously, you'd done similar stuff like this. Perhaps with the Rosie's 
venues, maybe they're a little more similar. Can you just walk us through a little bit
how this kind of footprint, how this kind operational model force you to change 
things like operational best practices and infrastructure and things of this nature?
Aaron Gomes:
Absolutely, so as I already mentioned, we develop each of our projects and 
supporting brands for each market we go into. So this is new to New Hampshire. 
However, I think from an operational standpoint, it's almost a duplicate of as you 
mentioned, Nick, what we created in Virginia. In Virginia, we created a shared 
services model with a high-level executive overseeing specific departments each of
the locations, and then a GM in each casino that reports up to shared services. I 
think this model allows us for a consistent experience across all the properties. 
New Hampshire is small state, and each of our locations has at least one other 
Revo within, call it an hour drive. So we want someone who visits us while at their 
beach house to have the same tasting burger and the same friendly guest service 
experience as they do when they're at their main residence in say, Manchester, 
and we get a of crossover play amongst all our properties here because of 
proximity.
Nick Hogan:
Sure.
Aaron Gomes:
Then just as an overall P2E philosophy, which we bring to each of our 
developments is we move fast. In fact, lightning speed. When you compare us to 
some larger casino companies, we've never allowed the bureaucratic practices that
sometimes always permeates through other gaming companies. We've never 
allowed that to infiltrate us, so when execs join our team from other organizations, 
they're always shocked at how fast we move and how fast we expect change. In 
fact, they coin it P2E time. This isn't only from a development perspective, but also
from an operational perspective. If we make a decision that looks like it wasn't the 
right one, I always say, "Let's fail quick and maneuver in a new direction." We 
don't wait for an analysis to prove an analysis and then send around a document 
for six people to sign off on and then take an alternate course. Most times it's just a
phone call and we move quick, which is one of our advantages, right? We drop 
promotions quicker than anyone else. If our numbers are down, we change 
marketing programs quicker, and I think that's benefited us overall through the 
history of P2E.
Nick Hogan:
Yeah, cool and I did want to take a second here to really compliment you on the 
Revo branding, all that. I was going through it. It's just tight. I like the way you 
guys brand stuff up every single time. It's just really well done, really super sharp. 
I just love it. I think you guys have just nailed it there.
Aaron Gomes:
I appreciate it, thank you, Nick.
Lucien Wijsman:
Yeah, so I think you touched on it a little bit. So you spoke about Cedar Crossing, 
what's next? What's in the pipeline for P2E?
Aaron Gomes:
So the same framework that we move quick in our operations, we're always 
looking at lots of opportunities, so nothing I guess I'd call concrete to announce, 
but I'm sure there'll be some new projects you'll hear about in the near future. 
Similar to Cedar Crossing that we've stayed after for a dozen plus years, there's 
plenty of other opportunities that we're kind of lying and waiting for the perfect 
time when the stars align that we've been evaluating for years to come. So 
unfortunately nothing to announce, but hopefully in short order.
Nick Hogan:
Okay, and Aaron, any big events or anything coming up here in the next little bit or
initiatives with any of the developments you have or any of the properties you have
up and running? Any big things coming?
Aaron Gomes:
Well, other than Manchester, which is hopefully we break ground here before long 
in Cedar Crossing. I just say if anybody lives in the New England area, go check 
out Revo Casino. I promise you'll have a fun time and a great meal. Then if 
anybody has opportunities in gaming outside the box, ideas or things they've tried 
to break through the bureaucracy of large casino companies to share their 
concepts, ideas, or opportunities, reach out to us. We're always looking at 
everything, and as I said, we move quick. So nothing else I'd say really to promote 
at any of our properties.
Nick Hogan:
Okay, and Lucien, since you're also sort of a guest on here today, anything that 
you'd like to promote before we sign off today? Anything coming up?
Lucien Wijsman:
Yeah, I figured that quite a few of the people who are listening to the podcast are 
actually not from the United States. You have very international audience here. So 
then I would have to say that May 13 to 15, Novogorica Slovenia is the Casino 
Operations Summit, and that's I think quickly growing to become the networking 
event for the European land-based operators, sea level. 39 sponsors, they're all 
there. So if you want to talk to any of your favorite suppliers, you can still register.
Nick Hogan:
It's my favorite type of format for these things where you do a three-day deal 
where you get that deep networking experience in addition to all these great talks 
and things. I've gone to all of them. I love them. I'm a big fan. Can't say enough 
good things about them, so Fantastic.
Lucien Wijsman:
Well, your contributions are highly appreciated because we talk the same 
language. It's fantastic to have you there with the speaker all the time.
Nick Hogan:
Thanks. Okay, well Aaron, thank you so much for spending time with us today. 
Always great to speak with you, I congratulate you guys on everything you have 
going, especially congrats on the new Iowa Project with Cedar Crossing.
Aaron Gomes:
Thank you.
Nick Hogan:
And yeah, gents, thanks so much for joining today and wish you both the best here.
Aaron Gomes:
Thanks for the time, really appreciate it.
Lucien Wijsman:
My pleasure.
Nick Hogan:
Thank you guys, bye-bye.
`,

};

export default S04E02;
