import React from "react";
import ReactGA from "react-ga";

import Cms from "../cms";
import ReelCastEpisodes from "./reelcastEpisodes";
import ReelCastEpisodeDescription from "./reelcastEpisodeDescription";
import ReelCastBanner from "../shared/ReelCastBanner";
import PageMetadata from "../PageMetadata";

class ReelCast extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.buzzsprout.com/2057836.js?container_id=buzzsprout-large-player&player=large';
        document.body.appendChild(script);
      }

    render() {
        const episodes = [
            {
                id: "11399244-s01e01-inventory-overdiversification-part-i",
                link: "s01e01-inventory-overdiversification-part-i",
                title: "Inventory Overdiversification - Part I",
                subTitle: "Season 1, Episode 1",
                description:
                    "Join us for the first episode of ReelCast as we dive into the topic of inventory overdiversification and its impact on your slot floor. Learn how to optimize your inventory for maximum performance.",
                datePublished: "2022-09-28",
                seasonNumber: 1,
                episodeNumber: 1,
            },
            {
                id: "11607232-s01e02-inventory-overdiversification-part-ii",
                link: "s01e02-inventory-overdiversification-part-ii",
                title: "Inventory Overdiversification - Part II",
                subTitle: "Season 1, Episode 2",
                description:
                    "In part two of our discussion on inventory overdiversification, we explore the latest data-driven strategies for optimizing your inventory and maximizing player satisfaction to drive revenue.",
                datePublished: "2022-11-01",
                seasonNumber: 1,
                episodeNumber: 2,
            },
            {
                id: "11771755-s01e03-making-your-peace-with-premium",
                title: "Making Your Peace with Premium",
                subTitle: "Season 1, Episode 3",
                description:
                    "Discover the importance of premium content and its impact on your slot floor. Our team shares data-driven insights and best practices for incorporating premium content into your inventory strategy.",
                link: "s01e03-making-your-peace-with-premium",
                datePublished: "2022-11-28",
                seasonNumber: 1,
                episodeNumber: 3,
            },
            {
                id: "11918058-s01e04-top-ten-insights-of-2022",
                title: "Top Ten Insights of 2022",
                subTitle: "Season 1, Episode 4",
                description:
                    "Join us for this special episode of ReelCast as we discuss the top ten insights of 2022 and their impact on your slot floor. Get some 'reel' practical advice on how to prepare for the year ahead.",
                link: "s01e04-top-ten-insights-of-2022",
                datePublished: "2022-12-22",
                seasonNumber: 1,
                episodeNumber: 4,
            },
            {
                id: "12140214-s02e01-updating-best-practices-part-i",
                title: "Updating Best Practices - Part I",
                subTitle: "Season 2, Episode 1",
                description:
                    "We kick off Season 2 of ReelCast as we explore the latest best practices for managing your slot floor. This is a must-listen episode for slot machine managers looking to stay ahead of the curve.",
                link: "s02e01-updating-best-practices-part-i",
                datePublished: "2023-01-29",
                seasonNumber: 2,
                episodeNumber: 1,
            },
            {
                id: "12338678-s02e03-updating-best-practices-part-ii",
                title: "Updating Best Practices - Part II",
                subTitle: "Season 2, Episode 2",
                description:
                    "In part two of our discussion on best practices for managing your slot machines, we dive deeper into data-driven strategies for optimizing your inventory and increasing player satisfaction. ",
                link: "s02e02-updating-best-practices-part-ii",
                datePublished: "2023-02-27",
                seasonNumber: 2,
                episodeNumber: 2,
            },
            { 
                id: "12517011-s02e03-updating-best-practices-part-iii",
                link: "s02e03-updating-best-practices-part-iii",
                title: "Updating Best Practices - Part III",
                subTitle: "Season 2, Episode 3",
                description: "In the final part of our discussion on best practices for managing your slot machines, we focus on the importance of staying up-to-date with industry trends and adjusting your strategies accordingly.",
                datePublished: "2023-03-26",
                seasonNumber: 2,
                episodeNumber: 3,
            },
            { 
                id: "12741246-s02e04-jackpot-liabilities-and-high-denom-links",
                link: "s02e04-Jackpot-Liabilities-and-High-Denom-Links",
                title: "Jackpot Liabilities and High-Denom Links",
                subTitle: "Season 2, Episode 4",
                description: 
                    "Welcome to this episode of Reelcast where Nick and Don share insights on how to manage jackpot liabilities and maximize the performance of high-denomination links on your slot floor.",
                datePublished: "2023-04-28",
                seasonNumber: 2,
                episodeNumber: 4,
            },
            { 
                id: "12867999-s02e05-marketing-mix-management-with-lucien-wijsman",
                link: "s02e05-marketing-mix-management-with-lucien-wijsman",
                title: "Marketing & Mix Management with Lucien Wijsman",
                subTitle: "Season 2, Episode 5",
                description: "Wijsman emphasizes the critical importance of player segmentation, and discusses how over-diversification, imbalance, and obsolete mix management practices are hampering slot performance.",
                datePublished: "2023-05-22",
                seasonNumber: 2,
                episodeNumber: 5,
            },
            {
                id: "13023778-s02e06-video-poker-with-action-gamings-mike-fields",
                link: "s02e06-video-poker-with-action-gamings-mike-fields",
                title: "Video Poker with Action Gaming's Mike Fields",
                subTitle: "Season 2, Episode 6",
                description: "In this discussion, Fields covers the latest trends in video poker, as well as general operational tips for transforming aging, long-neglected poker portfolios into differentiating, high-yield sources of competitive advantage.",
                datePublished: "2023-06-30",
                seasonNumber: 2,
                episodeNumber: 6,
            },
            {
                id: "13137763-s02e07-operator-vendor-tensions-with-buddy-frank",
                link: "s02e07-operator-vendor-tensions-with-buddy-frank",
                title: "Operator/Vendor Tensions with Buddy Frank",
                subTitle: "Season 2, Episode 7",
                description: "Explore operator-vendor tensions with Buddy Frank. Join Buddy, Nick and Don as they discuss hardware obsolescence, frequent cabinet releases, and vendor-operator relationships in the casino industry.",
                datePublished: "2023-08-24",
                seasonNumber: 2,
                episodeNumber: 7,
            },
            {
                id: "13654509-s02e08-increased-jackpot-activity-g2e-2023",
                link: "s02e08-increased-jackpot-activity-g2e-2023",
                title: "Increased Jackpot Activity & G2E 2023",
                subTitle: "Season 2, Episode 8",
                description: "Dive into a comprehensive discussion on increased jackpot activity and G2E 2023. Topics covered include the decision to move the ICE show, equitable revenue splits, and operator-vendor tensions.",
                datePublished: "2023-09-25",
                seasonNumber: 2,
                episodeNumber: 8,
            },
            {
                id: "13654509-s02e09-Budgetary-Guidance-for-2024",  
                link: "s02e09-budgetary-guidance-for-2024",
                title: "Budgetary Guidance for 2024",
                subTitle: "Season 2, Episode 9",
                description: "In this post-G2E episode, Nick & Don present budgetary guidance for 2024. Sub-topics include Halloween; conducting fair share, occupancy, layout, and demand analyses; the CapEx / OpEx split & pacing spend.",
                datePublished: "2023-10-30",
                seasonNumber: 2,
                episodeNumber: 9,
            },
            {
                id: "13989798-s02e10-aruze-gaming-global-with-kelcey-allison",
                link: "s02e10-aruze-gaming-global-with-kelcey-allison",
                title: "Aruze Gaming Global with Kelcey Allison",
                subTitle: "Season 2, Episode 10",
                description: "Join a candid talk with Kelcey Allison, COO of Aruze Gaming Global, discussing pivotal company events and the vision for its future. Highlights include Vegas F1 insights and casino signage trends.",
                datePublished: "2023-11-17",
                seasonNumber: 2,
                episodeNumber: 10,
            },
            {
                id: "11918058-s02e11-top-ten-insights-of-2023",
                title: "Top Ten Insights of 2023",
                subTitle: "Season 2, Episode 11",
                description:  "Welcome to our holiday episode of ReelCast, where we dive into the top ten insights of 2023. Tune in to discover innovative approaches and gain essential insights that will help you stay ahead.",
                link: "s02e11-top-ten-insights-of-2023",
                datePublished: "2023-12-28",
                seasonNumber: 2,
                episodeNumber: 11,
            },
            {
                id: "14296731-s03e01-bc-slots-with-brian-christopher-josh-o-connell",
                link: "s03e01-bc-slots-with-brian-christopher-josh-o-connell",
                title: "BC Slots with Brian Christopher & Josh O'Connell",
                subTitle: "Season 3, Episode 1",
                description: "In this first episode of Season 3, Nick and Don discuss the slot influencer phenomenon with domain pioneers and undisputed leaders, Brian Christopher & Josh O'Connell of BC Slots. Brian and Josh cover the company's full timeline, from Brian's first impromptu clip to becoming the industry's most powerful influencer brand.",
                datePublished: "2024-01-12",
                seasonNumber: 3,
                episodeNumber: 1,
            },
            {
                id: "14577542-s03e02-delaware-north-with-michael-carruthers",
                link: "s03e02-delaware-north-with-michael-carruthers",
                title: "Delaware North with Michael Carruthers",
                subTitle: "Season 3, Episode 2",
                description: "In our second episode of Season  3, Nick and Don speak with Michael Carruthers, Vice President of Gaming Operations at Delaware North. Michael shares his insights on the company's gaming operations, the impact of the pandemic, and the future of the industry. Join us for a deep dive into the world of gaming operations.",
                datePublished: "2024-02-26",
                seasonNumber: 3,
                episodeNumber: 2,
            },
            {
                id: "14807307-s03e03-blue-delta-jeans-with-nick-weaver",
                link: "s03e03-blue-delta-jeans-with-nick-weaver",
                title: "Blue Delta Jeans with Nick Weaver",
                subTitle: "Season 3, Episode 3",
                description: "Blue jeans?!? Yes, you read that correctly. In this episode, Nick and Don speak with Nick Weaver, Co-Founder and COO of Blue Delta Jeans. Learn how Nick and his team are using $500 custom-tailored blue jeans to thrill slot players and breathe new life into VIP events and continuity programs.",
                datePublished: "2024-03-29",
                seasonNumber: 3,
                episodeNumber: 3,
            },
            {
                id: "14981282-s03e04-bally-s-corporation-with-dan-cherry",
                link: "s03e04-bally-s-corporation-with-dan-cherry",
                title: "Bally's Corporation with Dan Cherry",
                subTitle: "Season 3, Episode 4",
                description: "In this episode, Nick & Don chat with Dan Cherry, Vice President of Gaming Operations at Bally's Corporation. Dan discusses the major projects underway in Vegas and Chicago, as well as the challenges and payoffs of implementing operational and analytical standards within hyper-growth gaming organizations. Also in this episode, should I replace ETGs with slots?",
                datePublished: "2024-04-30",
                seasonNumber: 3,
                episodeNumber: 4,
            },
            {
                id: "15085579-s03e05-gaming-arts-with-mike-dreitzer",
                link: "s03e05-gaming-arts-with-mike-dreitzer",
                title: "Gaming Arts with Mike Dreitzer",
                subTitle: "Season 3, Episode 5",
                description: "In this episode, Nick and Don speak with Mike Dreitzer, CEO of Gaming Arts. Learn about the risks & rewards of building a slot machine company, the group's startup phase, development philosophy, product portfolio, near-term plans, and its strategic alliance with Germany's Merkur Gaming. Also in this episode, demand balancing multi-game product.",
                datePublished: "2024-05-17",
                seasonNumber: 3,
                episodeNumber: 5,
            },
            {
                id: "15300571-s03e06-ags-with-jackson-floyd-and-steve-walther",
                link: "s03e06-ags-with-jackson-floyd-and-steve-walther",
                title: "AGS with Jackson Floyd and Steve Walther",
                subTitle: "Season 3, Episode 6",
                description: "In this episode, Nick and Don catch-up with AGS's VP of Slots, Jackson Floyd, and VP of Game Development, Steve Walther. Learn about AGS's history, cultural leadership, developmental philosophy (Steve's tango analogy is awesome), GameON customer summit, upcoming product releases, and more. Also in this episode, keeping Actual and Theo variances in-check.",
                datePublished: "2024-06-24",
                seasonNumber: 3,
                episodeNumber: 6,
            },
            {
                id: "15397255-s03e07-incredible-technologies-with-dan-schrementi",
                link: "s03e07-incredible-technologies-with-dan-schrementi",
                title: "Incredible Technologies with Dan Schrementi",
                subTitle: "Season 3, Episode 7",
                description: "In this episode, Nick & Don speak with Dan Schrementi, President of Gaming at Incredible Technologies (IT). Learn about IT's history as a pioneering arcade game supplier, its move into gaming, its design & commercial philosophies, its recent strategic rebuild, its product roadmap, and its 'Teed Up' initiative. Also in this episode, how free play reductions impact visitation & spend.",
                datePublished: "2024-07-12",
                seasonNumber: 3,
                episodeNumber: 7,
            },
            {
                id: "15628363-zitro-usa-with-derik-mooberry",
                link: "s03e08-zitro-usa-with-derik-mooberry",
                title: "Zitro USA with Derik Mooberry",
                subTitle: "Season 3, Episode 8",
                description: "In this episode, Nick and Don speak with Derik Mooberry, CEO of Zitro USA. Learn about Zitro's rise from niche electronic bingo provider to a global supply-side powerhouse, its plans for the North American market, and the company's increasingly compelling Class III slot portfolio. Also in this episode, leveraging superstar inventory to dial-back free play.",
                datePublished: "2024-08-23",
                seasonNumber: 3,
                episodeNumber: 8,
            },
            {
                id: "15767087-s03e09-bluberi-with-mike-brennan-casey-whalen",
                link: "s03e09-bluberi-with-mike-brennan-casey-whalen",
                title: "Bluberi with Mike Brennan & Casey Whalen",
                subTitle: "Season 3, Episode 9",
                description: "In this episode, Nick and Don catch-up with Bluberi's Chief Product Officer, Mike Brennan, and Chief Commercial Officer, Casey Whalen. Learn how an insurgent supplier with roots in Quebec is parlaying a string of surprise hits into some of the most exciting product offerings for the Class III slot space. Hear about their design philosophies, banking strategies, release plans, cultural imperatives, and (music to our ears) obsession with fair share performance. Also in this episode, G2E 2024.",
                datePublished: "2024-09-18",
                seasonNumber: 3,
                episodeNumber: 9,
            },
            {
                id: "16012692-s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau",
                link: "s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau",
                title: "Konami Gaming with Jay Bertsch, Chris Rowe, and Stephanie Lau",
                subTitle: "Season 3, Episode 10",
                description: "In this episode, Nick and Don catch-up with Konami's Jay Bertsch (SVP & Chief Commercial Officer), Chris Rowe (VP of North American Game Sales), and Stephanie Lau (VP of Sales Enablement). Hear the team's thoughts on G2E '24, as well as upcoming rollouts in systems, cabinets, and games. Also in this episode, Aristocrat, Light & Wonder, and the Dragon Train dispute.",
                datePublished: "2024-10-30",
                seasonNumber: 3,
                episodeNumber: 10,
            },
            {
                id: "16172077-s03e11-thanksgiving-episode",
                link: "s03e11-thanksgiving-episode",
                title: "Thanksgiving Episode",
                subTitle: "Season 3, Episode 11",
                description: "In this Thanksgiving-themed episode, Nick and Don list the top ten current slot trends for which they're most thankful. Also in this episode, game speed and the Las Vegas Grand Prix (mea culpa edition).",
                datePublished: "2024-11-26",
                seasonNumber: 3,
                episodeNumber: 11,
            },
            {
                id: "16697249-s04e01-wind-creek-hospitality-ken-rohman",
                link: "s04e01-wind-creek-hospitality-ken-rohman",
                title: "Wind Creek Hospitality with Ken Rohman",
                subTitle: "Season 4, Episode 1",
                description: "In this episode, Nick and Don catch-up with Ken Rohman, Chief Marketing Officer for Wind Creek Hospitality. Learn about the Poarch Creek tribe's growth and expansion into commercial markets, its guest-driven service culture, the Wind Creek Rewards loyalty program, and the licensing, construction, positioning, opening, and early successes of the group's newest resort, Wind Creek Chicago Southland. Also in this episode, Don hangs-up his spurs.",
                datePublished: "2025-02-26",
                seasonNumber: 4,
                episodeNumber: 1,
            },
            {
                id: "16874638-s04e02-peninsula-pacific-entertainment-with-aaron-gomes",
                link: "s04e02-peninsula-pacific-entertainment-with-aaron-gomes",
                title: "Peninsula Pacific Entertainment with Aaron Gomes",
                subTitle: "Season 4, Episode 2",
                description: "In this episode, Nick and guest co-host, Lucien Wijsman, speak with P2E President & COO, Aaron Gomes. Hear about growing-up in a prominent gaming family, Aaron's path to his current role, the ins and outs of HHR, and P2E's history, philosophies, current portfolio, and upcoming projects, including the new Cedar Crossing Casino in Cedar Rapids, Iowa. Also in this episode, listener questions and the new ReelHot Index",               
                datePublished: "2025-03-26",
                seasonNumber: 4,
                episodeNumber: 2,
            },
            ];
            
        const [
            episode1,
            episode2,
            episode3,
            episode4,
            episode5,
            episode6,
            episode7,
            episode8,
            episode9,
            episode10,
            episode11,
            episode12,
            episode13,
            episode14,
            episode15,
            episode16,
            episode17,
            episode18,
            episode19,
            episode20,
            episode21,
            episode22,
            episode23,
            episode24,
            episode25,
            episode26,
            episode27,
            episode28,
        ] = episodes;

        // Create JSON-LD structured data for the podcast
        const podcastStructuredData = {
            "@context": "https://schema.org",
            "@type": "PodcastSeries",
            "name": "ReelCast",
            "description": "ReelCast is the ReelMetrics Podcast where data-driven insights, tips, and tricks are shared to help professional slot teams improve their game.",
            "url": "https://www.reelmetrics.com/reelcast",
            "image": "https://cdn.buttercms.com/9nzbnxR9SnGfKpJAMnrm",
            "publisher": {
                "@type": "Organization",
                "name": "ReelMetrics",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://cdn.buttercms.com/9nzbnxR9SnGfKpJAMnrm"
                }
            },
            "mainEntityOfPage": "https://www.reelmetrics.com/reelcast",
            "inLanguage": "en-US",
            "potentialAction": {
                "@type": "ListenAction",
                "target": {
                    "@type": "EntryPoint",
                    "urlTemplate": "https://www.reelmetrics.com/reelcast"
                }
            }
        };

        // Create JSON-LD structured data for the latest episode
        const latestEpisode = episodes[episodes.length - 1]; // Dynamically get the latest episode
        const latestEpisodeStructuredData = {
            "@context": "https://schema.org",
            "@type": "PodcastEpisode",
            "episodeNumber": latestEpisode.episodeNumber,
            "seasonNumber": latestEpisode.seasonNumber,
            "name": latestEpisode.title,
            "datePublished": latestEpisode.datePublished,
            "description": latestEpisode.description,
            "url": `https://www.reelmetrics.com/${latestEpisode.link}`,
            "partOfSeries": {
                "@type": "PodcastSeries",
                "name": "ReelCast"
            }
        };

        return (
            <>
                <PageMetadata
                    title="ReelCast | The Slot Machine Analytics Podcast by ReelMetrics"
                    description="ReelCast is the ReelMetrics Podcast where data-driven insights, tips, and tricks are shared to help professional slot teams improve their game. Produced by ReelMetrics."
                    canonical="https://www.reelmetrics.com/reelcast"
                    
                    // OpenGraph tags
                    ogType="website"
                    ogTitle="ReelCast | The Slot Machine Analytics Podcast"
                    ogDescription="The podcast where data-driven insights, tips, and tricks are shared to help professional slot teams improve their game. Produced by ReelMetrics."
                    ogImage="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    ogUrl="https://www.reelmetrics.com/reelcast"
                    
                    // Twitter Card tags
                    twitterCard="summary_large_image"
                    twitterTitle="ReelCast | The Slot Machine Analytics Podcast"
                    twitterDescription="The podcast where data-driven insights, tips, and tricks are shared to help professional slot teams improve their game."
                    twitterImage="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
                    
                    // Additional meta tags
                    meta={[
                        { property: "og:locale", content: "en_US" },
                        { property: "og:site_name", content: "ReelMetrics" }
                    ]}
                    
                    // Structured data
                    structuredData={[
                        podcastStructuredData,
                        latestEpisodeStructuredData
                    ]}
                />
                
                <main className="text-page" itemScope itemType="https://schema.org/WebPage">
                    {/* Intro */}
                    <section className="box" aria-labelledby="reelcast-intro">
                        <div className="container">
                            <div className="row flex-row">
                                <div className="col-sm-6">
                                    <h1 id="reelcast-intro" className="title title--primary title--md" itemProp="headline">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelcast_title"
                                            type="title"
                                        />
                                    </h1>
                                    <div className="paragraph--small paragraph--small--small-screen" itemProp="description">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="reelcast_paragraph"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-1"> </div>
                                <div className="col-sm-5">
                                    <figure>
                                        <img
                                            src="https://cdn.buttercms.com/9nzbnxR9SnGfKpJAMnrm"    
                                            alt="ReelCast - The slot machine analytics podcast by ReelMetrics"
                                            className="img-responsive"
                                            loading="eager"
                                            width="400"
                                            height="400"
                                            itemProp="image"
                                        />
                                    </figure>
                                    {/* <div id="buzzsprout-large-player"></div> */}
                                </div>
                            </div>
                        </div>
                    </section>

                 {/* Season 4 */}
                 <section className="box box--light" aria-labelledby="season4-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 id="season4-heading">ReelCast Season 4</h2>
                            </div>
                        </div>

                        {/* Season 4 Episode 02 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="2" />
                            <meta itemProp="seasonNumber" content="4" />
                            <meta itemProp="datePublished" content={episode28.datePublished} />
                            <meta itemProp="name" content={episode28.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode28.title}
                                    subHeading={episode28.subTitle}
                                    link={episode28.link}
                                    description={episode28.description}
                                    episodeNumber={episode28.episodeNumber}
                                    seasonNumber={episode28.seasonNumber}
                                    datePublished={episode28.datePublished}
                                />
                            </div>
                        
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/xh6GYfhSY6IVoWh5W5pE" 
                                            alt="ReelCast Season 4 Episode 2 - Peninsula Pacific Entertainment with Aaron Gomes"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 4 Episode 01 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="1" />
                            <meta itemProp="seasonNumber" content="4" />
                            <meta itemProp="datePublished" content={episode27.datePublished} />
                            <meta itemProp="name" content={episode27.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode27.title}
                                    subHeading={episode27.subTitle}
                                    link={episode27.link}
                                    description={episode27.description}
                                    episodeNumber={episode27.episodeNumber}
                                    seasonNumber={episode27.seasonNumber}
                                    datePublished={episode27.datePublished}
                                />
                            </div>
                        
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/igAyejPcQZ6nCw0KbWnD" 
                                        alt="ReelCast Season 4 Episode 1 - Wind Creek Hospitality with Ken Rohman"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>
                    </div>
                </section>


                {/* Season 3 */}
                <section className="box box--light" aria-labelledby="season3-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 id="season3-heading">ReelCast Season 3</h2>
                            </div>
                        </div>

                        {/* Season 3 Episode 11 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="11" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="name" content={episode26.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode26.title}
                                    subHeading={episode26.subTitle}
                                    link={episode26.link}
                                    description={episode26.description}
                                    episodeNumber={episode26.episodeNumber}
                                    seasonNumber={episode26.seasonNumber}
                                    datePublished={episode26.datePublished}
                                />
                            </div>
                        
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/MQQRO9sOS12of786PyT6" 
                                        alt="ReelCast Season 3 Episode 11 - Thanksgiving Episode"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 3 Episode 10 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="10" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="name" content={episode25.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode25.title}
                                    subHeading={episode25.subTitle}
                                    link={episode25.link}
                                    description={episode25.description}
                                    episodeNumber={episode25.episodeNumber}
                                    seasonNumber={episode25.seasonNumber}
                                    datePublished={episode25.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/nJLtRkuQ1WaMgQlSSwOE" 
                                        alt="ReelCast Season 3 Episode 10 - Konami Gaming with Jay Bertsch, Chris Rowe, and Stephanie Lau"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 3 Episode 9 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="9" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode24.datePublished} />
                            <meta itemProp="name" content={episode24.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode24.title}
                                    subHeading={episode24.subTitle}
                                    link={episode24.link}
                                    description={episode24.description}
                                    episodeNumber={episode24.episodeNumber}
                                    seasonNumber={episode24.seasonNumber}
                                    datePublished={episode24.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/XuxkN7SHQ9iFpTYZGhYp"
                                        alt="ReelCast Season 3 Episode 9 - Bluberi with Mike Brennan & Casey Whalen"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 3 Episode 8 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="8" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode23.datePublished} />
                            <meta itemProp="name" content={episode23.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode23.title}
                                    subHeading={episode23.subTitle}
                                    link={episode23.link}
                                    description={episode23.description}
                                    episodeNumber={episode23.episodeNumber}
                                    seasonNumber={episode23.seasonNumber}
                                    datePublished={episode23.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/UfqDFJJGT5uNZfViYcxq"
                                        alt="ReelCast Season 3 Episode 8 - Zitro USA with Derik Mooberry"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 3 Episode 7 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="7" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode22.datePublished} />
                            <meta itemProp="name" content={episode22.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode22.title}
                                    subHeading={episode22.subTitle}
                                    link={episode22.link}
                                    description={episode22.description}
                                    episodeNumber={episode22.episodeNumber}
                                    seasonNumber={episode22.seasonNumber}
                                    datePublished={episode22.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/uvl2RfnQQFOybc2bNujq"
                                        alt="ReelCast Season 3 Episode 7 - Incredible Technologies with Dan Schrementi"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 3 Episode 6 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="6" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode21.datePublished} />
                            <meta itemProp="name" content={episode21.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode21.title}
                                    subHeading={episode21.subTitle}
                                    link={episode21.link}
                                    description={episode21.description}
                                    episodeNumber={episode21.episodeNumber}
                                    seasonNumber={episode21.seasonNumber}
                                    datePublished={episode21.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/4F3V7IvhSFyPuRgErKqy"
                                        alt="ReelCast Season 3 Episode 6 - AGS with Jackson Floyd and Steve Walther"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 3 Episode 5 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="5" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode20.datePublished} />
                            <meta itemProp="name" content={episode20.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode20.title}
                                    subHeading={episode20.subTitle}
                                    link={episode20.link}
                                    description={episode20.description}
                                    episodeNumber={episode20.episodeNumber}
                                    seasonNumber={episode20.seasonNumber}
                                    datePublished={episode20.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/PC2Hh3aFRRin56dd1HJf"
                                        alt="ReelCast Season 3 Episode 5 - Gaming Arts with Mike Dreitzer"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 3 Episode 4 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="4" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode19.datePublished} />
                            <meta itemProp="name" content={episode19.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode19.title}
                                    subHeading={episode19.subTitle}
                                    link={episode19.link}
                                    description={episode19.description}
                                    episodeNumber={episode19.episodeNumber}
                                    seasonNumber={episode19.seasonNumber}
                                    datePublished={episode19.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/rsvE0m7TL1IeYNZkfPlw"
                                        alt="ReelCast Season 3 Episode 4 - Bally's Corporation with Dan Cherry"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>

                        {/* Season 3 Episode 3 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="3" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode18.datePublished} />
                            <meta itemProp="name" content={episode18.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode18.title}
                                    subHeading={episode18.subTitle}
                                    link={episode18.link}
                                    description={episode18.description}
                                    episodeNumber={episode18.episodeNumber}
                                    seasonNumber={episode18.seasonNumber}
                                    datePublished={episode18.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/ASFvR4TESEgolGLsZdQr"
                                        alt="ReelCast Season 3 Episode 3 - Blue Delta Jeans with Nick Weaver"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>
                    
                        <hr/> {/* Episode 2 */}
                        <article className="row paddingTop" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="2" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode17.datePublished} />
                            <meta itemProp="name" content={episode17.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode17.title}
                                    subHeading={episode17.subTitle}
                                    link={episode17.link}
                                    description={episode17.description}
                                    episodeNumber={episode17.episodeNumber}
                                    seasonNumber={episode17.seasonNumber}
                                    datePublished={episode17.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/EpSM5TPnRAelCIlujXOp"    
                                        alt="ReelCast Season 3 Episode 2 - Delaware North with Michael Carruthers"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>
                    
                        <hr/> {/* Episode 1 */}
                        <article className="row paddingTopBottom" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="1" />
                            <meta itemProp="seasonNumber" content="3" />
                            <meta itemProp="datePublished" content={episode16.datePublished} />
                            <meta itemProp="name" content={episode16.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode16.title}
                                    subHeading={episode16.subTitle}
                                    link={episode16.link}
                                    description={episode16.description}
                                    episodeNumber={episode16.episodeNumber}
                                    seasonNumber={episode16.seasonNumber}
                                    datePublished={episode16.datePublished}
                                />
                            </div>
                            <div className="col-sm-6">
                                <figure itemProp="image">
                                    <img
                                        src="https://cdn.buttercms.com/d6SeL4CxTtecxcXGYGbx"    
                                        alt="ReelCast Season 3 Episode 1 - BC Slots with Brian Christopher and Josh O'Connell"
                                        className="img-responsive"
                                        loading="eager"
                                        width="600"
                                        height="400"
                                    />
                                </figure>
                            </div>
                        </article>
                    </div>
                </section>

                {/* Season 2 */}
                <section className="box" aria-labelledby="season2-heading">
                    <div className="container">
                        <h2 id="season2-heading">ReelCast Season 2</h2>
                        
                        {/* Season 2 Episode 11 */}
                        <article className="row" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                            <meta itemProp="episodeNumber" content="11" />
                            <meta itemProp="seasonNumber" content="2" />
                            <meta itemProp="datePublished" content={episode15.datePublished} />
                            <meta itemProp="name" content={episode15.title} />
                            
                            <div className="col-sm-6">
                                <ReelCastEpisodeDescription
                                    heading={episode15.title}
                                    subHeading={episode15.subTitle}
                                    description={episode15.description}
                                    link={episode15.link}
                                    episodeNumber={episode15.episodeNumber}
                                    seasonNumber={episode15.seasonNumber}
                                    datePublished={episode15.datePublished}
                                />
                            </div>
                        </article>
                        
                        <div className="row">
                            {/* Season 2 Episode 10 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="10" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode14.datePublished} />
                                <meta itemProp="name" content={episode14.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode14.title}
                                    subHeading={episode14.subTitle}
                                    description={episode14.description}
                                    link={episode14.link}
                                    episodeNumber={episode14.episodeNumber}
                                    seasonNumber={episode14.seasonNumber}
                                    datePublished={episode14.datePublished}
                                />
                            </article>
                            
                            {/* Season 2 Episode 9 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="9" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode13.datePublished} />
                                <meta itemProp="name" content={episode13.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode13.title}
                                    subHeading={episode13.subTitle}
                                    description={episode13.description}
                                    link={episode13.link}
                                    episodeNumber={episode13.episodeNumber}
                                    seasonNumber={episode13.seasonNumber}
                                    datePublished={episode13.datePublished}
                                />
                            </article>
                        </div>
                        
                        <div className="row">
                            {/* Season 2 Episode 8 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="8" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode12.datePublished} />
                                <meta itemProp="name" content={episode12.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode12.title}
                                    subHeading={episode12.subTitle}
                                    description={episode12.description}
                                    link={episode12.link}
                                    episodeNumber={episode12.episodeNumber}
                                    seasonNumber={episode12.seasonNumber}
                                    datePublished={episode12.datePublished}
                                />
                            </article>
                            
                            {/* Season 2 Episode 7 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="7" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode11.datePublished} />
                                <meta itemProp="name" content={episode11.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode11.title}
                                    subHeading={episode11.subTitle}
                                    description={episode11.description}
                                    link={episode11.link}
                                    episodeNumber={episode11.episodeNumber}
                                    seasonNumber={episode11.seasonNumber}
                                    datePublished={episode11.datePublished}
                                />
                            </article>
                        </div>
                        
                        <div className="row">
                            {/* Season 2 Episode 6 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="6" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode10.datePublished} />
                                <meta itemProp="name" content={episode10.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode10.title}
                                    subHeading={episode10.subTitle}
                                    description={episode10.description}
                                    link={episode10.link}
                                    episodeNumber={episode10.episodeNumber}
                                    seasonNumber={episode10.seasonNumber}
                                    datePublished={episode10.datePublished}
                                />
                            </article>
                            
                            {/* Season 2 Episode 5 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="5" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode9.datePublished} />
                                <meta itemProp="name" content={episode9.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode9.title}
                                    subHeading={episode9.subTitle}
                                    description={episode9.description}
                                    link={episode9.link}
                                    episodeNumber={episode9.episodeNumber}
                                    seasonNumber={episode9.seasonNumber}
                                    datePublished={episode9.datePublished}
                                />
                            </article>
                        </div>
                        
                        <div className="row">
                            {/* Season 2 Episode 4 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="4" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode8.datePublished} />
                                <meta itemProp="name" content={episode8.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode8.title}
                                    subHeading={episode8.subTitle}
                                    description={episode8.description}
                                    link={episode8.link}
                                    episodeNumber={episode8.episodeNumber}
                                    seasonNumber={episode8.seasonNumber}
                                    datePublished={episode8.datePublished}
                                />
                            </article>

                            {/* Season 2 Episode 3 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="3" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode7.datePublished} />
                                <meta itemProp="name" content={episode7.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode7.title}
                                    subHeading={episode7.subTitle}
                                    description={episode7.description}
                                    link={episode7.link}
                                    episodeNumber={episode7.episodeNumber}
                                    seasonNumber={episode7.seasonNumber}
                                    datePublished={episode7.datePublished}
                                />
                            </article>
                        </div>
                        
                        <div className="row">
                            {/* Season 2 Episode 2 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="2" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode6.datePublished} />
                                <meta itemProp="name" content={episode6.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode6.title}
                                    subHeading={episode6.subTitle}
                                    description={episode6.description}
                                    link={episode6.link}
                                    episodeNumber={episode6.episodeNumber}
                                    seasonNumber={episode6.seasonNumber}
                                    datePublished={episode6.datePublished}
                                />
                            </article>

                            {/* Season 2 Episode 1 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="1" />
                                <meta itemProp="seasonNumber" content="2" />
                                <meta itemProp="datePublished" content={episode5.datePublished} />
                                <meta itemProp="name" content={episode5.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode5.title}
                                    subHeading={episode5.subTitle}
                                    description={episode5.description}
                                    link={episode5.link}
                                    episodeNumber={episode5.episodeNumber}
                                    seasonNumber={episode5.seasonNumber}
                                    datePublished={episode5.datePublished}
                                />
                            </article>
                        </div>
                    </div>
                </section>

                {/* Reelcast CTA */}
                <ReelCastBanner />

                {/* Season 1 */}
                <section className="box box--light" aria-labelledby="season1-heading">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 id="season1-heading">ReelCast Season 1</h2>
                            </div>
                        </div>

                        <div className="row">
                            {/* Season 1 Episode 4 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="4" />
                                <meta itemProp="seasonNumber" content="1" />
                                <meta itemProp="datePublished" content={episode4.datePublished} />
                                <meta itemProp="name" content={episode4.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode4.title}
                                    subHeading={episode4.subTitle}
                                    link={episode4.link}
                                    description={episode4.description}
                                    episodeNumber={episode4.episodeNumber}
                                    seasonNumber={episode4.seasonNumber}
                                    datePublished={episode4.datePublished}
                                />
                            </article>
                            
                            {/* Season 1 Episode 3 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="3" />
                                <meta itemProp="seasonNumber" content="1" />
                                <meta itemProp="datePublished" content={episode3.datePublished} />
                                <meta itemProp="name" content={episode3.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode3.title}
                                    subHeading={episode3.subTitle}
                                    description={episode3.description}
                                    link={episode3.link}
                                    episodeNumber={episode3.episodeNumber}
                                    seasonNumber={episode3.seasonNumber}
                                    datePublished={episode3.datePublished}
                                />
                            </article>
                        </div>

                        <div className="row">
                            {/* Season 1 Episode 2 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="2" />
                                <meta itemProp="seasonNumber" content="1" />
                                <meta itemProp="datePublished" content={episode2.datePublished} />
                                <meta itemProp="name" content={episode2.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode2.title}
                                    subHeading={episode2.subTitle}
                                    description={episode2.description}
                                    link={episode2.link}
                                    episodeNumber={episode2.episodeNumber}
                                    seasonNumber={episode2.seasonNumber}
                                    datePublished={episode2.datePublished}
                                />
                            </article>
                            
                            {/* Season 1 Episode 1 */}
                            <article className="col-sm-6" itemScope itemType="https://schema.org/PodcastEpisode" itemProp="hasPart">
                                <meta itemProp="episodeNumber" content="1" />
                                <meta itemProp="seasonNumber" content="1" />
                                <meta itemProp="datePublished" content={episode1.datePublished} />
                                <meta itemProp="name" content={episode1.title} />
                                
                                <ReelCastEpisodeDescription
                                    heading={episode1.title}
                                    subHeading={episode1.subTitle}
                                    description={episode1.description}
                                    link={episode1.link}
                                    episodeNumber={episode1.episodeNumber}
                                    seasonNumber={episode1.seasonNumber}
                                    datePublished={episode1.datePublished}
                                />
                            </article>
                        </div>
                    </div>
                </section>

                <section aria-label="All episodes list">
                    <ReelCastEpisodes episodes={episodes} />
                </section>
                
                </main>
            </>
        );
    }
}

export default ReelCast;
