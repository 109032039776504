import React from "react";
import ReactGA from "react-ga";
import PageMetadata from "../components/PageMetadata";
import butter from "../utils/butterClient";
import { butterImage, butterImageURL } from "../utils/butterHandler";

import Cms from "../components/cms";
import Well from "../components/well";

class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            team_members: []
        };

        this._getBackgroundImage = x => {
            return { backgroundImage: `url(${x.photo})` };
        };

        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        butter.content.retrieve(["team_member"]).then(response => {
            this.setState({ team_members: response.data.data.team_member });
        });
    }

    render() {
        // Create structured data for the organization
        const organizationStructuredData = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "ReelMetrics",
            "url": "https://www.reelmetrics.com",
            "logo": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
            "description": "ReelMetrics is a team of data scientists who are passionate about casino data and slot machine analytics.",
            "sameAs": [
                "https://www.linkedin.com/company/reelmetrics/",
                "https://twitter.com/reelmetrics"
            ]
        };

        return (
            <>
            <PageMetadata
                title="About Us | Meet the ReelMetrics Team | Casino Data Analytics Experts"
                description="Meet the ReelMetrics team - data scientists and casino analytics experts dedicated to transforming slot machine performance data into actionable insights for casino operators."
                keywords="casino data, slot machine analytics, casino analytics, casino data analytics, slot machine data, slot machine data analytics, casino data scientist, slot machine data scientist, casino data science, slot machine data science, casino data analysis, slot machine data analysis, casino data analytics company, slot machine data analytics company, casino data science company, slot machine data science company, casino data analysis company, slot machine data analysis company"
                canonical="https://www.reelmetrics.com/about-us"
                
                // Open Graph tags
                ogUrl="https://www.reelmetrics.com/about-us"
                ogType="website"
                ogTitle="About Us | Meet the ReelMetrics Team | Casino Data Analytics Experts"
                ogDescription="Meet the ReelMetrics team - data scientists and casino analytics experts dedicated to transforming slot machine performance data into actionable insights for casino operators."
                ogImage={butterImageURL(this.props.cms_content, "about_us_splash")}
                
                // Twitter Card tags
                twitterCard="summary_large_image"
                twitterTitle="About Us | Meet the ReelMetrics Team"
                twitterDescription="Meet the ReelMetrics team - data scientists and casino analytics experts dedicated to transforming slot machine performance data into actionable insights."
                twitterImage={butterImageURL(this.props.cms_content, "about_us_splash")}
                
                // Structured data
                structuredData={[organizationStructuredData]}
                
                // Additional meta tags
                meta={[
                    { name: "author", content: "ReelMetrics" },
                    { property: "og:site_name", content: "ReelMetrics" },
                    { property: "og:locale", content: "en_US" }
                ]}
            />
                <main itemScope itemType="https://schema.org/AboutPage">
                    <section className="box box--light" aria-labelledby="team-intro">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="mt-xs-2">
                                        <h1 id="team-intro" className="title title--primary title--lg" itemProp="headline">
                                            <Cms
                                                content={this.props.cms_content}
                                                content_key="team_splash"
                                                type="title"
                                            />
                                        </h1>
                                        <div className="paragraph" itemProp="description">
                                            <Cms
                                                content={this.props.cms_content}
                                                content_key="team_splash"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="box box--end">
                                        <img
                                            src={butterImageURL(
                                                this.props.cms_content,
                                                "about_us_splash"
                                            )}
                                            className="img-responsive width-100"
                                            alt="The ReelMetrics team of data scientists and casino analytics experts"
                                            loading="eager"
                                            width="100%"
                                            height="auto"
                                            itemProp="image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="box" aria-labelledby="company-mission">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 id="company-mission" className="title title--primary" itemProp="mainContentOfPage">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="team_1"
                                            type="title"
                                        />
                                    </h2>
                                    <div className="paragraph" itemProp="text">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="team_1"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <h2 className="title title--primary" itemProp="mainContentOfPage">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="team_2"
                                            type="title"
                                        />
                                    </h2>
                                    <div className="paragraph" itemProp="text">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="team_2"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="container" aria-label="Office environment gallery">
                        <ul className="list-unstyled grid row" itemScope itemType="https://schema.org/ImageGallery">
                            <meta itemProp="about" content="ReelMetrics office and team environment" />
                            <li className="col-sm-6" itemScope itemType="https://schema.org/ImageObject">
                                <div
                                    className="grid__item grid__item--rectangle squared"
                                    style={butterImage(
                                        this.props.cms_content,
                                        "about_us_1"
                                    )}
                                    role="img"
                                    aria-label="ReelMetrics office space"
                                />
                                <meta itemProp="representativeOfPage" content="true" />
                            </li>

                            <li className="col-xs-6 col-sm-3" itemScope itemType="https://schema.org/ImageObject">
                                <div
                                    className="grid__item grid__item--squared squared"
                                    style={butterImage(
                                        this.props.cms_content,
                                        "about_us_2"
                                    )}
                                    role="img"
                                    aria-label="ReelMetrics team collaboration"
                                />
                                <meta itemProp="representativeOfPage" content="true" />
                            </li>
                            <li className="col-xs-6 col-sm-3" itemScope itemType="https://schema.org/ImageObject">
                                <div
                                    className="grid__item grid__item--squared squared"
                                    style={butterImage(
                                        this.props.cms_content,
                                        "about_us_3"
                                    )}
                                    role="img"
                                    aria-label="ReelMetrics data analysis"
                                />
                                <meta itemProp="representativeOfPage" content="true" />
                            </li>
                        </ul>
                        <ul className="list-unstyled grid row" itemScope itemType="https://schema.org/ImageGallery">
                            <meta itemProp="about" content="ReelMetrics office and team environment" />
                            <li className="col-xs-6 col-sm-3" itemScope itemType="https://schema.org/ImageObject">
                                <div
                                    className="grid__item grid__item--squared squared"
                                    style={butterImage(
                                        this.props.cms_content,
                                        "about_us_4"
                                    )}
                                    role="img"
                                    aria-label="ReelMetrics casino analytics"
                                />
                                <meta itemProp="representativeOfPage" content="true" />
                            </li>
                            <li className="col-xs-6 col-sm-3" itemScope itemType="https://schema.org/ImageObject">
                                <div
                                    className="grid__item grid__item--squared squared"
                                    style={butterImage(
                                        this.props.cms_content,
                                        "about_us_5"
                                    )}
                                    role="img"
                                    aria-label="ReelMetrics team meeting"
                                />
                                <meta itemProp="representativeOfPage" content="true" />
                            </li>
                            <li className="col-sm-6" itemScope itemType="https://schema.org/ImageObject">
                                <div
                                    className="grid__item grid__item--rectangle squared hidden-xs"
                                    style={butterImage(
                                        this.props.cms_content,
                                        "about_us_6"
                                    )}
                                    role="img"
                                    aria-label="ReelMetrics office environment"
                                />
                                <meta itemProp="representativeOfPage" content="true" />
                            </li>
                        </ul>
                    </section>

                    <section className="box" aria-labelledby="meet-team">
                        <div className="container">
                            <div className="text-center">
                                <h2 id="meet-team" className="title title--primary" itemProp="mainContentOfPage">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="team_3"
                                        type="title"
                                    />
                                </h2>
                                <div className="mw-570 center-block">
                                    <div className="paragraph" itemProp="text">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="team_3"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="box box--start" aria-labelledby="meet-team">
                        <div className="container">
                            <div className="row mb-xs-3" itemScope itemType="https://schema.org/ItemList">
                                <meta itemProp="name" content="ReelMetrics Team Members" />
                                {this.state.team_members.map((x, index) => (
                                    <div key={x.name} className="col-sm-3 col-xs-6" itemScope itemType="https://schema.org/Person" itemProp="itemListElement">
                                        <meta itemProp="position" content={index + 1} />
                                        <figure
                                            className="team"
                                            style={this._getBackgroundImage(x)}
                                        >
                                            <figcaption>
                                                <span className="team__name" itemProp="name">{x.name}</span>
                                                <span className="team__function" itemProp="jobTitle">{x.role}</span>
                                                <meta itemProp="worksFor" content="ReelMetrics" />
                                                <meta itemProp="image" content={x.photo} />
                                            </figcaption>
                                        </figure>
                                    </div>
                                ))}
                            </div>

                            <div itemScope itemType="https://schema.org/JobPosting">
                                <meta itemProp="hiringOrganization" content="ReelMetrics" />
                                <meta itemProp="jobLocation" content="ReelMetrics Headquarters" />
                                <meta itemProp="employmentType" content="FULL_TIME" />
                                <Well
                                    title="We're always on the lookout for new talent"
                                    link={{
                                        title: "View our job openings",
                                        target: "/careers"
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default Team;
