import React from "react";
import ReactGA from "react-ga";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E08 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname); 
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S03E08.defaultProps = {
    id: "15628363-s03e08-zitro-usa-with-derik-mooberry",
    link: "https://www.reelmetrics.com/s03e08-zitro-usa-with-derik-mooberry",
    title: "Zitro USA with Derik Mooberry",
    subTitle: "Season 3, Episode 8",
    description: "In this episode, Nick and Don speak with Derik Mooberry, CEO of Zitro USA. Learn about Zitro's rise from niche electronic bingo provider to a global supply-side powerhouse, its plans for the North American market, and the company's increasingly compelling Class III slot portfolio. Also in this episode, leveraging superstar inventory to dial-back free play.",
    episodeNumber: 8,
    seasonNumber: 3,
    datePublished: "2024-05-07",
    topics: [
        "Zitro's company history and evolution",
        "Entry challenges into the U.S. market",
        "Class III slot portfolio",
        "Leveraging superstar inventory",
        "Configuring games for different markets",
        "New cabinet launches at G2E",
        "Volatility settings and player preferences"
    ],
    keywords: [
        "Zitro USA",
        "Derik Mooberry",
        "slot machines",
        "casino gaming",
        "G2E 2023",
        "gaming market share",
        "class III gaming",
        "gaming cabinets",
        "bingo to slots transition",
        "slot volatility",
        "game configurations",
        "gaming innovations"
    ],    
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/15628363-s03e08-zitro-usa-with-derik-mooberry",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e08-zitro-usa-with-derik-mooberry", 
    transcript: `Nick Hogan:
Good morning, Don. How are things in the Midwest today?
Don Retzlaff:
Good morning, Nick. It's beautiful. It's another year where August is cooler than June and July. It's maybe 80 degrees today in St. Louis. So not bad at all.
Nick Hogan:
And you haven't been blown away by a tornado, which is good news.
Don Retzlaff:
Yeah, no severe weather at all in months.
Nick Hogan:
Okay, cool, cool.
Don Retzlaff:
What's weather like over there in the summer?
Nick Hogan:
Oh, maybe we get into the eighties sometimes. Sometimes we get sun if we're lucky, but other than that, it's just Northwestern Europe, lots of gray, so it's a mixed bag, but it's not too bad here in this time of year. So, okay, so industry news out last week, Don, I caught a headline about maybe it was a couple weeks ago, about further delays in New York City's selection process for the city's three proposed casinos. Now, Don, you and I, we know several people who've been wrapped up in these bids from the word go, and it seems like each time we see them at a trade show or other industry events, the question, how are things going, New York? Is invariably met with a long hefty groan. So after seeing that headline and I then read a New York State senator had referred to the process as quote an embarrassing picture of an ineptitude, I decided to take a closer look at that structure there and see what's going on there.
And indeed, it seems like a bit of a red tape nightmare. So as I understand, as opposed to the process that led to the upstate openings back in 16 and 17, the New York State Gaming Commission is actually not the body with final say over these licenses. So instead, the state created a five person gaming facility location board, which is going to render the ultimate decision. But this board can't even review proposals until they've been ratified by two thirds majorities by, so-called Community advisory committees, which are reviewing the details of the projects proposed for their given municipal communities.
So we have committees, piggybacking committees here, so it sounds like. In any event, the original deadlines for proposals was 31 August of this year with the community advisory committees to be announced and seeded no later than 30th of October. And then from there, the advisory committee votes were to be submitted no later than 27 February of next year. However, the state has now announced that the last milestone is now the first. So proposals are due by 27 February with the advisory committee votes due by 27 June. So that's a nine-month delay. And what insiders are saying is that they don't really expect it to be the last. So Don, it looks like it's going to be a while before you're bankrupting the big apple with your slot play.
Don Retzlaff:
There's always delays. I've been waiting for a New York and Texas it seems like for 20 years.
Nick Hogan:
Yeah, there you go. Japan's another one. Yeah, it just keeps on going. Okay, so we can move on to listener questions here. Don, before I do, let me say that we love to tackle any questions that anybody listening may have. If you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. That's R-E-E-L C-A-S-T#Realmetrics.com, our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity, not something that we do.
So Don, this one comes from an operator in the American Midwest who asks, hi guys, in the last episode you were discussing free play and Don made the comment that if you have the right product set at the right hold percentages, you can safely dial back the free play. Can you expand on this a bit? PS, if you're ever in the neighborhood, please come see us. We'd love to buy you guys dinner. Don, so first of all, thank you to the listener for that question as well as the dinner invite. Trust that we'll be cashing in next time we're in the hood. So Don, do you want to tackle that question there?
Don Retzlaff:
Oh, that's such a tough one. I ran a casino that had a very low penny hold percentage, and it really worked well for us. We didn't have a lot of amenities and it gave players a chance to have time on the vice without subsidizing their play. And then we've really seen a big change since COVID and we've seen a lot of companies dial back on free play. And it's worked, especially for those customers that have say an ADT below 150 or $100. So many of the casinos that we work with have a rated slot play of 50 to 70%. And so you're not hitting those players anyway, they're a good chunk of your players with free play. And we've really seen that the games that you select really has a big influence on how long they're playing and what their average bet is.
And that's been the big difference that we've been seeing. The top 50 games out there have such a longer session time and have such a higher average bet that companies can dial back on their free play. And if you want to push it towards your better customers, which shows the majority of your revenue. So it's always a tough one. I've had bosses that say, "We don't compete on hold, we compete in the mailbox or in their email box." But there's so much of that play out there not as unrated. And I know a lot of the chief marketing officers out there are trying to figure out how to get those customers. I think the best way to do that is giving them something that they can play.
Nick Hogan:
Yeah, I think what's been so interesting for me in these various experiments that we've conducted where we're altering inventory composition to really appeal more to your high end host level players. I mean, one of the controls that we put in for every single experiment that we conduct is no monkeying with free play. So they're not touching it at all. And what do we see? Well, we see when we alter that inventory composition and give them stuff that they want, the budgets that they were previously withholding all of a sudden come gushing in. And that's with no modifications whatsoever to free play. And it's just, again, free play it just warps my mind. It's one of those topics that just drives me nuts every time I start talking about it.
Don Retzlaff:
We always had that argument that if you're in a competitive environment, you have slot machines, your competitor has slot machines, you have tables, your competitor have tables. And the difference is obviously customer service is obviously a big thing, and that obviously is super important. But I think we've proven that just because you have a thousand slot machines and I have a thousand slot machines, there can be a huge difference in the quality of those slot machines if you give the customers what they want. We've seen it time and time again, we are really increasing the theoretical per customer, and we've doubled some of the theoretical by changing the mix, and that's all without touching free play. So we've increased theoretical massively, especially with host level customers, just by giving them the games that they want to play.
Nick Hogan:
Yeah. Okay, great. Well, thanks again to the listener for that question. Massively appreciated. And again, we're we're going to take you up on that dinner invite next time we're in your neighborhood there. Okay, Don, so time to introduce today's guests. So two firsts right at the top, Don. This is our first guest to graduate from UNLV, as well as our first guest to have passed a CPA exam. So it's two things there. Our guest, after graduating following a six-year stint with Arthur Anderson after school, he began his 23-year industry tenure with Valley Technologies where he spent 13 years ultimately ascending to senior vice president of gaming. And then following the Cygames acquisition, he served as a group CEO of gaming for several years, then continued on in advisory capacity through the company's transition to today's Light & Wonder. These days he's serving as CEO of Zitro USA, and I'm referring of course to Mr. Derik Mooberry. So Derik, howdy. How are things in the silver state today?
Derik Mooberry:
Fantastic in Nevada today, and thank you Nick and Don for having me on. I really appreciate it.
Nick Hogan:
You betcha. And I need to confess something at the top, Derik, I was not aware that you had a CPA background actually, and I think I've known four or five former CPAs who became gaming executives and all of them were absolutely stellar executives. Fantastic. And I would say that all four or five had three things in common. One was numbers driven to the core. Number two was just tirelessly grounded and logical. And three was they're absolutely delighted. They no longer work in accounting. So I was curious if you're in that group.
Derik Mooberry:
I think I actually can check all three of those boxes, yeah. So all those are very applicable to me. I think that's a fantastic analogy. And I often tell people I love the fact I have a CPA background. It helps me, I think, give a great understanding of how to run a business. But I also tell people that CPA for me probably stands for, I couldn't pass again, the test if I was required to. That's what probably CPA means for Derik today. But it was a great career and Arthur Anderson is what really gave me my start, obviously in my post UNLV world, which by the way, I'm honored to be your first UNLV alum on the show as well.
Nick Hogan:
Yeah, absolutely. Yeah. Fantastic. Well, so Derik, now you're a guy who's well known in the industry, but for those who don't know you, could you give us a bit of a walk through your gaming career? So how you started and how you came to take the helm at Zitro?
Derik Mooberry:
Yeah. Well, it really started, you gave a little brief overview. I mean, I had a passion for travel and hospitality, and I came to UNLV, I grew up in the Midwest, the state of Iowa, which you well know, obviously the little Iowain. And had dreams of getting into the travel industry and ended up getting my degree in accounting from UNLV and went to work for Arthur Anderson, which doesn't exist anymore, but was the leading accounting firm in the Las Vegas market and the leading accounting firm at the time for the gaming industry. And really what fueled the rest of the career from there was all my clients, my audit clients were gaming customers. So that got me experience into the inner workings of a casino. How does the cage balance, how does slot audit work? How does table games work? All these different things, because that was all part of the audit process itself. And I had an opportunity to leave Arthur Anderson and go to work for a small company that ended up getting bought by Bally, it was a software company called Casino Marketplace in Reno.
Nick Hogan:
I remember them, yep.
Derik Mooberry:
Yeah. Player tracking software that connected to the Bally SDS system. And we were acquired by Bally and that started my long career at Bally where I literally did everything. I had the pleasure of, I worked customer service, I did system sales. I eventually moved over to game sales. I ran Latin America for a while, don't speak any Spanish, but somehow ran Latin America, then was asked to run engineering for both games and systems as well. Here's a guy, a CPA, now all of a sudden running engineering, so how the heck does that work? But I was blessed to have really great people around me.
As you mentioned, in 2014, Scientific Games acquired Bally and I was fortunate enough to be selected as the CEO for the gaming division and had a great career there as well. Left there, sat out for a little while under a non-competition agreement, and then Johnny Ortiz who owns Zitro, reached out to me and said, "Have you thought about trying something different and trying to come and start something new for us? We're a global company, we've got a great presence in some other markets around the world, but we want to get in the US and we need a U.S leader." And I love the passion that Johnny had for the products, and it's been a great fit. I've been here two and a half years now.
Nick Hogan:
Okay, cool. Well, yeah, so my knowledge of Zitro goes back maybe 15, 20 years, something in that realm, but I knew them from the electronic bingo market and then also the Spanish Street market. And this is a company that has obviously grown and diversified massively since then. So can you walk us through maybe a bit of company history, how the company has evolved?
Derik Mooberry:
Yeah. So in terms of company history, we're a young company compared to a lot of our competitors. We just started in 2007, and you're right, our roots are in Bingo, specifically in the Latin America market. And then obviously you mentioned the Spanish market as well. And we've had tremendous success with that. And that allowed the company to grow significantly in those early years. And it was around 2016 that our owner made the decision that if we're really going to be a global gaming supplier, we have to do class three slots. That's the business.
And so we started making our first class three slots, and that grew tremendously as well. It gave us yet another market segment. Now we do obviously more class three slot revenue than we do bingo revenue. And that's changed significantly over the years, and it's really allowed us to continue to grow and flourish. And we're not just a little regional company anymore. I mean, we have offices all around the world. We have market-leading positions in many countries, and our vision is to be viewed as a true global supplier of content all around the world, not just a little regional supplier. And we're well on our way to doing that.
Nick Hogan:
Now. Was the progression there, so it started really in Spain, and then was it the model like Unidesa, a few other Spanish companies that then really went into Latin America? Was that the progression there regionally?
Derik Mooberry:
Well, it was really Latin America and Spain together and it just has taken off. And Spain is our corporate headquarters, our development teams, our R&D resources, our base there, but it's the bingo roots from Latin America is really the founding part of the company.
Nick Hogan:
Gotcha. Okay. Okay, cool. Now, obviously for foreign companies, we know several cases that have occurred. Tackling the US market can obviously be a very lengthy, complex and incredibly expensive undertaking often with fairly meager success rates. So can you give us a sense of the strategy here and discuss how Zitro is approaching this?
Derik Mooberry:
So first off, you are spot on, extremely complex and complicated and expensive. You're right on every single one of those fronts. And I probably didn't have an appreciation of how difficult it was until I really joined Zitro because my history was Bally and Scientific Games. We were one of the largest suppliers in the world. I mean, we were already well established. So when you got to put a shovel in the ground and start it from scratch, it's a whole different scenario, obviously. And so we're on a process to make ourselves a well-established supplier here in the US. But I would tell you this isn't our first try. And I often say through failure comes success.
And we initially thought that we could take our bingo products here more than a decade ago and brought the Bingo products and thought that G and maybe some of the markets like South Florida and some of these like, "Boy, those bingo products would really do well." And it just didn't work. I mean, it just didn't translate to the consumer. They just didn't play the games. And then a number of years ago, we also tried to bring some of our first class three slots to the US as well. And I think what we saw was we just weren't quite ready for the US market. The US market, as you guys know, is extremely competitive. It's got some of the best slot products in the world come from the United States market, and it's extremely competitive with all types of different vendors.
And so was really when 2019 that we really are now making this next wave or next attempt to enter the market. And I'll be honest with you, it was one of the first things I asked Mr. Ortiz when I joined the company is, "Why do we think we'll be successful now?" And that's a great question, why? And I thought his comment when we were talking about me joining was fantastic in that he said, "Listen, our initial maybe class three products weren't as good as they could be," And he said, "But now I've seen a transition for us. We've made significant investments in R&D and resources and design talent." And he says, "I've also seen markets that we've been in, in Latin America really transition." And he says, "Take Mexico as a great example, used to be primarily a bingo only market."
Nick Hogan:
Absolutely.
Derik Mooberry:
And then what's happened is the Light & Wonders and IGTs and Aristocrats have all come in there and the market has really transitioned to a class three slot market.
Nick Hogan:
Oh, a hundred percent.
Derik Mooberry:
And he says, "I've seen now our products can go toe to toe with some of the best products from the best vendors in the world." And he says, "That gives me the confidence that we can go to the US now and we can compete and our products are good enough now that we can see that." And we've seen that over the last two years as we've made this entry. When I joined, we were in three states and I think we had 30 machines in the market, and now we're, I think in 16 or 17 states. We've got over a hundred casinos with our products in them throughout the United States. So we've seen tremendous growth over the last couple years and customers have welcomed us in, which has been great. I think it is not easy as a small supplier to enter because as a casino operator, as you know, they have a lot of great choices of products out there. And competing against the biggest and the best in the world is not easy.
Nick Hogan:
Correct, yeah. And now you referred to a couple of things there, Derik that I'd like to dive into. So you've had really unique perspective here in that you were obviously running a supplier as an industry leader in the top three with Bally and Cygames. Now you're with a much smaller, so can you give us a sense of the unique sets of challenges to each of those, because that is a very interesting pair of perspectives that you have there.
Derik Mooberry:
It is. I've had really a great opportunity to see both sides of the coin. When you're the biggest in the industry, growth is always a challenge. I always like to say as a numbers guy, you run into the law of large numbers.
Nick Hogan:
You can't run underneath.
Derik Mooberry:
Yeah. When you're some of these big guys that are doing a billion five to two billion a year in revenue and somebody says, "Gee, we want to grow 10% a year." Well, you start thinking about 10%, well, that's the size of some of the other companies. So you're trying to grow by the size of an entire company in a year. That's tough to do. And so when you're with the big guys, that level of innovation that you have to bring to get that kind of growth is very challenging. And then from the smaller vendor perspective, and I always like to tell people, well, I think of Zitro small because running the US and we are small in the United States.
I mean we're just getting started, but we're not a small company and we got 1200 employees around the world, and like I said, market leading positions in many of the other markets, but we have to establish our brand here. And so for me, as someone running now a smaller slots to the buyer in the United States, it's about trying to get people aware of who we are, let them know that our products can compete on floors and get ourselves licensed in all these markets, which as you guys know, is a long complicated process in many cases.
Nick Hogan:
Oh, yes.
Derik Mooberry:
But what we've seen though overwhelmingly is customers have opened their doors to us, and I think customers want to see variety. They want to see choices. They want to be able to provide a diverse floor and different styles of cabinets and themes to their customers because I think that gives them the most unique offering. And so we have been very, very pleased with how operators have received us and given us chances to succeed on their floor.
Nick Hogan:
Okay, excellent. Well, and also as part of the perspective as you're doing this, I'm just curious if you've seen things like now that you had the ability to step back from it and look at it a bit from the outside and then come back in and these different roles and different perspectives. I'm just curious about aspects of our slot industry that you maybe feel in particularly the relationships with operators and suppliers and whatnot, but just aspects of the industry that seem broken to you. Do you see things like this from your perspective there?
Derik Mooberry:
I do, and this is the numbers side of me a little bit. I love looking at numbers and looking at them in different ways. And I wish people would analyze slots a little differently than just house average and section average. And when I ask how our machines are doing, I usually get a report that says it's some index of house or it's some index of section. And I don't know that that tells you much, in many cases, I mean, I had someone give numbers the other day and my games were doing okay in a section, and then I found out my games were in a section that included some of the most popular premium games in the industry. Well, and when I'm selling just a for sale product to even be doing close to section average would be really, really good.
So I think there's got to be unique ways, in my opinion, to start breaking down similar and products. And I even see it in some of the differences in just cabinet form factors. You can put the same game in a dual screen or a triple screen upright and then compare that same game into a vertical screen cabinet with a curve on it. You're probably going to get different performance because we know, I think one of the things that I learned in my early days at Bally was hardware really does matter. There was always a belief that software, it was all about the content and the players eventually would find the best games. And I think that's still true. Players ultimately play the content. They don't play the cabinet
Nick Hogan:
For sure.
Derik Mooberry:
But I think there is a huge amount of what I call curb appeal in hardware and what draws the player to the game originally. And I think we've seen the effects of that. And so I think sometimes the reporting and the analytics that we look at has to go much deeper than some index of house or some index of section average. [inaudible 00:25:16].
Nick Hogan:
That's a very interesting insight, and I can tell you you're going to love what we have planned for Q4 because we reach very much as we shifted our model a little bit, and we were doing not only performance aggregation and look at all the config data and looking at all the meter sets, et cetera, et cetera, but a few years back, we really dove into aggregating player data and now have these archetypes and things of this nature. So now very much, we're far more focused these days on with whom it's hitting. So that's a very different perspective, and we're going to roll that out a little bit later this year. But I agree with you wholeheartedly.
Derik Mooberry:
Super excited. Super excited to see that. And we don't really ever see as a manufacturer player related data, which would be amazing to see because it'd be just interesting to see what kind of demographics are the players that like to play our games. It might influence how we design a game in the future. The more information we know as a manufacturer of content, the more we can help operators make money. I mean, that's really the job we're doing, we're trying to put profitable products on their floor to help them optimize the player experience and give them a profitable return too. So any data sharing, we welcome it. Obviously we study those things. Any data get, trust me, we sit around the conference room table here and study a lot.
Nick Hogan:
Oh, I believe you. Okay. Well, so now as we're touching on products, I can just see Don just sitting there chomping at the bit. He's got all kinds of product questions he is going to throw at you. So Don, you want to take it away? Love it.
Don Retzlaff:
Yeah. Thanks Nick. Yeah, sitting here thinking about which players are playing your games and their average bets, and we get pretty deep into that type of things. And one of the neat things that I got to do years back was at one of your prior companies was I got to sit in with some of the research and development teams and see how they work. So it's always been super fascinating to me. Obviously I've seen thousands of themes produced over the last 30 years, and obviously it's key to selling games. Can you tell us about your research and development company, your part? You said it was in Spain, and are you building themes for a worldwide audience, or are you now building and targeting themes for the American audience?
Derik Mooberry:
Yeah, those are great questions. Yeah, so today our R&D is primarily based in Spain. As of probably a couple years ago it was a hundred percent based in Spain, but we've now started an India development center as well. We've got about 50 people in India, and that's growing as well. And we're looking to expand our R&D even broader than that, I think over the next couple of years you'll see us broaden our R&D efforts into potentially some other Latin America markets and then obviously into the United States as well. And so we've been growing it dramatically as the company has grown as well, which is exciting. In terms of the design, right now we're trying to make games that appeal as broadly as we can to as big a audience. But you raise a great point. Within a game, when we design a game though, we have many different math models, and each of those math models is oftentimes targeted to a specific market. So the model we may use in the United States may be a very different math model than what is used in say, Mexico
Because the betting is different. The RTP configurations are different, the progressive setups are often different. And I would probably tell you that based on my experience, what we're doing here at Zitro, we have probably some of the most configurable games in the world. I mean, we have more options and settings in our games than probably most others do. And we're still designing all of our games as multi-game as well. And so that's a little unique to us as well. So when you see a theme like 88 Link Lucky Charms from us, there's actually four separate themes underneath there with different math models as well. A lot of times people think, "Oh, well those just must be graphical clones with the same math model, and you put just different characters in there." Not the case at all for us. And in some cases, the math on our games in the sub themes can be wildly different.
We put out a game called Fu Pots in the last six months or so, and I can tell you the volatility differences between the sub games is dramatic actually. And we've seen dramatic difference in the performance. And so because when we set up in the US, we don't usually run them as multi-game. We pick one of the four themes to run it as the primary game and in the international markets we'll often run them as multi-game again, because in a lot of Latin markets, the players prefer the multi-game set up. So again, that's a lot of our flexibility, but yeah, then we'll tweak the flexibility based on the market that we're actually entering into.
Nick Hogan:
Now, Derik is that selectable by the operator. So for instance, could they choose to run either multi-game format or single game format?
Derik Mooberry:
Yeah, it is. And in the US, we have customers that do that actually. They say, "Hey, I know you don't recommend multi-game in the US isn't as popular in the US but we just would like to offer something different to our customers. So do you mind if we run a multi-game and we say, "By all means, go for it." Yeah. I mean it's not our default configuration in the United States, but when customers say, "I'd like to do it," we always say, "We encourage it."
Don Retzlaff:
Gotcha. You mentioned volatility. Where do you rank your games as far as volatility? Are you creating high volatility games, more time on device games? Somewhere in the middle, range of all?
Derik Mooberry:
I would say we don't generally do much, what I would call time on device games. That's not our design profile. We're doing what I would call more of the medium and medium to high range on the volatility side. And everybody's definition of volatility though tends to be a little different. I've learned over-
Don Retzlaff:
Oh yes.
Derik Mooberry:
... Over time. And I can give a great side story on that. We had a game come out and it's a multi-game. And of the four sub themes there was everywhere from a medium volatility to a high volatility. And I went to this one customer and he says, "Well, let's do half-and-half on the bank. Let's go half medium and half of the high." And I asked the question, "Which do you think will do better?" And he says, "Oh, absolutely the high volatility, that's what my players love. They love the longer periods, but yet to get to the bonus, but when you get to the bonus, it's really going to pay meaningful, less base game pay, et cetera." I said, "Okay, perfect. Let's see, let's let the numbers see." It was the exact opposite. The games with the medium volatility, we're doing nearly 50% higher win per unit than the high volatility ones.
And I equated it to, I think it's sometimes when you go to a Thai food restaurant and they ask you on a scale of zero or one to 10, how spicy do you want it? And the consumer says, "Oh, I want the 10." And then he goes back and tells the chef, "Trust me, he wants a five." And I think sometimes that's how sometimes we sometimes can misunderstand players and what players want. We think they want super high volatility, but maybe they don't, maybe they're more of the medium volatility. They want some base game wins along the way because some of these games, if you get into the high volatility, the time between bonus rounds can get lengthy. And it all depends on, as you guys know, session time and how much someone's willing to sit before they get that experience. And we don't want them to become too frustrated before they get that. Obviously that's the things we talk about all the time in terms of the design factor.
Don Retzlaff:
Yeah. One of the neat things that we've done over the last couple of years is we've actually tracked every slot theme and the percentage of winning sessions that customers have on those themes because obviously everyone rates their volatility differently. It's a one to five scale, a one to seven scale, and then everything's different according to that. And we see ranges of winning sessions from 7% to 27%. But there is definitely a sweet spot in there where like you said, it's those games that are in that medium to medium high. So probably in that six to seven, on a scale of one to 10 range that do really, really well, there are certain themes that do well above it. There aren't many themes time on the device wise that do well, especially with host level players anymore. Your time on the device games is mostly now your low theoretical customers. So I'm glad to hear that you're doing more of the five to seven range. That seems to be the sweet spot.
Derik Mooberry:
I think it is too. And that's really the conversations that we have about how to design games and where we think... And because obviously as a small vendor too, we have a unique challenge that might be different than the large manufacturers who are trying to spread a lot of things. I'm trying to break into casinos here in the United States, so I've got a... I mean literally break in sometimes, I'm trying to push the machine through the door. I have to bring my best foot forward from the start. I can't afford to miss because I am developing my brand here. So I got to come out with what I think is the highest chance of success or what's the best winning formula from the get-go. And I think you hit it right there, it's that medium high range in terms of a game set.
Don Retzlaff:
Yeah, you mentioned the configurations, and one of the big things that we've seen in the last couple of years is a higher average bet per spin. Nationwide on pennies, we're seeing about $1.55, a spin or so, which has doubled over the last decade easy.
Nick Hogan:
Yeah, easily.
Don Retzlaff:
And now we're seeing some penny games that are north of $3 a spin, especially some of the new releases from this year. Are you targeting a certain average bet in your themes or does it happen organically or with the customer configurations? Starting with things like cost to cover, things like that, your line counts. Did you have a sweet spot for your average bets?
Derik Mooberry:
I think it varies, and I think it varies by casino and market that we go to. And I think your comments are very interesting to me because it seems like it wasn't that long ago, and I remember 68 cents was a great bet.
Nick Hogan:
Exactly.
Derik Mooberry:
You got 60 and that wasn't that long ago. I mean, we're not that old. That wasn't that long ago, 68 cents was a great bet. And you're right, and I would say based on the stuff we're seeing, even 1.50, I mean you want to be above 1.50. I mean, you're kind of looking for stuff really in the 1.60 to 1.75 range in my mind, in terms of average bet. And I think we try and I guess design it to get the most out of it. I think we're doing the same thing that a lot of people are doing.
You're certainly multi-denom, pretty much every game we put out is in at least one, two and five cent denom, sometimes more, sometimes 10 cents as well or even higher. So we're giving the player, if the player's winning and having fun and they really want to go all in and play and be a real gambler, we're giving them the choice that they can spend a lot of money if they want to. And I think when you look at capturing wallet share and really increasing average bet, giving that high roller the opportunity to really spend and enjoy the experience, I think is critical. And so we try and set the games up in that way the best we can. I think in terms of cost to cover, most of our stuff's in kind of that 60 some odd cent to 80 some odd cent cost to cover. We did some interesting studies though with some of our own machines, which I found a little fascinating actually. We went to a couple of large casinos that easily could have done, let's say the 88 cent cost to cover.
And we initially had the game set at 88 cents cost cover, and we had some conversations about let's maybe just try and lower it to 68, let's see what it does. Does it bring down win because we gave the consumer an opportunity to bet down, or does it attract some new consumers who might've walked by our game midweek who were really only looking for a 68 cent game? And strangely enough, we saw that sometimes lowering the cost to cover actually increases overall win. And my take on it is there's a fair amount of midweek play, the person who's not the 150 ADT player, they're more like the $40 coming for quick visit, cup of coffee, got a few bucks in my pocket, and they want to try and maybe make that last a little bit. And if you're at 88 or a dollar cost to cover, maybe your game is too expensive and they walk right by.
So maybe you're picking up some of that midweek play with some of these lower cost to cover options. It depends, every operator is a little different. These are the dialogues that I love having with operators. I mean, I love hearing their thoughts and opinions. I mean, obviously the games are going on their floor and we'll configure them how they think is best for them. But I love operators that are willing to work with us and experiment with these types of settings too, because it's fun. To me, this is a little puzzle at times trying to solve things like just how very small deviations in the setup can actually make really meaningful differences in win.
Nick Hogan:
Oh yeah. We see this all the time. It's a huge feature of what we do is guiding people on configs and you see complete reversals of fortune, they do this.
Derik Mooberry:
Yeah, it's amazing. I mean, you can really change a game with very small differences in settings. I mean, it's just shocking to me. And a lot of times people think, oh, that's just a small little change. These little changes are a big deal in the long run.
Nick Hogan:
And like you said earlier about the law of large numbers is that stuff stacks up pretty quickly when you're talking about a four or $500 daily premium due to config changes, that becomes big money quick.
Derik Mooberry:
Yeah.
Don Retzlaff:
Tim Horner, our head of North American sales, and I did a presentation for one of your competitors last summer, and we actually broke down two of their themes and showed them exactly configuration options that worked and ones that didn't. We showed them in a graph that, here's your games that are doing above two times house average and how they're set up. Here's your games that are doing house average and how they're set up. And you can actually see it for hold percentages, line counts and location, where these games were successful, where they needed to be. And if you put this game at 50 line count at a 12% hold, you killed it. If you had it at a 75 line count at a 10% hold in even a decent location, it was one of your stops. And we showed it for both their lease games and their core. So you're right, it's very fascinating and it's a puzzle.
Derik Mooberry:
Well, for those of us that love math and numbers, it's a fun exercise. And that's why I always say we're so blessed to be in this industry. What a great industry this is. And to be able to try and work with these types of things on a day-to-day basis is how much more fun can you have coming to work?
Don Retzlaff:
Yeah, exactly. I was even having fun yesterday prepping for this podcast, and I looked at all your new titles, say the last two years or so. And you're right, your average bet on all of your penny games is $1.70 plus or minus 5 cents. And a lot of them, I went down the line, it was $1.67, $1.68, $1.72, so you're right where you want to be, which is really good, which is a step above what that average is. So they're getting decent play. So how many themes do you plan on releasing this year and next year for your cabinets, especially for the North American audience?
Derik Mooberry:
Yeah. Well, a couple things. One, at G2E, we're launching a new cabinet as well. So today we've got four cabinets on our Glare lineup. We're going to be launching a new cabinet that'll be coming out at the show, so that's super exciting as well. And there'll be some different variants of that cabinet, which will give us a little bit more diversity yet in our portfolio in terms of how we sell. And the great thing about that is a lot of the games are transferable across the cabinets, meaning they work on-
Nick Hogan:
Oh, fantastic.
Derik Mooberry:
Yeah. And I think customers like that. They hate it when you come out with a new cabinet and they can only get the theme on the new one. So pretty much all our themes are going to be portable amongst the cabinets, and I think that'll be a great story for us at G2E. [inaudible 00:43:22].
Nick Hogan:
Oh, it's fantastic. It's a great idea.
Derik Mooberry:
Yeah. So we're really excited about that and we're really excited to show the new stuff at the show. And then in terms of quantities of themes, we do about what I would call 17 to 20 families of themes a year. Now, keep in mind, in a family of us could be two to four different sub themes and math models. So you're looking at 60 to 80 games roughly per year that we're going to bring into the North American market. So we're not doing just a small amount of content, we're putting out a fair-
Nick Hogan:
Definitely not. That's a lot of games.
Derik Mooberry:
It's a lot of games and we're super excited about our lineup. We think we've done a really nice job, I think building a foundation here in the US, but we're a firm believer that our best is really yet to come. The stuff we're working on is really exciting, and I've continued to see just the amazing progress from the team in my two and a half years here that Zitro has really taken itself to a new level and we're really confident that we're going to be a real meaningful supplier here in the US someday.
Don Retzlaff:
Well, I could see that. I can see it in the numbers and the cabinets that I'm seeing placed, especially over the last 12 months, 18 months. Some of the placements that we're seeing on the Allure cabinets are two times house average for things like that you mentioned earlier the 88 Link Lucky Charms themes, they're doing really well. Do you have a ship share or cabinet sold goal in your head for the near future for Zitro?
Derik Mooberry:
Yeah. I know this will sound strange, but in the US, the supplier market's about a $4 billion market at some point. We'd love to be a 1% supplier in the US to start would be great. To grow to that. I mean, we want to keep our goals manageable, and I think we can grow significantly beyond that, but that's really where we're at. And what that takes though is getting out and first getting all the licenses, which is complicated in all these markets. And then we take it just one bank at a time. I mean, literally every bank for us is a big win and an opportunity.
And every new casino we get into is super exciting. Every new market. And it is a one bank at a time strategy for us, which is different than, again, a biggest supplier who if they don't sell five banks a games to a customer, they're disappointed. And so we like to start with one, prove ourselves to the customer. And when you get, as you say, some two times house average numbers on a game like 88 Link Lucky Charms, it's a lot easier to go back to the customer and say, "Let's talk about a new cabinet or a different cabinet and maybe another set of themes." And that's really our placement strategy. It's really that simple, one bank at a time in every casino.
Don Retzlaff:
And that's the way it works is especially if you have games that work really well at two times house average, because if not, the first thing I'm going to ask you, when you come back and say, "Okay, fix the games that we've got on the floor first." It's a lot easier to sell from a position of strength. I remember buying my first Konami Banks when they first came out, incredible technology banks. It was six, it was eight, it was 12. And then if they did well, next year you got another six or eight or 12. And we're starting to see that now.
Obviously there's been some momentum with some of the smaller manufacturers the last couple of years, and we're seeing stuff from Bluberi and Ainsworth, Aruze IT. There's several of them now that are starting to sell those six to 10 to 12 cabinets per casino because they've got the themes. And we're seeing a lot of six packs or four packs with one or two themes that are working really, really well for these companies because it doesn't test their depth of their theme base, you've got three or four really good hits if you put two of them on, you've got something else to fall back on 18 months from now when these games do start to slide a little bit.
Nick Hogan:
Yeah. Yeah.
Derik Mooberry:
I think you hit it. I mean, if you're a slot operator today, you have probably some of the best choices that you've ever had in terms of buying and taking on new content. I mean, there's just, obviously the big guys are making amazing content. I call them, the big three are making wonderful, wonderful content and they're very tough competitors. But amongst the rest of us, boy, when I walk a trade show, I'm just amazed. I mean, there's some great games out there now to choose from, and as a slot operator, it's great, you got more choices than you probably ever had before.
Don Retzlaff:
Yeah. There was a time where you weren't buying machines from certain manufacturers because of the lack of titles. And now if I'm buying machines, I'm including AGS in my purchase because their games are doing so well. And obviously the big thing for Zitra was to get more and more licenses out there because you look at a wide swaths, especially like the Midwest and all that, and you, okay, got some stuff in the upper Midwest, but not a whole lot in the Midwest. But I guess the last thing I wanted to ask you about was recurring revenue themes, because I don't see any lease or premium type games from you. Are you considering doing that or are you concentrating more on cabinet sales and game sales for the near future?
Derik Mooberry:
In the short term, more just the sales strategy. Although someone can lease just our standard for sale stuff too, if they want. We have very affordable lease rates. If they say, "Hey, either I don't have capital and I'm looking for a lease product." We can be a nice lower cost option than some of the premium branded stuff that you're getting from some of the others in the industry. I think over time you'll see us get into that segment and start to develop some lease only content for the future. But in the short term, our strategy has really been trying to get into the biggest percent of the floor space, which as you guys know, is the owned space. And try and get ourselves into that, establish ourselves, establish the brand, and then we will expand from there into the more competitive lease footprint. And as you know, that's the most competitive space. That's where the bar is very high to stay on the floor and now you are-
Nick Hogan:
And it's not cheap to compete in that space either.
Derik Mooberry:
Yeah, I mean, you're either spending, as I know from my previous life, that you're either spending a lot of money on brands or specialty hardware and sign packages are extremely expensive as well. And so we want to be in that space someday. There's no doubt that's a great space for slot manufacturers to be in, but for us in the short term, it's about establishing ourselves in that for sale segment.
Don Retzlaff:
Okay, thanks.
Nick Hogan:
Great. Well guys, I see we're coming up on the hour here. So Don, did you have any additional questions that you want to ask Derik today?
Don Retzlaff:
Derik, was there anything else you wanted to promote? I know we talked about G2Es upcoming here in less than two months. Anything else on the Zitro front that you wanted to talk about?
Derik Mooberry:
Yeah, I think, as I mentioned, super excited about our G2E lineup. I think when customers come to the booth, I think they're going to see yet another new chapter in the Zitro story with new cabinets and even new hardware. And if it's someone that came and visited Zitro several years ago, they probably won't even recognize us. And so we hope that many, many people will come by and continue to see our story and listen to us talk about how we really have a vision of being a global supplier. And that includes a significant presence in the US.
Nick Hogan:
Okay, fantastic. Well, you can expect we're going to be showing up and crashing your booth there at some point, Derik. So get ready for that.
Derik Mooberry:
You guys are welcome anytime.
Nick Hogan:
All right, fantastic.
Don Retzlaff:
We walk your floor every year and always been fascinated by the cabinets and the content. Obviously I had a casino in Illinois, I couldn't get your games, but I always enjoyed walking through your booth and looking at it, and I was always impressed by the details of your games. [inaudible 00:51:58].
Derik Mooberry:
Yeah, that's a lot of substance there. That is great. Thank you so much. Appreciate that and look forward to seeing you guys at G2E.
Nick Hogan:
Yeah, thank you Derik. And best of luck with everything. And yeah, we'll be seeing you here in just a few weeks. I guess it's coming up quick.
Derik Mooberry:
It will be here before we know it.
Nick Hogan:
Okay, well thanks again, Derik. Appreciate your time.
Don Retzlaff:
Thank you.
Derik Mooberry:
Thanks.
    `,
};

export default S03E08;