import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S01E01 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S01E01.defaultProps = {
    id: "11399244-s01e01-inventory-overdiversification-part-i",
    link: "s01e01-inventory-overdiversification-part-i",
    buzzSproutLink:
        "https://reelcast.buzzsprout.com/2057836/11399244-s01e01-inventory-overdiversification-part-i",
    spotifyLink: "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s01e01-inventory-overdiversification-part-i-104029262/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s01e01-inventory-overdiversification-part-i/",
    title: "Inventory Overdiversification",
    subTitle: "Part I",
    description:
        "Tune in to the inaugural episode of ReelCast, where we explore inventory overdiversification and its effects on casino slot floors. Discover strategies to optimize your inventory and boost performance effectively.",
    keywords: [
        "Inventory overdiversification",
        "Slot machines",
        "Slot floor optimization",
        "Inventory management",
        "Performance improvement",
        "Casino operations",
        "Gaming industry",
        "Revenue enhancement",
        "Player satisfaction",
        "Anecdotal referrals",
        "Floor apportionment",
        "Depreciation rate",
        "Cognitive dissonance",
        "Data-driven decisions",
        "Conversion rates",
        "Lease games",
        "Marketing and operations coordination",
        "Player ratings"
    ],
    topics: [
        "Understanding the impact of overdiversification on casino performance",
        "The role of data and analytics in optimizing casino floor inventory",
        "Best practices for managing and updating slot machine inventory",
        "Collaboration between marketing and operations departments for casino success",
        "Evaluating lease games and their effect on revenue and player experience"
    ],
    episodeNumber: 1,
    seasonNumber: 1,
    datePublished: "2022-10-13",
    transcript: `Nick Hogan:  
    At ReelMetrics, we collect and analyze a ton of slot data, and we're hit up frequently for insights, tips, and tricks. So we decided to do a webcast in which we present, discuss, and otherwise nerd out on our work. Coming to you from our offices in Lovely Leiden in the Netherlands. Welcome to ReelCast. Good morning, Don. How are things in Missoura, as my very Midwestern father would say?
    Don Retzlaff:
    It's nice. It's the first day of fall. We went from 99 degrees yesterday to 75 degrees today. The calendar knows when it's fall.
    Nick Hogan:  
    Yeah, we have the same thing in Leiden now. We are looking at lead skies and rain. That's how we know fall is here. So, okay, here we go. Our inaugural ReelCast episode. I guess the best place to start is to summarize why we're doing this. Obviously, we amass a ton of data with comprehensive meter sets on hundreds of thousands of machines, around 8 million individual game configs and billions of rated player sessions. As a lot of people know, we spend almost as much time researching data as we do collecting, standardizing, and distributing data. In the process, we made a lot of critical discoveries regarding inventory management, and we tend to get hammered with phone calls from folks looking for the inside track on these insights.
    Now sometimes these calls are very specific, so somebody could be having problems with specific properties or individual game titles that are dragging, but plenty of them are quite general, and really I have this sense that people understand that pan-industrial data services such as ours, they confer gigantic operational advantages, but they're a bit unsure as to the applicable best practices because it does represent a bit of a paradigm shift. So, questions are how should they prioritize inventory changes, what guidelines should they follow in allocating supplier floor share, what about premium floor share, when they install six packs, how many titles should be on them, and then just generally, how can we engineer the most positive experiences for our best players. Now obviously we reserve the really deep dives into such questions for our subscribers, but the evidence shows us there are some general principles here that everyone should keep in mind and observe as they're managing their inventory. So we thought a webcast would be a great vehicle to get some of these things out into the market.
    For our first topic, we thought we'd begin with one of the clearest observations we've made over the past several years, and that is that slot floors are massively overdiversified. The question is, which slot floors? Well, in our experience, every last one of them. To date, we've yet to onboard a single mix that we would describe as anything other than massively overdiversified. Now, the good news here is that there's plenty you can do about it. In this episode, however, we're going to focus simply on what it is and why you need to care about it.
    Before we get underway, just two quick points. First, when it comes to producing webcasts, we truly have no clue what we're doing here. So please bear with us regarding any chop in the production and we promise to improve as we do more of these. And secondly, we'd love to tackle any questions that anyone listening may have. If you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's R-E-E-L-C-A-S-T, @reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That's not something we do.
    Okay, without further ado, let's get a little nerdy here. Today what we're going to do, we're going to do this. Part one is really focused on these four questions. What is overdiversification? Why should I care about it? Industry-wide, how pervasive or problematic is it? And then lastly, how did we get here? Then next month, we're hoping in late October, we'll have a follow-up episode that we'll post and that's really focused on two questions. How do I know if my floor is overdiversified, and if it is, what can I do about it?
    We're going to start with just a simple definition. What is overdiversification and why should you care about it? Let's talk about its definition and presentation. Overdiversification quite simply is just also known as overchoice or choice overload, and this is a recognizable, cognitive impairment that occurs when people are faced with too many options. So, the decisions as to what they should buy, for example, become overwhelming due to the many outcomes and risks that may result from making the wrong choice. This in turn leads to consumer anxiety, consumer frustration, and ultimately indecision.
    Well, why should you care about it? Well, very frankly, because players are productive when they sit and spin, and when there's too much variety on the floor, we get this overchoice or choice overload situation and our players end up like deer in the headlights. It results in these effects that we'll talk about here in a bit, ricochet and biting dogs. But just as general principles, remember that an overabundance of fragmented, low demand, underperforming product tends to create scarcities in high demand, highly performing product. Where this is truly problematic is that these scarcities in high demand product disproportionately impact your highest value players. I think, Don, I'm sure you would agree that one of the first things that really jumped out at us when we started really diving into player data is looking at things such as industry assumptions historically about who's playing premium product, who's playing the big participation titles, et cetera. That was fascinating, was it not, to look into.
    Don Retzlaff:
    It was. It was really shocking to find out which player segments are carrying the lease games, and it ends up being in almost every casino, it's the host level players, it's your best players that are playing the best of the premium products, and your daily low theoretical players shy away from them. They spend about 10% of their wallet on lease games where the percentage of wallet spend on lease games is so much higher for your better players.
    Nick Hogan:  
    Absolutely. We've seen it over and over. The more inventories we analyze and the more player data we look at, we just get more and more affirmation that that's precisely what's happening. Okay. So this is overdiversification, definition of it, why you should care about it.
    Then let's move to the question, how problematic or how pervasive is this throughout the industry. This is actually a study that we did some time ago where we looked at all of our enterprise clientele. What we saw was that of these clients, and I don't recall the total number of organizations that was in this study, I think it was five or six, but they had the libraries, the game title libraries that they were operating, the mean size of that was 2,645 active game titles. We started asking questions about it. these are all multi-site operations. We asked, "Okay, out of this game library, what percentage of these titles is installed on just one box anywhere in the entire enterprise?" And we were absolutely shocked to see that it was nearly 20% of units. There's really no strategic value in such products because you're nothing that you're replicating. So these are truly just standalone, singleton titles installed on one box anywhere in the enterprise.
    Then the next question we asked was, "Okay, of this library, what percentage is installed on fewer than one terminal per venue on average?" And again, we were absolutely blown away to see that was nearly 70% of all active game titles. When we look at this overall, what we see is that 88.9% of the titles within these organizations are deployed in low volume formats. When we look at the performance correlations, when you classify in this nature, what we see is that the more fragmentation we see, the more diversification we see, typically that's at the expense of performance. We see over on the very left-hand bottom corner there where it's just one inside of the organization, we see that stuff's performing at a mean of 70% of floor. When we go to the next category, that's about 77. So we just keep going across.
    Now, some may look at this analysis or what this is telling us and say, "Okay, well this is a bit self-fulfilling," because if games are performing well, we tend to duplicate them or add more of them, but when we dive into the data to see if that's actually going on, we don't see that. What we see is there's just a huge degree of fragmentation and overdiversification out there, and it really starts to create problems.
    Now one of those problems is a footfall effect that we call ricochet, and this is just kind of an interesting guideline to keep in mind when thinking about the variety level in your mixes. The way that this works is we just assume two player archetypes. We have our budget constraint players where satisfaction drives expenditure, and then we have our time constrained players where satisfaction drives duration. Then on the product side, what we're looking at here are slippery games, so we also call these low demand products or dogs oftentimes. The key characteristic there is that the game, whatever it is about the game, it tends to discourage and deflect play. On the other side, you have sticky games or what we call high demand or stars. We have different names for these, but sticky games, the characteristic there is they tend to capture, sorry, captivate and capture play.
    Now, when we look at the ratio of slippery to sticky, it's very common that we see four to one. Some venues we may see five to one, but that four to one is a pretty common ratio. That's out on the floor. And then the footfall effect is this, when you have these players enter the facility, the problem is the volume of sticky product is tiny relative to the balance. So there's just such a plenitude of this low demand, slippery product out there that when they're entering facilities and going around, they're just pinballing from bad experience to bad experience, and it takes them a long time to get on the games that they really want to play.
    What we see is that when this happens, when you have this slippery product really crowding out the sticky, it just hammers overall productivity. They're struggling to find and get on the stuff that they like, and what they're doing is just having these very, very short, clearly unfulfilling experiences bouncing off of all this inventory that's of truly no interest. Don, is there anything you'd like to add? I'm just monopolizing the microphone here. Is there anything you want to want to point out here on the ricochet?
    Don Retzlaff:
    I guess one of the recent studies we worked on was how much of a difference there is in the play of your best players when they play in a game that they want to play versus in a game that they have to play, and the amount of money that they spend, their average bet, their time on device really plummets when they play one of the games that's not one of their preferred choices.
    Nick Hogan:  
    Yeah, I think the numbers that we've seen, that we've been seeing pretty reliably is, well, it's particularly acute when we see high occupancy periods and we look at that, and we've seen decay in productivity 50, 60, 70%, and those are big numbers. And then the other thing is to your point there, Don, as we've altered composition in inventory and we've put more high demand, more of the sticky product out at the expense of slippery product, what we're seeing are very significant increases in trip wallet, and as you mentioned, average bet, and session duration is another huge one. So indeed, we're seeing that when the overall quality profile of the mix goes up, basically all the metrics, everything that you want out of the players we're seeing delivered basically is what I would say.
    Don Retzlaff:
    That that's probably been the most shocking discovery on the most recent validations that we've been running is that host level players have a deeper wallet and if you have the right floor mix, you can get deeper into their wallet and get a higher theoretical per trip which is kind of the holy grill of marketing departments is they're trying to figure out how to get people to come more often and spend more money. We're finding that if you put the best games on the floor and have more of them, you can do just that.
    Nick Hogan:  
    Yeah, and it almost feels to me, I know I've said this to a few people around here, is that what the real problem is that people are coming into these operations with sizable budgets and we're just not getting them. Right? We're not extracting them. I think, at least what our evidence has shown us is a lot of that is down to the composition of the inventory. Fascinating stuff to be sure. But then the other thing that's just kind of interesting for us to look at with this model is historically I think the tendency, it was certainly the way that I looked at it, I know plenty of people in gaming who looked at it, and that is that when we have that really low demand, slippery, doggy product on the floor, the assumption was, okay, there's a lot of it out here, but it's really benign. It doesn't necessarily hurt anything. Maybe nobody's playing it, but at least it's not hurting you. Yeah, I think I'll turn that over to you, Don, about some of the things that we've found there.
    Don Retzlaff:
    We're talking about things like average bets and how long they'll play on those machines and we're able to do a loyalty score on those machines. For people who have at least seven trips in a season on a top calendar year, how often will they return to those games and the loyalty of those games that they don't want to play is extremely low. On the other hand, the games that they want to play, they come back to every single time, and you can tell it's the first machine they play, it's the second machine they play, and there's a big difference between the Tuesday night where they can get on anything they want and the Saturday evening where they have to play their third and fourth choices just because there's not enough machines available. Like you said earlier, it's a 50, 60% decline in revenue per trip from your very best players, and we're talking about people with 500 to $1,000 theoretical per trip. It's a pretty sizable decline.
    One of the interesting things on the worst machines on the floor is at most casinos, it's 25% of your floor and it gets played an average of two minutes per hour. With a session time of eight minutes per trip, you're looking at that one machine gets played once every four hours and it's a good part of your floor. Especially post-COVID, there's a lot of floors out there that just have too many machines and the wrong types of machines. We've seen things like decline in real play, especially quarter reels. There's just too many of them on the floor. There's just not enough of the good premium games out there. People are really concerned about these costs, especially in a declining environment like we're seeing this summer with higher gas prices. It's just very uncomfortable out there right now for the customers.
    Nick Hogan:  
    And Don, because one of my concerns about this too is I know that so much of this inventory that really is just attractive to no one, it stays on the floor for so long. I'm curious, in your operational experience, were you ever approached by players who said, "Hey, I can never get on the games that I want." Did you hear this type of stuff from players very often? Do they perceive, do they feel that the casinos aren't paying attention to what they like, or do you get this sense when you speak with them?
    Don Retzlaff:
    I do. I used to keep a list on my wall of games that were requested either that I didn't have on the floor or that I needed more of. You're looking at high density machines like some of the best lease games out there now that are running 90% density on a monthly basis which means these games are getting played at midnight at four o'clock in the morning. So, yeah, I did [inaudible 00:19:05].
    Nick Hogan:  
    Bonkers fair share numbers too you see on these.
    Don Retzlaff:
    Oh yeah.
    Nick Hogan:  
    The occupancy rates.
    Don Retzlaff:
    You're looking at four and five times house average, and some of the games are eight and nine times house average for some of the high limit best lease games.
    Nick Hogan:  
    Right, yeah. Okay. Well, so we covered ricochet and biting dogs which are two principles I think that are really important for folks to keep in mind. I think we can really turn to the last point that we want to discuss today which is how do we get here. I think it really boils down to the trial and error that's just been so pervasive throughout our industry. I came in '95 and it's been there full force ever since I've been in, so I can take the couple of points here. A lot of it is just down to industry practices. The first one is about how we're sharing information and how that impacts product development. Historically, of course, there was very little data that made its way to the suppliers, and I don't want to get into the political reasons for that, but just say that most games have been developed independently of sizable data sets.
    This has resulted in what's a colossal failure rate in new product that comes to market, around 78%. So the way that we define that now, that 78% is not consistent across suppliers, so we're going to just state that right out of the gate. It's a top level number. What that means is that of all the titles coming to market, only 22% really is ever going to get to an index value pan-industrial of 1.0 or higher. 22% success rate out of product development generally largely due to an empirical data void persisted for multiple decades.
    And then secondly, it's just the sales and marketing practices. Everybody I think in gaming uses formulas. So placements historically, so new installs were driven predominantly by anecdotal referrals, and these would come from account executives. Let's say you agree to a certain number of units and you want to put it on the floor and you say, "Okay, pick me your best stuff," or you may get recommendations from peers, but there just really wasn't a lot of hard data driving that stuff so plenty of those recommendations and referrals were wrong.
    Secondly, floor apportionment on things, so who gets what percentage of the floor has not been based on demand historically as to what our customers like to play, et cetera, but it's based on things like ship share, market share and strategic agreements. And then lastly, a lot of the techniques that were put in place to buttress the weak appeal of certain products, things like trials, free conversions, and performance guarantees, leave the free conversions part out of it for a second, but with the trials and performance guarantees, a lot of stuff that let's say wouldn't have stayed on the floor necessarily have made it through a trial. They maybe kind of hug the mean, and so people say, "Okay, we'll just leave them on the floor." And so they convert to sale and then they promptly nose dive and sit there and rot. These are two practices where we've seen that really contributed to overdiversification over the years. Don, you want to take the next two?
    Don Retzlaff:
    Yeah, there's been a couple things. If you were buying slot machines 15, 20 years ago, the average price of the machines was $8,000 a unit and now you're in the $20,000-plus range, 25 for a lot of them, and I'm pretty sure most people have not tripled their CapEx budgets over the last 15 or 20 years. They've pretty much stayed the same. So now you're basically buying a third of the games that you used to before. I remember my goal every year was a 20% changeover in titles. You just can't get to 20% on an annual basis anymore. So now you're hitting the smaller percentage of machines on the floor. So therefore you're going to have machines on the floor that are older and older and older every year and it just gets worse. It just compounds every year. You're just not doing as many conversions, you're not buying as many cabinets, and therefore now you've got machines on the floor that you bought 10, 12 years ago. Even with a higher depreciation rate in the standards of five or seven years, you just can't get rid of all your dogs off the floor.
    And then you come to the fact that when you do put new machines on, what do you remove, and you try to please everybody by keeping some of the older machines on the floor just in case it's one of your better players' favorites because you don't have the data that shows that your best players are totally ignoring these banks. So instead of getting rid of all of your old, obsolete machines, you keep some of them on the floor in hopes that a consolidated bank will have a better improvement in win per unit versus the 30 or 40 or $50 a day it was doing before. It just gains momentum. Every single year, you just have more and more older, obsolete machines on the floor. There's a couple fixes for it, including an increase in premium and lease games which would cure some of it, but some of it actually needs additional capital or additional ways to spend your capital wisely.
    Nick Hogan:  
    Well, I have this conversation a lot when... Let's say you have somebody who's picked up a core product that's only a year or two old and the thing is just absolutely hugging the bottom. It's not doing anything. Nobody wants anything to do with it. There may not be any good titles in the library that could be used for conversion, and in my experience, no one will take that asset off the floor.
    Don Retzlaff:
    You're right. No one wants to go to their CFO and say, "You need to accelerate depreciation on this unit because I'm pulling it," because it's got four years of depreciation left. They'll pull something else and they'll just have that sit out there and just be an anchor on the floor. It will not attract play. I had one bank from that.
    Nick Hogan:  
    Well, what I was going to say, Don, is this is one of those cases too where I think the kind of best practices model really starts to change because let's say historically if we had that on there where it was, let's say a machine that's a year or two old that didn't seem like there was any good conversion, et cetera, et cetera, I think one of the reasons that people were so reluctant to get rid of that asset, and this is what we call, you're not really depreciating an asset, but you're amplifying a liability, that that's the idea. I think part of the fear about that historically has been the unpredictability in game performance of anything that would come in to replace it. Now that we have the ability to screen this stuff before it's installed, I think people can relax a little bit on that point. I mean, do you feel that that's a legit point?
    Don Retzlaff:
    Yeah, absolutely. Like you said earlier, there's such a low success rate if you're not looking at the data for picking new games on your floor. You've got some really good account executives out there and you've got some rookies out there that are new to the industry. If you don't have the data to look at, you're basically throwing darts and you're going to hit it 25, 35% of the time. That's kind of scary. But if you got the data, there's no reason why your games that you purchase in the next year shouldn't be doing 150% of house average for the entire year. There's enough data out there and there's enough looking at the data points that we have. There's a lot of low hanging fruit out there. There's a lot of stars that people do not have on their floor that they should.
    Nick Hogan:  
    Yeah, absolutely right. No doubt about that one. There was another thing that did you want to discuss this last point as well, Don, on the kind of cognitive dissonance and some of the magical thinking that's out there?
    Don Retzlaff:
    Yeah. Especially on the lease games, it's tough to prove, in the past at least, that by adding lease games, you're just not moving money around the floor. There's a big fear because you're adding additional cost. You're adding $300 a day for a bank of six type stuff. That adds up really quickly. We've been able to run some experiments to show that you just don't move money around, that you can increase wallet per trip and spend per trip from your best customers if you give them the games that they want to play. It's been quite remarkable. I was expecting moves of 5, 10%. In one of the experiments, we were getting moves of 40 and 50% increases in theoretical per trip, and these are for a very high worth players, customers who are bringing in over $400 a trip.
    It was very enlightening. You always see that cost on your bottom line in your P&L reviews when you've increased your lease games by 20, $30,000 a month. It's really tough to find that revenue, but you can actually run those experiments and if you do it properly, you can see an increase in the revenue per trip of your very best customers, the ones that you want to see more often. They play the lease games. They pound the new lease games. That's what they want to play. Their average bets are phenomenal on these games. We saw one group at one of the casinos we work with, their average bet went from 3,29 to 4,30 a spin after three months.
    Nick Hogan:  
    Good God.
    Don Retzlaff:
    Just remarkable increases. It's fantastic. Never a dull [inaudible 00:29:28].
    Nick Hogan:  
    Yeah, and your point, when I saw some of these numbers about the increases in theoretical per trip, that's really where, because John Boushy, our chairman and I have had just an ongoing conversation since the very beginning on cannibalization, which rate to utilize. We were always of the school that, well, it's 90%. So the idea is that if you put a new machine on the floor and it's doing X per day, 90% of that X is coming from other products. So the true increment was only around 10%. When we started looking at those numbers and the increases in theo per trip, it really showed me that we are so far away from being able to discuss cannibalization in an intelligent way. I think the one thing I know is that 90% number is nowhere even remotely accurate. It's just been really conservative assumptions that we use, but what we're seeing here is that there is a lot of upside in player budgets, particularly within those highest value segments.
    Don Retzlaff:
    You start with that 90%, and a lot of that comes from budgeting purposes. You're going to expand your slot count on your floor by 200 units and you know how much it's going to cost to buy those units. Well, for budgeting purposes, how much are you going to raise your EBITDA because you have to make it worthwhile. No one wants to say that they're going to increase their EBITDA by 30%, so you end up having this really small number that you always exceed, but that ends up being the standard. And so, that 10% is out there and say, "Okay, we can spend 2 million and we're definitely going to make more than that back, and here's what our adjusted EBITDA is going to be for our bonus purposes."
    And so people always lowball that, and it's very tough to justify unless you're adding a bar next to your sports book and things like that and where you can absolutely see what your revenue created is. It's very tough unless you can actually dive into your player ratings and track it and look at it and see what they're playing, how long they're playing, and what games they're not playing. You really have to do a deep dive to figure it out.
    Nick Hogan:  
    Yeah, and I think just on that note, one thing that we are constantly emphasizing now, and I think it's a bit of an issue throughout the industry, and that is the degree of coordination between the marketing departments and the operations departments as it pertains to things like inventory management. I think there's a lot of data share that isn't happening that really needs to happen. And so we're always strongly encouraging organizations if there are walls up between those two departments, it's really critical to break them down.
    Don Retzlaff:
    Yeah, even the basics, like when you're getting ready to remove games, if you don't have access to who's playing what games, talk to your marketing department. Find out who's playing the games that you're planning on pulling. Is it your host level players? Is it your best customers? Is that their go-to classic game? Even though it may only be doing 50% of house average, is that something you should be saving? Because there's so many games that need to come off the floor, make sure you're pulling the right ones to start.
    Nick Hogan:  
    No doubt. Okay, well, Don, I think that pretty much wraps us up for this first part on overdiversification. Again, if there's anybody out there that would like to ask questions about what we presented today or would like us to take on another topic of some sort, please reach out to us, reelcast@reelmetrics.com. I think that's a wrap for now, Don. We'll be back here in about a month's time.
    Don Retzlaff:
    Very good. Almost time for G2E.
    Nick Hogan:  
    Yeah, can't wait. It's going to be cool.
    Don Retzlaff:
    Wear comfortable shoes.
    Nick Hogan:  
    Yes. Okay, Don, thanks for your time.
    Don Retzlaff:
    Thanks, Nick.
    Nick Hogan:  
    Talk to you later. See you. Bye-bye.
    Don Retzlaff:
    Bye-bye.
    `
};

export default S01E01;
