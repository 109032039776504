import React from "react";
import PropTypes from "prop-types";
import PageMetadata from "../../PageMetadata";

const ReelCastHelmetTemplate = ({
  title,
  subTitle,
  description,
  keywords,
  link,
  episodeNumber,
  seasonNumber,
  datePublished,
  buzzSproutLink,
  topics,
  spotifyLink,
  applePodcastsLink,
  iHeartRadioLink
}) => {
  // Create canonical URL
  const canonicalUrl = `https://www.reelmetrics.com/${link}`;
  
  // Safely combine keywords and topics
  const allKeywords = [...(keywords || []), ...(topics || [])].filter(Boolean);
  
  // Create structured data for podcast episode
  const podcastEpisodeSchema = {
    "@context": "https://schema.org",
    "@type": "PodcastEpisode",
    "name": title,
    "datePublished": datePublished || new Date().toISOString().split('T')[0],
    "episodeNumber": episodeNumber || 1,
    "seasonNumber": seasonNumber || 1,
    "description": description,
    "url": canonicalUrl,
    "partOfSeries": {
      "@type": "PodcastSeries",
      "name": "ReelCast by ReelMetrics",
      "url": "https://www.reelmetrics.com/reelcast"
    },
    "associatedMedia": {
      "@type": "MediaObject",
      "contentUrl": buzzSproutLink
    },
    "keywords": allKeywords.join(", ")
  };
  
  // Add sameAs only if links are available
  const sameAsLinks = [
    buzzSproutLink,
    spotifyLink,
    applePodcastsLink,
    iHeartRadioLink
  ].filter(Boolean);
  
  if (sameAsLinks.length > 0) {
    podcastEpisodeSchema.sameAs = sameAsLinks;
  }

  // Create breadcrumb structured data
  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.reelmetrics.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "ReelCast",
        "item": "https://www.reelmetrics.com/reelcast"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": title,
        "item": canonicalUrl
      }
    ]
  };

  // Define platform-specific links for alternate content
  const alternateLinks = [];
  if (spotifyLink) {
    alternateLinks.push({
      rel: "alternate",
      type: "application/rss+xml",
      title: "Listen on Spotify",
      href: spotifyLink
    });
  }
  if (applePodcastsLink) {
    alternateLinks.push({
      rel: "alternate",
      type: "application/rss+xml",
      title: "Listen on Apple Podcasts",
      href: applePodcastsLink
    });
  }
  if (iHeartRadioLink) {
    alternateLinks.push({
      rel: "alternate",
      type: "application/rss+xml",
      title: "Listen on iHeartRadio",
      href: iHeartRadioLink
    });
  }

  return (
    <PageMetadata
      title={`${title} | ${subTitle} | ReelCast by ReelMetrics`}
      description={description}
      keywords={allKeywords.join(", ")}
      canonical={canonicalUrl}
      
      // OpenGraph tags
      ogType="website"
      ogUrl={canonicalUrl}
      ogTitle={`${title} | ${subTitle} | ReelCast by ReelMetrics`}
      ogDescription={description}
      ogImage="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
      
      // Twitter Card tags
      twitterCard="summary_large_image"
      twitterTitle={`${title} | ${subTitle}`}
      twitterDescription={description}
      twitterImage="https://cdn.buttercms.com/OaWFV5QDqgOJxaKViwdg"
      
      // Structured data
      structuredData={[podcastEpisodeSchema, breadcrumbSchema]}
      
      // Additional meta tags
      meta={[
        { name: "author", content: "ReelMetrics" },
        { property: "og:locale", content: "en_US" },
        { property: "og:site_name", content: "ReelMetrics" },
        { name: "article:published_time", content: datePublished || new Date().toISOString() },
        { name: "article:section", content: "Podcast" },
        { name: "article:tag", content: allKeywords.join(", ") },
        { property: "og:audio", content: buzzSproutLink },
        { property: "og:audio:type", content: "audio/mpeg" }
      ]}
    >
      {/* Add custom link elements for platform-specific links */}
      {alternateLinks.map((linkItem) => (
        <link key={`alternate-${linkItem.href}`} {...linkItem} />
      ))}
    </PageMetadata>
  );
};

ReelCastHelmetTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  link: PropTypes.string.isRequired,
  buzzSproutLink: PropTypes.string.isRequired,
  episodeNumber: PropTypes.number,
  seasonNumber: PropTypes.number,
  datePublished: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.string),
  spotifyLink: PropTypes.string,
  applePodcastsLink: PropTypes.string,
  iHeartRadioLink: PropTypes.string
};

ReelCastHelmetTemplate.defaultProps = {
  episodeNumber: null,
  seasonNumber: null,
  datePublished: null,
  topics: [],
  spotifyLink: null,
  applePodcastsLink: null,
  iHeartRadioLink: null
};

export default ReelCastHelmetTemplate; 