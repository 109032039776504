import React from "react";
import { OktaAuth } from "@okta/okta-auth-js";
import Cms from "./cms";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionToken: null,
            attempts: 0,
            username: "",
            password: "",
            submitDisabled: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    componentDidMount() {
        fetch("/api/oidc_info")
            .then(response => response.json())
            .then(json => {
                const config = {
                    url: json.oidc_base_url,
                    clientId: json.oidc_identifier,
                    issuer: `${json.oidc_base_url}/oauth2/${json.oidc_issuer}`,
                    authorizeUrl: `${json.oidc_base_url}/oauth2/${json.oidc_issuer}/v1/authorize`,
                    userinfoUrl: `${json.oidc_base_url}/oauth2/${json.oidc_issuer}/v1/userinfo`,
                    redirectUri: json.oidc_redirect_uri,
                    forgotPasswordUrl: json.oidc_forgot_password_url,
                    pkce: false,
                    authParams: {
                        responseType: "id_token, token",
                        responseMode: "fragment",
                        scope: [
                            "openid",
                            "email",
                            "profile",
                            "address",
                            "phone"
                        ]
                    }
                };
                this.setState({ authConfig: config });
            });
    }

    handleUsernameChange(e) {
        this.setState({ error: null, username: e.target.value });
    }

    handlePasswordChange(e) {
        this.setState({ error: null, password: e.target.value });
    }

    handleSubmit(e) {
        this.setState({ submitDisabled: true });
        e.preventDefault();
        const authClient = new OktaAuth(this.state.authConfig);
        authClient
            .signIn({
                username: this.state.username,
                password: this.state.password
            })
            .then(transaction => {
                this.setState({ submitDisabled: false });
                authClient.token.getWithRedirect({
                    sessionToken: transaction.sessionToken,
                    responseType: ["code"],
                    scopes: [
                        "openid",
                        "email",
                        "profile",
                        "address",
                        "phone",
                        "groups",
                        "offline_access"
                    ]
                });
            })
            .catch(error => {
                const { attempts } = this.state;
                this.setState({
                    error: error.errorSummary,
                    submitDisabled: false,
                    attempts: attempts + 1
                });
            });
    }

    attemptsWarning = () => {
        return this.state.attempts > 2 ? (
            <div className="alert alert-warning" role="alert">
                {this.state.attempts}
            </div>
        ) : null;
    };

    _isSubmitDisabled() {
        if (this.state.submitDisabled) {
            return true;
        }
        if (
            this.state.username.length === 0 ||
            this.state.password.length === 0
        ) {
            return true;
        }
        return false;
    }

    render() {
        if (this.state.sessionToken) {
            this.props.auth.redirect({ sessionToken: this.state.sessionToken });
            return null;
        }

        return (
            <div
                className="modal fade login-box"
                id="login"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="Login"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <div className="text-center">
                                <div className="title title--primary" style={{ fontSize: '32px' }}>
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="login_splash"
                                        type="title"
                                    />
                                </div>
                                <p className="paragraph">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="login_splash"
                                        type="text"
                                    />
                                </p>
                            </div>

                            <form
                                className="login"
                                onSubmit={this.handleSubmit}
                            >
                                <div className="form-group">
                                    <label htmlFor="login-email">
                                        E-mail
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="login-email"
                                            placeholder="E-mail"
                                            value={this.state.username}
                                            onChange={this.handleUsernameChange}
                                        />
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="login-password">
                                        Password
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="login-password"
                                            placeholder="Password"
                                            value={this.state.password}
                                            onChange={this.handlePasswordChange}
                                        />
                                    </label>
                                </div>
                                {this.state.error ? (
                                    <div
                                        className="alert alert-warning"
                                        role="alert"
                                    >
                                        {this.state.error}
                                    </div>
                                ) : null}
                                {this.state.attempts > 4 ? (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        WARNING: Your account will been locked
                                        for 60 mins after excessive failed login
                                        attempts. Should you not remember your
                                        password, you can reset it after 60 mins
                                        by using the I forgot my password link
                                        on this page.
                                    </div>
                                ) : null}
                                <div className="row login__actions flex-switch">
                                    <div className="col-sm-6">
                                        <a
                                            href={
                                                this.state.authConfig
                                                    ? this.state.authConfig
                                                          .forgotPasswordUrl
                                                    : ""
                                            }
                                            className="block mt-xs-1 link link--primary"
                                        >
                                            I forgot my password
                                        </a>
                                    </div>
                                    <div className="col-sm-6 text-right flex-switch__item">
                                        <button
                                            disabled={this._isSubmitDisabled()}
                                            id="submit"
                                            type="submit"
                                            className="btn-block-mobile btn btn-lg btn-primary"
                                            value="Submit"
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>

                                <div className="row request-access">
                                    <strong>
                                        <a href="https://operators.reelmetrics.com/member_request">
                                            Request Access
                                        </a>
                                    </strong>{" "}
                                    if your organization is already subscribed
                                    to ReelMetrics.
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <div className="mw-470 center-block">
                                <Cms
                                    content={this.props.cms_content}
                                    content_key="login_footer"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default LoginForm;
