import React, { Suspense, lazy } from "react";
import ReactGA from "react-ga";
import { Button, Row, Col } from 'react-bootstrap';

import Hero from "../components/hero";
import Statistics from "../components/statistics";
import Questions from "../components/questions";
import Trust from "../components/trust";
import Testimonial from "../components/testimonial";
import Cms from "../components/cms";
import CaseStudy from "../components/case-study";
import RedBlock from "./redBlock";
import PageMetadata from "../components/PageMetadata";

const Vimeo = lazy(() => import("react-vimeo"));

class Index extends React.Component {
    constructor(props) {
        super(props);

        ReactGA.pageview(window.location.pathname);
    }

    render() {
        // Structured data for Organization
        const organizationSchema = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "ReelMetrics",
            "url": "https://www.reelmetrics.com",
            "logo": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
            "description": "ReelMetrics combines industry&apos;s deepest slot data pools, hardest science & hottest AI to deliver BIG efficiencies in purchasing, configuration, conversion & marketing.",
            "sameAs": [
                "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
                "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
                "https://iheart.com/podcast/104029260/",
                "https://www.buzzsprout.com/2057836"
            ]
        };

        // Structured data for WebPage
        const webpageSchema = {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "ReelMetrics - Slot Machine Performance Data | Casino Gaming Analytics",
            "description": "ReelMetrics combines industry&apos;s deepest slot data pools, hardest science & hottest AI to deliver BIG efficiencies in purchasing, configuration, conversion & marketing.",
            "url": "https://www.reelmetrics.com",
            "mainEntity": {
                "@type": "SoftwareApplication",
                "name": "ReelMetrics Analytics Platform",
                "applicationCategory": "BusinessApplication",
                "operatingSystem": "Web-based",
                "description": "Enterprise-grade slot analytics platform providing comprehensive data analysis, performance optimization, and strategic insights for casino operations.",
                "publisher": {
                    "@type": "Organization",
                    "name": "ReelMetrics",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
                        "width": "350",
                        "height": "100"
                    }
                }
            }
        };

        // Structured data for BreadcrumbList
        const breadcrumbSchema = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://www.reelmetrics.com"
                }
            ]
        };

        // FAQ Schema - These should be replaced with actual questions and answers from the CMS
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": "What is ReelMetrics?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "ReelMetrics combines industry&apos;s deepest slot data pools, hardest science & hottest AI to deliver BIG efficiencies in purchasing, configuration, conversion & marketing."
                    }
                },
                {
                    "@type": "Question",
                    "name": "How does ReelMetrics help casino operators?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "ReelMetrics provides data-driven insights and analytics tools to optimize slot machine performance, improve purchasing decisions, and enhance marketing strategies."
                    }
                }
                // Additional FAQs should be dynamically generated from CMS content
            ]
        };

        // Video Schema for the Vimeo video
        const videoSchema = {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "ReelMetrics Overview",
            "description": "Learn about ReelMetrics slot analytics platform and how it can help casino operators optimize their slot floor performance.",
            "thumbnailUrl": "https://i.vimeocdn.com/video/293602484_640.jpg",
            "uploadDate": "2020-01-01T08:00:00+08:00", // This should be updated with actual upload date
            "contentUrl": "https://vimeo.com/293602484",
            "embedUrl": "https://player.vimeo.com/video/293602484",
            "duration": "PT2M30S", // This should be updated with actual duration in ISO 8601 format
            "publisher": {
                "@type": "Organization",
                "name": "ReelMetrics",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
                    "width": "350",
                    "height": "100"
                }
            }
        };

        return (
            <>
                <PageMetadata 
                    title="ReelMetrics - Slot Machine Performance Data | Casino Gaming Analytics"
                    description="ReelMetrics combines industry's deepest slot data pools, hardest science & hottest AI to deliver BIG efficiencies in purchasing, configuration, conversion & marketing."
                    canonical="https://www.reelmetrics.com"
                    keywords="slot analytics, casino data, slot machine analytics, casino performance, slot data analysis"
                    
                    // OpenGraph tags
                    ogTitle="ReelMetrics - Powering the Casino Industry's Leading Slot Data Analysis Tools"
                    ogDescription="ReelMetrics combines industry's deepest slot data pools, hardest science & hottest AI to deliver BIG efficiencies in purchasing, configuration, conversion & marketing."
                    ogImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                    ogUrl="https://www.reelmetrics.com"
                    ogType="website"
                    
                    // Twitter card data
                    twitterCard="summary_large_image"
                    twitterTitle="ReelMetrics - Powering the Casino Industry's Leading Slot Data Analysis Tools"
                    twitterDescription="ReelMetrics combines industry's deepest slot data pools, hardest science & hottest AI to deliver BIG efficiencies in purchasing, configuration, conversion & marketing."
                    twitterImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                    
                    // Structured data
                    structuredData={[
                        organizationSchema,
                        webpageSchema,
                        breadcrumbSchema,
                        faqSchema,
                        videoSchema
                    ]}
                    
                    // Performance optimization
                    preconnect={[
                        "https://player.vimeo.com",
                        "https://cdn.buttercms.com",
                        "https://upload.wikimedia.org",
                        "https://images.squarespace-cdn.com",
                        "https://cdn.pixabay.com"
                    ]}
                    
                    // Preload critical assets
                    preload={[
                        {href: "/assets/images/rm_logo_basic_white.svg", as: "image"},
                        {href: "/assets/images/rm_logo_pro_white.svg", as: "image"}
                    ]}
                />
                

                <h1 className="sr-only">
                ReelMetrics - Slot Machine Performance Data | Casino Gaming Analytics
                </h1>
                
                <Hero cmsContent={this.props.cms_content} />
                <Statistics cms_content={this.props.cms_content} />

                {/* DEFAULT BLOCK OF TEXT  */}
                <section className="box box--end">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-8 col-md-offset-2">
                                <div className="text-center">
                                    <h2 className="title title--primary">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="hard_questions_1"
                                            type="title"
                                        />
                                    </h2>
                                    <p className="paragraph">
                                        <Cms
                                            content={this.props.cms_content}
                                            content_key="hard_questions_1"
                                            type="text"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="box box--end">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-8 col-md-offset-2">
                                <div className="text-center">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Vimeo
                                            videoId="293602484"
                                            className="vimeo video-background-transparent"
                                        />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* END DEFAULT BLOCK OF TEXT  */}

                <Questions cms_content={this.props.cms_content} />
                <RedBlock link="/slot-machine-data-analytics-platform">
                    <img
                        src="/assets/images/rm_logo_basic_white.svg"
                        alt="ReelMetrics Basic Logo"
                        loading="lazy"
                        width="350"
                        height="100"
                        className="col-md-3"
                    />
                    <div className="text-center col-md-6">
                        <h2
                            className="title hidden-sm hidden-xs"
                            style={{ marginTop: "0" }}
                        >
                            <Cms
                                content={this.props.cms_content}
                                content_key="red_block_plans_title"
                                type="text"
                            />
                        </h2>
                        <p
                            className="paragraph"
                            style={{ marginBottom: "0", padding: "20px" }}
                        >
                            <Cms
                                content={this.props.cms_content}
                                content_key="red_block_plans"
                                type="text"
                            />
                        </p>
                    </div>
                    <img
                        src="/assets/images/rm_logo_pro_white.svg"
                        alt="ReelMetrics Pro Logo"
                        loading="lazy"
                        width="350"
                        height="100"
                        className="col-md-3"
                    />
                </RedBlock>
                <Trust cms_content={this.props.cms_content} />
                <CaseStudy />
                <Testimonial />
                <section className="box box--end">
                    <div className="container">
                        <div className="container">
                            <Row className="justify-content-md-start justify-content-center text-center">
                                <Col className="col-sm-1" />
                                <Col className="col-sm-10">
                                            <a href="/reelcast"> <h2 className="title title--primary">
                                                Check out the ReelMetrics Podcast!
                                            </h2>
                                            </a>
                                            <p className="paragraph">
                                                The Casino Podcast for Slot Professionals and Enthusiasts Where data-driven insights, tips, and tricks are shared to help professional slot teams improve their game. Produced by ReelMetrics, the world&apos;s largest independent aggregator of slot data, ReelCast brings you the latest industry knowledge and expertise.
                                            </p>
                                    <Row className="justify-content-center">
                                        <Col className="col-sm-12 text-center">
                                            <Button variant="default" style={{ border: "none" }} onClick={() => window.open(`https://cdcgaming.com/reelcast/`, '_blank')} aria-label="Listen on CDC Gaming">
                                                <img src="/assets/images/cdcLogo.svg" alt="CDC Gaming Logo" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5`, '_blank')} aria-label="Listen on Spotify">
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg" alt="Spotify Logo" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625`, '_blank')} aria-label="Listen on Apple Podcasts">
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" alt="Apple Podcasts Logo" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                            <Button variant="default" style={{ marginLeft: 8, border: "none" }} onClick={() => window.open(`https://iheart.com/podcast/104029260/`, '_blank')} aria-label="Listen on iHeartRadio">
                                                <img src="https://images.squarespace-cdn.com/content/v1/54becebee4b05d09416fe7e4/1475275223141-LJT001HD9RFZQUCM9H0F/favicon.ico" alt="iHeartRadio Logo" width="40" height="40" style={{ verticalAlign: "middle" }} />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center ">
                                        <Col className="col-sm-12 text-center">
                                            <Button variant="default" style={{padding: 8, marginTop: 10, marginBottom: 10}}onClick={() => window.open('/reelcast', '_blank')} aria-label="Listen to ReelCast">
                                                <img src="https://cdn.pixabay.com/photo/2012/04/24/16/24/microphone-40327_960_720.png" alt="Microphone Icon" width="13" height="20" style={{ verticalAlign: "middle" }} />
                                                {" "} Listen Now
                                            </Button>
                                            <Button variant="default" style={{padding: 8, marginTop: 10, marginBottom: 10}}onClick={() => window.open('https://www.buzzsprout.com/2057836', '_blank')} aria-label="View on all platforms">
                                                <img src="https://cdn.pixabay.com/photo/2013/07/12/17/11/add-151736_1280.png" alt="Add Icon" width="20" height="20" style={{ verticalAlign: "middle" }} />
                                                {" "} All Platforms
                                            </Button>


                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="col-sm-1" />
                            </Row>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Index;
