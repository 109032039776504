import React from "react";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";

class S02E07 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }

    // calls API and attaches script to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props in Helmet
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S02E07.defaultProps = {
    id: "13462583-s02e07-operator-vendor-tensions-with-buddy-frank",  
    link: "s02e07-operator-vendor-tensions-with-buddy-frank",
    title: "Operator-Vendor Tensions with Buddy Frank",
    subTitle: "Season 2, Episode 7",
    description:  
        "In this episode of ReelCast, Nick and Don are joined by guest speaker Buddy Frank to explore operator-vendor tensions in the casino industry. They discuss insights into managing relationships between operators and vendors. Tune in for an engaging conversation with Buddy Frank and the latest insights from ReelMetrics.",
    keywords: [
        "S02E07",
        "Operator-Vendor Tensions",
        "Casino Industry",
        "Hardware Obsolescence",
        "Slot Machines",
        "Leasing Games",
        "Vendor-Operator Relationships",
        "Buddy Frank",
        "Dragon Link",
        "IGT Machines",
        "Cabinet Release",
        "El Dorado Casino",
        "ReelMetrics",
        "ReelCast",
        "Nick Hogan",
        "Don Retzlaff"
    ],
    topics: [
        "How Do Pain Points Differ Between Suppliers and Operators in the Casino Industry?",
        "What Challenges Do Casino Operators Face Regarding Hardware Obsolescence?",
        "How Do Gaming Vendors Compare to Mainstream IT Companies in Terms of R&D Budgets?",
        "How Does Cabinet Release Frequency Impact Casino Operators?",
        "What Considerations Impact the Addressable Market for Gaming Vendors?"
    ],
    episodeNumber: 7,
    seasonNumber: 2,
    datePublished: "2023-08-18",
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/13462583-s02e07-operator-vendor-tensions-with-buddy-frank",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
   iHeartRadioLink:
        "https://www.iheart.com/podcast/269-reelcast-by-reelmetrics-104029260/episode/s02e07-operator-vendor-tensions-121791824/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s02e07-operator-vendor-tensions-with-buddy-frank/",
    transcript: `Introduction:
    At ReelMetrics, we collect and analyze a ton of slot data, and we're hit up frequently for insights, tips, and tricks. So, we decided to do a podcast in which we present, discuss and otherwise, nerd out on our work. Coming to you from our offices in lovely Leiden, the Netherlands, welcome to ReelCast.
    Nick Hogan:
    Good morning, Don. How are things with you today?
    Don Retzlaff:
    Fine, Nick. How are you?
    Nick Hogan:
    Not too shabby, thank you. Here we are in the back half of August. We took a short break in July in order to accommodate some vacations, my own included. Now, it's time to climb back on the old data science horse.
    As you know Don, I just returned from the US where I'd been for about a month. In mid-July, we flew out to Reno for Ravings Consulting's Casino and Technology and Marketing Conference. It was a great event. We did a small booth there. I spoke on a panel and then we spent several days meeting and mingling with marketing folks from around the industry. That was very cool. Lots of new contacts and perspectives and really happy I made the trip over. And then with that wrapped, I drove over to San Francisco and picked up the wife and kiddos who flew in from Holland for a bit of a family truckster tour of the American West.
    We zipped around California, Nevada, Arizona, checking out the sites, letting dad log the occasional casino visit, and just listening to all the uplifting life-affirming commentary that two teens in a backseat can produce. How about you Don? What'd you get up to during the period?
    Don Retzlaff:
    I actually moved from one part of the city to another, and enjoying this massive heat wave we've had this summer. It's only supposed to be 103 today.
    Nick Hogan:
    God!
    Don Retzlaff:
    It's been a brutal summer and it's supposed to cool off starting next week for a little bit at least. But heck, we're getting it almost to September, so it better start cooling off soon.
    Nick Hogan:
    Well, at least you're not as soggy as the people in the west today. It's lots of upheaval over there. Palm Springs is pretty much underwater it looked like.
    Don Retzlaff:
    Oh, that's terrible.
    Nick Hogan:
    Yeah. Okay, so let's see. For this episode, we thought it would be interesting to tackle a topic that's been proposed by a couple of different listeners over the past several months. It's a bit delicate, but hey, we like that kind of stuff.
    Before we dive in, we'd love to tackle any questions that anyone listening may have. So if you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that is R-E-E-L-C-A-S-T @reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. We do not do that.
    Today's topic covers the symbiotic and often rather strained relationships between the industry's operators and vendors. In preparation for this, we pulled a number of people on each side of the fence.
    I must say, we did hear a fair bit of bitching. So I thought we could lay out a few complaints from each side, discuss them a bit, and then see if we can whip up some guidance helping our listeners to navigate this stuff.
    In tackling this topic, I thought it would be a good idea to invite a guest who throughout his long and very distinguished career, always seemed to have these relationships down pat. Now, this gentleman is a familiar name to most listening today.
    Raised in northern Nevada, he's been obsessed with gaming since he used crayons to mark his mom's keynote tickets while dining at local casinos. Eventually, he covered the gaming industry as a TV journalist after graduating from the University of Nevada, Reno.
    After a decade of reporting, he moved into casino marketing and eventually slot operations. And after 30 years with various casinos and senior executive roles, he retired from full-time work at Pechanga Resort and Casino back in 2016. He now runs a consulting service, BF Slot Strategies where his focus is on slot performance systems, analytics and team training. And if that's not enough for a man in semi-retirement and a big emphasis on the semi there, he also edits the Frank Floor talk column and contributes as a writer to CDC gaming reports and teaches classes on gaming and marketing at UNR.
    With that very condensed and abbreviated summary behind me gives me great pleasure to introduce Mr. Buddy Frank. Buddy, how are you today?
    Buddy Frank:
    That's like the longest intro I've ever heard. Thank you very much.
    Nick Hogan:
    Well, it's a very distinguished career, lots to talk about. I would say it's an abbreviated summary, but since we were on the weather, are you soggy out there in Reno today or how are things there?
    Buddy Frank:
    Everyone here, the governor declared the state of emergency. Everybody filled up sandbags, everything else and Hurricane Hillary turned out to be a little like misting in Reno. Apparently it was just hit-and-miss across the west. Some people got drenched and others were going, "Hey, where's that hurricane?"
    We got spared. We had a couple of cloudy days, which is unusual for Reno, but other than that it was pretty mild. No complaints here. We're just all preparing for the next storm that's coming called Burning Man [inaudible].
    Nick Hogan:
    But at least this year actually, you're not contending with any smoke though, which is a nice thing. I've spent a fair bit of time in Reno this summer and no smoke.
    Buddy Frank:
    Knock on the wood.
    Nick Hogan:
    Yeah, really terrible. We were up driving a bit in the Trinity Alps there in Northern California and came across Lassen Volcanic National Park. And the devastation from the fires there is just sickening. It's so depressing to look at all that. It's just such a beautiful area and it's really been ravaged in the last few years.
    Okie-doke. So tensions between operators and vendors. Now, as I mentioned in the intro, I wanted to bring Buddy into this conversation because in the many years that I've known him, I've always really marveled at two things, and first is at how constructive and mutually advantageous his vendor relationships are. Secondly is really how few operators have checked in on this and followed your lead, buddy. Could you walk us through the philosophy you've utilized on this front over the years and maybe talk a bit about how it's benefited your operations?
    Buddy Frank:
    Yeah, I will. But first, I wanted to mention something that you said about you heard some comments on this subject. I write a column, that you mentioned for Frank Flo talk for CDC. Often I write these pieces and after they go out, crickets. I have no idea if one person or no people are reading it whatsoever, but I wrote one about three to four months ago based on Aretha Franklin's song, Respect, R-E-S-P-C-T, about operators not showing respect to salespeople.
    I got more comments about that than I've ever, ever received. Dozens came in mainly from salespeople, but a few from operators as well, saying, "Yes, this is a big issue in our industry." I had no idea that so many operators treated so many vendors badly. And with that said, I used to have to battle with some of my colleagues of saying, "Why care about these guys? They're just trying to sell you something. They can't do anything for you."
    Well, I found the exact opposite. When you're nice to them, they're nice to you. I think it has something to do with that thing called the Golden Rule. It's just that I always found that hey, I got to premiere a lot of products, got to have them first in the market. Always, if there was a discount available, I was eligible for it and first call. If I needed info, Don, as an operator knows sometimes stuff's going wrong and you want to call someone, ask what's going on.
    They'll answer your calls quickly and thing, if you maintain a good relationship. I saw a real value in doing that as opposed to having us-them relationship. And I think you can put it in terms of dollars and cents between discounts, product advantages, everything else for the few alpha and beta tests that flopped, I still think it was worth it to be open with the vendors, to try new things and to work with them.
    Nick Hogan:
    That was one thing that I certainly recall and would associate with you is I was trying a lot of new products that came to market, sometimes entirely new concepts that folks had ever seen. I know your name was on pretty much everybody's shortlist. If we needed to take a new technology and beat it up in the field, buddy was one of the first guys you called because he liked to check out new stuff.
    You always seem willing to really work with people on that kind of stuff. Do you have examples where that just paid off in spades for you, where you really got the jump on a technology and were way out in front of everybody else?
    Buddy Frank:
    Well, one was your business, Nick, years ago when you started. You greatly do a lot of analytical work now, but you started as a survey company and I think I understood the same. Well, that's not exactly a new concept.
    People were talking about doing that 25 years ago, but all the vendors said, "Get lost." They didn't even want to be associated with it. They felt some great secret would be revealed and they were kind of antagonistic. And it took a change in attitude for people to realize that by cooperating, they would all gain instead of be hurt. I think you and I, the minute you mentioned your concept, I loved it and jumped on it. Same with the others and anybody else who tried, but that was like the fifth service I'd tried and thank God you guys survived and made it and opened up the whole category. There was a lot of negative opposition at first, and I'm sure you felt that.
    Nick Hogan:
    Oh, from both sides. We'd hear it from... Actually three sides. You'd hear it from operators, suppliers and investors. There was a lot of nay-saying up front and in the end we had to just overcome it and just keep plowing and that's what we did.
    But yeah, certainly to your point, that just kind of baseline of mutual respect and just staying open and factual. I think that just goes so far in this. Really, we had talked about this previously and one of the things I'd mentioned was when I started my career back in the '90s, I had really a lot of good fortune. I was working a lot with the Harris team there under Phil Satre, that really built out Harris there in the '90s. The philosophy that I... They weren't pussycats. They'd beat you up on this, that or the other thing.
    But the underlying philosophy there was always that healthy relationships with our vendors and healthy and vibrant partners equals healthy and vibrant partnerships. I felt that that was a good philosophy and I had just assumed coming in to the industry early that that's how everybody did it, but it really isn't.
    Yeah, I think a lot can be extracted from everybody in that. I'd say it's a good way to run the business and a good way to manage the relationships generally. Okay. Let's look and discuss, as I mentioned, we can go through some pain points on other either side. Let's discuss some of the more prominent pain points starting with the vendors. Now, in putting this together, I use not only my own direct experience, but I've also polled and formally a number of people on supply side, all of whom shall remain anonymous here.
    I have three top level complaints including one that's related to product trials, which has multiple parts. I'll cover these, we'll chat about them and then we'll move on to operators. The first pain point has to do with simply understanding the vendor's businesses, especially as it pertains to their addressable markets and what it costs them to get products out onto your floor. In terms of those addressable markets, the first thing to understand is really that gaming is a closed loop economy.
    In terms of licensed class three casino operators, you're looking at somewhere in the neighborhood of 5,000 qualified customers globally and that's it. It's a very small pool. Further, that number is only applicable provided and this is a huge caveat that you have the resources to maintain licenses in as many as 400 gaming jurisdictions worldwide.
    With the exception of four or five vendors, you don't. For many vendors, the addressable market is perhaps 30, 20 or even 10% of that global tally. Secondly, when you compare the financials of gaming vendors to a mainstream IT cohort of the more mature B2B hardware and software bundlers, so I'm referring to companies like Cisco or Canon or Xerox, companies of this nature, you see some truly crazy contrast. First, as a percentage of sales, the R&D budgets of gaming vendors are at least double, if not quadruple those of the cohort set.
    That's really driven by the colossal failure rates that we see in new product introduction. As we've discussed here many times, about three quarters of the titles that go to market ultimately fail. Third, when you load up the costs of all these things, regulatory certification, trade shows, trade show previews, roadshows, travel budgets, trials, test banks, discount, customer summits, all that stuff, you're often looking at upwards of 30 to 35% of sales spent on R&D and sales and marketing costs alone.
    And this is especially painful for the smaller vendors which can spread their losses only so far. The bottom line here is that it's important for operators to do their homework and to be reasonable. So if a vendor is telling you that they simply lack the margins to accommodate your demands, please listen to them. The grim reality of that closed loop economy is that it's extremely difficult to offset aggressive incentives to one client through volume sales to the others.
    The denominator is just too small and frankly, absolutely everyone demands special incentives. And for the vendors, what I would say there is you owe it to the operator to be very factual and to have these metrics at your fingertips. So you both need to make money. The more facts you place on the table, I would say the greater the odds that you'll reach an amicable solution that enables you to do so. Guys, on that point, just knowing the vendor's businesses, any thoughts from you guys on that one?
    Speaker 5:
    Let's take a quick break.
    Speaker 1:
    As a Reel Cast listener, we devote a lot of time to clearing up misconceptions. Well, here's a big one that we've yet to tackle, the cost of a real metric subscription. For as little as the cost of a single daily fee machine, you have access to the entire ReelMetrics analytical platform as well as the world's largest repository of slot design, performance and behavioral data.
    Use this combination to capture incredible efficiencies in all your slot management tasks, selection, configuration, conversion, segment marketing and more. And listen, we get one of the first problems with analytical tools, simply understanding and using the stuff. You know that you could tighten the ship, but you're short-staffed and budget constrained. You have a day job and you don't have unlimited hours to pour into analysis. Who knows? Maybe you've even purchased analytical tools that you simply never use.
    It's not that uncommon. This is where we make things really easy for you. Our AI fueled recommendations do a ton of heavy lifting for you, scanning your inventory 24/7 and alerting you to optimization opportunities. Also, and this is a biggie, each subscription features an allotment of professional service hours to help you get started. Not only do we train you up, but we help you calculate your inventory baselines, set goals, implement better practices, and get you on your way to a healthier, more vibrant slot operation.
    Best of all, moving forward you can scale these services to meet your demands. Whether you need a few extra hours of analysis per week or a bigger block to cover a one-off special project we have you covered. Think of it as fractional ownership in the industry's sharpest, most well-equipped analytical team. No need to take on the headaches and expense of maintaining your own internal nerdery. We can get you to the same place at a fraction of the cost and managerial burden.
    And speaking of costs, we put our money where our mouths are with the ReelMetrics ROI guarantee. It's simple. If during your first year you fail to achieve a triple digit return on your subscription, you don't pay. That's triple digit. 100% or higher. Are we crazy? Nope. We simply know a safe bet when we see one. Also, we're currently offering a surprise incentive exclusively for Reel Cast listeners.
    It's material, it's only for you, but you need to contact us to learn what it is. Use the code SESAME, as in open sesame, and we'll spill the beans to get ahold of us. Drop us an email at sales@reelmetrics.com or use the contact forms on our website. And if you're one of those still using a telephone, rumor is that you're out there, give us a call.
    In the US and Canada. It's 1-855-FOR-REEL. That's 1-855- F-O-R-R-E-E-L. From everyone else, it's +317-1560-5606. Give us a jingle, you'll be happy you did. And that's guaranteed. End of shameless plug.
    Buddy Frank:
    Well, certainly I think historically that is actually one source of what has angered operators is when people release products that aren't good. One would argue did they know it wasn't good yet still tried to sell it to you.
    Nick Hogan:
    Sure, sure.
    Buddy Frank:
    It sounds like who on earth would do something that stupid, but I bet both Don and I have examples where that happened.
    Don Retzlaff:
    Yes.
    Buddy Frank:
    Aristocrat is so good, it's crazy. But I know they probably sold people some Viridians about two weeks before the Helix was introduced. And if you don't know, the Viridian was an average or worse cabinet and the Helix was one of the best, so you would not be happy if that happened to you.
    So stories like that, we have long memories in the industry and those things upset you. On the other hand, I do realize how much the companies have to invest and how hard it is to produce a hit and every operator's got a basement full of stuff that wasn't so good. There's that balance between you've got to protect your own company and you want to be kind to the vendors because there they're our future. I can see where some of my colleagues might've got some negative attitudes and I'll talk about that later, but you've got to separate that from unrealistic behavior like you mentioned, Nick. You just can't beat them up to say, "I want everything for free or I want a free trial that lasts for a year." You've got to be realistic.
    Nick Hogan:
    Yeah, for sure. Don, any thoughts from your side on that one?
    Don Retzlaff:
    Yeah. I only did trials for companies that I wasn't sure of or products that I wasn't sure of that were going to work for me. For the big manufacturers that had proven records, I rarely did trials with them. It was for the up-and-coming companies, ones that I wanted to try on the floor, but I just didn't have that confidence yet that they'd work for me.
    And it was smaller banks. It was banks of six or banks of eight back in the '90s. But those are the people I tried the trials with and I converted trials to sales probably 90% of the time. There was a few times they'd put something out and it either wasn't mechanically working or the themes didn't fit my customer base. But I usually didn't abuse the trials. I tried to do it going in knowing that if it works, I was going to buy it. That was my philosophy going and not that I was going to try it just to beat somebody up.
    Nick Hogan:
    Yeah, yeah. Okay. Well, so now we will go into, so you mentioned trials there are a lot of complaints and a lot of concerns about everything related to trial. What I'm going to do is I'm going to discuss trial complaints, but in three parts. That's the way we'll attack this.
    Trial complaint number one and just generally is that the thought is that trials have become so loaded in favor of operators that they've become almost punitive for vendors. If you think about it, trials are effectively... They're typically 90 day retail consignment arrangements with basically the vendors serving as consignors and then the operators serving as consignees.
    In mainstream retail, typically the way that these consignment sales work is that the proceeds from the sales are typically split either 60/40 or 70/30 with the bulk going to the vendor.
    And why the vendor? Well, it's a question of just financial risk. So for the vendor, the risk is the cost incurred in manufacturing the product, getting it to your store, providing whatever support collateral you require, et cetera. And then as the retailer, the consignee, your risk is really the shelf space that you give to the product or more specifically the opportunity cost of selling a different product in that same shelf space.
    The basic logic there in retail consignment is that the bulk of the spoils goes to the party assuming the bulk of the risk. It's pretty straightforward, it's equitable and a model that's working in countless retail environments, the world over. But in gaming, that ratio is inverted. Typically, in the paid trials, the standard split is 80/20 in favor of the operator. Further, as we all know, a fair volume of product is still going out in the form of free trials where the vendor receives absolutely nothing unless the trial converts to sale.
    In supply, there's really quite a bit of grumbling about these arrangements and the argument basically goes like this to say, well first, now that there are pan-industrial ranking services like our real hot index, there's far less need to mitigate risk, that performance risk in trials. The operators can review reliable performance data before the install and in all, but the rarest exceptions, performance matches those levels.
    Further, the argument goes that 80/20 split doesn't make a lot of sense economically. Whereas the vendors assume all the trials hard costs, operators assume only the opportunity costs. When you look at the quality profile or the average slot inventory these days, and we've been discussing this over and over about how bad it is, the thought is that in the final tally, the operators always end up ahead and it's not this case for the vendors, and as for free trials where you can guess where the vendors fall on that one.
    They detest them, especially the smaller vendors who need to do it more frequently and for whom such arrangements are disproportionately painful. This one's a far more delicate topic guys, but let's discuss that 80/20 split. Does it make sense objectively and is there something that this argument that I just presented is just ignoring or costs that we're not considering here?
    Buddy Frank:
    Well, two things Nick. One is 80/20 is real different if your house is $100 a day or your house is $600 a day average. That's why almost every 80/20 deal has another side to it, which is called fee per day. If you're one of those 500 or 600, you'll probably want to fix fee per day. If you're a small joint, you'll love 80/20 because you can't afford a fixed fee per day.
    Nick Hogan:
    But that's in the trial phase as well then, Buddy?
    Buddy Frank:
    Yes. But I want to take you to task for your own words. About two years ago, you and I talked about the importance of fine-tuning your floor. I'll just throw this out. One of the great concepts you had was slippery versus sticky. I'm going to summarize your years of research and say that sticky slots are ones people like to play and they'll stay there for a while and slippery ones are ones they don't really like and they move on.
    But you pointed out that slippery slots also discourage and make your guests unhappy. They don't like playing slippery slots. There is a real cost to an operator putting too many slippery slots on the floor. I know you agree with that because you talked about it extensively.
    There is a little risk in trialing stuff. Now, the opposite side of that is any good casino always wants a mix of new and different things. You've got another group of players who just like trying new things. Even if those new things are terrible, they at least want to try it for themselves. So we have to balance that. I'm saying there is a cost to your floor about trying new stuff that doesn't work too often. I don't totally believe that because I like trying stuff all the time, but there is a cost to doing that.
    Nick Hogan:
    Gotcha.
    Buddy Frank:
    Defend yourself, Nick.
    Nick Hogan:
    Well, I guess where I hear too is, and this is just in speaking with suppliers, and I know the flip side of this is operators, they often have the feeling, justified or not, that they're being gamed, that they're being played. And I know there are many cases where they are, so I won't discount that and say it's not.
    But as it pertains to that split, I hear more calls from people increasingly. I didn't hear this much in the past. They say really during trial phase that should be a more equitable split. We should move to something like 60/40 or 50/50 or something along those lines because we're just taking on so much risk. Don, I see you shaking your head.
    Don Retzlaff:
    No way. Yeah, like Buddy said, if you're doing $400-500-600 a day, there's no way you're going to do a 50/50 split. The best you can do is maybe move some of that revenue towards the offsetting the purchase price. That's the only other thing you can do. If it's not going to be...
    Most of the trials are to prove the performance of those cabinets and of those themes to you, so you will purchase them. If they want to offset some of that risk and they want to charge you $20 a day or $30 a day with that entire fee going to offset the purchase price, that's wonderful. That's a win-win for everybody. It kind of minimizes the risk of the vendor and it's a cheap way to introduce new product.
    Personally, it wasn't one of my favorite ways to do it because it increased my daily fees. I wanted to do this all with capital dollars. It was a different bucket with many organizations, so I was okay with that. Most of the trials that I had set up were really free trials. They put it on the floor and then I bought them. But I can definitely see the way forward to do a small daily fee in that $20-30 a day range, but I wouldn't do 200 a day. That's silly.
    Buddy Frank:
    [inaudible] Don and I both agree that that's a nice compromise for both the operator and the vendor to do that. By the way, I talked about that column I wrote about respect. Where did all this animosity towards the vendors come from? I actually think it really started with the whole category of participation. First, the wide area progressives from IGT, then they took our favorite product video poker and put a fee on that, the best games, and then they launch this whole 80/20 or fee per day kind of thing. You've got to remember where that hits.
    The way the industry works is there's a big difference between your capital budget and your operating budget. For most of us who were in the operating side, and I know Don you'll agree with this, our report card is the operating budget. That's the one they based a lot of stuff on like are you going to get a bonus or not get a bonus? It's all based on your operating budget. And participation fees don't end up in the capital budget. They end up right there in the middle of your operating budget.
    In every casino I worked with, other than labor and the associated insurance and all those costs related to labor, my biggest expense was participation fees of some sort, whether they lease fee per day or what. It made a big difference of where it is. Some people put it as contra revenue, some people put it as expense.
    Bottom line is it hits the bottom line. That created a little bit of animosity for some slot directors because it was kind of like you're taking this money out of my pocket if you don't do this right. It was the source of a lot of it. Again, I'm not in that camp.
    I like trying stuff. I thought the best compromise was to do a little of each. By the way, Oklahoma emerged a whole new category of that where you put a person in between the vendor and the operator and that's another vendor. They just took some people like gaming capital and they became the vendor you paid, believe it or not, 20% of everything, of all your product and they did everything else for you.
    They selected the products. And I always thought that was the stupidest thing I ever heard of in all my life until I realized how come those Oklahoma casinos have developed from little tents to be the biggest casinos in the world? They have no labor problems, they have no parts inventories, they have no machines in the basement and they have no worries. Guess what? They have the latest, greatest and best products. There is some reasons why these kinds of strategies can work for both sides.
    Nick Hogan:
    Yeah. Okay. We can move on to, so we'll call this trial complaint number two, which is it is a bit specific to multi-jurisdictional operators and in this case, it involves a trial of proven products. Let's say that you have something like a dozen or so properties and you've trialed a given title in a third of them. And then in each property they've delivered solid performance and converted to sale.
    At that point, the product is proven, especially the performance levels jive with what's being reported in the pan-industrial services. Accordingly, when you as an operator continue to demand trials on each incremental instance of that title that hits your floors, in my view and in view of most vendors, this is really crossing a line. What's being argued here with copious evidence to the contrary is that demand tendencies within each individual geographic market are wholly unique and that you need to mitigate performance risk.
    This is what we call here at ReelMetrics, the unique market theory, which relative to around 95% of slot titles is just straight up BS. It's a really thin ruse and everyone knows it's a ruse. For operators, what I would say is really just kind of stop trying to make the unique market arguments because there's just a ton of data out there on this.
    The arguments, they don't hold and the vendors are keenly aware that they don't hold. When these get served up, they don't reflect particularly well on the organization making them. Either you're not keeping up with industry analysis or you're simply ignoring it in order to gain advantage over the vendor. Neither is a particularly good look. For vendors on this, again, you just have to argue based on facts and insert performance conditions into your contract.
    If it's just put something in there, if it performs in X percent of casinos at levels of Y or above, no more trials. I'm always going to default to facts in trying to strip contention out of these things. But again guys, is there something that I'm missing here on this one about why operators do this and do you feel it's a fair practice and this kind of thing?
    Buddy Frank:
    I've never heard of this and to me it's like reverse gouging. I hate it when operators have a really hot product and all of a sudden, they'll create a whole new category of pricing for that product. That's really makes operators mad.
    But this one is really stupid. It's actually hurting the operator. I'll use a really old example, but if that was Buffalo and you know it's just killer and then you try to hold out for a contract extension to get more free trials of Buffalo when you know it's killing everywhere, you're hurting yourself and it is just gouging. And the same thing. I'd be mad if a vendor did that. I think an operator's just stupid to try things like that. Is that widespread, Nick? I've never heard of that.
    Nick Hogan:
    Yeah, I heard that it actually is pretty common. Again, it's something that's more specific to the multi-jurisdictional companies and there are only so many of those. But yeah, especially again, a lot of this is really smaller vendors, they just feel they're just really getting knocked around and unfairly so. Yeah, that's apparently a fairly common demand that's out there. Don, when you're-
    Buddy Frank:
    [inaudible] Mess up your whole agenda based on the responses I got off my story about respect and the number one complaint I heard from vendors was a concept called ghosting. Have you heard of that?
    Nick Hogan:
    Oh, yes!
    Buddy Frank:
    That was their number one thing. And if you haven't heard of it out there, ghosting is you deal with a vendor, whatever, you talk, you agree and he sends over a contract and then it's like he disappeared or she disappeared. You can't find them. They don't answer emails, text, won't call you back. When you call the admin assistant, they say he's unavailable or whatever.
    In fact, it's gotten some bad, some salesman told me they were concerned that the person may have died and they didn't know about it. It's just ghosting. And their comment was, "Hey, just say no. It doesn't work or I'm not interested or whatever." And the comment, a lot of people said, "Hey, we're big boys. We've heard no before." It's much rather get a no than just be ghosted.
    Nick Hogan:
    Oh, I assure you there is not a vendor out there that would rather hear nothing than no. Okay. Not one because you can do nothing with nothing. On the supply side, of course, everybody has the same pressures. We have performance metrics to which we have to answer. And if we can't provide guidance against those metrics because customers aren't getting back to us, it puts us in really bad positions.
    There's always a boss no matter who you are. So you have investors, you have clients, you have employees, et cetera, et cetera. Yeah, it's important. If it's a no, just communicate that. You're not going to hurt anybody's feelings.
    Don Retzlaff:
    Yeah, it's something that Buddy and I talked about a couple of weeks ago is the vendors are trying to get a personal relationship with you because they know that if you like them and if you have a personal relationship with them, you are more likely to buy. The same should hold true for the casino operators.
    If you have a personal relationship with these manufacturers, you can get more out of them than the others. So you should be able to pick up the phone and say, "Hey, I can't meet this week or this month." Or, "I can't do a formal meeting. Can you come by for lunch instead and we can talk over lunch because I'm really jam packed?" But the courtesy of an answer goes a long way. "I'm on vacation this week or it's budget season, I can't meet until next month. Can we talk on the phone instead of you coming over and meeting?"
    There are times where you just can't meet with the vendors, but they always need the courtesy of an answer because you really can leverage your personal experience and your personal relationships with these people to get more for your property than the other people can. I have many, many examples of building relationships with vendors and them helping me out when I was in a pinch and them gladly doing so because they knew that I respected them, I bought from them, I was honest with them and you can build that relationship with them. I used to say I used to get more out of my capital dollars than I should be able to just because of building relationships with these long-term vendors. For sure.
    Buddy Frank:
    Amen. Amen. Amen. That is exactly great advice. One of the things, you don't necessarily see it on the balance sheet, but one of the most valuable things in our industry is knowledge. Knowing what's going on with your competitor, knowing what's going on in the industry, how are customer attitudes and preferences changing?
    I know you appreciate that, Nick, because your whole business is based on that, but one of the most valuable resources anyone can have is information. And there's no better source of that than vendors, at least good vendors. If you have a vendor come in and you start asking questions and they don't know any of the answers or providing any information, information, it's probably a pretty bad or brand new vendor.
    Most of these guys are among the most knowledgeable out there because they're visiting properties on a daily, weekly basis that you don't get. And then they go to corporate meetings where they hear input from all over the country, what's going on, what's not both about their product, and they're pretty up on their competitors' products.
    Nick Hogan:
    Oh boy. I'll tell you, when we have something that we're curious about, let's say an industry practice or the way things work out in the front lines, et cetera, et cetera, we have a shortlist of vendors, we give them a call and these guys are out there 200 days a year traveling.
    They're in all these different environments. They can tell you all kinds of comparative things about how they do it in this market versus that market. Absolutely. That's a grand slam suggestion because indeed, and you see this with the operators too, when I go into an operator and the first 15 minutes of our conversation is just me getting interrogated with a light in my eyes, I know that that operator is switched on and they're doing it to others as well, and they're getting a good view of the industry by doing that. I always appreciate it when they're asking me things.
    Buddy Frank:
    Well, hopefully it goes two ways. You got to be very careful about the guidelines for proprietary information. But other than that, I tried to share every single thing I could.
    Don Retzlaff:
    I agree. Especially-
    Buddy Frank:
    Tell them about the strengths and weaknesses of other products, what I saw on the market changing preferences. I would obviously keep back proprietary financial information and things like that. But other than that, if you do a two-way street, it'll come back to you.
    Nick Hogan:
    Yeah.
    Don Retzlaff:
    Yeah, absolutely. And same with performance data too. If you don't want to tell them they're doing $600 a day, but you can tell them they're doing a 1.4 times house average and they're in a decent spot, you can share that information with them because that's what they feed off of.
    It's just like you'd want to know the information from the test banks. If you're not a real rental subscriber or this is something brand new in the North America and you want information from it, you're going to have to go to that vendor. How are these themes doing on their test banks? They can tell you straight up that they're doing great, you should order them or they're doing okay, the jury's still out, whatever it is, but you've got to have that confidence that they're going to give you the correct answer and it is really a two-way street.
    Buddy Frank:
    Yeah. That's one thing I've really appreciated about your service, Nick, is you help keep those guys a little bit more honest too, meaning that all the vendors probably subscribe to your service are ones like yours. They can't just tell fairytales anymore without getting caught.
    Nick Hogan:
    Oh yeah. They want to know it in advance.
    Buddy Frank:
    Well, we could call around before and have a fair idea, but we didn't know facts because there were a lot of myths and stuff, but there's a lot of facts out there now. There's a lot of ReelMetrics users or clients and they know the real stories versus the stories up core or a weak or an unreliable vendor may tell you.
    Don Retzlaff:
    Sure, sure. In the early days of ReelMetrics, when I was a subscriber, the vendors would come and the manufacturers would come into my office and they'd ask me questions. So I'd turned my monitor around and I'd pull up the ReelMetrics site and we'd dive into the different cabinets and I'd show them, okay, this is what these ones are doing and these are how they're performing, so please don't try to sell me this one.
    This is the ones I want. These are the ones that are doing well. So a lot of times, I would have more information than they would. Now, thankfully, some of the major ones that done a really good job of building their database and they're getting more and more information in, but when you have something like a ReelMetrics out there, you can actually look at this and either verify what they're telling you is true, or you can say, "Well, maybe we need to wait on this one because this is only doing house average, and I want more than that when I'm buying something new from you."
    It's a great tool. And the vendors ate that stuff up. They'd be jotting down notes furiously when I was showing them all this stuff.
    Buddy Frank:
    Well, and I think because of that, they're more honest when they come in now than they might've been 10 years ago.
    Nick Hogan:
    Yeah, for sure. But I think with a lot of them, what I found is because we were pretty stealthy about ReelMetrics in the early days, and we didn't go around advertising it so much to everybody. Initially, there was a bit of fear, but I have to say that the objections that we heard from suppliers were far less passionate than what we'd hear from operators.
    The suppliers are just like, "How do we get this information?" That was kind of their angle on it. But anyway. Okay, so I had one more point on trials, guys. One other thing that I mentioned, I want to mention this because it was a totally new one for me that I hadn't heard of and I know you guys have heard of this, and that was you put a product on trial and then the operators who then convert the trial to sale demand discounts on the hardware used for trial as it's now, and I'm using air quotes on this, "secondhand equipment".
    Now this one to me was just bad behavior defined. Is there something I'm missing on this one, guys? Do you have the sense you're getting those games at the end of the line. Maybe it's been trialed at four or five other casinos and you get them, or is it just another chance to milk a few bucks out?
    Buddy Frank:
    Who on earth would do that?
    Nick Hogan:
    That sounds like a guilty question there.
    Buddy Frank:
    In my career, especially when I worked for smaller capital restrained companies, I tried to get every dime out of it. That was the only reason, Nick. Take a shot. They say [inaudible]. Interesting. At the very start of the show, you said there's a small closed loop community of 5,000 or so.
    I'll argue that in many ways there's a group of about three dozen or so key operators who you want to get your product in because lots of other people follow them. I think I was fortunate enough at the end of my career that if Pechanga had something, all the salesmen who say, "Well, Pechanga put it in." And that used to be true for, "Well, Foxwoods put it in." Or MGM went ahead, or Caesars went ahead. Actually, you like those independent guys more than the corporate guys, but those guys can abuse that power even more significantly than anybody else if they choose to.
    Those were the guys that I didn't have respect because they weren't nickel and dimming. I did that practice when I was starting with really capital restrained people. They've got all the money they need to do that. If they do that kind of behavior, then it's really bad. It depends.
    Again, that group is very small. There are a couple of small properties that you listen to. I remember Barona used to be one of single small Indian reservation, but they were well respected. If you heard they have it and bought secondary units, it's kind of a clue that it was a good product.
    I think Pechanga ended up in that category and several others across the country. If it was good operators and you heard they were putting it in, you would do more. This was pre-ReelMetrics, pre some of the other survey data.
    Nick Hogan:
    Right, right. Okay. Well-
    Buddy Frank:
    I did that early in my career [inaudible].
    Nick Hogan:
    Okay. Okay. We'll switch gears here a little bit. Those are just some things I wanted to talk about, really kind of the most prominent pain points that I heard out of the supplier. So let's switch gears here and really go over to the operator perspective. In the first place, I think a good place to start, which would be with one of the more material pain points in operations. I certainly understand it and that is the whole topic of hardware obsolescence. Maybe you guys could discuss this a bit because this really is a material issue for them.
    Buddy Frank:
    I mentioned that one at the start about the Viridian, just for the Helix release. Man, I've been doing this so long I've got a huge list of complaints about that side. At El Dorado, we did a huge refresh of all our IGT machines about two weeks before they introduced the [inaudible].
    Talk about upset. They had to know that's coming, but hardware obsolescence really bothers you and it seems to ebb and flow, meaning all of a sudden, they'll do so. Cabinet release, release a cabinet, release a cabinet, release. That upsets you.
    You want a cabinet to last. You can make your money back, if it's a hot game, in a year or less, but still you want to get a minimum of five years out. In fact, aren't we all lucky that an S2000 or an IGT or an S6000 Bali has been around 20 or 30 years and still, house average kind of machine. But to get games that come out and then die almost immediately, that's pretty upsetting. That does strain, vendor-operator relationships.
    Don Retzlaff:
    It's one of the early lessons you learn when you start buying slot machines, is to find out how long those machines have been out in the market for. Because you want to buy the latest and greatest. You don't want to buy those second generation games that they're still selling just because of the fact with the cost of cabinets now, you're going to have these games on your floor for a decade.
    If after two years on your floor they've obsoleted them and they're no longer producing conversions, it becomes a problem. You can get parts through secondary markets, you can get parts through Frankenstein in your own machines. I had a warehouse full of old machines that I would do and I'd take real strips off or power supplies or slot glass back in the day.
    But you really want to stick with the current generation because that's where these manufacturers make their money, is selling new machines. When they're the latest and greatest cost more to produce, they have higher hardware costs. They obsolete those old games. It's painful when they do because you know that a large percentage of your floor, if your floor has been at least 10, 15 years old, there's a large percentage of your floor out there right now that's obsolete. You can't tell anything about it. It's maddening.
    Nick Hogan:
    Honestly, if we assume like a million terminals in the US market, just in the United States alone, I mean it wouldn't surprise me if over 200,000 of them are exactly in that category that you just described on. Maybe 20%.
    Don Retzlaff:
    Oh, I bet you it's more than that. Yeah, that's an interesting question.
    Buddy Frank:
    There's like two categories within that, Nick, of obsolete machines. Obsolete machines that still perform well and obsolete machines that are just crashing. And Aristocrat Mark V, IGTS S2000, Bali 6000, there's still a little glimmer of life in all those cabinets.
    Don Retzlaff:
    Absolutely.
    Buddy Frank:
    You know they're quite dated, but they're probably doing house average or better and that's why they're still on the floor. But there's others that just never really clicked really well. Those are the ones that just wasted your money. By the way, interesting middle ground that when I talk about vendors, there's two categories. There's vendor sales reps and there's vendor management.
    Often, I found the management hasn't told the sales rep that something's going to be obsolete and they're out there with a smile on their face and they honestly tell you, "This is a great product. We're behind it forever." And then it gets replaced next week. That puts them in a horrible position, and you probably take them off your Christmas card list, no fault of their own.
    Nick Hogan:
    Yeah, that's very bad form in the vendor side for sure. Something like that. No doubt.
    Don Retzlaff:
    That's a great point. I actually had that happen where one of my account executives came in probably three months after a sale and apologized that I did not know. And so he helped me make it right, but they'll put them in a bad spot.
    Buddy Frank:
    And then there's the fickle part of our industry that some stuff is really hot, but for a very short period of time. Aruze, say a prayer, they've departed the earth now, but they came out with a new real spinner, a five reel spinner, incredibly bright. Don and I probably thought it would never work, and it was a smash hit.
    Don Retzlaff:
    Yeah, I remember this thing.
    Buddy Frank:
    But it also fell off the map about what? Six months, eight months later after being a superstar. It's just this weird part of our industry that stuff like that does happen, and no one predicted it or knew it would happen. One, I was surprised that it worked because I went, "Oh, this is too bright. It'll never ever work." Yet, it was a smash hit for a while and then it was a disaster.
    Don Retzlaff:
    Now, I see it on cruise ships and things like that. Right?
    Nick Hogan:
    Yeah. Yeah. Kind of a closely related pain point then on that one would be the portability of games overall and this tendency in supply to really focus more on the latest, not necessarily the greatest. It's two sides to the same coin, really. With respect to the game portability, this really refers to that excessive platform dependence.
    It's a great game, but it just gets locked into a single platform. And then focusing on latest, not the greatest is in Don's best practices, stack. He's always looking at, it's always number one is expand your successes. Build out on your successes. And I know that a lot of operators feel well, yeah, the vendors just aren't... They're a little too reluctant to do that.
    So rather than taking a title that's really, really working and giving it, putting it on another platform or really pushing it, trying to expand it, they don't go far enough with it. So maybe you guys can talk around this one a bit?
    Buddy Frank:
    My biggest complaint is I think the sales guys all have these rah-rah cheerleading sessions. They get together and say, "Yeah, let's push X, Y, Z because it just came out this week and we want to do it." When the far better strategy would be to give you some more games of a really hot topic that you do not have enough of on your floor and you would hope the operators are sharp enough using their own analytics to do that on their own.
    But then when the sales guy rolls in and really pushing you the other way, you just go, who are you working for? The company? I know they're paying your bills, but you also have some obligation to help me be a better property. I mentioned Buffalo earlier, that was the best example. There was a point in time where I thought you could do 100% Buffalo Casino and probably be okay.
    But you go into a property and well... Lightning Links and all the Lightning Link-like products are a good example. You'd put a bank of six in and then the guide come in trying to sell you their next new title and you're going, "Yeah, but these things are 400% house average. Should I put another bank in?" And of course, once you said that, they went, "Oh, sure!"
    But that wasn't the first words out of their mouth. It was try the newest whizzbang thing. I've never been on the vendor side, so I don't know if you get more points for pushing a new game than you do selling another one of the popular games. But to me, it's the same bottom line for their company.
    Nick Hogan:
    I know there are some cases where it's probably not as prevalent these days, but certainly back in the days where there was so much third party licensing going on with the various studios and the game shows and all this stuff, you'd get some kind of crosswise incentives in there sometimes where they were really pushing because they had prepaid royalties they had to deal with, et cetera, et cetera.
    I don't know if that's as common as it was 10, 15 years ago, but that was certainly something where you'd see, maybe comp levels were different for one title versus another and things of this nature. Sometimes when you need to push something new, then that's one way to do it. But I wouldn't say it's as prevalent as it was at one point.
    Buddy Frank:
    I mentioned Buffalo and Lightning Link. Those are two categories that, yeah, it is a third-party studio, but there's no royalties or trademarks or kind of thing-
    Nick Hogan:
    Yeah, when I say studio, I'm referring to a Hollywood studio. So like Warner Brothers or one of these. Yeah, yeah, yeah.
    Buddy Frank:
    Exactly. So I never understood that. By the way, I want to interrupt one more time. My number two complaint of my story was just rude behavior. I have an appointment at 1:30 and you don't see the vendor till 2:15 and make them wait in the lobby. I was surprised how many sales reps said that really, really is common.
    I don't see why anyone would do that. They need your business and it might be a power thing, but that just hurts you in the long run because again, it's a small industry and it comes back to haunt you. When you want something from them, if you're rude to them all the time, what on earth makes you think they won't reciprocate?
    Nick Hogan:
    There's a flip side to this too. When running sales teams, nothing infuriated me more than hearing about salespeople who showed up late because I said, you have absolutely no excuse to be there late, because what you're supposed to do is walk that floor for an hour before you talk to them.
    You have to understand their environment there and really, really get what it's all about. So there's no excuse for making an operator wait and showing up late. I think that one definitely runs both ways.
    Buddy Frank:
    To your point there is be familiar with their floors and know what you're talking about. That's critical. Great advice to show up. Just change your whole calendar. If you've got a nine o'clock, put it down as eight and be there and walk that floor.
    Don Retzlaff:
    Yeah. Yeah. I'd say it did happen where you've got salespeople or your account execs would show up late. Usually, I would get a call and they would say, "Hey, my previous appointment is running late." So it ends up being the snowball effect where they're going to see somebody and that person made them wait 45 minutes to get in and now it's affecting the rest of the schedule for the rest of the day.
    It really does snowball on them and I try to be sympathetic because I know that sometimes when you're running a casino, things can happen. I had it happen to me recently where I had a session planned with a VP of slots and he didn't show up. And then 15 minutes later I get an email saying, "I'm sorry, Don, the gaming board pulled me into the office and I'm still here. I'm going to be here for a while. We'll have to reschedule."
    Things like that happen. Or you've got major problems on your floor, the system goes down, stuff like that. It ends up being a two-way street. You try to be sympathetic, but you never want to be rude. You never want to be disrespectful, but you try to be sympathetic with them and work with them, but sometimes your schedules are tight, sometimes you just can't adjust on that day.
    These guys are usually from out of town, so it's not like they have a long schedule to reach on. I do want to go back to the porting over issue. It's something that I think more vendors should do and I feel bad for the new jurisdictions that can't get the classic games that still work really, really well because the vendors have not ported over that software to their new boxes. It's something that a couple of them are doing really well lately, but there's some really good games out there that you just can't get.
    I remember when Ohio opened up, they didn't approve a couple of the old boxes, so you could not get those games on the floor, and it puts their casinos at a competitive disadvantage to the ones that are right across the border because they can play their favorite games at the other state, but you can't play them inside your state. That's always a big one.
    Nick Hogan:
    Yeah. Don, what do you typically hear as a justification for that? So if you asked for instance, "Well, why don't you port this over?" What did you typically hear?
    Don Retzlaff:
    It's a cost. You have to rearrange the software, you have to rewrite code, things like that. You have to go through the approval process, but it didn't always hold water at me just knowing some of the design teams that some of the studios like I did. It's just a time factor. They just wanted to produce the latest and greatest versus rehashing old code to work on a new box.
    Buddy Frank:
    It goes back to what I said earlier too as well. In a way, Don, I'll argue it's easier to port an old title over because you know all the stuff.
    Don Retzlaff:
    Yeah. The math works already.
    Buddy Frank:
    They want to find the next new shiny bright thing. I mentioned Lightning link earlier. It's the first product that ever knocked Wheel of Fortune out of the top after 20, 30 years. And just a wonderful product.
    But I love that design. Some of the design studios at Aristocrat, I worked with them really closely and they produced a title right before that called Cash Explosion and Cash Explosion was the first version of Lightning Link. Didn't work, it was terrible. I loved it because I spent a lot of time with the game designers who's one of the best in the industry and in the hall of fame and it just didn't work. He just tweaked a couple little things and all of a sudden it's Lightning Link and off the chart so hat off to those guys about how hard it is to produce a hit.
    Don Retzlaff:
    Oh yeah. I've been doing a lot of floor reviews this summer. Actually, I'm working on number 11 from the last couple of months now and I've got a section in every one of these about both owned and leased games that you have on your floor that you don't have enough of. Really concentrating on your best players who can't get to these games.
    Your host level players who are spending thousands of dollars per trip and they can't get on their favorite games because you only have two of them on the floor or you only have a Bank of four Dragon Link or you only have two huffing more puff and these people are sitting there wanting to play and wanting to pound these games and they can't. Every casino has this and sometimes, it's understandable. You get a hot new game, you only have a certain number of these cabinets and everything on these cabinets is working for you. You need to buy more cabinets and maybe you don't have the capital for it.
    But for the older cabinets, the stuff that's been out for a while, where you've got games performing at or below house average and you've got other games on the sames banks doing two and two and a half times house average and you won't expand, there's no call for that. Every casino has that issue and it needs to be addressed. It's probably the biggest thing holding back your customers from spending more money.
    Buddy Frank:
    I hope an awful lot of operators have gotten bored and tuned out this podcast before Don mentioned that because as a consultant, I go in and they always think I'm brilliant because of that one suggestion Don just told them. [inaudible] Change and bam! The floor is more productive.
    I know you've done podcasts and stories and seminars about this forever, Nick, but I'm amazed when I go out there and find this one flaw everywhere. Don, I'm surprised to hear you say it, but it's the number one thing I see. I'm just going, "So you've only got the new prosperity link. We've only got four of them. Why wouldn't you add eight or 16 until you start seeing some performance bumps?" Because obviously it's a hot product.
    Don Retzlaff:
    We still have properties that I look at that have a single bank of four or six Dragon Link that are doing seven times house average and they have other lease-
    Nick Hogan:
    And 98% occupancy or something like that.
    Don Retzlaff:
    Yeah, every entire month and they're doing 10,000 spins a day. And then I've got other lease games that they're playing the same fee for that are doing house average or 1.5 times house average. Why? It just blows my mind that these people do that. Now, I understand some of the reasons behind it, but financially, they're just shooting themselves in a foot.
    Buddy Frank:
    Now, I'm going to kick myself in the morning, but the second-biggest violation is what Don just said. I always tell them, "Give me a list of all your participation games and show me all those that are less than 10% net. 10% greater than house average." When that list came back, there's a whole bunch that are below house average, but you're paying fees on them. And I'm going, "I can't believe this." But it's there. Every property I deal with, I'll find some. They went, "Yeah, we'll fix that."
    Don Retzlaff:
    I just talked to a client recently and I said, "If you're still leasing these, because I just couldn't wrap my head around that they might still be leasing these." I thought maybe they bought them and that's why they're still on the floor. I know that happens on occasion. I said, if you're still leasing these, "Get rid of these. If you still own them, fine, let's find a different home for them. But if you're still leasing these, please stop. There are so many better options out there?"
    Nick Hogan:
    Was it leased or was it owned?
    Don Retzlaff:
    It was leased.
    Buddy Frank:
    There goes my whole consulting business, Don. Thanks a lot guys.
    Don Retzlaff:
    Some of it's just basic blocking and tackling though, isn't it?
    Nick Hogan:
    Yeah. It's true. It's true.
    Don Retzlaff:
    We have practice stuff.
    Nick Hogan:
    Now obviously guys, I'd be remiss if I failed to tee up some discussion regarding pain points dealing with slot management systems. I will just kind of throw out that acronym, SMS like a big piece of red meat and let you guys tear it.
    Buddy Frank:
    I mentioned that I thought a big source of operator anger or distrust or whatever was participation fees. Right alongside that is your system pain. If you have an Aristocrat system, all of a sudden you don't think those games are as great as they were before you got the original. By the way, it's clearly emerged these days of what the best system is. Have you guys heard?
    Nick Hogan:
    What's that?
    Buddy Frank:
    Clearly today what the best system in the industry? It's overwhelming now.
    Nick Hogan:
    Oh, which one is it?
    Buddy Frank:
    It's whatever system you own.
    Nick Hogan:
    Okay.
    Buddy Frank:
    It's the worst. It's the worst.
    Nick Hogan:
    The worst. Whichever one you own is the worst?
    Buddy Frank:
    Yeah.
    Nick Hogan:
    Yeah. That's pretty much what we...
    Buddy Frank:
    [inaudible].
    Nick Hogan:
    Now, I spent a lot of my career on the system side and the adage against which everyone works is there's not a single happy systems' customer anywhere in the industry.
    Buddy Frank:
    No, nowhere.
    Nick Hogan:
    Yeah. Structurally what I would say about the systems business and really in defensive because some of the people who work in systems, they're some of the toughest, sharpest people in the industry and they've really been through the ringer and just grafters and really have a lot of time for them.
    But structurally, it's such a difficult business because what we know is that in software, what you never want to do is branch software. If you think about softwares life as the trunk of a tree, you want all new functionality, all fixes, et cetera, to be in that main stem. You don't want to branch it because every time you branch it, you create more burden for yourself, maintaining custom applications, custom patches, et cetera, et cetera.
    In most industries, it's not a problem. You bundle these fixes, you bundle new application features, et cetera into the next release. But in gaming, there's this extra complexity in that we have the regulatory certification. Basically, when you're putting that stuff out, it has to get all blessed by regulators in so many different jurisdictions. And then the other thing is that when the operators, when it comes time for them to upgrade, well that's almost never a straightforward process.
    They have their own certification that they have to do, they have to do a lot of integration testing and it's a very demanding undertaking to upgrade. The result is for a given operator, an upgrade can take six to nine months, which puts you in the position as a systems' provider where at any given time, a maximum of one third of your account base is on the latest version of your software. This is so difficult for everybody doing systems, but just to defend those folks a bit, that's a big part of what's going on out there. It's a very, very tough business.
    Buddy Frank:
    When young people ask me how to succeed in this business, if it's on the operator side, I always say get a college degree. I may have to change that with ridiculous amount of money it costs to get that degree now. But I always say start as a change person, because then you'll learn the industry from the ground up.
    On the vendor side, I say, "If you want to be successful, start as a system salesman because you've got to learn every single part of the business." They'll get complaints from auditors, from the cage, from the security guards who have to do drops on the table games to all the different slot things. It's a way to really learn our industry from the ground up. Again, I'm like, "You have a lot of passion." Because those guys have to deal with multiple vendors who are producing new variants of slot machines every day.
    SDS that changes all the time, SaaS, that changes all the time. Regulators that are different in every state. I understand why they have such problems, but it is the source of a lot of complaints. It's funny, it's almost self-serving, the complaints. Operators will make an update to solve problems. Vendors will make updates to solve problems. Operators won't put the update in because the update itself is such a problem. Then they're five or six updates behind and then they do an update and it's a nightmare because they didn't do the updates. You're right. It's right behind participation fees. System complaints are huge and create lots of problems for vendors.
    Don Retzlaff:
    I had back at my property, we were right across the street from corporate, so when system upgrades would come out, they would come to our property first. We would have the beta site and then we'd list all the problems so they would fix all the problems before it came out to all the bigger properties.
    I got to deal with both ends, both on the system updates and things that it broke instead of fixed. There's some of the brightest people in the business, some of the nicest people, these people will work 24 hours a day to make it right. It's still very frustrating, especially when things used to work and they don't now. Or the most frustrating thing is when you have to shut the casino down for longer than you planned and you've got customers waiting to come in and they can't get on their slot machines because the system's still down.
    But these people work tireless hours and one of the biggest things is they just don't have enough teams usually to get all the system updates done across the country. If you want an update now you start scheduling, it's going to be months before they can get out to you because they are that busy doing system updates. It's pretty much a thankless job. But you've got some people out there, some programmers out there that are just basically brilliant.
    Nick Hogan:
    I will have to say a very common site when you go into systems, environments is engineers sleeping at their desks. I'm not kidding about that. People who are at the end of an all-nighter because they were working with somebody in some jurisdiction in Asia or something and they're based in the US, you see it all the time. You guys are killing it.
    Don Retzlaff:
    Absolutely.
    Buddy Frank:
    Unfortunately, if you want to see the enemy, you look in the mirror and it's us on the operator side because early on, the software vendors want to do everything for Don and I. You request a special thing because here at our property, we like the accounting to be in purple and green instead of the normal red and black.
    The vendor in their, what I call looking backwards, stupidity, make that change for you. And there's thousands of customized versions of every program. When you do an update, it's rare that anyone's going to get all those in and then we're all mad.
    Nick Hogan:
    One of the-
    Buddy Frank:
    Today are showing some discipline and saying, "Sorry Don, we can't do it red and green. We'll consider that in our next general update and do it for everybody. But not that." And that complicates their life. They have literally hundreds of different versions of their own version of software out there and it makes their job harder.
    Nick Hogan:
    You also get...
    Buddy Frank:
    We ask for those two things.
    Nick Hogan:
    You also get appendages that are developed by operators. Sometimes what the operator will do is contract the software developer to come in, build some kind of plugin to a system that's completely custom to that property and then one of the things that's holding them back from upgrading and going to the next version is it's going to break whatever ancillary software they've developed. So it really is just every time I get so many times in my career, it's come back up again, "Nick, get back into systems." And I just have to fight with every fiber of my being to resist that temptation to go back into that business because it's, as Don you said, it's thankless and it's brutal.
    Buddy Frank:
    Again, if you're a good IT operator on the property side, what you really want is for them to give you the data dictionary. That way, you can solve a lot of your own problems.
    Don Retzlaff:
    Absolutely.
    Buddy Frank:
    At the same time, you can create a lot of problems, just what you said by going-
    Nick Hogan:
    If you get clever. Yeah.
    Buddy Frank:
    Yeah.
    Nick Hogan:
    Okay. Well guys, let's see. We're over the hour now, so I think we could go on this topic for a long time because we've all been in the front lines on this stuff too many times to count, but it's good discussion and good topics. Buddy, do you have anything coming up here in terms of events or anything that you would like to mention here today?
    Buddy Frank:
    Well, we're getting closer to G2E time, so obviously everybody's attention goes to that because for the vendors, I think their most important client is probably Wall Street. But for us, it's a time to restrengthen relationships.
    If you've been doing what we talked about, the best part is reconnecting with good friends and establishing and reestablishing those great vendor connections. You can't see it here on a podcast, but I'm wearing a shirt. I've got a logo out on my little consulting business called ROI, Return On Investment, and there's no better return on investment in my mind of then, of having good vendor relationships.
    Nick Hogan:
    Yeah, and I think on that note, that's a perfect way to end the podcast, I think. It's a very, very, very good point there, buddy. Okay guys, well thank you so much for your time today and the discussion.
    Same for us. Our next thing is G2E as well. I will be speaking there. I've got a speaking slot. We do not yet have the time and date though, but as soon as we have that, we'll mention it here on Reel Cast and we'll put up on social media and whatnot.
    Okay, gents, thanks again for your time today. I really appreciate it.
    Don Retzlaff:
    Thank you, Buddy.
    Nick Hogan:
    All right, great day guys.
    Don Retzlaff:
    See you next. Bye-bye.
    Buddy Frank:
    Bye.
    `
};

export default S02E07;
