export const slideImages = {
    slide_1: {
        key: "SMS",
        items: [
            {
                src: "/assets/images/product-compatibility/slide_1/logo_aristocrat.svg",
                alt: "Aristocrat Gaming - Analyzed with ReelMetrics Platform",
                height: 52
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_drgt.svg",
                alt: "DRGT Systems - Analyzed with ReelMetrics Platform",
                height: 40
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_igt.svg",
                alt: "IGT (International Game Technology) - Analyzed with ReelMetrics Platform",
                height: 45
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_konami.svg",
                alt: "Konami Gaming - Analyzed with ReelMetrics Platform",
                height: 30
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_playtech.svg",
                alt: "Playtech Gaming - Analyzed with ReelMetrics Platform",
                height: 40
            },
            {
                src: "/assets/images/product-compatibility/slide_1/logo_sci_games.svg",
                alt: "Scientific Games - Analyzed with ReelMetrics Platform",
                height: 45
            }
        ]
    },
    slide_2: {
        key: "EDW",
        items: [
            {
                src: "/assets/images/product-compatibility/slide_2/logo_aws.svg",
                alt: "Amazon Web Services (AWS) - Integrated with ReelMetrics Platform",
                height: 51
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_microsoft.svg",
                alt: "Microsoft Azure - Integrated with ReelMetrics Platform",
                height: 49
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_cloudera.svg",
                alt: "Cloudera Data Platform - Integrated with ReelMetrics Platform",
                height: 32
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_oracle.svg",
                alt: "Oracle Database - Integrated with ReelMetrics Platform",
                height: 31
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_IBM.svg",
                alt: "IBM Cloud - Integrated with ReelMetrics Platform",
                height: 34
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_teradata.svg",
                alt: "Teradata Database - Integrated with ReelMetrics Platform",
                height: 42
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_vertica.svg",
                alt: "Vertica Analytics Platform - Integrated with ReelMetrics Platform",
                height: 40
            },
            {
                src: "/assets/images/product-compatibility/slide_2/logo_mark_logic.svg",
                alt: "MarkLogic Database - Integrated with ReelMetrics Platform",
                height: 50
            }
        ]
    },
    slide_3: {
        key: "BI Suite",
        items: [
            {
                src: "/assets/images/product-compatibility/slide_3/logo_tableau.svg",
                alt: "Tableau Business Intelligence - Integrated with ReelMetrics Platform",
                height: 45
            },
            {
                src: "/assets/images/product-compatibility/slide_3/logo_cognos.svg",
                alt: "IBM Cognos Analytics - Integrated with ReelMetrics Platform",
                height: 37
            },
            {
                src: "/assets/images/product-compatibility/slide_3/logo_bally_bi.svg",
                alt: "Bally Business Intelligence - Integrated with ReelMetrics Platform",
                height: 60
            },
            {
                src: "/assets/images/product-compatibility/slide_3/logo_power_bi.svg",
                alt: "Microsoft Power BI - Integrated with ReelMetrics Platform",
                height: 37
            },
            {
                src: "/assets/images/product-compatibility/slide_3/logo_viz_explorer.svg",
                alt: "VizExplorer Analytics - Integrated with ReelMetrics Platform",
                height: 57
            }
        ]
    }
};
