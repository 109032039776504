import React from "react";
import ReactGA from "react-ga";
import PageMetadata from "../components/PageMetadata";

import Cms from "../components/cms";
import { butterImage } from "../utils/butterHandler";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        ReactGA.pageview(window.location.pathname);
    }

    render() {
        // Contact page structured data
        const contactSchema = {
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Contact ReelMetrics",
            "description": "Contact ReelMetrics for fact-based actionable insights! Improve purchases and conversions, Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop",
            "url": "https://www.reelmetrics.com/contact",
            "mainEntity": {
                "@type": "Organization",
                "name": "ReelMetrics",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Leiden",
                    "addressRegion": "Zuid-Holland",
                    "postalCode": "2311 GJ",
                    "streetAddress": "Rapenburg 65",
                    "addressCountry": "The Netherlands"
                },
                "email": "info@reelmetrics.com",
                "logo": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
                "telephone": "+31 (0) 71 790 1168"
            }
        };

        // Breadcrumb structured data
        const breadcrumbSchema = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://www.reelmetrics.com"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Contact",
                    "item": "https://www.reelmetrics.com/contact"
                }
            ]
        };

        return (
            <>
                <PageMetadata
                    title="Contact us | ReelMetrics"
                    description="Contact ReelMetrics for fact-based actionable insights! Improve purchases and conversions, Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop"
                    canonical="https://www.reelmetrics.com/contact"
                    keywords="contact reelmetrics, slot analytics contact, casino data contact, slot machine insights"
                    
                    // OpenGraph tags
                    ogUrl="https://www.reelmetrics.com/contact"
                    ogType="website" 
                    ogTitle="Contact ReelMetrics | Casino Data Analytics"
                    ogDescription="Contact ReelMetrics for fact-based actionable insights! Improve purchases and conversions, Optimize conf, Drive revenues, Detect hits early, Gain clearer insights, Stay in the loop"
                    ogImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                    
                    // Twitter Card
                    twitterCard="summary"
                    twitterTitle="Contact ReelMetrics"
                    twitterDescription="Contact us for slot machine data analytics solutions"
                    
                    // Structured data
                    structuredData={[
                        contactSchema,
                        breadcrumbSchema
                    ]}
                />

                <div className="box--light">
                    <div className="container">
                        <div className="row img-stretched">
                            <div className="col-sm-6 col-md-offset-1 col-md-5">
                                <div className="box--padded">
                                    <div className="mt-sm-2">
                                        <h1 className="title title--lg title--primary">
                                            <Cms
                                                content={this.props.cms_content}
                                                content_key="contact_splash"
                                                type="title"
                                            />
                                        </h1>
                                        {/* <p className="paragraph mb-xs-4"><Cms content={this.props.cms_content} content_key="contact_splash" type='text'/></p> */}

                                        <div className="paragraph paragraph--emphasized mb-xs-4">
                                            <address className="mb-xs-3 link--primary">
                                                Rapenburg 65 <br />
                                                2311 GJ Leiden <br />
                                                The Netherlands
                                            </address>
                                            <Cms
                                                content={this.props.cms_content}
                                                content_key="contact_phone_numbers"
                                                type="text"
                                            />
                                            <a
                                                className="link--primary"
                                                href="mailto:info@reelmetrics.com"
                                            >
                                                info@reelmetrics.com
                                            </a>
                                        </div>

                                        <p className="paragraph paragraph--smallest">
                                            Trade Register: 62121537 <br />
                                            VAT Number: NL8546.65.687.B.01
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 img-stretched__media">
                                <div
                                    className="media-img media-img--stretch-right"
                                    style={butterImage(
                                        this.props.cms_content,
                                        "contact_page"
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Contact;
