import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import PageMetadata from "../components/PageMetadata";
import butter from "../utils/butterClient";
import Hero from "../components/shared/Hero";
import ServicesBlock from "../components/Services/ServicesBlock";
import Services from "../components/Services/Services";

function ServicesPage({ cmsContent }) {
    ReactGA.pageview(window.location.pathname);

    const [services, setServices] = useState({
        services: [],
        servicesBlock: []
    });

    useEffect(() => {
        butter.content
            .retrieve(["services_page_items", "services_page_items_red_block"])
            .then(response => {
                const { services_page_items, services_page_items_red_block } =
                    response.data.data;
                setServices({
                    services: services_page_items,
                    servicesBlock: services_page_items_red_block
                });
            });
    }, []);

    // Structured data for the Services page
    const servicesSchema = {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "ReelMetrics Casino Slot Data Analytics Services",
        "provider": {
            "@type": "Organization",
            "name": "ReelMetrics",
            "logo": {
                "@type": "ImageObject",
                "url": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ",
                "width": "350",
                "height": "100"
            }
        },
        "description": "Professional slot machine data analytics services providing fact-based, actionable insights to improve purchases, conversions, and detect market trends early.",
        "serviceType": "Slot Machine Data Analytics",
        "areaServed": "Global",
        "serviceOutput": "Slot machine analytics and insights"
    };

    // Breadcrumb structured data
    const breadcrumbSchema = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.reelmetrics.com"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Services",
                "item": "https://www.reelmetrics.com/services"
            }
        ]
    };

    return (
        <>
            <PageMetadata
                title="Casino Slot Data Analytics Services | ReelMetrics"
                description="ReelMetrics offers comprehensive slot machine analytics services providing fact-based, actionable insights to improve purchases, conversions, and optimize your casino floor performance."
                keywords="slot machine data analytics, casino analytics services, slot floor optimization, gaming data services, casino business intelligence"
                canonical="https://www.reelmetrics.com/services"
                
                // OpenGraph tags
                ogUrl="https://www.reelmetrics.com/services"
                ogType="website"
                ogTitle="Casino Slot Data Analytics Services | ReelMetrics"
                ogDescription="ReelMetrics offers comprehensive slot machine analytics services providing fact-based insights to improve purchases, conversions, and optimize your casino floor performance."
                ogImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                
                // Twitter Card tags
                twitterCard="summary_large_image"
                twitterTitle="Casino Slot Data Analytics Services | ReelMetrics"
                twitterDescription="ReelMetrics offers comprehensive slot machine analytics services providing fact-based insights to improve purchases, conversions, and optimize your casino floor performance."
                twitterImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                
                // Performance optimization
                preconnect={["https://cdn.buttercms.com"]}
                
                // Additional meta tags
                meta={[
                    { name: "robots", content: "index, follow" },
                    { name: "language", content: "English" }
                ]}
                
                // Structured data
                structuredData={[
                    servicesSchema,
                    breadcrumbSchema
                ]}
            />
            
            <main className="services" itemScope itemType="https://schema.org/Service">
                <h1 className="sr-only">Casino Slot Data Analytics Services by ReelMetrics</h1>
                
                <Hero
                    cmsContent={cmsContent}
                    contentKey="services_hero"
                    imageSrc="/assets/images/rm_logo_csm.svg"
                    imageAlt="ReelMetrics Casino Services Management Logo"
                />
                <ServicesBlock services={services.servicesBlock} />
                <Services services={services.services} imageHeight="265px" />
            </main>
        </>
    );
}

ServicesPage.propTypes = {
    cmsContent: PropTypes.object.isRequired
};

export default ServicesPage;
