import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactGA from "react-ga";
import PageMetadata from "../components/PageMetadata";

import CardsContainer from "../components/shared/cardsContainer";
import Card from "../components/shared/card";
import Panel from "../components/shared/panel";
import CardsRow from "../components/shared/cardsRow";
import TimeSeriesChart from "../components/shared/TimeSeriesChart";
import PercentileTimeSeriesChart from "../components/shared/PercentileTimeSeriesChart";
import BarChart from "../components/shared/BarChart";
import SingleStatComponent from "../components/shared/SingleStatComponent";
import ConfidenceLegend from "../components/shared/ConfidenceLegend";
import PerformanceProfileTable from "../components/shared/PerformanceProfileTable";
import { measureColors } from "../components/shared/colors";
import PreviewTeaser from "../components/preview_teaser";

export default class GameProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            game_title: "",
            supplier_name: "",
            cost_model: "",
            lifecycle: [],
            stats: [],
            error: false
        };

        this.clickHandler = this.clickHandler.bind(this);

        ReactGA.pageview(window.location.pathname);
    }

    /* eslint-disable */
    UNSAFE_componentWillMount() {
        fetch(`/api/games/${this.props.match.params.uuid}/performance`)
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    performance_profile: data.performance_profile_json,
                    lifecycle: data.lifecyle_analysis_json,
                    stats: data.stats_json,
                    game_title: data.title,
                    cost_model: data.cost_model,
                    supplier_name: data.supplier
                });
            })
            .catch(() => {
                this.setState({ error: true });
            });
    }
    /* eslint-enable */

    handleErrors = response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    };

    // Helper function to safely encode URLs
    getSafeUrl(uuid, title = this.state.game_title) {
        // Base URL with UUID
        const baseUrl = `https://www.reelmetrics.com/games/${uuid}`;
        
        // Always include properly encoded title
        // Replace spaces with hyphens, remove special characters, and convert to lowercase
        const safeTitle = title
            .replace(/[^\w\s-]/g, '') // Remove special chars except spaces and hyphens
            .replace(/\s+/g, '-')     // Replace spaces with hyphens
            .toLowerCase();           // Convert to lowercase
        
        return `${baseUrl}/${safeTitle}`;
    }

    convertData = (data, xAxisKey) => {
        const convertedData = data || [];
        const returnValue = convertedData.map(row => ({
            x: new Date(row.reporting_month_yyyymm),
            y: row[xAxisKey]
        }));
        return returnValue;
    };
    
    convertRangesData = (data, xAxisKey) => {
        const convertRangesData = data || [];
        const returnValue = convertRangesData.map(row => {
            if (row && row.reporting_month_yyyymm && row[`${xAxisKey}_median`] && row[`${xAxisKey}_q1`] && row[`${xAxisKey}_q3`]) {
                return {
                    x: new Date(row.reporting_month_yyyymm),
                    y: row[`${xAxisKey}_median`],
                    y0: row[`${xAxisKey}_q1`],
                    y1: row[`${xAxisKey}_q3`]
                };
            }
            return null;
        }).filter(row => row !== null);
        return returnValue;
    };

    error(message) {
        this.setState({ error: true, game_title: message });
    }

    clickHandler(value) {
        ReactGA.event({
            category: `Card ${value}`,
            action: "Navigation",
            label: this.state.game_title
        });
    }

    // Helper function to truncate descriptions to valid lengths
    // eslint-disable-next-line class-methods-use-this
    truncateDescription(description, maxLength = 160) {
        if (!description) return "";
        if (description.length <= maxLength) return description;
        return `${description.substring(0, maxLength - 3)}...`;
    }

    render() {
        // Corrected error handling: Directly render error message based on state
        if (this.state.error) {
            return (
                <section className="box box--light">
                    <div className="container">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="alert alert-warning">
                                        Oh snap! This preview is not available any longer or there was an issue loading the data.
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            );
        }
        // Display loading message while fetching data (without calling setState in render)
        if (this.state.game_title === "") {
            return (
                <section className="box box--light">
                    <div className="container">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="alert alert-info">
                                        Loading game data...
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            );
        }

        const { lifecycle: data = [], stats = [] } = this.state;
        const { uuid } = this.props.match.params;

        /* const gptwData = this.convertData(data, 'gptw_rpm');
      const gpData = this.convertData(data, 'gp_rpm');
      const twData = this.convertData(data, 'tw_rpm');
      const gameData = this.convertData(data, 'games'); */
        const terminalData = this.convertData(data, "terminals");
        const venueData = this.convertData(data, "venues");
        const marketshareData = this.convertData(data, "marketshare");

        const percentileSeriesGP = [
            {
                label: "Games Played",
                color: measureColors.gpColor,
                data: this.convertRangesData(this.state.lifecycle, "gp")
            }
        ];
        const percentileSeriesTW = [
            {
                label: "Theo Win",
                color: measureColors.twColor,
                data: this.convertRangesData(this.state.lifecycle, "tw")
            }
        ];
        const percentileSeriesGPTW = [
            {
                label: "Theo Win",
                color: measureColors.gptwColor,
                data: this.convertRangesData(this.state.lifecycle, "gptw")
            }
        ];
        const series = [
            {
                label: "Pan Industrial",
                color: measureColors.gptwColor,
                gptwData: this.convertData(data, "gptw_rpm"),
                twData: this.convertData(data, "tw_rpm"),
                gpData: this.convertData(data, "gp_rpm"),
                terminalData: this.convertData(data, "terminals"),
                venueData: this.convertData(data, "venues"),
                gameData: this.convertData(data, "games"),
                marketshareData: this.convertData(data, "marketshare")
            }
        ];

        // Create structured data for the slot machine game analytics
        const baseDescription = `Game performance profile of ${this.state.game_title} by ${this.state.supplier_name}.`;
        const fullDescription = `${baseDescription} From ReelMetrics, the leading source of casino game performance data & analytics for the slot machine industry.`;
        const truncatedFullDescription = this.truncateDescription(fullDescription, 160);
        const structuredDataDescription = this.truncateDescription(`Comprehensive performance analytics and metrics for ${this.state.game_title} by ${this.state.supplier_name}.`, 160);

        const gameStructuredData = {
            "@context": "https://schema.org",
            "@type": "Dataset",
            "name": `Performance Analytics for ${this.state.game_title}`,
            "description": structuredDataDescription,
            "url": this.getSafeUrl(uuid),
            "identifier": uuid,
            "creator": {
                "@type": "Organization",
                "name": "ReelMetrics"
            },
            "publisher": {
                "@type": "Organization",
                "name": "ReelMetrics",
                "url": "https://www.reelmetrics.com"
            },
            "provider": {
                "@type": "Organization",
                "name": "ReelMetrics"
            },
            "license": "https://www.reelmetrics.com/legal",
            "about": {
                "@type": "Game",
                "name": this.state.game_title,
                "manufacturer": {
                    "@type": "Organization",
                    "name": this.state.supplier_name
                },
                "gameLocation": "Casino",
                "genre": "Slot Machine"
            },
            "variableMeasured": [
                "Games Played",
                "Theoretical Win",
                "Market Share",
                "Performance Metrics",
                "Venue Distribution"
            ],
            "includedInDataCatalog": {
                "@type": "DataCatalog",
                "name": "ReelMetrics Casino Game Analytics"
            }
        };

        // Create breadcrumb structured data
        const breadcrumbSchema = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://www.reelmetrics.com"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "ReelHot",
                    "item": "https://www.reelmetrics.com/reelhot"
                },
                {
                    "@type": "ListItem",
                    "position": 3,
                    "name": this.state.game_title,
                    "item": this.getSafeUrl(uuid)
                }
            ]
        };

        return (
            <>
                <PageMetadata
                    title={`${this.state.game_title} by ${this.state.supplier_name} | Slot Performance Data | ReelMetrics`}
                    description={truncatedFullDescription}
                    canonical={this.getSafeUrl(uuid)}
                    
                    // OpenGraph tags
                    ogTitle={`${this.state.game_title} by ${this.state.supplier_name} | ReelMetrics | Casino Game Performance Data`}
                    ogDescription={truncatedFullDescription}
                    ogUrl={this.getSafeUrl(uuid)}
                    ogType="website"
                    
                    // Keywords
                    keywords={`${this.state.game_title}, ${this.state.supplier_name}, slot machine performance, casino analytics, slot data, game profile, ${this.state.cost_model}`}
                    
                    // Twitter Card
                    twitterCard="summary_large_image"
                    twitterTitle={`${this.state.game_title} by ${this.state.supplier_name} | Slot Performance Data`}
                    twitterDescription={this.truncateDescription(`${baseDescription} View performance metrics and analytics.`, 160)}
                    
                    // Structured data
                    structuredData={[
                        gameStructuredData,
                        breadcrumbSchema
                    ]}
                    
                    // Additional meta tags
                    meta={[
                        { property: "og:site_name", content: "ReelMetrics" },
                        { name: "author", content: "ReelMetrics" }
                    ]}
                />

                <main itemScope itemType="https://schema.org/Dataset">
                    <meta itemProp="name" content={`Performance Analytics for ${this.state.game_title}`} />
                    <meta itemProp="description" content={structuredDataDescription} />
                    <meta itemProp="identifier" content={uuid} />
                    <meta itemProp="license" content="https://www.reelmetrics.com/legal" />
                    <meta itemProp="url" content={this.getSafeUrl(uuid)} />
                    
                    <div itemProp="creator" itemScope itemType="https://schema.org/Organization">
                        <meta itemProp="name" content="ReelMetrics" />
                        <meta itemProp="url" content="https://www.reelmetrics.com" />
                    </div>
                    
                    <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
                        <meta itemProp="name" content="ReelMetrics" />
                        <meta itemProp="url" content="https://www.reelmetrics.com" />
                    </div>
                    
                    <div itemProp="about" itemScope itemType="https://schema.org/Game">
                        <meta itemProp="name" content={this.state.game_title} />
                        <div itemProp="manufacturer" itemScope itemType="https://schema.org/Organization">
                            <meta itemProp="name" content={this.state.supplier_name} />
                        </div>
                        <meta itemProp="gameLocation" content="Casino" />
                        <meta itemProp="genre" content="Slot Machine" />
                    </div>
                    
                    <meta itemProp="variableMeasured" content="Games Played" />
                    <meta itemProp="variableMeasured" content="Theoretical Win" />
                    <meta itemProp="variableMeasured" content="Market Share" />
                    <meta itemProp="variableMeasured" content="Performance Metrics" />
                    <meta itemProp="variableMeasured" content="Venue Distribution" />
                    
                    <div itemProp="includedInDataCatalog" itemScope itemType="https://schema.org/DataCatalog">
                        <meta itemProp="name" content="ReelMetrics Casino Game Analytics" />
                    </div>
                    
                    <section className="box box--light" aria-labelledby="game-profile-title">
                        <div className="container">
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        <div className="alert alert-warning">
                                            This is a limited preview of the full
                                            Game Profile page of{" "}
                                            <span>{this.state.game_title} by{" "}
                                            {this.state.supplier_name}</span>.
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="card">
                                    <Col
                                        md={6}
                                        lg={6}
                                        sm={12}
                                        className="game-title-section card-header"
                                    >
                                        <h1 id="game-profile-title" className="game-page-title title" itemProp="name">
                                            {this.state.game_title}
                                        </h1>
                                        <h2 style={{ color: "#686D70" }}>
                                            {this.state.supplier_name} -{" "}
                                            <span>{this.state.cost_model}</span>{" "}
                                        </h2>
                                    </Col>
                                    <Col
                                        md={6}
                                        lg={6}
                                        sm={12}
                                        className="game-stats card-header"
                                    >
                                        <div className="game-stats-section">
                                            {stats.map(itemData => (
                                                <SingleStatComponent
                                                    key={JSON.stringify(itemData)}
                                                    data={itemData}
                                                />
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="card card-locked">
                                    <Col
                                        md={12}
                                        className="game-title-section card-header"
                                    >
                                        <h3
                                            className="game-page-title title"
                                            style={{
                                                float: "left",
                                                margin: "0",
                                                lineHeight: "1"
                                            }}
                                        >
                                            Configuration Details &gt;
                                        </h3>
                                        <span
                                            className="glyphicon glyphicon-lock"
                                            style={{
                                                float: "left",
                                                paddingLeft: "8px",
                                                top: "-1px"
                                            }}
                                            aria-hidden="true"
                                        />
                                    </Col>
                                </Row>
                            </Container>

                            <CardsContainer
                                defaultActive="timeseries_analysis"
                                onClick={this.clickHandler}
                            >
                                <CardsRow>
                                    <Card
                                        title="Internal Overview"
                                        cardId="internal_overview"
                                        locked
                                    >
                                        <img
                                            src="/assets/images/internal_overview.png"
                                            alt={`Internal overview for ${this.state.game_title}`}
                                            className="img-responsive width-100"
                                            loading="lazy"
                                            width="100%"
                                            height="auto"
                                        />
                                    </Card>
                                    <Card
                                        title="Pricing Analysis"
                                        cardId="pricing_analysis"
                                        locked
                                    >
                                        <img
                                            src="/assets/images/pricing.png"
                                            alt={`Pricing analysis for ${this.state.game_title}`}
                                            className="img-responsive width-100"
                                            loading="lazy"
                                            width="100%"
                                            height="auto"
                                        />
                                    </Card>
                                    <Card
                                        title="Time Series Analysis"
                                        cardId="timeseries_analysis"
                                    >
                                        <TimeSeriesChart
                                            series={series}
                                            height={200}
                                        />
                                    </Card>
                                    <Card
                                        title="Performance Profile"
                                        cardId="performance_profile"
                                    >
                                        <PerformanceProfileTable
                                            data={this.state.performance_profile}
                                        />
                                    </Card>
                                    <Panel
                                        title="Premium Feature"
                                        cardId="internal_overview"
                                    >
                                        <PreviewTeaser />
                                    </Panel>
                                    <Panel
                                        title="Premium Feature"
                                        cardId="pricing_analysis"
                                    >
                                        <PreviewTeaser />
                                    </Panel>
                                    <Panel
                                        title="Time Series Analysis"
                                        cardId="timeseries_analysis"
                                    >
                                        <p className="paragraph paragraph--extra-smallest">
                                            The Time Series Analysis shows
                                            performance (Blended Games Played Theo
                                            Win vs Floor Average) over time.
                                        </p>
                                        <TimeSeriesChart
                                            series={series}
                                            height={350}
                                            legend
                                        />
                                        <h3 className="title title--primary">
                                            Terminals (Sample size)
                                        </h3>
                                        <BarChart
                                            data={terminalData}
                                            height={130}
                                            label="Terminals"
                                        />
                                        <h3 className="title title--primary">
                                            Market Share
                                        </h3>
                                        <BarChart
                                            data={marketshareData}
                                            height={130}
                                            label="Market Share"
                                        />
                                        <h3 className="title title--primary">
                                            Venues
                                        </h3>
                                        <BarChart
                                            data={venueData}
                                            height={130}
                                            label="Venues"
                                        />
                                    </Panel>
                                    <Panel
                                        title="Performance Profile"
                                        cardId="performance_profile"
                                    >
                                        <Row>
                                            <Col md={4} xs={12}>
                                                <PerformanceProfileTable
                                                    data={
                                                        this.state
                                                            .performance_profile
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <h3 className="title title--primary">
                                            Games Played & Theo Win Blend Percentile
                                        </h3>
                                        <PercentileTimeSeriesChart
                                            series={percentileSeriesGPTW}
                                            height={200}
                                        />
                                        <ConfidenceLegend
                                            color={measureColors.gptwColor}
                                        />
                                        <h3 className="title title--primary">
                                            Theo Win Percentile
                                        </h3>
                                        <PercentileTimeSeriesChart
                                            series={percentileSeriesTW}
                                            height={200}
                                        />
                                        <ConfidenceLegend
                                            color={measureColors.twColor}
                                        />
                                        <h3 className="title title--primary">
                                            Games Played Percentile
                                        </h3>
                                        <PercentileTimeSeriesChart
                                            series={percentileSeriesGP}
                                            height={200}
                                        />
                                        <ConfidenceLegend
                                            color={measureColors.gpColor}
                                        />
                                    </Panel>
                                </CardsRow>
                                <CardsRow>
                                    <Card
                                        title="Locational Analysis"
                                        cardId="locational_analysis"
                                        locked
                                    >
                                        <img
                                            src="/assets/images/loco.png"
                                            alt={`Locational analysis for ${this.state.game_title}`}
                                            className="img-responsive width-100"
                                            loading="lazy"
                                            width="100%"
                                            height="auto"
                                        />
                                    </Card>
                                    <Card
                                        title="Game Conversions"
                                        cardId="game_conversions"
                                        locked
                                    >
                                        <img
                                            src="/assets/images/conversions.png"
                                            alt={`Game conversions for ${this.state.game_title}`}
                                            loading="lazy"
                                            width="100%"
                                            height="auto"
                                        />
                                    </Card>
                                    <Card
                                        title="Average Bet"
                                        cardId="average_bet"
                                        locked
                                    >
                                        <img
                                            src="/assets/images/average_bet.png"
                                            alt={`Average bet for ${this.state.game_title}`}
                                            className="img-responsive width-100"
                                            loading="lazy"
                                            width="100%"
                                            height="auto"
                                        />
                                    </Card>
                                    <Panel
                                        title="Premium Feature"
                                        cardId="locational_analysis"
                                    >
                                        <PreviewTeaser />
                                    </Panel>
                                    <Panel
                                        title="Premium Feature"
                                        cardId="game_conversions"
                                    >
                                        <PreviewTeaser />
                                    </Panel>
                                    <Panel
                                        title="Premium Feature"
                                        cardId="average_bet"
                                    >
                                        <PreviewTeaser />
                                    </Panel>
                                </CardsRow>
                            </CardsContainer>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}
