import React from "react";
import PropTypes from "prop-types";
import ServicesBlockItem from "./ServicesBlockItem";

function ServicesBlock({ services }) {
    return (
        <section 
            className="box box--primary box-background--slot_symbols services_block"
            aria-labelledby="service-highlights-title"
            itemScope
            itemType="https://schema.org/ItemList"
        >
            <h2 id="service-highlights-title" className="sr-only">Our Key Casino Analytics Services</h2>
            <meta itemProp="name" content="ReelMetrics Key Analytics Services" />
            <meta itemProp="numberOfItems" content={services.length.toString()} />
            
            <div className="container">
                <div className="flex-row">
                    {services.map((service, index) => {
                        const { title, text, image } = service;
                        return (
                            <ServicesBlockItem
                                key={title}
                                title={title}
                                text={text}
                                image={image}
                                position={index + 1}
                            />
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

ServicesBlock.propTypes = {
    services: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired
        })
    ).isRequired
};

export default ServicesBlock;
