import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import PageMetadata from "../components/PageMetadata";
import butter from "../utils/butterClient";
import { butterImage } from "../utils/butterHandler";
import Cms from "../components/cms";

class Vacancies extends React.Component {
    constructor(props) {
        super(props);
        this.state = { jobs: [] };
        ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
        caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });

        butter.content.retrieve(["jobs"]).then(response => {
            this.setState({
                jobs: response.data.data.jobs
            });
        });
    }

    render() {
        // Structured data for careers page
        const careersSchema = {
            "@context": "https://schema.org",
            "@type": "JobPosting",
            "title": "Careers at ReelMetrics",
            "description": "Join the ReelMetrics team! Explore job opportunities in casino data analytics and help shape the future of the gaming industry with data-driven insights.",
            "datePosted": new Date().toISOString().split('T')[0],
            "validThrough": new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString().split('T')[0],
            "employmentType": "FULL_TIME",
            "hiringOrganization": {
                "@type": "Organization",
                "name": "ReelMetrics",
                "sameAs": "https://www.reelmetrics.com",
                "logo": "https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Leiden",
                    "addressRegion": "Zuid-Holland",
                    "postalCode": "2311 GJ",
                    "addressCountry": "The Netherlands"
                }
            }
        };

        // Breadcrumb structured data
        const breadcrumbSchema = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://www.reelmetrics.com"
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Careers",
                    "item": "https://www.reelmetrics.com/careers"
                }
            ]
        };

        return (
            <>
                <PageMetadata
                    title="Careers at ReelMetrics | Casino Data Analytics Jobs"
                    description="Join the ReelMetrics team! Explore job opportunities in casino data analytics and help shape the future of the gaming industry with data-driven insights."
                    canonical="https://www.reelmetrics.com/careers"
                    keywords="casino data jobs, slot analytics careers, data science jobs, gaming industry careers, slot machine data analyst"
                    
                    // OpenGraph tags
                    ogUrl="https://www.reelmetrics.com/careers"
                    ogType="website"
                    ogTitle="Careers at ReelMetrics | Casino Data Analytics Jobs"
                    ogDescription="Join the ReelMetrics team! Explore job opportunities in casino data analytics and help shape the future of the gaming industry."
                    ogImage="https://cdn.buttercms.com/3kLVer3QKi5X1kyv2ulQ"
                    
                    // Twitter Card
                    twitterCard="summary"
                    twitterTitle="Careers at ReelMetrics"
                    twitterDescription="Join our team and help transform the casino gaming industry with data-driven insights."
                    
                    // Structured data
                    structuredData={[
                        careersSchema,
                        breadcrumbSchema
                    ]}
                />

                <section className="box box--light">
                    <div className="container">
                        <div className="mb-xs-3">
                            <div className="col-md-offset-1 col-md-8">
                                <h1 className="title title--lg title--primary">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_splash_1"
                                        type="title"
                                    />
                                </h1>
                                <p className="paragraph">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_splash_1"
                                        type="text"
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-offset-1 col-sm-10">
                                <span className="mb-xs-2 title title--explanation">
                                    We are currently looking for:
                                </span>
                                <table className="list-table">
                                    <tbody>


                                        {this.state.jobs.map(vacancy => (
                                            <tr key={vacancy.title}>
                                                <td>
                                                    <span className="title title--primary list-table__title">
                                                        {vacancy.title}
                                                    </span>
                                                    <span className="list-table__subtitle">
                                                        {
                                                            vacancy.summary
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/job/${vacancy.title}`}
                                                    >
                                                        <span className="btn btn-default btn-lg">
                                                            Learn more
                                                        </span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="box">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-offset-1 col-sm-5">
                                <h2 className="title title--primary title--md">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_band_1_message_1"
                                        type="title"
                                    />
                                </h2>
                                <p className="paragraph paragraph--small">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_band_1_message_1"
                                        type="text"
                                    />
                                </p>
                            </div>
                            <div className="col-sm-offset-1 col-sm-5">
                                <ul className="list-bullet list-bullet--diamonds paragraph paragraph--small">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="vacancies_band_1_message_2"
                                        type="text"
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box box--light">
                    <div className="container">
                        <div className="text-center mb-xs-5">
                            <h1 className="title title--primary title--md">
                                We&apos;re gaming vets, coders <br /> and
                                quants.
                            </h1>
                        </div>

                        <div className="container">
                            <ul className="list-unstyled grid row">
                                <li className=" col-sm-6">
                                    <div
                                        className="grid__item grid__item--rectangle squared"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_splash"
                                        )}
                                    />
                                </li>

                                <li className="col-xs-6 col-sm-3">
                                    <div
                                        className="grid__item grid__item--squared squared"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_2"
                                        )}
                                    />
                                </li>
                                <li className="col-xs-6 col-sm-3">
                                    <div
                                        className="grid__item grid__item--squared squared"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_3"
                                        )}
                                    />
                                </li>

                                <li className="col-sm-6">
                                    <div
                                        className="grid__item grid__item--rectangle squared hidden-xs"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_6"
                                        )}
                                    />
                                </li>

                                <li className="col-sm-6">
                                    <div
                                        className="grid__item grid__item--rectangle squared"
                                        style={butterImage(
                                            this.props.cms_content,
                                            "about_us_5"
                                        )}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Vacancies;
