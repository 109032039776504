import React from "react";
import PropTypes from "prop-types";

import Service from "../service";

function Services({ services, imageHeight }) {
    return (
        <section 
            className="box box--light"
            aria-labelledby="services-title"
            itemScope
            itemType="https://schema.org/ItemList"
        >
            <h2 id="services-title" className="sr-only">Our Casino Analytics Services</h2>
            <meta itemProp="name" content="ReelMetrics Casino Analytics Services" />
            <meta itemProp="numberOfItems" content={services.length.toString()} />
            
            <div className="container container--970">
                {services.map((service, index) => {
                    const last = index + 1 === services.length;

                    const { title, text, image } = service;
                    return (
                        <Service
                            key={title}
                            title={title}
                            text={text}
                            image={image}
                            imageHeight={imageHeight}
                            index={index}
                            last={last}
                            position={index + 1}
                        />
                    );
                })}
            </div>
        </section>
    );
}

Services.propTypes = {
    services: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired
        })
    ).isRequired,
    imageHeight: PropTypes.string
};

Services.defaultProps = {
    imageHeight: ""
};

export default Services;
