import React from "react";

const PlanDescription = props => {
    const basicPlanCard = (
        <article 
            className="card card-plan-description row--flex-column"
            style={{ minHeight: "365px" }}
            itemScope
            itemType="https://schema.org/ServicePlan"
        >
            <meta itemProp="name" content="ReelMetrics Basic Plan" />
            <meta itemProp="category" content="Slot Machine Analytics Platform" />
            <meta itemProp="serviceType" content="Analytics Software" />
            
            <img
                className="img-responsive"
                style={{
                    maxWidth: "200px",
                    marginBottom: "20px"
                }}
                src="/assets/images/rm_logo_basic.svg"
                alt="ReelMetrics Basic Plan - Essential Slot Analytics Platform"
                loading="lazy"
                width="200"
                height="100"
                itemProp="image"
            />
            <div
                className="paragraph"
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
                itemProp="description"
            >
                Data cleansing and standardization with baseline application
                functionality
            </div>
            <div
                className="paragraph"
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
            >
              <span className="text--emphasized text--red">Free</span>, no annual subscription fees
            </div>
            <button
                className="btn btn-block-mobile btn-lg btn-orange mw-270 hidden-xs"
                data-toggle="modal"
                data-target="#contact"
                aria-label="Contact us about ReelMetrics Basic Plan"
            >
                CONTACT US
            </button>
        </article>
    );

    const proPlanCard = (
        <article 
            className="card card-plan-description row--flex-column"
            style={{ minHeight: "365px" }}
            itemScope
            itemType="https://schema.org/ServicePlan"
        >
            <meta itemProp="name" content="ReelMetrics Pro Plan" />
            <meta itemProp="category" content="Slot Machine Analytics Platform" />
            <meta itemProp="serviceType" content="Analytics Software" />
            
            <img
                className="img-responsive"
                style={{
                    maxWidth: "200px",
                    marginBottom: "20px"
                }}
                src="/assets/images/rm_logo_pro.svg"
                alt="ReelMetrics Pro Plan - Advanced Slot Analytics Platform"
                loading="lazy"
                width="200"
                height="100"
                itemProp="image"
            />
            <div
                className="paragraph"
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
                itemProp="description"
            >
                Data cleansing and standardization with full platform
                functionality
            </div>
            <div
                className="paragraph"
                style={{
                    width: "100%",
                    textAlign: "center"
                }}
            >
           Annual subscription fees apply
            </div>
            <button
                className="btn btn-block-mobile btn-lg btn-primary mw-270 hidden-xs"
                data-toggle="modal"
                data-target="#contact"
                aria-label="Contact us about ReelMetrics Pro Plan"
            >
                CONTACT US
            </button>
        </article>
    );

    const listOfAttributes = [
        {
            attribute: "Slot system anonimization script",
            subAttributes: []
        },
        {
            attribute: "Data cleaning & standardization",
            subAttributes: []
        },
        {
            attribute: "Monthly venue reports",
            subAttributes: []
        },
        {
            attribute: "ReelMetrics Recovery",
            subAttributes: []
        },
        {
            attribute: "On Demand Analytics",
            subAttributes: [
                {
                    attribute: "ReelHot Index"
                },
                {
                    attribute: "Reports",
                    inactive: props.isBasic
                },
                {
                    attribute: "Game Profile",
                    inactive: props.isBasic
                },
                {
                    attribute: "Cabinets",
                    inactive: props.isBasic
                },
                {
                    attribute: "Cabinet Profile",
                    inactive: props.isBasic
                }
            ]
        },
        {
            attribute: "Prescriptive Analytics",
            inactive: props.isBasic,
            subAttributes: [
                {
                    attribute: "Advisor Stars",
                    inactive: props.isBasic
                },
                {
                    attribute: "Advisor Dogs",
                    inactive: props.isBasic
                },
                {
                    attribute: "Advisor Anomalies",
                    inactive: props.isBasic
                },
                {
                    attribute: "Star Scout",
                    inactive: props.isBasic
                }
            ]
        },
        {
            attribute: "Utilities",
            subAttributes: [
                {
                    attribute: "Access Identity Management"
                },
                {
                    attribute: "Dual Factor Authentication"
                },
                {
                    attribute: "My tools"
                },
                {
                    attribute: "Automated Venue Reports"
                },
                {
                    attribute: "ReelScan Pro"
                }
            ]
        }
    ];

    const attributeComponent = obj => (
        <div
            className="flex-row"
            style={{
                justifyContent: "left",
                alignItems: "baseline"
            }}
            key={obj.attribute}
            role="listitem"
        >
            <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{
                    color: obj.inactive ? "lightgray" : "green",
                    marginRight: "5px"
                }}
            />
            <p
                className={obj.inactive ? "text-muted" : ""}
                aria-disabled={obj.inactive ? "true" : "false"}
            >
                {obj.attribute}
            </p>
            <div className="flex-break" />
            {obj.subAttributes && obj.subAttributes.length > 0 && (
                <div
                    className="row--flex-column"
                    style={{
                        alignItems: "flex-start",
                        marginLeft: "20px"
                    }}
                    role="list"
                    aria-label={`Features of ${obj.attribute}`}
                >
                    {obj.subAttributes.map(item => attributeComponent(item))}
                </div>
            )}
        </div>
    );

    const planType = props.isBasic ? "Basic" : "Pro";
    
    return (
        <div
            className="col-xs-4 row--flex-column"
            style={{
                flex: "1"
            }}
            itemScope
            itemType="https://schema.org/ServicePlan"
        >
            <meta itemProp="name" content={`ReelMetrics ${planType} Plan`} />
            <meta itemProp="serviceType" content="Slot Machine Analytics Platform" />
            
            {props.isBasic ? basicPlanCard : proPlanCard}
            
            <section 
                className="row--flex-column"
                style={{ alignItems: "flex-start" }}
                role="list"
                aria-label={`ReelMetrics ${planType} Plan Features`}
                itemProp="hasOfferCatalog"
                itemScope 
                itemType="https://schema.org/OfferCatalog"
            >
                <meta itemProp="name" content={`${planType} Plan Features`} />
                {listOfAttributes.map(item => attributeComponent(item))}
            </section>
        </div>
    );
};

export default PlanDescription;
