import React from "react";
import Cms from "./cms";

class ProductInfo extends React.Component {
    render() {
        return (
            <section 
                className={`box ${this.props.className}`}
                aria-labelledby="product-features-title"
                itemScope
                itemType="https://schema.org/ItemList"
            >
                <div className="container">
                    <h2 id="product-features-title" className="sr-only">ReelMetrics Platform Technical Features</h2>
                    
                    <div className="row flex-row">
                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <article className="question-box" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                                <meta itemProp="position" content="1" />
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/product_page_icon_extraction_script.svg"
                                        alt="Data Extraction Scripts for Casino Management Systems"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                        width="120"
                                        height="120"
                                        itemProp="image"
                                    />
                                </figure>
                                <h3 className="question-box__title" itemProp="name">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="product_info_1"
                                        type="title"
                                    />
                                </h3>
                                <div className="question-box__body" itemProp="description">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="product_info_1"
                                        type="text"
                                    />
                                </div>
                            </article>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <article className="question-box" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                                <meta itemProp="position" content="2" />
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/product_page_icon_pristine_data.svg"
                                        alt="Pristine Data Processing for Casino Analytics"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                        width="120"
                                        height="120"
                                        itemProp="image"
                                    />
                                </figure>
                                <h3 className="question-box__title" itemProp="name">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="product_info_2"
                                        type="title"
                                    />
                                </h3>
                                <div className="question-box__body" itemProp="description">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="product_info_2"
                                        type="text"
                                    />
                                </div>
                            </article>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-4 text-center">
                            <article className="question-box" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                                <meta itemProp="position" content="3" />
                                <figure className="question-box__media">
                                    <img
                                        src="assets/images/product_page_icon_benchmarks.svg"
                                        alt="Industry Benchmarks for Slot Machine Performance"
                                        className="img-responsive center-block"
                                        loading="lazy"
                                        width="120"
                                        height="120"
                                        itemProp="image"
                                    />
                                </figure>
                                <h3 className="question-box__title" itemProp="name">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="product_info_3"
                                        type="title"
                                    />
                                </h3>
                                <div className="question-box__body" itemProp="description">
                                    <Cms
                                        content={this.props.cms_content}
                                        content_key="product_info_3"
                                        type="text"
                                    />
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ProductInfo;
