import React from "react";
import ReactGA from "react-ga";
import ReelCastHelmetTemplate from "./reelcastHelmetTemplate";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E03 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname);
    }
    
    // calls API and attaches scriptt to DOM
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);
    }

    // checkes if component should update based on props
    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }
    
    render() {
        return (
            <>
                <ReelCastHelmetTemplate
                    title={this.props.title}
                    subTitle={this.props.subTitle}
                    description={this.props.description}
                    keywords={this.props.keywords}
                    link={this.props.link}
                    episodeNumber={this.props.episodeNumber}
                    seasonNumber={this.props.seasonNumber}
                    datePublished={this.props.datePublished}
                    buzzSproutLink={this.props.buzzSproutLink}
                    topics={this.props.topics}
                    spotifyLink={this.props.spotifyLink}
                    applePodcastsLink={this.props.applePodcastsLink}
                    iHeartRadioLink={this.props.iHeartRadioLink}
                />
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

S03E03.defaultProps = {
        id: "14793211-s03e03-blue-delta-jeans-with-nick-weaver",
        link: "s03e03-blue-delta-jeans-with-nick-weaver",
        title: "Blue Delta Jeans with Nick Weaver",
        subTitle: "Season 3, Episode 3",
        description: "Blue jeans?!? Yes, you read that correctly. In this episode, Nick and Don speak with Nick Weaver, Co-Founder and COO of Blue Delta Jeans. Learn how Nick and his team are using $500 custom-tailored blue jeans to thrill slot players and breathe new life into VIP events and continuity programs. Also in this episode, the EVERI / IGT merger.",
        episodeNumber: 3,
        seasonNumber: 3,
        datePublished: "2024-02-27",
        topics: [
            "The entrepreneurial journey and formation of Blue Delta Jeans",
            "The unique challenges and triumphs in crafting bespoke denim",
            "Innovative partnerships between Blue Delta Jeans and the casino industry",
            "The impact of personalized gifting on casino VIP programs",
            "Expansion and future aspirations of Blue Delta Jeans in the global market",
            "The EVERI / IGT merger and its implications for the gaming sector",
        ],
        keywords: [
            "Blue Delta Jeans",
            "Nick Weaver",
            "Bespoke denim",
            "Casino VIP programs",
            "Entrepreneurship",
            "Personalized gifting",
            "Casino industry partnerships",
            "Custom jeans",
            "Gaming sector innovation",
            "Global expansion"
        ],
        buzzSproutLink:
            "https://www.buzzsprout.com/2057836/14793211-s03e03-blue-delta-jeans-with-nick-weaver",
        spotifyLink:
            "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
        applePodcastsLink:
            "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
        iHeartRadioLink:
            "https://iheart.com/podcast/104029260/",
        cdcLink:
            "https://cdcgaming.com/reelmetrics/s03e03-blue-delta-jeans-with-nick-weaver",
    
    transcript: `Nick:
    Howdy Don, how are things in your neck of the woods today?
    
    Don:
    Gorgeous today, it's going to be 75 degrees and sunny. I think I've got to cut the grass, so it's beautiful here.
    
    Nick:
    Okay, well I'm just fresh out of the dentist chair, so that gives you a idea of my state of mind. Although I have to say we have the world's nicest dentist, such a good guy, and amusingly kind of an interesting tick. He has this penchant for always asking open-ended questions. So you're in the chair and it's like, "So Nick, what do you have planned for the weekend?" [inaudible 00:00:39] Kind of an interesting tick.
    Anyway, so the big news since our last episode, the EVERI IGT merger. So in addition to being the big news item, it's also the topic of several listener questions that we received asking for our thoughts about the deal. So we'll do a two birds with one stone approach today on the news and listener questions. So the deal top-level, here's my understanding of it. So IGT is going to spin off an entity that owns its non-lottery and iGaming businesses. EVERI is then going to purchase 46% of that entity for roughly a hundred million shares of every common stock plus 2.6 billion in cash. Then post-transaction, IGT will own 54% of the combined entity and together with De Agostini, the Italian Publishing Group behind it all, they're going to control nine of the 11 board seats.
    So IGT will use the cash proceeds of the deal, that 2.6 billion to pay down debt, and that cash plus an incremental 1.1 billion in financing will become debt on the combined entities balance sheet. From a management perspective, EVERI's current chairman Mike Rumbolz, will chair the combined entity with IGT's current chief Vince Sadusky serving as the combined entities CEO. And once all the dust is settled, the combined entity will be renamed to International Game Technology Inc with EVERI's name disappearing presumably, and it'll trade on the NYSE under the ticker IGT. So the deal is obviously subject to regulatory approvals, so it'll be a while before we see this all affected.
    And on the synergy front, they project a few things. So first, the combination of the product portfolios is potentially quite interesting. On the one hand we'll have the combined game output of each company in one bucket, and without question, there's some potentially interesting crossover there with the iGaming and system side of IGT with EVERI's fintech portfolio. And then secondly, EVERI is going to tap into that IGT global distribution network, which as we all know is absolutely immense. And then third, there are obviously a bunch of financial metrics that we don't really need to discuss here. So that's the quick and dirty of the deal mechanics. Hopefully I got it roughly right. And now we can address those listener questions regarding our thoughts about the deal and what it means for slot operations.
    So Don, you and I spend a lot of time researching and discussing the economic fundamentals of slot floors and as we've said on too many occasions to count, the abundance and diversity of what we see in slot supply are by no means supported by what we see on the demand side. And as a refresher there, what we often see is around 10% of players generating upwards of 80% of the revenue. And then doing so through a very narrow band of product.
    So as you've said many times you consistently see around 10% of titles on floor, essentially carrying the bulk of the operation. So my bottom line on this, when I look at the economic fundamentals, really two things. One, bravo. I have to say consolidation is definitely warranted and I feel this is a step in the right direction. And two, really I see this as kind of a harbinger of things to come. It wouldn't surprise me again, just looking at those fundamentals to see two or three more of these deals coming down the pike in the next 12 to 24 months or so. So that's really me. How about you, Don? From more of the operational perspective, how do these types of things and how's this one sit with you?
    
    Don:
    It's very interesting. It didn't seem like a natural fit to me, but you're looking at two of the best three in physical reels, although that's maybe 10% of your purchases anymore. On the video front, IGT has been making some strides the last year and a half. Their cabinets are really well. EVERI just introduced a couple new cabinets, but they've been working off the Empire Flex for a while. So on the video side is where I'm most curious to see what's going to happen.
    You've got basically two new cabinets from EVERI, a couple of new cabinets from IGT. When will they obsolete them? When will they introduce new stuff? That's the things I'm going to be most curious about and where they're going to keep their talent at. So they both had really good success there for a while. Obviously IGT was the... They're still the best in video poker. Their physical reels are doing really well. EVERI's physical reels do really well get a great average bet, but they're probably both obviously behind Aristocrat and Light & Wonder on the video side. And you've got a company like AGS has been coming on here recently too, and they've been doing really well on the video side. So where is the EvERI IGT going to slot in? That's going to be my big question.
    
    Nick:
    Yeah, and are you one of those that gets nervous when you see consolidation just in terms of what it means with leverage against suppliers and stuff?
    
    Don:
    Yeah, I really do. The casinos really need competition to keep prices down for one, and you see how expensive these new cabinets are getting and we know that Light & Wonder and Aristocrat have really been dominating the video side for the last two years and we really need some more vendors out there producing stuff. On the good side is AGS is doing well. I actually played an Aruze game this weekend that played really well. So I'm hopeful for that. Incredible technologies. Bluberi. There's so many of these smaller companies that are getting smaller orders that I really would like to see them just produce another 3, 4, 5 hits each and that would just take a lot of pressure off the casinos.
    
    Nick:
    Yeah, gotcha. Well so that's kind of wraps that. Thanks to the listeners for your question. Sorry we didn't hit them all, but they were on the same topic, so we just decided to consolidate there and just hit the watch. So time to set the table for today's guest. Now our listeners have certainly seen the title of today's show and I have no doubt the question is, "Jeans, what in the hell do jeans have to do with slots?"
    Now, although we spend a lot of time discussing the host level segment, those players and their relationships to slot inventory, we haven't really touched upon the other ways that we strive to keep them happy and engaged. And among those methods, the whole VIP gifting scene and continuity programs. Now historically this has been a pretty sleepy topic and the industry's approach has been pretty perfunctory, just kind of cobbling together some stuff, throwing it at players and hoping that they like them. But there's one company which over the past several years has been really breathing new life into the topic and really lighting up these programs across the North American casino industry. So our guest today, he's a Mississippi native. Incidentally Don, our second guest in a row who graduated from Mississippi State. So go Bulldogs I guess- [inaudible 00:08:40]
    
    Nick Weaver:
    Whoa, Ole Miss here, guys.
    
    Don:
    Ole Miss-
    
    Nick:
    I'm sorry.
    
    Don:
    We're going to start a war.
    
    Nick:
    So after a brief stint post-graduation with New York Life, he went on to co-found a custom blue jean company called Blue Delta Jeans. He's since embarked upon a wide array of entrepreneurial ventures ranging from some venture cap to luxury mattresses, construction, and if I have it right, even some stone masonry. And among his many distinctions, he is also personally measured well in excess of 30,000 butts, which is a great line. I'm talking of course about co-founder and COO of Blue Delta Jeans Mr. Nick Weaver. Howdy Nick, how are things in the south today?
    
    Nick Weaver:
    It is a beautiful spring day in Mississippi and that may be the longest intro I've ever got, so thank you for making me sound a little crazy.
    
    Nick:
    What is the butt count now? I think that video is a few years old.
    
    Nick Weaver:
    It is probably closer to the 50,000- [inaudible 00:09:56]
    
    Nick:
    All right. So Nick, I've already mentioned that there's a big casino angle in our chat today, but I know you'd to be a great storyteller and I thought we'd start today with your personal story. So can you walk us through a bit of your background and tell us how it is that you came to co-found a custom blue jean company?
    
    Nick Weaver:
    Yes, sir. So this definitely wasn't on my to do list or a dream of mine was to ever start a clothing manufacturing company. However, being an entrepreneur was always in my blood. Even in college I was painting parking lots and starting smaller companies from carpet cleaning to roofing. So post-graduation, I got with a software startup company and I was over sales and marketing and those are two things that I always wanted to do. The selling side, the business development side, that's really what I was meant to do in life and it doesn't matter if it's mattresses or blue jeans, if you will. So I had a childhood friend, Josh West, he is my co-founder and the CEO of Blue Delta. We are grade school friends. We are very different people, but his last name is West and my last name is Weaver. So he sat behind me in grade school on alphabetical [inaudible 00:11:28].
    So I literally got to sit next to this guy for years and I grew up with him. And me and him are from a very small sleepy town and we were the two guys that left. Josh went to Nashville and I went to New Orleans and LA, but we wanted to go back and we wanted to start a company. So we had both moved back. We were trying to figure out what it was going to be and he called me one day and said, "Hey man, there's 3,000 seamstress in a 30-mile radius." I was like, "Cool, what does that mean?" He said, "There used to be a 501 plant here for decades." He said, "Let's take the sewing talent that nobody else has," because after NAFTA sewing left the US in droves, so there's only two pockets of seamstress left, North Carolina and North Mississippi. Furniture stepped in and kept this sewing talent alive.
    He said, "We have this sewing talent and I can't find a custom jean made in the USA." And I was like, "Man, somebody got to be making custom jeans." Of course we went to the Google machine and we looked it up and really nobody was really doing it. Not only custom, we wanted to make a true bespoke, not just taking an inseam and a button. We are going to draft a patent for every single person where we're going to make a jean. I didn't realize how hard that was. Levi's tried to do this three or four times and failed making bespoke denim is tough. Suits that are bespoke, well that cloth is forgiving and suits don't have cuts. They don't have a boot cut suit, but they don't have a high wasted wool suit. Generally it's, how do you wear your suit? Do you want it tighter or not that tight or this tight?
    That's it. And the fabrics are really forgiving and they're easy to alter. Denim is not. Once you do a bar tacking machine if you took that out, that's going to leave a hole in the denim and you have to start over. So the first year we were in a damn junk yard with no heat, no AC, we had $15,000. Swear, this is all true. I had to interview my first seamstress in my insurance conference room because it had AC and the internet and I didn't want to take her to the junkyard where we were going to start this company. Josh's grandfather had an old welding shop and literally in a junkyard and we had to tar the roof. We bought 22 sewing machines. We plugged them up, which you can't plug up 22, 220 volt sewing machines if you don't rewire the building. We started a fire almost burned the whole damn thing down.
    I mean, just taking licks and neither one of us are independently wealthy. We're literally taking our paychecks and setting them on fire every week. First year did $7,000 and I was like, "Anybody..." Not payers, dollars. The pants weren't that great. We had four fabrics and three of them were blue. We're not fashionistas over here. Second year did $6,000, so we're going the wrong way by the way. Anybody logical would've quit. And my dad definitely told me I should quit many, many times. And then instead of retreating, Josh is a brilliant man and I'm kind of the gas and he's kind of the less slow-down to think. I convinced him into opening up a store. We didn't even have a full-time employee then, so we opened the store because it was chicken and eggs, I can't make inventory. I had to sell it and then I had to make it. And then that was kind of our first hit and we're like, "Okay, this is going to work."
    
    Nick:
    Did you open the storefront in Oxford then, or where was it?
    
    Nick Weaver:
    Yes. Yeah, so we were sewing pants in Tupelo where all the seamstress live. And then the Oxford Square is a very, very good part of Mississippi that has higher end restaurants, higher end stores. It was a good fit, no pun intended.
    
    Don:
    Yeah, gotcha.
    
    Nick Weaver:
    And a nice older man, he gave us a space on the square for under a thousand bucks a month. We were literally bootstrapping it. So I quit my full-time job and I worked that store. So people from all over the country, people started writing about us. People would come and they would quiz me and they knew more about denim than I did. So now I was like, "All right, I got to figure this out." So I am very knowledgeable on denim now because I had a bunch of that came to my store early, but fast-forward and just one step in front of the other.
    Then we got into major league baseball with one [inaudible 00:16:35] pitcher, and then they started talking and now we have over 250 MLB players. We service 29 locker rooms. So we literally get invited to go to spring training, go to the clubhouse. Then ended up with the former CEO of the PGA, ended up being a good client of mine. So we ended up partnering with the PGA and we are the official hand of the Ryder Cup. So no denim company has ever partnered with the PGA. We partnered with Churchill Downs this year on the 150th anniversary. And you have one of the most prestigious sporting events or two of them, the Ryder Cup and the Kentucky Derby and they're partnering with a denim company. And I think that that shows a lot about the brand that we've built and the product because it isn't those jeans you're buying off rack or off Amazon.
    So just kind of put one step in front of the other. And that's kind of how I got into the casino space. It was one of the slot companies that you mentioned earlier, Don. It was a AGS guy and Robert Perry wanted to take care of his big clients in Oklahoma. He's like, "Have you ever heard of this casino called WinStar?" I was like, "No, never heard of it." [inaudible 00:18:07] "I want you to go to Oklahoma. These guys are good clients of mine and I want to take care of them.: So I drove to Oklahoma, I didn't even look it up. He sent me an address, I pull up and this building is bigger than my town.
    
    Nick:
    Oh, yeah.
    
    Don:
    Massive.
    
    Nick:
    I think, what is it? Is it a full mile of casino under roof, something like that. It's some crazy stat.
    
    Nick Weaver:
    Yeah. And if you're going to the Mexican restaurant in there, it's a half a mile walk from your hotel. Seriously. I'm like, man. So went there and met with those guys and they said, "Hey, why don't you come do a VIP event for us? Have you ever done that?" I was like, "I don't know what a VIP event is." He said, "Well, we're going to buy a bunch of jeans and we're going to give them away to people." I was like, "Sure." So we bought different stations. We have over 50 fabrics, we have 18 thread colors and we take 16 measurements. We draft a pattern for every client. So we brought about 17 stylists with us and we really didn't know what to expect. We did over 300 people the first night because it was a great response. And then halfway through the event, their GM came up and said, "Hey, do you want to do this three more times that they share?" And I was like, "Hell yeah, I do."
    And then that's when I realized, I was like, we can make these marketing guys in the casinos job easier. So after the event I called up with the GM, I said, "Hey, really appreciate the business, but why is this is a success for you guys?" He said, "Easy. It's gender friendly, it's age friendly and size friendly." He said, "If I'm given a partial way, half the VIPs aren't coming, and then you may not wear that brand. That may be a brand for a 50 plus year old woman or that may be a brand for a 25-year-old or younger." So they're doing different events and they're having to come up with new creative ways to think about it and they're trying to appease this group and that group. But custom pants, it doesn't matter if you're seven-foot tall or seven foot wide. We got you. One leg, no legs. We've literally done it all in the casino space.
    
    Nick:
    Well, and that to me really, if I just look at the demographic profile of these VIP players, so you're typically in terms of age... Well, it's solid annual income, typically solid net worth. They like to spend money and most of the stuff that gets cobbled together in these types of programs, the people already have this stuff. So it's like say, "Hey, we're going to give away some iPads." "Well, I already have four of them," or an Apple Watch. "Yeah, I wear a Rolex." Or just kind of the whole idea of what do you get for somebody who has everything. And then if you look at the profiles, you're typically talking 50 years old or up. It's about 55% women, 45% men. And we know that with men over 50, your body is a completely weird shape. You're not fitting into the 25-year-old jeans. And if I ask any woman I know to list their top 10 least favorite activities on that list somewhere would be trying on off the rack blue jeans.
    
    Nick Weaver:
    No, you're exactly right. We are definitely anti going to a store and locking yourself in a closet and trying on a bunch of pants that don't fit right. That's something that we have definitely changed the game on. And fast-forward, that first event with the Chickasaws was in 2019. Since then, even during Covid, we've done 25 events with Chickasaw now.
    
    Don:
    Wow.
    
    Nick:
    Just with Chickasaw, 25 with Chickasaw?
    
    Nick Weaver:
    We've done probably over 40 to 50 casinos in the US because it's something that you maybe or maybe not buy for yourself, but you will go take it as a gift. And it's turned into a thing because people are going to see the $500 price point and they're going to be, "Okay, what makes this different?" "Everything. We are going to draft you a pattern and then you get to design the pant from thread to fabric to the style." Everybody wants a pant to fit the way they want it to. We also guarantee fit. So asking somebody how something fits, it's like asking how something tastes. You may like it spicy and you may like more salt, but the same thing with a fit. You may want your jeans to fit tighter than Nick or y'all are different guys. Y'all live on different continents. There are different styles.
    So that's where I think us starting from the old way of making clothes really a true bespoke pant. Nobody's doing it like this in the world and not to the volume that we're doing. Fast-forward, we aren't in the junkyard anymore. We have 75 employees. We're making over 200 jeans a day. It's AC and heat, indoor bathroom now. So we're good.
    
    Don:
    All right, there you go.
    
    Nick Weaver:
    But these people are... And we don't really advertise. It's really taken off in the casino space and just the corporate gifting in general. We get invited to high-end parties and net worth because what do you give somebody that has everything. Like you said Nick, and you can't order this off Amazon, you can't go down to the local store and get it. This is a true unique gifting experience and we have curated it that way. All our guys are going to have tape measured with sports jackets. All our females are going to be dressed very classy as well. And we take you through the process of getting measured. We give you a little story time and then we're going to help you design the jean. All that happens at the casino now. So it's been a really big part of my business. Definitely wasn't on the roadmap, start a custom jeans company, sell to casinos. That was never- [inaudible 00:24:54]
    
    Don:
    Do you still have your storefront in Oxford or did you close that?
    
    Nick Weaver:
    No, no, no. It's still there. It's more like a trophy case now. But if I show you the people that we've had in there from Morgan Wallen to Morgan Freeman. We have these incredible clients that show up and I'm really good at drinking with people as I stated previously. So it's a great place to snag a drink and drink [inaudible 00:25:25] pants.
    
    Don:
    The testimonials on your website are just eye-opening, looking at the names and how grateful they are and how glowing they are about your product. I didn't know a whole lot about your product. Nick, our director of sales for North America wears a pair of your pants and Nick talked about them in the past, but when I was looking at your website, seeing names like Dak Prescott, Jay Bilas, and I mean these people are just glowing about your stuff. And obviously if you're working with Major League Baseball and a Ryder Cup, these are people who have a little bit of money and want quality. So if you're doing well with them, you must have a heck of a product.
    
    Nick Weaver:
    Yeah, it's kind of surreal on certain things and we take care of folks. We may not get things exactly right on the first try, but we have over a 90% first fit rate and we guarantee fit. We just take care of folks. It's this crazy thing in marketing, if you'll be nice to people and treat them right, then they'll tell somebody about it. That's it. People always ask, "Who's doing your marketing?" I'm like, "Me." "Well, what do you do?" "I'm nice to folks." And we've been very good, people take us with them. Like Nick invited me on this podcast because we sat down in Reno and probably had an hour long conversation and we hit it off and he's been very good to me and my company. So it's just these little personal relationships have really changed our company.
    
    Don:
    Well, that's wonderful. Well, obviously being in the casino business for over 30 years, I've seen a lot of VIP events. Everything from cruise lines to like Nick said, things like Apple iPad giveaways and iWatches and all that type of good stuff. You said you bring in a bunch of tailors, people to measure I guess the suits and all that. How long does a fitting take and then how long does it take them to get their product? Do they come back to the casino? Do you mail it to them, mail it back to the casino?
    
    Nick Weaver:
    Great question. Yeah, so we can measure you in under two minutes at the event because we also definitely want to make sure that we're not taking away from their gambling time either. But you want them to get the experience too. So there is a little balance in there, but we can measure you in a two minutes with one tailor writing and one person measuring. And then we have a stylist sitting in front of the fabrics. That's one station, if you will. And two people, one could be getting measured and one could be styling at the same time. When we do the Chickasaws, we will bring six stations. We've measured up to 800 casino guests in one day. We are really good at processes. So we use our process mentality from manufacturing and we overlay it on top because if I can make it easy on the casino guys, like, "Hey, they showed me who we build so that it fits in their matrix."
    And even if Jerry got the jean but he wanted to give it to Cindy, we document that. I mean, just small things so they know where their money is going, be good on running a smooth event, take care of their folks. Also, if you had a guy and orders a red jean and we mail him a red jean and he called and said, "Hey, I ordered a blue jean." I'm going to make them that blue jean and I'm never going to tell the casino about it just because they're going to brag to their host and to their people and say, "Oh, those people from Mississippi were so nice. They did exactly..." And it's, "Hey," we want to make it so easy for them to say yes, and then we want them to invite us back. So I have a ton of repeat business.
    JACK Entertainment in Ohio. I got a couple of quotes, but Chad Barnhill, the COO said that it's their highest attendance rate, it's their biggest profitable event, and they do it twice a year and they are always... It's like clockwork. Their people are waiting on that blue jean event now because crazy thing, once you get pants that fit right, well then you want another one. So it's not like a purse or that iPad, it's something that people do it over and over. Now we have added some other products. We're doing a custom belt, which is about half the price of the pant. We can put the casino logo on the belt, they pick out their hardware. There's still a little measuring process. And we started painting custom Nike tennis shoes. I've never wore a pair of Nike tennis shoes. I know the accent probably threw you off on that one.
    I'm a cowboy boot wearing guy. But these marketing guys in the casino space, they get paid to be creative and find experiences. And I feel like there's a ton of people that have products, but they don't know how to do the experience. And then there's experienced people that don't have products. Well, we're both. So we're trying to keep it fresh and we're doing a lot of these events with the Seminoles down in Florida too and all over, is that we want to come up with cool concepts and make it easy for your team to say, "All right, we're going to call Blue Delta because [inaudible 00:31:04] got this price point. We can do belts, tennis shoes, pants, whatever you need." And building out these custom experiences. So we're trying to keep it real every year, if you will.
    
    Nick:
    Now on these events, so the casino, obviously they're bringing them in, so they get the trip to come in and do the fitting. And then do they come in again to the casino to pick up the pants or are those you ship them to them?
    
    Nick Weaver:
    Yeah, so we do it two ways. Either we ship them directly to the individuals, but we're also doing pick up events where we're like, "We'll fly the 300 jeans to your casino and we'll have a tailor there so people get two trips out of one deal."
    
    Don:
    Perfect.
    
    Nick:
    Yeah, great idea.
    
    Nick Weaver:
    It normally takes two to three weeks on the turnaround time, but if you said-
    
    Nick:
    [inaudible 00:31:54]
    
    Nick Weaver:
    "Hey, we want to give it a month." "Okay, cool." So we're pretty strategic and it's my factory, so we can make the pants, hold the pants. It's not like it's some big company. We are definitely mindful of we're going to have everybody's done at one time too because we're keeping track of this.
    
    Nick:
    So Nick, overall in terms of just let's say overall numbers and stuff, so can you share with us the number of these things you do per year, number of clients, this type of stuff, whatever metrics you have on that.
    
    Nick Weaver:
    Last year I made 50,000 pants.
    
    Don:
    Wow.
    
    Nick Weaver:
    A long way from $7,000 I'll say that.
    
    Nick:
    Yeah, for sure.
    
    Nick Weaver:
    But probably in our system we have 12,000 casino VIPs, but like individuals. Now, some of them... Especially some of them may have seven to nine payers too. So it's not just one pant. I mean unique individuals. In the casino space, probably about 12,000.
    
    Don:
    So the casinos are a decent chunk of your business?
    
    Nick Weaver:
    Yes, yes. Oh, yeah. And we do a ton of golf courses too. So golf course gifting, like the member guests gifting. We do a ton of alcohol, Southern Glazers Wine and Spirit is a ton... Gifting is now over 50% of my business.
    
    Don:
    Fantastic.
    
    Nick:
    And you mentioned that the casino say it's their best attended events, and so they give you any detailed metrics on what the response rates are on these? I'd imagine they're very, very high. At least everything I've heard is suggesting that.
    
    Nick Weaver:
    Yeah, so one person says that they get normally get a 10% attendance rate. Ours are over 30% because people, are they going to buy themselves a Blue Delta jean? Maybe not. Maybe they're not going to buy a $500 jean, but will they show up and take one for free? Hell yes, they will. And it's a gift that people start asking, "Okay, what is this?" It's something different. They don't have it. And they never knew they wanted it until you get it. So it was definitely a surprise. Like I said, this was never on my roadmap. I'm going to make custom jeans for casinos, but it's really turned into be a really good business.
    
    Don:
    That's really neat because obviously, like I said, I've been in the casino industry since 1991 and I've got storage racks downstairs of gifts from various casinos, everything from kitchen utensils to appliances to t-shirts and knickknacks. But I can't think of any of them that I've got used in my house right now. I've set up my nieces and nephews first kitchens with all the supplies I've gotten from the casinos. But something like this, obviously it'd be something that you want to keep for yourself. It's not that every day you run out and buy a pair of high-end jeans that you're going to live in for a while. And looking at your website, and how long these jeans last, and the type of denim that you use, and how to take care of it properly and all that. It was really rather interesting to look at and to read the website. Very well... The website's great. If you haven't looked at it yet, it's really a neat website to look at.
    
    Nick Weaver:
    Thank you. Thank you. Yeah, and to compare it to cooking, when you cook the way we cook, then you're going to use good meat because we are literally drafting you personal pattern. So we're using the best fabrics in the world. It really is, if you wanted to not cut a single corner and make the best jean that you could, that's what we did. And people will always buy the best. People will buy the beach house, people will buy the fast car. People are willing to pay for quality. And hey, in 1999, if you would've told me I'd spend $400 on a cooler, I'd say, you're crazy. I got seven of those because they last me.
    
    Don:
    Me too.
    
    Nick Weaver:
    And they're better. Yeah. Yeah, they're better.
    
    Don:
    Yeah. There's nothing wrong with quality. You buy quality, you'll have it for a long time. And I'm in a pair of jeans right now that I've worn holes in and just a year or so get into them and it doesn't take long.
    
    Nick Weaver:
    Yeah. Well, we need to change those out, Don. All right. And we do have a virtual tailor as well. It's a AI platform. If you answer those seven questions. Now they're the seven questions that you should never ask a woman. But if you are honest with the AI, that thing will predict your body measurements and it is super, super, super accurate. I would not have it on my website if it did not work.
    
    Don:
    [inaudible 00:37:12] waste the denim. And I know that stuff's not cheap to secure.
    
    Nick:
    Now, Nick, with most of these... So you mentioned... So with the casinos, so they start with you and then you see that you end up doing then multiple events for them every single year. So let's say Chickasaw, you do a bunch of fittings for those guys over a calendar year?
    
    Nick Weaver:
    So we do once a quarter at their two main properties probably every year because-
    
    Nick:
    Oh, man.
    
    Nick Weaver:
    ... we have over 40 fabrics. So we have denim, we have a chino and we have a performance, and it's kind of like the kitchenware where people collect it. I got the big pot, now I want the skillet. It's the same thing on a different level, but once people get, "Oh, I got me a black pair, but next time I'm going to get the blue pair." And people come to every one of those events and they're hugging us, they're posting on social media. I mean, it's a really fun event. It really is.
    
    Don:
    That's great. Have you branched out beyond the borders of the US yet?
    
    Nick Weaver:
    Well, I've been trying to get my friend Nick over here to [inaudible 00:38:28]. That is something that is a goal of my 2024 is to cross the pond. So I haven't done it yet, but I'm coming to Amsterdam in a couple weeks to do a speech and I'm going to meet with some people and I hope it works.
    
    Nick:
    Yeah, you're going to be over here for the Casino operations Summit in late April, which will be great. And I think it's another... For sure, you get a lot of operators from around Europe obviously coming in for this. So it's a great thing to do there. And I think you're going on a bit of a trip then after that, running around Europe and meeting with some folks.
    
    Nick Weaver:
    Yep, that's it. That's it. So we're finally crossing the pond in year 12, I like it.
    
    Don:
    Do you have the capacity to continue to increase the output every year?
    
    Nick Weaver:
    So we've scaled our sales and our manufacturing at the exact same time for 12 years, and I'm a better manufacturer. If you build houses, if you're not better after 50,000, well then you're probably just stupid. I feel like we have created processes and we're going faster, but we're making a better product. Now a jean gets inspected 14 times even before it makes it to the inspection line. So that's one thing as we grow, we wanted to make sure that the quality didn't go down. So we're actually making a better pant today. We're just making more of them.
    
    Don:
    I've always been fascinated by people with skill, whether it's professional athletes or golfers or chefs or even people like seamstresses, some of these designers of clothes and these people have extraordinary skills, and I'm sure you see that when you walk to the factory. It's just amazing to watch people with high school level work.
    
    Nick Weaver:
    And we used to hire the old 501 seamstress and they had to be able to sew a jean from start to finish. Now I have it broken in 14 steps. If you show up in my factory now and you've never sewed, I can teach you. I can train you. Somewhere, somebody had to learn to sew, and that's what I'm very happy to be in the US and we're creating a trade and we're teaching them a skill that they can have for a long time. And robots and AI can't take that away from them. It's been really fun to keep this sewing talent alive and we pay a little bit better. We take care of our folks internally too. We don't have a big turnover rate at all. So I still have my first hire is still working for me and her daughter and her granddaughter work for me as well. So we got three generations in there.
    
    Nick:
    Wow.
    
    Don:
    That's awesome.
    
    Nick:
    So now you mentioned Nick, there was a 501 factory. So had that been shuttered for a long time before you guys came around then or what was- [inaudible 00:41:31]
    
    Nick Weaver:
    Yeah. So that moved to Mexico after NAFTA.
    
    Nick:
    I see.
    
    Nick Weaver:
    Yeah, so most of the sew shops in the US all packed up shop and moved to Mexico. Now they'd never heard the word siesta, so that got them. And then unfortunately, a lot of the fabric mills chased the sewing machines because it doesn't make any sense to make fabric here, and then send it to Asia to get sewn, and then send it back. So we lost a lot more than just the sewers. We lost a lot of fabric mills as well.
    
    Nick:
    Yeah, I would assume that. Well, of course the textile being there with the cotton industry being so massive down there, of course that makes all the sense in the world. That's where all that legacy for sure. So Nick, any upcoming casino events that you guys have on the schedule here? You guys doing IGA over in California or?
    
    Nick Weaver:
    Yes. So we will have our booths set up at IGA. So if there is somebody out there that would like to learn more, we can throw a tape around you and give you the old denim bribe so we can get an event set up.
    
    Don:
    I love that.
    
    Nick:
    All right, great. And then we mentioned, of course, the Casino Operations Summit in Amsterdam in late April. Anything else on the agenda for coming months here?
    
    Nick Weaver:
    We're all over the country almost every weekend. We are in and out of casinos, so you'll probably... If you are in the gaming space, if you get that email custom jean event, come see us, please.
    
    Nick:
    Very good. Don, do you have any more questions for Nick today?
    
    Don:
    No, no. It's very educational and like I said, I've enjoyed learning about it and reading the websites and everybody I talk to just raves about your product.
    
    Nick:
    Yeah, I've just heard nothing but positive just about lighting up these programs and really getting those players excited. So that's the ticket right there. So that's what everybody's looking for.
    
    Don:
    That's really neat. And Nick's really right about so many events that the casino marketing people put on are limited in scope, because not everybody wants a new kitchen appliance or a new suitcase or bed linens or whatever it is. Because everyone's got that stuff right, and nobody has a really nice pair of jeans that they paid a couple hundred dollars for. It's just not something that's-
    
    Nick:
    I saw one that sticks in my mind that I'll never forget was a VIP gifting thing, which was perfume. And I thought, "Man, that is the..." I don't know if you could come up with a worse idea for something that's so incredibly personal, buying that in bulk and trying to send it out. But yeah, as I said, it's gotten a bit lazy over the years and it's just really nice to see something super creative and energetic and unique coming into this.
    
    Don:
    It is. There's a lot of tier programs now that give out really, really nice gifts once you hit that tier. The once of the year type things, whether it's a lease of a car or it's a $5,000 Apple package, stuff like that. Something like this works really, really well in that it's just very unique and very personal. It's something you're going to, every time you put on that pair of jeans, you're going to think about where you got that pair of jeans from and how cool is that?
    
    Nick:
    Yeah, exactly- [Inaudible 00:45:19]
    
    Don:
    I've got a jacket I'm sitting here looking at right now from one of the casinos that gave me to me and it's got their logo on it. Every time I put that on, I think of that casino. And it'd be the same thing with the jeans. Those would be a pair of jeans you live in and every time those people that got it from the Chickasaw Nation put that jeans on, they think of them and how cool that was. That's really special.
    
    Nick:
    Yeah, indeed.
    
    Nick Weaver:
    I got to put y'all on payroll. [inaudible 00:45:47] good Lord. Yeah, please send me the recordings so I can share this with everybody.
    
    Nick:
    We only get behind stuff that we know is effective and works.
    
    Don:
    We like rooting for people and we like sharing their stories and that's what we get out of it. We get the joy out of that, whether we're talking to people like Michael Carruthers or Buddy Frank and all those types of people. We enjoy it because you can see the joy that you have in this product and how proud you are of it, and it's a win-win for everybody. That's pretty cool. Plus you're in SEC nation, which is right to my heart. And Mizzou was a newcomer to the SEC for a decade or so now, but have a soft spot in my heart for all the SEC schools. I've been treated so well by the SEC fans when I travel to the other sites and whether it was Auburn or Alabama or Ole Miss, LSU, the people that have been so kind to us. So I have a soft spot for all things SEC.
    
    Nick Weaver:
    Well, you'll have to come tailgate with me, because- [inaudible 00:46:56]
    
    Don:
    I'd love to do that-
    
    Nick Weaver:
    ... a ride in the Grove for sure.
    
    Don:
    That'd be really neat to see a game at Ole Miss. That's a bucket list item for me.
    
    Nick Weaver:
    Come on down, brother. Thank y'all for having me-
    
    Nick:
    Yeah, thanks so much for joining us today, Nick, and we'll see you over here in a couple of weeks, man.
    
    Nick Weaver:
    Absolutely. All right, guys- [inaudible 00:47:17]
    
    Nick:
    Okay. Thanks, gents. See you.
    
    Nick Weaver:
    See ya.
    
    `,


};

export default S03E03;
