import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import butter from "../../../utils/butterClient";

import Select from "../../shared/Select/Select";
import Carousel from "./Carousel";

const sortIndexes = indexes =>
    indexes
        .map(index => ({ ...index, date: new Date(index.date) }))
        .sort((a, b) => b.date - a.date);

const filterIndexesByYear = (indexes, year) =>
    indexes.filter(index => index.date.getFullYear() === year);

const getSelectOptions = indexes => {
    if (!indexes || indexes.length === 0) {
        return [];
    }

    const uniqueDates = [
        ...new Set(sortIndexes(indexes).map(index => index.date.getFullYear()))
    ];

    return uniqueDates.map(date => ({ value: date, label: date }));
};

function ReelHotIndexes({ contentField }) {
    const [indexes, setIndexes] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        if (!indexes) {
            butter.content.retrieve([contentField]).then(response => {
                const reelHotIndexes = response.data.data[contentField];

                const sortedIndexes = sortIndexes(reelHotIndexes);
                const defaultYear = sortedIndexes[0].date.getFullYear();

                setIndexes(sortedIndexes);
                setSelectedYear(defaultYear);
                setSelectOptions(getSelectOptions(reelHotIndexes));
            });
        }
    }, [indexes, contentField]);

    const handleSelectChange = selected => {
        setSelectedYear(selected.value);
    };

    return (
        <section 
            className="box box--light"
            aria-labelledby="reelhot-indexes-heading"
            itemScope
            itemType="https://schema.org/DataCatalog"
        >
            <meta itemProp="name" content="ReelHot Performance Indexes Catalog" />
            <meta itemProp="description" content="Historical collection of ReelHot slot machine performance indexes organized by year" />
            <meta itemProp="url" content="https://www.reelmetrics.com/reelhot" />
            
            <div className="outside-wrapper container-fluid flex-row">
                <div className="col-xs-12 col-sm-11 reelhot-indexes">
                    <div className="reelhot-indexes-title">
                        <h2 id="reelhot-indexes-heading" className="title--banner text--red" itemProp="headline">
                            ReelHot Indexes
                        </h2>

                        <Select
                            className="reelhot-indexes-select"
                            options={selectOptions}
                            defaultValue={selectOptions[0]}
                            onChange={handleSelectChange}
                            aria-label={`Filter indexes by year: ${selectedYear || ''}`}
                        />
                    </div>

                    <div 
                        itemProp="dataset" 
                        itemScope 
                        itemType="https://schema.org/Dataset"
                    >
                        <meta itemProp="name" content={`ReelHot Performance Indexes for ${selectedYear}`} />
                        <meta itemProp="description" content={`Monthly performance analysis of top-performing slot machine games in the casino industry for ${selectedYear}. Includes detailed metrics, market trends, and industry insights.`} />
                        <meta itemProp="temporalCoverage" content={`${selectedYear}`} />
                        <meta itemProp="spatialCoverage" content="Global Casino Industry" />
                        <meta itemProp="variableMeasured" content="Games Played" />
                        <meta itemProp="variableMeasured" content="Theoretical Win" />
                        <meta itemProp="variableMeasured" content="Market Share" />
                        <meta itemProp="variableMeasured" content="Performance Metrics" />
                        <meta itemProp="variableMeasured" content="Venue Distribution" />
                        
                        <Carousel
                            items={
                                indexes &&
                                filterIndexesByYear(indexes, selectedYear)
                            }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

ReelHotIndexes.propTypes = {
    contentField: PropTypes.string.isRequired
};

export default ReelHotIndexes;
