import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function Details({ imageDetails }) {
    const { title, title_slug, uuid, image } = imageDetails;
    const detailsId = `game-details-${uuid || 'modal'}`;

    return (
        <article 
            className="reelhot-details"
            itemScope
            itemType="https://schema.org/Product"
            aria-labelledby={detailsId}
        >
            <h3 id={detailsId} className="sr-only">{title || 'Game Details'}</h3>
            <meta itemProp="name" content={title} />
            {uuid && <meta itemProp="identifier" content={uuid} />}
            {title_slug && <meta itemProp="productID" content={title_slug} />}
            
            <figure itemProp="image">
                <img 
                    src={image} 
                    alt={`${title} - Detailed slot machine performance view`} 
                    loading="lazy"
                />
            </figure>
            
            <div className="button-wrapper">
                <Link 
                    to={`/games/${uuid}/${title_slug}`}
                    itemProp="url"
                    aria-label={`View detailed performance information for ${title}`}
                >
                    <Button variant="primary">More information</Button>
                </Link>
            </div>
        </article>
    );
}

Details.propTypes = {
    imageDetails: PropTypes.shape({
        title: PropTypes.string,
        title_slug: PropTypes.string,
        uuid: PropTypes.string,
        image: PropTypes.string
    })
};

Details.defaultProps = {
    imageDetails: {
        title: "",
        title_slug: "",
        uuid: "",
        image: ""
    }
};

export default Details;
